import React, { useEffect, useState } from "react";

import { Center, Container, Loader, Stack, Table, Text } from "@mantine/core";

import {
  DailyReport,
  getTeamProgress,
  TeamDailyReport,
  TeamDailyReportResponse,
} from "admin/api/labeling/progressApi";

export const AdminCreatorLabelingTeamReportDailyTableRow = ({
  userKey,
  agreement,
  num_labeled,
}: {
  userKey: string;
  agreement: number;
  num_labeled: number;
}) => {
  return (
    <Table.Tr>
      <Table.Td>{userKey}</Table.Td>
      <Table.Td>{num_labeled}</Table.Td>
      <Table.Td>{Math.round((agreement / num_labeled) * 100)}%</Table.Td>
    </Table.Tr>
  );
};

export const AdminCreatorLabelingTeamReportDailyTable = ({
  date,
  report,
}: {
  date: string;
  report: TeamDailyReport;
}) => {
  // sort report by key
  const [sortedReport, setSortedReport] = useState<[string, DailyReport][]>(null);
  useEffect(() => {
    setSortedReport(Object.entries(report).sort((a, b) => a[0].localeCompare(b[0])));
  }, [report]);

  return (
    <Stack>
      <Text>{date}</Text>
      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Ops Manager</Table.Th>
            <Table.Th>Creators Labeled</Table.Th>
            <Table.Th>Accuracy</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {sortedReport &&
            Object.entries(sortedReport).map(([index, [userKey, userReport]]) => (
              <AdminCreatorLabelingTeamReportDailyTableRow
                key={userKey}
                userKey={userKey}
                agreement={userReport.agreement}
                num_labeled={userReport.num_labeled}
              />
            ))}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};

export const AdminCreatorLabelingTeamReport = () => {
  const [loading, setLoading] = useState(false);
  const [rawProgress, setRawProgress] = useState<TeamDailyReportResponse>(null);
  const [report, setReport] = useState<[string, TeamDailyReport][]>(null);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getTeamProgress(setRawProgress, abortController)
      .then((resp) => {
        if (resp) {
          // Convert to array of [date, TeamDailyReport] sorted by date descending
          const sortedDates = Object.keys(resp).sort(
            (a, b) => new Date(b).getTime() - new Date(a).getTime(),
          );
          const sortedReport = sortedDates.map(
            (date) => [date, resp[date]] as [string, TeamDailyReport],
          );
          setReport(sortedReport);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    return () => abortController.abort();
  }, []);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (!report) {
    return <Center>No data available. Please flag with the team in #ops-bugs-and-issues.</Center>;
  }

  return (
    <Stack p="md" w="100%">
      {report?.length > 0 &&
        report.map(([date, rep]) => (
          <AdminCreatorLabelingTeamReportDailyTable key={date} date={date} report={rep} />
        ))}
    </Stack>
  );
};

export default AdminCreatorLabelingTeamReport;
