import React, { useState } from "react";

import { Box, Divider, Group } from "@mantine/core";

import { ContentStatus, ContentType, INTEGRATED_FORMATS } from "components/contracts/common/Common";

import Deliverable, { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import ReviewTimelineData from "components/contracts/models/ReviewTimelineData";
import VersionedDeliverableVideo from "components/contracts/models/VersionedDeliverableVideo";
import VersionedScript from "components/contracts/models/VersionedScript";

import EmbeddedVideoPlayer from "components/contracts/common/EmbeddedVideoPlayer";

import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import Script from "components/contracts/models/Script";

import CollapsibleCard from "components/contracts/common/CollapsibleCard";
import ContentReviewCardActions from "components/contracts/review/ContentReviewCardActions";
import ContentReviewCardControls from "components/contracts/review/ContentReviewCardControls";
import ContentReviewCardHeader from "components/contracts/review/ContentReviewCardHeader";
import LiveContentVerification from "components/contracts/review/LiveContentVerification";
import ReviewStatusProps from "components/contracts/review/ReviewStatusProps";
import ReviewTimeline from "components/contracts/review/ReviewTimeline";
import ScriptContent from "components/contracts/common/ScriptContent";

function ReviewContent({
  deliverableVideo,
  script,
}: {
  deliverableVideo: DeliverableVideo;
  script: Script;
}) {
  if (deliverableVideo) {
    const isIntegration = INTEGRATED_FORMATS.includes(deliverableVideo.deliverable.format);

    return (
      <EmbeddedVideoPlayer
        url={deliverableVideo.transcodedVideoLocation || deliverableVideo.location}
        title={deliverableVideo.title}
        caption={deliverableVideo.caption}
        showRawLink
        rawUrl={deliverableVideo.location}
        integrationTimestamp={deliverableVideo.integrationTimestamp}
        isIntegration={isIntegration}
      />
    );
  } else if (script) {
    return <ScriptContent script={script} />;
  }
  return null;
}

export default function ContentReviewCard({
  brandName,
  deliverable,
  versionedScript,
  versionedDeliverableVideo,
  idsToStatuses,
  setIdsToStatuses,
  refreshContractStatus,
  numUnreadMessages,
  totalNumUnreadMessages,
  setTotalNumUnreadMessages,
  clearedUnreadMessages,
  contentType,
  reviewTimelineData,
  showAdminOptions,
}: {
  brandName: string;
  deliverable: Deliverable;
  versionedScript: VersionedScript;
  versionedDeliverableVideo: VersionedDeliverableVideo;
  idsToStatuses: Map<string, ReviewStatusProps>;
  refreshContractStatus: (numUnreadMessagesToSubtract: number, callbackFn: () => void) => void;
  setIdsToStatuses: (idsToStatuses: Map<string, ReviewStatusProps>) => void;
  numUnreadMessages: number;
  totalNumUnreadMessages: number;
  setTotalNumUnreadMessages: (totalNumUnreadMessages: number) => void;
  clearedUnreadMessages: boolean;
  contentType: ContentType;
  reviewTimelineData: ReviewTimelineData;
  showAdminOptions?: boolean;
}) {
  if (
    contentType === ContentType.SCRIPT &&
    (!versionedScript || !versionedScript.scripts || versionedScript.scripts.length === 0)
  ) {
    return null;
  }

  if (
    contentType === ContentType.VIDEO &&
    (!versionedDeliverableVideo ||
      !versionedDeliverableVideo.deliverableVideos ||
      versionedDeliverableVideo.deliverableVideos.length === 0)
  ) {
    return null;
  }
  const {
    requiresReferralLink,
    requiresPromoCode,
    referralLinkRedirectUrl,
    promoCode,
    missingReferralLink,
    missingPromoCode,
    liveContentUrl,
    assetUrl,
    adCode,
    creatorHandle,
    platform,
    format,
    profileLink,
  } = deliverable;

  const hasUsageRights = deliverable.usageRightsDays > 0 || deliverable.usageRightsInPerpetuity;
  const isMissingPromoCode = missingPromoCode;
  const isMissingReferralLink = missingReferralLink;
  const brandMessagesLastRead = deliverable.brandMessagesLastRead || new Date(0);

  const script = versionedScript.scripts[0];
  const previousScripts = versionedScript.scripts.slice(1);
  const deliverableVideo = versionedDeliverableVideo.deliverableVideos[0];
  const previousDeliverableVideos = versionedDeliverableVideo.deliverableVideos.slice(1);
  const justSigned = !deliverableVideo && !script && !liveContentUrl;
  const [contentReviewStatus, setContentReviewStatus] = useState(
    deliverableVideo?.contentStatus || script?.status,
  );
  const [reviewTimelineDataState, setReviewTimelineDataState] =
    useState<ReviewTimelineData>(reviewTimelineData);

  const [numUnreadMessagesState, setNumUnreadMessagesState] = useState(numUnreadMessages);
  const [disputeReason, setDisputeReason] = useState<string>("");

  const deliverableId = deliverableVideo?.deliverable?.id || script?.deliverableId || deliverable.id;
  const scriptId = contentType === ContentType.SCRIPT ? script.scriptId : undefined;
  const videoId = contentType === ContentType.VIDEO ? deliverableVideo.videoId : undefined;
  const sumbmissionDate = deliverableVideo?.dateCreated || script?.submissionDate;
  const numContentVersions =
    contentType === ContentType.VIDEO ? previousDeliverableVideos.length : previousScripts.length;

  const updateCardState = (reviewStatus: ContentStatus, reviewDate: Date) => {
    refreshContractStatus(numUnreadMessagesState, () => {
      setContentReviewStatus(reviewStatus);

      if (contentType === ContentType.SCRIPT) {
        setReviewTimelineDataState(
          new ReviewTimelineData({
            ...reviewTimelineDataState,
            scriptReviewDate: reviewDate,
            scriptReviewStatus: reviewStatus,
          }),
        );
      } else if (contentType === ContentType.VIDEO) {
        setReviewTimelineDataState(
          new ReviewTimelineData({
            ...reviewTimelineDataState,
            videoReviewDate: reviewDate,
            videoReviewStatus: reviewStatus,
          }),
        );
      }

      // Update state to trigger Contract Badge re-render
      const newIdsToStatuses = new Map(idsToStatuses);
      newIdsToStatuses.set(deliverableId, {
        ...idsToStatuses.get(deliverableId),
        contentStatus: reviewStatus,
      });
      setIdsToStatuses(newIdsToStatuses);
    });
  };

  const updateDeliverableStatus = (newDeliverableStatus: ContractDeliverableStatus) => {
    refreshContractStatus(numUnreadMessagesState, () => {
      setReviewTimelineDataState(
        new ReviewTimelineData({
          ...reviewTimelineDataState,
          deliverableStatus: newDeliverableStatus,
        }),
      );

      const newIdsToStatuses = new Map(idsToStatuses);
      newIdsToStatuses.set(deliverableId, {
        ...idsToStatuses.get(deliverableId),
        deliverableStatus: newDeliverableStatus,
      });
      setIdsToStatuses(newIdsToStatuses);
    });
  };

  const deliverableStatus =
    idsToStatuses.get(deliverableId)?.deliverableStatus || deliverable.status;

  const expanded =
    deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED ||
    contentReviewStatus === ContentStatus.PENDING_REVIEW ||
    numUnreadMessages > 0;

  const [missingReferralLinkState, setIsMissingReferralLinkState] = useState(isMissingReferralLink);
  const [missingPromoCodeState, setIsMissingPromoCodeState] = useState(isMissingPromoCode);

  return (
    <CollapsibleCard
      isOpen={expanded}
      header={
        <ContentReviewCardHeader
          platform={platform}
          format={format}
          creatorHandle={creatorHandle}
          profileLink={profileLink}
          numContentVersions={numContentVersions}
          submissionDate={sumbmissionDate}
          liveByDate={reviewTimelineData.liveDate}
          reviewStatus={contentReviewStatus}
          deliverableStatus={deliverableStatus}
          numUnreadMessages={numUnreadMessagesState}
          liveContentSubmissionDate={reviewTimelineData.liveContentSubmissionDate}
          usageRightsEndDate={reviewTimelineData.usageRightsEndDate}
        />
      }
      controls={
        <ContentReviewCardControls
          deliverableId={deliverableId}
          platform={platform}
          format={format}
          creatorHandle={creatorHandle}
          deliverableStatus={deliverableStatus}
          reviewStatus={contentReviewStatus}
          liveContentUrl={liveContentUrl}
          requiresReferralLink={requiresReferralLink}
          requiresPromoCode={requiresPromoCode}
          isMissingReferralLink={missingReferralLinkState}
          isMissingPromoCode={missingPromoCodeState}
          setIsMissingReferralLink={setIsMissingReferralLinkState}
          setIsMissingPromoCode={setIsMissingPromoCodeState}
          referralLink={referralLinkRedirectUrl}
          promoCode={promoCode}
          updateCardState={updateCardState}
          videoId={videoId}
          scriptId={scriptId}
          showAdminOptions={showAdminOptions}
        />
      }
      content={
        <Box px="xs">
          <Divider mb="md" />
          <Box mb="xs">
            <ReviewTimeline reviewTimelineData={reviewTimelineDataState} />
          </Box>
          {!justSigned && (
            <LiveContentVerification
              deliverableId={deliverableId}
              deliverableStatus={deliverableStatus}
              setDeliverableStatus={updateDeliverableStatus}
              disputeReason={disputeReason}
              setDisputeReason={setDisputeReason}
              creatorHandle={creatorHandle}
              brandName={brandName}
              hasUsageRights={hasUsageRights}
              liveContentUrl={liveContentUrl}
              assetUrl={assetUrl}
              adCode={adCode}
              platform={platform}
              deliverableFormat={format}
              reviewDeadline={reviewTimelineData.liveContentReviewDeadline}
              usageRightsEndDate={reviewTimelineData.usageRightsEndDate}
              usageRightsInPerpetuity={deliverable.usageRightsInPerpetuity}
              hasDraftContent={!!deliverableVideo || !!script}>
              <Group my="md" justify="center">
                <ReviewContent deliverableVideo={deliverableVideo} script={script} />
              </Group>
              <ContentReviewCardActions
                deliverableId={deliverableId}
                creatorHandle={creatorHandle}
                contentReviewStatus={contentReviewStatus}
                isMissingReferralLink={missingReferralLinkState}
                isMissingPromoCode={missingPromoCodeState}
                numUnreadMessages={numUnreadMessagesState}
                setNumUnreadMessages={setNumUnreadMessagesState}
                brandMessagesLastRead={brandMessagesLastRead}
                totalNumUnreadMessages={totalNumUnreadMessages}
                setTotalNumUnreadMessages={setTotalNumUnreadMessages}
                clearedUnreadMessages={clearedUnreadMessages}
                updateCardState={updateCardState}
                versionedScript={versionedScript}
                versionedDeliverableVideo={versionedDeliverableVideo}
                scriptId={scriptId}
                videoId={videoId}
                showAdminOptions={showAdminOptions}
              />
            </LiveContentVerification>
          )}
        </Box>
      }
      disableHoverStyle
      lazyLoadContent={!expanded}
    />
  );
}
