import React from "react";

import { Divider, Flex, Stack, Text } from "@mantine/core";

import { AdminCreatorFunnelDebug } from "admin/app/labeling/AdminCreatorFunnelDebug";
import { AdminCreatorLabelingInputSection } from "admin/app/labeling/AdminCreatorLabelingInputSection";
import { AdminLabelingCreatorSetRequirements } from "admin/app/labeling/AdminLabelingCreatorSetRequirements";
import { AdminLabelingCreatorSetSelector } from "admin/app/labeling/AdminLabelingCreatorSetSelector";
import {
  FilteredReasonStat,
  LabelingDatasetItemsResponse,
} from "components/creator_lists/LabelingUtils";
import { CreatorSet } from "components/discovery/Datamodels";

const SIDEBAR_WIDTH = 425;

export const AdminLabelingSidebar = ({
  creatorSet,
  creatorId,
  candidates,
  getNextCandidate,
  isLoadingCreatorDetails,
  creatorSetId,
  setCreatorSetId,
  onChangeCreatorSetId,
  filteredStats,
}: {
  creatorSet: CreatorSet;
  creatorId: number;
  candidates: LabelingDatasetItemsResponse;
  getNextCandidate: () => void;
  isLoadingCreatorDetails: boolean;
  creatorSetId: number;
  setCreatorSetId: (creatorSetId: number) => void;
  onChangeCreatorSetId: (creatorSetId: number) => void;
  filteredStats: FilteredReasonStat[];
}) => {
  return (
    <Stack
      py="md"
      miw={SIDEBAR_WIDTH}
      w={SIDEBAR_WIDTH}
      justify="space-between"
      style={{
        position: "sticky",
        top: 0,
      }}>
      <Flex align="flex-end" justify="center">
        <AdminLabelingCreatorSetSelector
          creatorSetId={creatorSetId}
          setCreatorSetId={setCreatorSetId}
          onChangeCreatorSetId={onChangeCreatorSetId}
        />
      </Flex>
      <Divider />
      <AdminLabelingCreatorSetRequirements creatorSet={creatorSet} />
      <AdminCreatorFunnelDebug filteredStats={filteredStats} />
      <Divider />
      <AdminCreatorLabelingInputSection
        creatorId={creatorId}
        creatorSet={creatorSet}
        candidates={candidates}
        getNextCandidate={getNextCandidate}
        isLoadingCreatorDetails={isLoadingCreatorDetails}
      />
    </Stack>
  );
};

export default AdminLabelingSidebar;
