import React from "react";

import { Avatar, Flex, Stack, Text } from "@mantine/core";

export default function CreatorAvatar({
  displayName,
  avatarUrl,
  description,
  size,
  onClick,
}: {
  displayName: string;
  avatarUrl: string;
  description: React.ReactElement;
  size?: "sm" | "md" | "lg";
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  return (
    <Flex align="center" wrap="nowrap" gap="sm">
      <Avatar
        src={avatarUrl}
        size={size ?? "md"}
        radius="xl"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      />
      <Stack gap={0}>
        <Text fw="600">{displayName}</Text>
        {description}
      </Stack>
    </Flex>
  );
}