import { API_URL } from "configs/Configs";
import { Campaign } from "models/Campaign";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const MANAGE_CAMPAIGN_URL = `${API_URL}/api/models/campaigns`;

export interface CampaignResponse {
  success: boolean;
  message: string;
  campaign: Campaign;
}

export const startCampaign = async ({
  campaignId,
  campaignHashId,
  abortController,
}: {
  campaignId?: number;
  campaignHashId?: number;
  abortController: AbortController | null;
}): Promise<CampaignResponse> => {
  const requestUrl = new URL(`${MANAGE_CAMPAIGN_URL}/start_campaign/`);

  if (!campaignId && !campaignHashId) {
    throw new Error('Either campaignId or campaignHashId must be provided');
  }

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({ campaignId, campaignHashId }),
  });

  const response: CampaignResponse = await handleResult(request, abortController);
  return response;
};

export const markCampaignComplete = async (
  campaignId: number,
  abortController: AbortController | null,
): Promise<CampaignResponse> => {
  const requestUrl = new URL(`${MANAGE_CAMPAIGN_URL}/mark_campaign_complete/`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({ campaignId }),
  });

  const response: CampaignResponse = await handleResult(request, abortController);
  return response;
};
