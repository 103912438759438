import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { Button, Group, Modal, Stack, Text } from "@mantine/core";

import { API_URL } from "configs/Configs";
import { auth } from "auth/firebaseAuthHelpers";
import { AuthenticationState, SetAuthState } from "onboarding/OnboardingUtils";
import SocialAuthButton from "onboarding/v2/SocialAuthButton";
import { AuthButton, AuthRowView } from "social/AuthenticationComponents";
import instagramIcon from "social/fb/assets/instagram_icon.svg";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const INSTAGRAM_STATUS_URL = `${API_URL}/authentication/instagram/status/`;

const INSTAGRAM_LOGOUT_URL = `${API_URL}/authentication/instagram/logout/`;
const INSTAGRAM_DELETE_DATA_URL = `${API_URL}/authentication/instagram/delete/`;
// Instagram Login URL
const INSTAGRAM_EMBED_URL =
  "https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=2283948065294215&redirect_uri=https://www.1stcollab.com/authentication/instagram/callback&response_type=code&scope=instagram_business_basic";

const getLoginStatus = async (): Promise<AuthenticationState> => {
  const request = await createRequestWithFirebaseToken({ url: INSTAGRAM_STATUS_URL });
  const response = await handleResult(request);
  return response?.auth_status || AuthenticationState.NOT_AUTHENTICATED;
};

const logout = async () => {
  const request = await createRequestWithFirebaseToken({
    url: INSTAGRAM_LOGOUT_URL,
    method: "POST",
  });
  const response = await handleResult(request);
  return response?.auth_status || AuthenticationState.NOT_AUTHENTICATED;
};

const deleteData = async () => {
  const request = await createRequestWithFirebaseToken({ url: INSTAGRAM_DELETE_DATA_URL });
  const response = await handleResult(request);
  return response?.auth_status || AuthenticationState.NOT_AUTHENTICATED;
};

export const InstagramLogout = () => {
  useEffect(() => {
    logout();
  }, []);
  return <>Successfully disconnected Instagram.</>;
};

export const InstagramDeleteData = () => {
  useEffect(() => {
    deleteData();
  }, []);
  return <>Successfully deleted Instagram data.</>;
};

export const connectInstagramExternalWindow = async (): Promise<Window> => {
  const width = 500;
  const height = 700;
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const title = `Tiktok Auth`;
  const requestUrl = new URL(INSTAGRAM_EMBED_URL);

  const popup: Window = window.open(
    requestUrl,
    title,
    `width=${width},height=${height},left=${left},top=${top}`,
  );
  return popup;
};

const InstagramLoginInstructionModal = ({
  showInstructions,
  setShowInstructions,
}: {
  showInstructions: boolean;
  setShowInstructions: (showInstructions: boolean) => void;
}) => {
  return (
    <Stack>
      <Modal
        size="auto"
        opened={showInstructions}
        onClose={() => setShowInstructions(false)}
        title="Instagram Login">
        <Stack>
          <Group>
            <Button color="gray" onClick={() => setShowInstructions(false)}>
              Cancel
            </Button>
            <Button color="blue" onClick={() => connectInstagramExternalWindow()}>
              Login with Instagram
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Stack>
  );
};

const InstagramConnect = () => {
  const [user, loading] = useAuthState(auth);
  const [externalPopup, setExternalPopup] = useState(null);
  const [authState, setAuthState] = useState(AuthenticationState.LOADING);
  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    if (authState === AuthenticationState.LOADING) {
      getLoginStatus().then((authStatus) => setAuthState(authStatus));
    }
  }, [loading]);

  const logoutFunc = () => {
    setAuthState(AuthenticationState.LOADING);
    logout().then(() => {
      setAuthState(AuthenticationState.NOT_AUTHENTICATED);
    });
  };

  useEffect(() => {
    // Uses a timer that polls every 0.5s to tracks the state of the externalPopup that is created in connectClick
    // Once it is closed, we query the backend to check whether or not the authorization credentials
    // were saved correctly.
    if (!externalPopup) {
      return;
    }
    const timer = setInterval(() => {
      if (!externalPopup) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        timer && clearInterval(timer);
      } else if (externalPopup !== null && externalPopup.closed) {
        getLoginStatus().then((status) => {
          setAuthState(status);
        });
        setExternalPopup(null);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        timer && clearInterval(timer);
      }
    }, 500);
  }, [externalPopup]);

  return (
    <>
      <SocialAuthButton
        label={
          authState === AuthenticationState.AUTHENTICATED
            ? "Disconnect Instagram"
            : "Connect Instagram"
        }
        authState={authState}
        iconSrc={instagramIcon}
        onClick={() => {
          if (
            authState === AuthenticationState.NOT_AUTHENTICATED ||
            authState === AuthenticationState.CANCELLED
          ) {
            connectInstagramExternalWindow().then((popup) => {
              setExternalPopup(popup);
            });
          } else if (authState === AuthenticationState.AUTHENTICATED) {
            logoutFunc();
          }
        }}
      />
      <InstagramLoginInstructionModal
        showInstructions={showInstructions}
        setShowInstructions={setShowInstructions}
      />
    </>
  );
};

export default InstagramConnect;
