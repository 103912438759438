import React, { useState } from "react";

import {
  Alert,
  Anchor,
  Button,
  Card,
  CopyButton,
  Flex,
  Group,
  List,
  Modal,
  Paper,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconCheck, IconCopy, IconExternalLink, IconInfoCircle, IconX } from "@tabler/icons-react";

import { approveLiveUrl, requestLiveUrlRevision } from "components/contracts/tasks/api/Api";
import { showSuccessNotification, showFailureNotification } from "components/common/Notifications";
import { LiveContent } from "components/contracts/tasks/models/Common";

import { SupportedPlatform } from "models/Common";

import { toLongDateString } from "utils/DateUtils";

import ReactPlayer from "react-player";

const getAdCodeTitle = (platform: SupportedPlatform) => {
  if (platform === SupportedPlatform.INSTAGRAM) {
    return "Instagram Ad Code";
  } else if (platform === SupportedPlatform.TIKTOK) {
    return "TikTok Spark Code";
  }
  return "";
};

const getAdCodeShortName = (platform: SupportedPlatform) => {
  if (platform === SupportedPlatform.INSTAGRAM) {
    return "Ad code";
  } else if (platform === SupportedPlatform.TIKTOK) {
    return "Spark code";
  }
  return "";
};

function DisputeButton({
  taskId,
  displayName,
  handleCompleteTask,
}: {
  taskId: string;
  displayName: string;
  handleCompleteTask: () => void;
}) {
  const [disputeReason, setDisputeReason] = useState("");
  const [requestRevisionButtonLoading, setRequestRevisionButtonLoading] = useState(false);
  const [modalOpened, { open, close }] = useDisclosure(false);

  const handleRequestRevision = () => {
    setRequestRevisionButtonLoading(true);
    requestLiveUrlRevision({ taskId, disputeReason })
      .then((response) => {
        const { success, error } = response;

        if (success) {
          showSuccessNotification({
            title: "Live video issue reported",
            message: `An issue has been reported for ${displayName}'s live video.`,
          });
          setRequestRevisionButtonLoading(false);
          handleCompleteTask();
        } else {
          setRequestRevisionButtonLoading(false);
          showFailureNotification({ message: error });
        }
        close();
      })
      .catch(() => {
        setRequestRevisionButtonLoading(false);
        showFailureNotification({ message: "Failed to report issue." });
      });
  };

  return (
    <>
      <Modal
        opened={modalOpened}
        size="lg"
        onClose={close}
        title={<Text fw="600">Describe the Issue to {displayName}</Text>}>
        <Stack>
          <Textarea
            minRows={3}
            autosize
            value={disputeReason}
            onChange={(event) => setDisputeReason(event.currentTarget.value)}
          />
          <Flex gap="sm" justify="right" align="center">
            <Button variant="default" color="gray" onClick={close}>
              Cancel
            </Button>
            <Button loading={requestRevisionButtonLoading} onClick={handleRequestRevision}>
              Submit
            </Button>
          </Flex>
        </Stack>
      </Modal>
      <Button leftSection={<IconX size="1rem" />} color="red" variant="light" onClick={open}>
        Report Issue
      </Button>
    </>
  );
}

function AdCode({ platform, adCode }: { platform: SupportedPlatform; adCode: string }) {
  const title = getAdCodeTitle(platform);

  if (!title) {
    return null;
  }

  return (
    <Stack gap={2}>
      <Text size="xs" pl={2} fw="500">
        {title}
      </Text>
      <Group>
        <Paper px="xs" py={4} shadow="xs" withBorder>
          <Flex gap="xs" align="center">
            <Text size="xs">{adCode}</Text>
            <CopyButton value={adCode} timeout={1000}>
              {({ copied, copy }) => (
                <Button
                  w={80}
                  size="compact-xs"
                  onClick={copy}
                  variant="light"
                  color={copied ? "teal" : "blue"}
                  rightSection={copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}>
                  {copied ? "Copied" : "Copy"}
                </Button>
              )}
            </CopyButton>
          </Flex>
        </Paper>
      </Group>
    </Stack>
  );
}

function AssetAndAdCode({
  assetUrl,
  adCode,
  platform,
  usageRightsEndDate,
  usageRightsInPerpetuity,
}: {
  assetUrl: string;
  adCode: string;
  platform: SupportedPlatform;
  usageRightsEndDate: Date;
  usageRightsInPerpetuity: boolean;
}) {
  const hasAdCode = platform !== SupportedPlatform.YOUTUBE && adCode;

  return (
    <Group justify="center">
      <Card shadow="xs" padding={0} radius="sm" withBorder>
        <ReactPlayer url={assetUrl} muted controls />
        {hasAdCode && (
          <Group justify="center" p="xs">
            <AdCode platform={platform} adCode={adCode} />
          </Group>
        )}
        <Flex pl="xs" pb="xs" gap={2} align="center" pt={hasAdCode ? "0" : "xs"}>
          <ThemeIcon size="sm" variant="subtle">
            <IconInfoCircle size="1rem" />
          </ThemeIcon>
          {usageRightsInPerpetuity ? (
            <Text size="xs">Usage Rights In Perpetuity</Text>
          ) : (
            <Text size="xs">
              Usage Rights Expire on{" "}
              <Text span fw="500">
                {toLongDateString(usageRightsEndDate)}
              </Text>
            </Text>
          )}
        </Flex>
      </Card>
    </Group>
  );
}

function AdCodeDescription({ platform }: { platform: SupportedPlatform }) {
  const title = getAdCodeTitle(platform);
  const shortName = getAdCodeShortName(platform);

  return (
    <>
      <Text fw="600">{title}</Text>
      <Text>Confirm the {shortName} is correct and has the correct expiration date.</Text>
    </>
  );
}

function Description({
  hasUsageRights,
  liveContentUrl,
  assetUrl,
  adCode,
  platform,
  usageRightsEndDate,
  usageRightsInPerpetuity,
}: {
  hasUsageRights: boolean;
  liveContentUrl: string;
  assetUrl: string;
  adCode: string;
  platform: SupportedPlatform;
  usageRightsEndDate: Date;
  usageRightsInPerpetuity: boolean;
}) {
  const hasAdCode = platform !== SupportedPlatform.YOUTUBE && adCode;

  return (
    <Stack gap="md">
      <Text>
        <Text span fw="600">
          Please review the following and confirm they meet your requirements.
        </Text>{" "}
        You cannot undo this step; once you confirm the deliverables, payment is released to the
        creator.
      </Text>
      <List spacing="sm">
        <List.Item>
          <Anchor href={liveContentUrl} target="_blank" rel="noopener noreferrer">
            <Flex align="center" gap={4}>
              <Text fw="600" td="underline">
                Live Video
              </Text>
              <IconExternalLink size="1rem" />
            </Flex>
          </Anchor>
          <Text>
            Confirm the video is public, matches the approved draft, and that a link/promo code was
            used (if required).
          </Text>
        </List.Item>
        {hasAdCode && hasUsageRights && (
          <List.Item>
            <AdCodeDescription platform={platform} />
          </List.Item>
        )}
        {hasUsageRights && (
          <List.Item>
            <Text fw="600">Usage Rights</Text>
            <Text>
              Confirm the video copy provided is high-resolution and matches the live video.
            </Text>
          </List.Item>
        )}
      </List>
      {hasUsageRights && (
        <AssetAndAdCode
          assetUrl={assetUrl}
          adCode={adCode}
          platform={platform}
          usageRightsEndDate={usageRightsEndDate}
          usageRightsInPerpetuity={usageRightsInPerpetuity}
        />
      )}
    </Stack>
  );
}

export default function LiveContentDisplay({
  taskId,
  platform,
  displayName,
  liveContent,
  handleCompleteTask,
}: {
  taskId: string;
  platform: SupportedPlatform;
  liveContent: LiveContent;
  displayName: string;
  handleCompleteTask: () => void;
}) {
  const [approveButtonLoading, setApproveButtonLoading] = useState(false);

  const handleApprove = () => {
    setApproveButtonLoading(true);
    approveLiveUrl({ taskId })
      .then((response) => {
        const { success, error } = response;

        if (success) {
          showSuccessNotification({
            title: "Live video confirmed",
            message: `The live video from ${displayName} has been confirmed, and payment will be released.`,
          });
          setApproveButtonLoading(false);
          handleCompleteTask();
        } else {
          setApproveButtonLoading(false);
          showFailureNotification({ message: error });
        }
      })
      .catch(() => {
        setApproveButtonLoading(false);
        showFailureNotification({ message: "Failed to approve live video." });
      });
  };

  let adCode = null;
  if (liveContent.requiresUsageRights) {
    if (platform === SupportedPlatform.INSTAGRAM) {
      adCode = liveContent.instagramAdCode;
    } else if (platform === SupportedPlatform.TIKTOK) {
      adCode = liveContent.tiktokAdCode;
    }
  }

  return (
    <Stack>
      <Alert variant="outline" color="red" radius="md">
        <Description
          hasUsageRights={liveContent.requiresUsageRights}
          liveContentUrl={liveContent.url}
          assetUrl={liveContent.assetUrl}
          adCode={adCode}
          platform={platform}
          usageRightsEndDate={liveContent.usageRightsExpirationDate}
          // TODO(albert): Add this once we have the backend logic
          usageRightsInPerpetuity={false}
        />
      </Alert>
      <Group justify="right">
        <DisputeButton
          taskId={taskId}
          displayName={displayName}
          handleCompleteTask={handleCompleteTask}
        />
        <Button
          leftSection={<IconCheck size="1rem" />}
          color="green"
          variant="light"
          loading={approveButtonLoading}
          onClick={handleApprove}>
          Confirm Live
        </Button>
      </Group>
    </Stack>
  );
}
