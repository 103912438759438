import React, { Dispatch } from "react";

import { Box, Checkbox, Flex, Stack, TextInput, Title } from "@mantine/core";

import {
  DEFAULT_SUB_TITLE_SIZE,
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_TITLE_SIZE,
  MultiPlatformState,
} from "components/contracts/common/Common";

import { SupportedPlatform, SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES } from "models/Common";

export enum MultiPlatformActionType {
  UPDATE_HANDLE = "UPDATE_HANDLE",
  UPDATE_SELECTED = "UPDATE_SELECTED",
  SET_SELECTED = "SET_SELECTED",
  ADD_API_DATA = "ADD_API_DATA",
}

export interface MultiPlatformAction {
  type: MultiPlatformActionType;
  payload: {
    platform: SupportedPlatform;
    selected?: boolean;
    handle?: string;
    profileLink?: string;
    creatorId?: string;
  };
}

export const platformSelected = (multiPlatformState: MultiPlatformState) =>
  Object.values(multiPlatformState).some(({ selected }) => selected);

export function initializeMultiPlatformState() {
  return Object.values(SupportedPlatform).reduce((acc, platform) => {
    acc[platform] = { selected: false, handle: "", profileLink: "", creatorId: "" };
    return acc;
  }, {} as MultiPlatformState);
}

export const multiPlatformReducer = (state: MultiPlatformState, action: MultiPlatformAction) => {
  switch (action.type) {
    case MultiPlatformActionType.UPDATE_HANDLE:
      return {
        ...state,
        [action.payload.platform]: {
          ...state[action.payload.platform],
          handle: action.payload.handle || "",
        },
      };
    case MultiPlatformActionType.UPDATE_SELECTED:
      return {
        ...state,
        [action.payload.platform]: {
          ...state[action.payload.platform],
          selected: !state[action.payload.platform].selected,
          handle: state[action.payload.platform].selected
            ? ""
            : state[action.payload.platform].handle,
          profileLink: state[action.payload.platform].selected
            ? ""
            : state[action.payload.platform].profileLink,
          creatorId: state[action.payload.platform].selected
            ? ""
            : state[action.payload.platform].creatorId,
        },
      };
    case MultiPlatformActionType.SET_SELECTED:
      return {
        ...state,
        [action.payload.platform]: {
          ...state[action.payload.platform],
          selected: action.payload.selected,
        },
      };
    case MultiPlatformActionType.ADD_API_DATA:
      return {
        ...state,
        [action.payload.platform]: {
          ...state[action.payload.platform],
          profileLink: action.payload.profileLink || "",
          creatorId: action.payload.creatorId || "",
        },
      };
    default:
      return state;
  }
};

export const normalizeCreatorHandle = (handle: string) => {
  if (handle.startsWith("@") && handle.length === 1) {
    return "";
  }
  return handle.startsWith("@") ? handle.trim() : `@${handle.trim()}`;
};

export default function MultiPlatformInput({
  multiPlatformState,
  editMultiPlatformState,
  disabled,
}: {
  multiPlatformState: MultiPlatformState;
  editMultiPlatformState: Dispatch<MultiPlatformAction>;
  disabled: boolean;
}) {
  const checkBoxes = Object.values(SupportedPlatform).filter((platform) => platform !== SupportedPlatform.NONE).map((platform) => (
    <Checkbox
      key={`checkbox-${platform}`}
      id={`checkbox-${platform}`}
      checked={multiPlatformState[platform].selected}
      label={SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]}
      onChange={() => {
        editMultiPlatformState({
          type: MultiPlatformActionType.UPDATE_SELECTED,
          payload: { platform },
        });
      }}
      disabled={disabled}
    />
  ));

  const creatorHandleInputs = Object.values(SupportedPlatform).filter((platform) => platform !== SupportedPlatform.NONE).map((platform) => (
    <TextInput
      key={`handle-${platform}`}
      id={`handle-${platform}`}
      label={SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]}
      value={multiPlatformState[platform].handle}
      disabled={!multiPlatformState[platform].selected || disabled}
      onChange={(event) => {
        editMultiPlatformState({
          type: MultiPlatformActionType.UPDATE_HANDLE,
          payload: { platform, handle: normalizeCreatorHandle(event.currentTarget.value) },
        });
      }}
    />
  ));

  return (
    <>
      <Title order={DEFAULT_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Platform Details
      </Title>
      <Box mt="sm">
        <Title order={DEFAULT_SUB_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
          Platform Selection
        </Title>
        <Box my="sm">
          <Stack gap="xs">{checkBoxes}</Stack>
        </Box>
        <Title order={DEFAULT_SUB_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
          Creator Handles
        </Title>
        <Box my="sm">
          <Flex gap="sm">{creatorHandleInputs}</Flex>
        </Box>
      </Box>
    </>
  );
}
