import React from "react";

import { CreatorInfo } from "components/creator_lists/CreatorListsUtils";
import CreatorHeader from "components/discovery/search/CreatorHeader";

export const AdminCreatorLabelingProfileHeader = ({
  creatorId,
  creatorInfo,
  platform,
}: {
  creatorId: number;
  creatorInfo: CreatorInfo;
  platform: string;
}) => {
  return (
    <CreatorHeader
      creatorId={creatorId}
      creatorInfo={creatorInfo}
      archetype={null}
      price={null}
      creatorSetControls={null}
      includeLinkToProfile
      platform={platform}
    />
  );
};

export default AdminCreatorLabelingProfileHeader;
