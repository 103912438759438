import React from "react";
import { Route, Routes } from "react-router-dom";

import Admin from "admin/Admin";
import AdminBrands from "admin/AdminBrands";
import AdminCampaigns from "admin/AdminCampaigns";
import AdminCRM from "admin/AdminCRM";
import AdminInstagramProfile from "admin/AdminInstagramProfile";
import AdminLayout from "admin/AdminLayout";
import AdminLiveContent from "admin/AdminLiveContent";
import AdminRelatedCreators from "admin/AdminRelatedCreators";
import AdminUserEdit from "admin/AdminUserEdit";
import AdminAppShell from "admin/app/AdminAppShell";
import { AdminCreatorSetSettings } from "admin/app/creator_sets/AdminCreatorSetSettings";
import { OpsAdminHome } from "admin/app/ops/OpsAdminHome";
import InstagramProfiles from "admin/InstagramProfiles";
import RelatedCreatorsBackend from "admin/RelatedCreatorsBackend";
import RelatedCreatorsFrontend from "admin/RelatedCreatorsFrontend";
import UserTable from "admin/UserTable";
import Invoice from "components/contracts/billing/Invoice";
import CreateContract from "components/contracts/contract/CreateContract";
import AdminDashboard from "components/contracts/dashboard/AdminDashboard";
import CreatorContract from "components/contracts/deliverables/CreatorContract";
import ContentReviewFeed from "components/contracts/review/ContentReviewFeed";
import CreatorProfile from "components/creator/CreatorProfilePage";
import { CreatorSearchPage } from "components/creator/search/CreatorSearchPage";
import OpsLayout from "ops_team/OpsLayout";
import { OpsTaskDetails } from "admin/app/ops/OpsTaskDetails";
import { OpsUserSummary } from "admin/app/ops/OpsUserSummary";
import BrandHashtagLabelingHome from "admin/app/ops/labeling/brand_hashtag/BrandHashtagLabelingHome";
import AdminCampaignParameterSettings from "admin/app/campaign_parameters/AdminCampaignParameterSettings";
import { TikTokVideoFeed } from "admin/app/ops/labeling/debug/TikTokVideoFeed";
import { HashtagVideoFeed } from "admin/app/ops/labeling/debug/HashtagVideoFeed";
import { CreatorDebugFeed } from "admin/app/ops/labeling/debug/CreatorDebugFeed";
import HashtagUserFeed from "admin/app/ops/labeling/debug/HashtagUserFeed";
import CreatorSetRecommendations from "admin/app/ops/recommendations/CreatorSetRecommendations";
import CreatorSetSearch from "components/discovery/search/CreatorSetSearch";
import ContractOfferDashboard from "admin/app/negotiations/ContractOfferDashboard";
import BudgetUtilizationDashboard from "components/metrics/BudgetUtilizationDashboard";
import CashFlowMetrics from "components/metrics/CashFlowMetrics";
import Metrics from "components/metrics/Metrics";
import BrandOnboardingDashboard from "components/admin/onboarding/BrandOnboardingDashboard";
import AdminLabelingReporting from "admin/app/labeling/AdminLabelingReporting";
import { AdminLabeling } from "admin/app/labeling/AdminLabeling";
import { OpsCrawlTool } from "admin/app/ops/crawling/OpsCrawlTool";
import ViewPrintContract from "components/contracts/contract/ViewPrintContract";

const AdminApp = () => (
  <Routes>
    <Route element={<OpsLayout />}>
      <Route path="/admin" element={<AdminAppShell />}>
        <Route index element={<Admin />} />
        <Route path="user_edit" element={<AdminUserEdit />} />
        <Route path="user_table" element={<UserTable />} />
        <Route path="related_creators" element={<AdminRelatedCreators />}>
          <Route path="backend_logs" element={<RelatedCreatorsBackend />} />
          <Route path="frontend_logs" element={<RelatedCreatorsFrontend />} />
        </Route>
        <Route path="create_contract" element={<CreateContract showAdminOptions />} />
        <Route element={<AdminLayout />}>
          <Route path="dashboard" element={<AdminDashboard />} />
        </Route>
        <Route path="export_contract/:contractId" element={<ViewPrintContract />} />
        <Route path="contracts/:contractId" element={<CreatorContract showAdminOptions />} />
        <Route path="invoice/:invoiceId" element={<Invoice />} />
        <Route path="review/:campaignHashId" element={<ContentReviewFeed showAdminOptions />} />
        <Route path="instagram_profiles" element={<InstagramProfiles />} />
        <Route path="instagram_profile/:username" element={<AdminInstagramProfile />} />
        {/* <Route path="campaigns/settings" element={<AdminCampaignSettings />} /> */}
        <Route path="campaigns" element={<AdminCampaigns />} />
        <Route path="brands" element={<AdminBrands />} />
        <Route path="creator_sets" element={<AdminCreatorSetSettings />} />
        <Route path="creator/search/" element={<CreatorSearchPage />} />
        <Route path="creator/" element={<CreatorProfile />} />
        <Route path="campaign_parameters" element={<AdminCampaignParameterSettings />} />
        <Route path="negotiations" element={<ContractOfferDashboard />} />
        <Route path="onboarding_status" element={<BrandOnboardingDashboard />} />
        {/* Place above /ops to match first */}
        <Route path="ops/user" element={<OpsUserSummary />} />
        <Route path="ops/task/:taskId" element={<OpsTaskDetails />} />
        <Route path="ops" element={<OpsAdminHome />} />
        <Route path="global_crm" element={<AdminCRM userOnly={false} />} />
        <Route path="crm" element={<AdminCRM userOnly />} />
        <Route path="live" element={<AdminLiveContent />} />
        {/* Labeling paths */}
        <Route path="labeling/brand_hashtags" element={<BrandHashtagLabelingHome />} />
        <Route path="labeling/reporting" element={<AdminLabelingReporting />} />
        <Route path="labeling" element={<AdminLabeling />} />
        {/* Crawling paths */}
        <Route path="crawling/" element={<OpsCrawlTool />} />
        {/* Discover paths */}
        <Route path="discover" element={<CreatorSetSearch showDebug />} />
        {/* Recommendations paths */}
        <Route path="recommendations" element={<CreatorSetRecommendations />} />
        {/* Metrics paths */}
        <Route path="metrics" element={<Metrics />} />
        <Route path="metrics/cash_flow" element={<CashFlowMetrics />} />
        <Route path="metrics/budget" element={<BudgetUtilizationDashboard />} />
        {/* Debugging paths */}
        <Route path="debug/feed/tiktok" element={<TikTokVideoFeed />} />
        <Route path="debug/feed/hashtag" element={<HashtagVideoFeed />} />
        <Route path="debug/feed/locket_hashtag" element={<HashtagVideoFeed endpoint="locket" />} />
        <Route path="debug/feed/all_hashtag" element={<HashtagVideoFeed endpoint="all" />} />
        <Route path="debug/feed/user_hashtags" element={<HashtagUserFeed />} />
        <Route path="debug/feed/creators" element={<CreatorDebugFeed />} />
      </Route>
    </Route>
  </Routes>
);

export default AdminApp;
