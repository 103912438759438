import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  sendEmailVerification,
  User,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import { Button, Loader, PasswordInput, Stack, Text, Title } from "@mantine/core";
import { notifications, Notifications } from "@mantine/notifications";

import { auth } from "auth/firebaseAuthHelpers";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { completeUserOnboarding } from "reduxStore/meSlice";
import { confirmEmailVerification } from "onboarding/v2/api/OnboardingApi";

const sendEmail = async (user: User) => {
  await sendEmailVerification(user);
};

export const ResendEmail = ({ user }: { user: User }) => (
  <Stack gap="md" align="center">
    <Text>Having trouble verifying or need a new verification email? Click the button below.</Text>
    <Button onClick={() => sendEmail(user)}>Resend Email</Button>
  </Stack>
);

const PasswordReset = ({
  password,
  setNewPassword,
  actionCode,
  hidden,
  setHidden,
}: {
  password: string;
  setNewPassword: (password: string) => void;
  actionCode: string;
  hidden: boolean;
  setHidden: (hidden: boolean) => void;
}) => {
  const [pwVisible, setPwVisible] = useState(false);
  const toggle = () => setPwVisible(!pwVisible);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pwMatch, setPwMatch] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    setPwMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  const submit = () => {
    // Save the new password.
    confirmPasswordReset(auth, actionCode, password)
      .then((resp) => {
        // Password reset has been confirmed and new password updated.
        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        notifications.show({
          title: "Password Reset",
          message:
            "Your password has been reset successfully! You will be redirected to the home page.",
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        notifications.show({
          title: "Password Reset Error",
          message: "There was an error resetting your password. Please try again.",
          color: "red",
        });
      });
  };
  return (
    <Stack style={{ visibility: hidden ? "hidden" : "visible" }}>
      <Notifications />
      <Title order={2}>Reset Account Password</Title>
      <PasswordInput
        label="Password"
        visible={pwVisible}
        onVisibilityChange={toggle}
        withAsterisk
        value={password}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <PasswordInput
        label="Confirm password"
        visible={pwVisible}
        onVisibilityChange={toggle}
        withAsterisk
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        error={!pwMatch ? "Passwords do not match!" : ""}
      />
      <Button onClick={submit}>Submit</Button>
    </Stack>
  );
};

const PasswordResetInvalidCode = () => (
  <Stack align="center">
    <Text>The password reset code is invalid or expired. Please try again.</Text>
  </Stack>
);

export const AuthenticationLanding = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.me.user);
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const [firebaseUser, firebaseLoading] = useAuthState(auth);
  const [authMode, setAuthMode] = useState<"verifyEmail" | "resetPassword">(null);

  // States for password reset
  const [newPassword, setNewPassword] = useState("");
  const [resetPasswordHidden, setResetPasswordHidden] = useState(true);
  const [isInvalidPwResetCode, setIsInvalidPwResetCode] = useState(false);

  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");

  useEffect(() => {
    // Handle email verification
    if (mode === "verifyEmail") {
      setAuthMode("verifyEmail");
      if (oobCode && currentUser !== null) {
        setLoading(true);
        checkActionCode(auth, oobCode)
          .then((info) => {
            applyActionCode(auth, oobCode)
              .then(() => {
                const abortController = new AbortController();
                dispatch(
                  completeUserOnboarding({
                    abortController,
                    user_key: currentUser.key,
                  }),
                );
                // TODO(andrew): we should put the steps below
                // in the .then for confirmEmailVerification
                confirmEmailVerification();
                setVerified(true);
                setLoading(false);
                // Redirect after waiting 3s
                setTimeout(() => {
                  const isUnauthSearch = localStorage.getItem("unauth-search") === "true";
                  if (isUnauthSearch) {
                    navigate("/campaigns/discover");
                  } else {
                    navigate("/campaigns");
                  }
                }, 3000);
              })
              .catch((error) => {
                setVerificationError(error);
              });
          })
          .catch((error) => {
            setVerificationError(error);
          })
          .finally(() => setLoading(false));
      }
    } else if (mode === "resetPassword") {
      setAuthMode("resetPassword");
      if (oobCode) {
        // Handle password reset
        // Verify the password reset code is valid.
        verifyPasswordResetCode(auth, oobCode)
          .then((email) => {
            const accountEmail = email;
            setResetPasswordHidden(false);
          })
          .catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            setIsInvalidPwResetCode(true);
          });
      }
    }
  }, [mode, currentUser]);

  if (loading && mode === "verifyEmail")
    return (
      <Stack align="center">
        <Text>Attempting to verify email. Please wait.</Text>
        <Loader />
      </Stack>
    );

  if (verified && mode === "verifyEmail") {
    return (
      <Stack align="center">
        <Text>Email Verified! Thank you for signing up!</Text>
      </Stack>
    );
  }

  return (
    <Stack align="center">
      {authMode === "verifyEmail" && verificationError && !verified ? (
        <Text color="red">Error verifying email. Please try again.</Text>
      ) : null}
      <PasswordReset
        password={newPassword}
        setNewPassword={setNewPassword}
        actionCode={oobCode}
        hidden={resetPasswordHidden}
        setHidden={setResetPasswordHidden}
      />
      {isInvalidPwResetCode && <PasswordResetInvalidCode />}
      {/* <ResendEmail user={firebaseUser} /> */}
    </Stack>
  );
};

export default AuthenticationLanding;
