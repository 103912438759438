import React, { useEffect, useState } from "react";

import { CloseButton, Combobox, InputBase, useCombobox } from "@mantine/core";

import { Brand } from "models/Brand";

export const BrandSelector = ({
  brands,
  selectedBrandId,
  setSelectedBrandId,
  searchParams = null,
  setSearchParams = null,
}: {
  brands: Brand[];
  selectedBrandId: number | null;
  setSelectedBrandId: (brandId: number) => void;
  searchParams?: URLSearchParams | null;
  setSearchParams?: (searchParams: URLSearchParams) => void | null;
}) => {
  const [search, setSearch] = useState("");
  const [cleared, setCleared] = useState(false);

  const brandData = brands.map((brand: Brand) => ({
    value: brand.id.toString(),
    label: brand.display_name || brand.brand_name,
  }));

  const [brandIdToLabel, setBrandIdToLabel] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    if (brands?.length > 0) {
      const newBrandIdToLabel: { [key: number]: string } = {};
      brands.forEach((brand: Brand) => {
        newBrandIdToLabel[brand.id] = brand.display_name;
      });
      setBrandIdToLabel(newBrandIdToLabel);
    }
  }, [brands]);

  // parse initial brandId from URL
  useEffect(() => {
    const brandIdParam = searchParams.get("brandId");
    const parsedBrandId = brandIdParam ? parseInt(brandIdParam, 10) : null;
    if (parsedBrandId) {
      if (parsedBrandId !== selectedBrandId) {
        setSelectedBrandId(parsedBrandId);
        // also set search val
        setSearch(brandIdToLabel[parsedBrandId]);
      }
    } else {
      setSelectedBrandId(null);
      setSearch("");
    }
  }, [brandIdToLabel, searchParams]);

  useEffect(() => {
    let changed = false;
    if (selectedBrandId) {
      if (selectedBrandId.toString() !== searchParams.get("brandId")) {
        searchParams.set("brandId", selectedBrandId.toString());
        changed = true;
      }
    }
    if (changed) {
      setSearchParams(searchParams);
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (cleared) {
      setSearch("");
      setCleared(false);
      setSelectedBrandId(null);
      // clear search params
      searchParams.delete("brandId");
      setSearchParams(searchParams);
    }
  }, [cleared]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const shouldFilterOptions = brandData.every((item) => item.label !== search);
  const filteredOptions =
    shouldFilterOptions && search?.length > 0
      ? brandData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
      : brandData;

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.value} key={`${item.label}-${item.value}`}>
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setSelectedBrandId(val ? Number(val) : null);
        setSearch(val ? brandIdToLabel[Number(val)] : val);
        combobox.closeDropdown();
      }}>
      <Combobox.Target>
        <InputBase
          rightSection={
            setSelectedBrandId !== null ? (
              <CloseButton
                size="sm"
                onMouseDown={(event: { preventDefault: () => void }) => event.preventDefault()}
                onClick={() => {
                  setCleared(true);
                }}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={setSelectedBrandId === null ? "none" : "all"}
          value={search}
          onChange={(event: { currentTarget: { value: React.SetStateAction<string> } }) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(selectedBrandId ? brandIdToLabel[selectedBrandId] : "");
          }}
          placeholder="Select a Brand"
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default BrandSelector;
