import React, { useEffect, useState } from "react";

import { CloseButton, Combobox, InputBase, useCombobox } from "@mantine/core";

type BrandType = {
  brand_name: string;
};

type ItemWithBrand = {
  id: number;
  name: string;
  brand?: BrandType;
};

export const BrandAndNameSelector = ({
  items,
  selectedItemId,
  setSelectedItemId,
  searchParams = null,
  setSearchParams = null,
  objType,
  objName,
}: {
  items: ItemWithBrand[];
  selectedItemId: number | null;
  setSelectedItemId: (itemId: number) => void;
  searchParams?: URLSearchParams | null;
  setSearchParams?: (searchParams: URLSearchParams) => void | null;
  objType: string;
  objName: string;
}) => {
  const [search, setSearch] = useState("");
  const [cleared, setCleared] = useState(false);

  const formatName = (item: ItemWithBrand): string => `${item.name} (${item?.brand?.brand_name})`;

  const itemData = items.map((item: ItemWithBrand) => ({
    id: item.id.toString(),
    value: item.id.toString(),
    label: formatName(item),
  }));

  const [itemIdToLabel, setItemIdToLabel] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    if (items?.length > 0) {
      const newItemIdToLabel: { [key: number]: string } = {};
      items.forEach((item: ItemWithBrand) => {
        newItemIdToLabel[item.id] = formatName(item);
      });
      setItemIdToLabel(newItemIdToLabel);
    }
  }, [items]);

  // parse initial creatorSetId from URL
  useEffect(() => {
    if (!searchParams) {
      if (selectedItemId && itemIdToLabel[selectedItemId]) {
        setSearch(itemIdToLabel[selectedItemId]);
      }
      return;
    }
    const itemIdParam = searchParams.get(objType);
    const parsedItemId = itemIdParam ? parseInt(itemIdParam, 10) : null;

    if (parsedItemId) {
      if (parsedItemId !== selectedItemId && itemIdToLabel[parsedItemId]) {
        setSelectedItemId(parsedItemId);
        // also set search val
        setSearch(itemIdToLabel[parsedItemId]);
      }
    } else {
      setSelectedItemId(null);
      setSearch("");
    }
  }, [itemIdToLabel, searchParams]);

  useEffect(() => {
    if (!searchParams) {
      if (selectedItemId && itemIdToLabel[selectedItemId]) {
        setSearch(itemIdToLabel[selectedItemId]);
      }
      return;
    }
    let changed = false;
    if (selectedItemId) {
      if (selectedItemId.toString() !== searchParams.get(objType)) {
        searchParams.set(objType, selectedItemId.toString());
        changed = true;
      }
    }
    if (changed) {
      setSearchParams(searchParams);
    }
  }, [selectedItemId]);

  useEffect(() => {
    if (cleared) {
      setSearch("");
      setCleared(false);
      setSelectedItemId(null);
      // clear search params
      if (searchParams) {
        searchParams.delete(objType);
        setSearchParams(searchParams);
      }
    }
  }, [cleared]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const shouldFilterOptions = itemData.every((item) => item.label !== search);
  const filteredOptions =
    shouldFilterOptions && search?.length > 0
      ? itemData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
      : itemData;

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.value} key={`${item.label}-${item.id}`}>
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setSelectedItemId(val ? Number(val) : null);
        setSearch(val ? itemIdToLabel[Number(val)] : val);
        combobox.closeDropdown();
      }}>
      <Combobox.Target>
        <InputBase
          w={350}
          rightSection={
            setSelectedItemId !== null ? (
              <CloseButton
                size="sm"
                onMouseDown={(event: { preventDefault: () => void }) => event.preventDefault()}
                onClick={() => {
                  setCleared(true);
                }}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={setSelectedItemId === null ? "none" : "all"}
          value={search}
          onChange={(event: { currentTarget: { value: React.SetStateAction<string> } }) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(selectedItemId ? itemIdToLabel[selectedItemId] : "");
          }}
          placeholder={`Select a ${objName}`}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default BrandAndNameSelector;
