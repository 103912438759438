import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import {
  Container,
  LoadingOverlay,
  MultiSelect,
  Group,
  Stack,
  Table,
  Paper,
  Text,
} from "@mantine/core";

import { fetchCampaignProgressData, getAndSetCampaigns } from "components/campaign/CampaignUtils";
import { BudgetType, Campaign, CampaignProgressReportResponse } from "models/Campaign";
import {
  fetchCampaignOverview,
  getCampaignAdGroupOverallStats,
  translateCampaignProgressResponse,
} from "campaigns/api/fetchCampaignOverview";
import {
  CampaignOverviewSpendStats,
  SpendBar,
  sumCampaignSpendStats,
} from "components/campaign/dashboard/CampaignOverviewComponents";
import Spacer from "components/Spacer";

interface CampaignProgressEntry {
  brandId: number;
  brandName: string;
  campaignId: number;
  campaignName: string;
  creatorSetId: number;
  creatorSetName: string;
  allTimeClientApproval: number;
  lastWeekClientApproval: number;
  thisWeekClientApproval: number;
  allTimeClientReview: number;
  lastWeekClientReview: number;
  thisWeekClientReview: number;
  allTimeClientApprovalRate: number;
  lastWeekClientApprovalRate: number;
  thisWeekClientApprovalRate: number;
  allTimeInternalApproval: number;
  lastWeekInternalApproval: number;
  thisWeekInternalApproval: number;
  allTimeInternalReview: number;
  lastWeekInternalReview: number;
  thisWeekInternalReview: number;
  allTimeInternalApprovalRate: number;
  lastWeekinternalApprovalRate: number;
  thisWeekinternalApprovalRate: number;
  allTimeOpsApproved: number;
  lastWeekOpsApproved: number;
  thisWeekOpsApproved: number;
  allTimeEmailsFound: number;
  lastWeekEmailsFound: number;
  thisWeekEmailsFound: number;
  allTimeEmailsSent: number;
  lastWeekEmailsSent: number;
  thisWeekEmailsSent: number;
  allTimeEmailSearchAttempted: number;
  lastWeekEmailSearchAttempted: number;
  thisWeekEmailSearchAttempted: number;
  allTimeEmailSearchRequested: number;
  lastWeekEmailSearchRequested: number;
  thisWeekEmailSearchRequested: number;
  allTimeDiscussionStart: number;
  lastWeekDiscussionStart: number;
  thisWeekDiscussionStart: number;
  allTimeNegotiationStart: number;
  lastWeekNegotiationStart: number;
  thisWeekNegotiationStart: number;
  allTimeCreatorReplies: number;
  lastWeekCreatorReplies: number;
  thisWeekCreatorReplies: number;
  allTimeCollabResponse: number;
  lastWeekCollabResponse: number;
  thisWeekCollabResponse: number;
  internalRemaining: number;
  internalRemainingNoEmails: number;
  brandReviewRemaining: number;
  brandReviewRemainingNoEmails: number;
  thisWeekContractCreated: number;
  thisWeekContractPending: number;
  thisWeekContractAccepted: number;
  thisWeekContractInProgress: number;
  thisWeekContractComplete: number;
  thisWeekContractRejected: number;
  lastWeekContractCreated: number;
  lastWeekContractPending: number;
  lastWeekContractAccepted: number;
  lastWeekContractInProgress: number;
  lastWeekContractComplete: number;
  lastWeekContractRejected: number;
  allTimeContractComplete: number;
  allTimeContractAmount: number;
}

interface CampaignProgressResponse {
  success: boolean;
  rawProgress: CampaignProgressEntry[];
  campaignProgress: CampaignProgressEntry[];
  brandProgress: CampaignProgressEntry[];
}

const getColor = (thisWeekStat: number, lastWeekStat: number) => {
  if (thisWeekStat === lastWeekStat) {
    return "gray";
  }
  return thisWeekStat >= lastWeekStat ? "#2F9E44" : "red";
};

const Comparison = ({
  allTimeStat,
  thisWeekStat,
  lastWeekStat,
}: {
  allTimeStat: string | number;
  thisWeekStat: number;
  lastWeekStat: number;
}) => (
  <Table.Td style={{ color: getColor(thisWeekStat, lastWeekStat) }}>
    <Stack gap="xs">
      <Group>
        <Text size="s">
          {thisWeekStat} ({lastWeekStat})
        </Text>
      </Group>
      <Text size="xs">{allTimeStat}</Text>
    </Stack>
  </Table.Td>
);

const MixedSpendRowItem = ({ spendStats }: { spendStats: CampaignOverviewSpendStats }) => (
  <Table.Td>
    {spendStats !== undefined && (
      <SpendBar
        budget={100 * spendStats.budget}
        spent={spendStats.spent}
        allocated={spendStats.allocated}
      />
    )}
  </Table.Td>
);

const SpendRowItem = ({ spendStats }: { spendStats: CampaignOverviewSpendStats }) => (
  <Table.Td>
    {spendStats !== undefined && (
      <SpendBar
        budget={100 * spendStats.budget}
        spent={
          spendStats.budgetType > 0
            ? spendStats.stats.monthlyBudgetSpent
            : spendStats.stats.totalBudgetSpent
        }
        allocated={
          spendStats.budgetType > 0
            ? spendStats.stats.monthlyBudgetAllocated
            : spendStats.stats.totalBudgetAllocated
        }
      />
    )}
  </Table.Td>
);

const BrandProgressRow = ({
  entry,
  spendStats,
  isSimple = false,
  isMixedSpendStats = false,
}: {
  entry: CampaignProgressEntry;
  spendStats: CampaignOverviewSpendStats;
  isSimple?: boolean;
  isMixedSpendStats?: boolean;
}) => (
  <Table.Tr key={`${entry.brandId}-${entry.campaignId}-${entry.creatorSetId}`}>
    <Table.Td>
      <a href={`#${entry.brandId}`}>{entry.brandName}</a>
    </Table.Td>
    <Comparison
      allTimeStat={entry.allTimeOpsApproved}
      thisWeekStat={entry.thisWeekOpsApproved}
      lastWeekStat={entry.lastWeekOpsApproved}
    />
    {!isSimple ? (
      <>
        <Table.Td>{entry.internalRemainingNoEmails}</Table.Td>
        {/* KEVIN - UNCOMMENT NEXT WEEK <Comparison
            allTimeStat='--'
            thisWeekStat={entry.thisWeekEmailSearchRequested}
            lastWeekStat={entry.lastWeekEmailSearchRequested}
          /> */}
        <Comparison
          allTimeStat="--"
          thisWeekStat={entry.thisWeekEmailSearchAttempted}
          lastWeekStat={entry.lastWeekEmailSearchAttempted}
        />
        <Comparison
          allTimeStat="--"
          thisWeekStat={entry.thisWeekEmailsFound}
          lastWeekStat={entry.lastWeekEmailsFound}
        />
      </>
    ) : null}
    <Table.Td>{entry.internalRemaining}</Table.Td>
    <Comparison
      allTimeStat={entry.allTimeInternalApproval}
      thisWeekStat={entry.thisWeekInternalApproval}
      lastWeekStat={entry.lastWeekInternalApproval}
    />
    {!isSimple ? (
      <>
        <Comparison
          allTimeStat={entry.allTimeInternalReview}
          thisWeekStat={entry.thisWeekInternalReview}
          lastWeekStat={entry.lastWeekInternalReview}
        />
        <Comparison
          allTimeStat={entry.allTimeInternalApprovalRate}
          thisWeekStat={entry.thisWeekinternalApprovalRate}
          lastWeekStat={entry.lastWeekinternalApprovalRate}
        />
      </>
    ) : null}
    <Table.Td>{entry.brandReviewRemaining}</Table.Td>
    <Comparison
      allTimeStat={entry.allTimeClientApproval}
      thisWeekStat={entry.thisWeekClientApproval}
      lastWeekStat={entry.lastWeekClientApproval}
    />
    {!isSimple ? (
      <>
        <Comparison
          allTimeStat={entry.allTimeClientReview}
          thisWeekStat={entry.thisWeekClientReview}
          lastWeekStat={entry.lastWeekClientReview}
        />
        <Comparison
          allTimeStat={entry.allTimeClientApprovalRate}
          thisWeekStat={entry.thisWeekClientApprovalRate}
          lastWeekStat={entry.lastWeekClientApprovalRate}
        />
      </>
    ) : null}
    <Comparison
      allTimeStat={entry.allTimeEmailsSent}
      thisWeekStat={entry.thisWeekEmailsSent}
      lastWeekStat={entry.lastWeekEmailsSent}
    />
    <Comparison
      allTimeStat={entry.allTimeDiscussionStart}
      thisWeekStat={entry.thisWeekDiscussionStart}
      lastWeekStat={entry.lastWeekDiscussionStart}
    />
    {!isSimple ? (
      <>
        <Comparison
          allTimeStat={entry.allTimeNegotiationStart}
          thisWeekStat={entry.thisWeekNegotiationStart}
          lastWeekStat={entry.lastWeekNegotiationStart}
        />
        <Comparison
          allTimeStat="--"
          thisWeekStat={entry.thisWeekContractAccepted}
          lastWeekStat={entry.lastWeekContractAccepted}
        />
      </>
    ) : null}
    <Comparison
      allTimeStat={entry.allTimeContractComplete}
      thisWeekStat={entry.thisWeekContractComplete}
      lastWeekStat={entry.lastWeekContractComplete}
    />
    <Table.Td>
      {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
        entry.allTimeContractAmount / 100,
      )}
    </Table.Td>
    {isMixedSpendStats ? (
      <MixedSpendRowItem spendStats={spendStats} />
    ) : (
      <SpendRowItem spendStats={spendStats} />
    )}
  </Table.Tr>
);

const CampaignCreatorSetProgressRow = ({ entry }: { entry: CampaignProgressEntry }) => (
  <>
    <Comparison
      allTimeStat={entry.allTimeOpsApproved}
      thisWeekStat={entry.thisWeekOpsApproved}
      lastWeekStat={entry.lastWeekOpsApproved}
    />
    <Table.Td>{entry.internalRemainingNoEmails}</Table.Td>
    {/* kevin - UNCOMMENT NEXT WEEK <Comparison
      allTimeStat='--'
      thisWeekStat={entry.thisWeekEmailSearchRequested}
      lastWeekStat={entry.lastWeekEmailSearchRequested}
    /> */}
    <Comparison
      allTimeStat="--"
      thisWeekStat={entry.thisWeekEmailSearchAttempted}
      lastWeekStat={entry.lastWeekEmailSearchAttempted}
    />
    <Comparison
      allTimeStat={entry.allTimeEmailsFound}
      thisWeekStat={entry.thisWeekEmailsFound}
      lastWeekStat={entry.lastWeekEmailsFound}
    />
    <Table.Td>{entry.internalRemaining}</Table.Td>
    <Comparison
      allTimeStat={entry.allTimeInternalApproval}
      thisWeekStat={entry.thisWeekInternalApproval}
      lastWeekStat={entry.lastWeekInternalApproval}
    />
    <Comparison
      allTimeStat={entry.allTimeInternalReview}
      thisWeekStat={entry.thisWeekInternalReview}
      lastWeekStat={entry.lastWeekInternalReview}
    />
    <Comparison
      allTimeStat={entry.allTimeInternalApprovalRate}
      thisWeekStat={entry.thisWeekinternalApprovalRate}
      lastWeekStat={entry.lastWeekinternalApprovalRate}
    />
    <Table.Td>{entry.brandReviewRemaining}</Table.Td>
    <Comparison
      allTimeStat={entry.allTimeClientApproval}
      thisWeekStat={entry.thisWeekClientApproval}
      lastWeekStat={entry.lastWeekClientApproval}
    />
    <Comparison
      allTimeStat={entry.allTimeClientReview}
      thisWeekStat={entry.thisWeekClientReview}
      lastWeekStat={entry.lastWeekClientReview}
    />
    <Comparison
      allTimeStat={entry.allTimeClientApprovalRate}
      thisWeekStat={entry.thisWeekClientApprovalRate}
      lastWeekStat={entry.lastWeekClientApprovalRate}
    />
    <Comparison
      allTimeStat={entry.allTimeEmailsSent}
      thisWeekStat={entry.thisWeekEmailsSent}
      lastWeekStat={entry.lastWeekEmailsSent}
    />
    <Comparison
      allTimeStat={entry.allTimeDiscussionStart}
      thisWeekStat={entry.thisWeekDiscussionStart}
      lastWeekStat={entry.lastWeekDiscussionStart}
    />
    <Comparison
      allTimeStat={entry.allTimeNegotiationStart}
      thisWeekStat={entry.thisWeekNegotiationStart}
      lastWeekStat={entry.lastWeekNegotiationStart}
    />
    <Comparison
      allTimeStat="--"
      thisWeekStat={entry.thisWeekContractInProgress}
      lastWeekStat={entry.lastWeekContractInProgress}
    />
    <Comparison
      allTimeStat={entry.allTimeContractComplete}
      thisWeekStat={entry.thisWeekContractComplete}
      lastWeekStat={entry.lastWeekContractComplete}
    />
    <Table.Td>
      {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
        entry.allTimeContractAmount / 100,
      )}
    </Table.Td>
    <Comparison
      allTimeStat="--"
      thisWeekStat={entry.thisWeekContractRejected}
      lastWeekStat={entry.lastWeekContractRejected}
    />
  </>
);

const CampaignProgressRow = ({
  entry,
  spendStats,
}: {
  entry: CampaignProgressEntry;
  spendStats: CampaignOverviewSpendStats;
}) => (
  <Table.Tr key={`${entry.brandId}-${entry.campaignId}-${entry.creatorSetId}`}>
    <Table.Td>{entry.campaignName}</Table.Td>
    <CampaignCreatorSetProgressRow entry={entry} />
    <SpendRowItem spendStats={spendStats} />
  </Table.Tr>
);

const CreatorSetProgressRow = ({ entry }: { entry: CampaignProgressEntry }) => (
  <Table.Tr key={`${entry.brandId}-${entry.campaignId}-${entry.creatorSetId}`}>
    <Table.Td>{entry.campaignName}</Table.Td>
    <Table.Td>{entry.creatorSetName}</Table.Td>
    <CampaignCreatorSetProgressRow entry={entry} />
  </Table.Tr>
);

function roundToDecimalPlace(num: number, places: number): number {
  const multiplier = 10 ** places;
  return Math.round(num * multiplier) / multiplier;
}

function sumCampaignProgressEntriesAcrossBrands(
  entries: CampaignProgressEntry[],
  testBrands: Set<number>,
): CampaignProgressEntry {
  const summedEntry: CampaignProgressEntry = {
    brandId: 0, // Assuming brandId should also be summed, adjust if necessary
    brandName: "All Brands (excluding test)", // Placeholder, decide how to handle string fields
    campaignId: 0, // Assuming campaignId should also be summed, adjust if necessary
    campaignName: "All Campaigns", // Placeholder
    creatorSetId: 0, // Assuming creatorSetId should also be summed, adjust if necessary
    creatorSetName: "All CreatorSets", // Placeholder
    allTimeClientApproval: 0,
    lastWeekClientApproval: 0,
    thisWeekClientApproval: 0,
    allTimeClientReview: 0,
    lastWeekClientReview: 0,
    thisWeekClientReview: 0,
    allTimeClientApprovalRate: 0,
    lastWeekClientApprovalRate: 0,
    thisWeekClientApprovalRate: 0,
    allTimeInternalApproval: 0,
    lastWeekInternalApproval: 0,
    thisWeekInternalApproval: 0,
    allTimeInternalReview: 0,
    lastWeekInternalReview: 0,
    thisWeekInternalReview: 0,
    allTimeInternalApprovalRate: 0,
    lastWeekinternalApprovalRate: 0,
    thisWeekinternalApprovalRate: 0,
    allTimeOpsApproved: 0,
    lastWeekOpsApproved: 0,
    thisWeekOpsApproved: 0,
    allTimeEmailsFound: 0,
    lastWeekEmailsFound: 0,
    thisWeekEmailsFound: 0,
    allTimeEmailsSent: 0,
    lastWeekEmailsSent: 0,
    thisWeekEmailsSent: 0,
    allTimeEmailSearchAttempted: 0,
    lastWeekEmailSearchAttempted: 0,
    thisWeekEmailSearchAttempted: 0,
    allTimeEmailSearchRequested: 0,
    lastWeekEmailSearchRequested: 0,
    thisWeekEmailSearchRequested: 0,
    allTimeDiscussionStart: 0,
    lastWeekDiscussionStart: 0,
    thisWeekDiscussionStart: 0,
    allTimeNegotiationStart: 0,
    lastWeekNegotiationStart: 0,
    thisWeekNegotiationStart: 0,
    allTimeCreatorReplies: 0,
    lastWeekCreatorReplies: 0,
    thisWeekCreatorReplies: 0,
    allTimeCollabResponse: 0,
    lastWeekCollabResponse: 0,
    thisWeekCollabResponse: 0,
    internalRemaining: 0,
    internalRemainingNoEmails: 0,
    brandReviewRemaining: 0,
    brandReviewRemainingNoEmails: 0,
    thisWeekContractCreated: 0,
    thisWeekContractPending: 0,
    thisWeekContractAccepted: 0,
    thisWeekContractInProgress: 0,
    thisWeekContractComplete: 0,
    thisWeekContractRejected: 0,
    lastWeekContractCreated: 0,
    lastWeekContractPending: 0,
    lastWeekContractAccepted: 0,
    lastWeekContractInProgress: 0,
    lastWeekContractComplete: 0,
    lastWeekContractRejected: 0,
    allTimeContractComplete: 0,
    allTimeContractAmount: 0,
  };

  entries
    .filter((entry) => !testBrands.has(entry.brandId))
    .forEach((entry) => {
      // Sum numeric fields
      summedEntry.allTimeClientApproval += entry.allTimeClientApproval;
      summedEntry.lastWeekClientApproval += entry.lastWeekClientApproval;
      summedEntry.thisWeekClientApproval += entry.thisWeekClientApproval;
      summedEntry.allTimeClientReview += entry.allTimeClientReview;
      summedEntry.lastWeekClientReview += entry.lastWeekClientReview;
      summedEntry.thisWeekClientReview += entry.thisWeekClientReview;
      summedEntry.allTimeInternalApproval += entry.allTimeInternalApproval;
      summedEntry.lastWeekInternalApproval += entry.lastWeekInternalApproval;
      summedEntry.thisWeekInternalApproval += entry.thisWeekInternalApproval;
      summedEntry.allTimeInternalReview += entry.allTimeInternalReview;
      summedEntry.lastWeekInternalReview += entry.lastWeekInternalReview;
      summedEntry.thisWeekInternalReview += entry.thisWeekInternalReview;
      summedEntry.allTimeOpsApproved += entry.allTimeOpsApproved;
      summedEntry.lastWeekOpsApproved += entry.lastWeekOpsApproved;
      summedEntry.thisWeekOpsApproved += entry.thisWeekOpsApproved;
      summedEntry.allTimeEmailsFound += entry.allTimeEmailsFound;
      summedEntry.lastWeekEmailsFound += entry.lastWeekEmailsFound;
      summedEntry.thisWeekEmailsFound += entry.thisWeekEmailsFound;
      summedEntry.allTimeEmailsSent += entry.allTimeEmailsSent;
      summedEntry.lastWeekEmailsSent += entry.lastWeekEmailsSent;
      summedEntry.thisWeekEmailsSent += entry.thisWeekEmailsSent;
      summedEntry.allTimeEmailSearchAttempted += entry.allTimeEmailSearchAttempted;
      summedEntry.lastWeekEmailSearchAttempted += entry.lastWeekEmailSearchAttempted;
      summedEntry.thisWeekEmailSearchAttempted += entry.thisWeekEmailSearchAttempted;
      summedEntry.allTimeEmailSearchRequested += entry.allTimeEmailSearchRequested;
      summedEntry.lastWeekEmailSearchRequested += entry.lastWeekEmailSearchRequested;
      summedEntry.thisWeekEmailSearchRequested += entry.thisWeekEmailSearchRequested;
      summedEntry.allTimeDiscussionStart += entry.allTimeDiscussionStart;
      summedEntry.lastWeekDiscussionStart += entry.lastWeekDiscussionStart;
      summedEntry.thisWeekDiscussionStart += entry.thisWeekDiscussionStart;
      summedEntry.allTimeNegotiationStart += entry.allTimeNegotiationStart;
      summedEntry.lastWeekNegotiationStart += entry.lastWeekNegotiationStart;
      summedEntry.thisWeekNegotiationStart += entry.thisWeekNegotiationStart;
      summedEntry.allTimeCreatorReplies += entry.allTimeCreatorReplies;
      summedEntry.lastWeekCreatorReplies += entry.lastWeekCreatorReplies;
      summedEntry.thisWeekCreatorReplies += entry.thisWeekCreatorReplies;
      summedEntry.allTimeCollabResponse += entry.allTimeCollabResponse;
      summedEntry.lastWeekCollabResponse += entry.lastWeekCollabResponse;
      summedEntry.thisWeekCollabResponse += entry.thisWeekCollabResponse;
      summedEntry.internalRemaining += entry.internalRemaining;
      summedEntry.internalRemainingNoEmails += entry.internalRemainingNoEmails;
      summedEntry.brandReviewRemaining += entry.brandReviewRemaining;
      summedEntry.brandReviewRemainingNoEmails += entry.brandReviewRemainingNoEmails;
      summedEntry.thisWeekContractCreated += entry.thisWeekContractCreated;
      summedEntry.thisWeekContractPending += entry.thisWeekContractPending;
      summedEntry.thisWeekContractAccepted += entry.thisWeekContractAccepted;
      summedEntry.thisWeekContractInProgress += entry.thisWeekContractInProgress;
      summedEntry.thisWeekContractComplete += entry.thisWeekContractComplete;
      summedEntry.thisWeekContractRejected += entry.thisWeekContractRejected;
      summedEntry.lastWeekContractCreated += entry.lastWeekContractCreated;
      summedEntry.lastWeekContractPending += entry.lastWeekContractPending;
      summedEntry.lastWeekContractAccepted += entry.lastWeekContractAccepted;
      summedEntry.lastWeekContractInProgress += entry.lastWeekContractInProgress;
      summedEntry.lastWeekContractComplete += entry.lastWeekContractComplete;
      summedEntry.lastWeekContractRejected += entry.lastWeekContractRejected;
      summedEntry.allTimeContractComplete += entry.allTimeContractComplete;
      summedEntry.allTimeContractAmount += entry.allTimeContractAmount;
    });

  summedEntry.allTimeClientApprovalRate = roundToDecimalPlace(
    summedEntry.allTimeClientApproval / summedEntry.allTimeClientReview,
    2,
  );
  summedEntry.lastWeekClientApprovalRate = roundToDecimalPlace(
    summedEntry.lastWeekClientApproval / summedEntry.lastWeekClientReview,
    2,
  );
  summedEntry.thisWeekClientApprovalRate = roundToDecimalPlace(
    summedEntry.thisWeekClientApproval / summedEntry.thisWeekClientReview,
    2,
  );
  summedEntry.allTimeInternalApprovalRate = roundToDecimalPlace(
    summedEntry.allTimeInternalApproval / summedEntry.allTimeInternalReview,
    2,
  );
  summedEntry.lastWeekinternalApprovalRate = roundToDecimalPlace(
    summedEntry.lastWeekInternalApproval / summedEntry.lastWeekInternalReview,
    2,
  );
  summedEntry.thisWeekinternalApprovalRate = roundToDecimalPlace(
    summedEntry.thisWeekInternalApproval / summedEntry.thisWeekInternalReview,
    2,
  );

  return summedEntry;
}

export const sumMixedCampaignSpendStats = (
  stats: CampaignOverviewSpendStats[],
): CampaignOverviewSpendStats => {
  const { budget, spent, allocated } = stats.reduce(
    (acc, stat) => {
      acc.budget += stat.budget;
      if (stat.budgetType === BudgetType.Once) {
        acc.spent += stat.stats.totalBudgetSpent;
        acc.allocated += stat.stats.totalBudgetAllocated;
      } else if (stat.budgetType === BudgetType.Monthly) {
        acc.spent += stat.stats.monthlyBudgetSpent;
        acc.allocated += stat.stats.monthlyBudgetAllocated;
      }
      return acc;
    },
    { budget: 0, spent: 0, allocated: 0 },
  );

  const totalStats = stats.reduce(
    (acc, curr) => {
      acc.totalSpent += curr.stats.totalSpent;
      acc.totalBudgetSpent += curr.stats.totalBudgetSpent;
      acc.totalBudgetAllocated += curr.stats.totalBudgetAllocated;
      acc.monthlySpent += curr.stats.monthlySpent;
      acc.monthlyBudgetSpent += curr.stats.monthlyBudgetSpent;
      acc.monthlyBudgetAllocated += curr.stats.monthlyBudgetAllocated;
      acc.numLiveContent += curr.stats.numLiveContent;
      acc.views += curr.stats.views;
      acc.cpm += curr.stats.cpm;
      acc.clicks += curr.stats.clicks;
      acc.cpc += curr.stats.cpc;
      return acc;
    },
    {
      totalSpent: 0,
      totalBudgetSpent: 0,
      totalBudgetAllocated: 0,
      monthlySpent: 0,
      monthlyBudgetSpent: 0,
      monthlyBudgetAllocated: 0,
      numLiveContent: 0,
      views: 0,
      cpm: 0,
      clicks: 0,
      cpc: 0,
    },
  );

  return {
    budget,
    budgetType: 0,
    stats: totalStats,
    spent,
    allocated,
  };
};

function sumCampaignSpendsAcrossBrands(
  entries: CampaignProgressEntry[],
  testBrands: Set<number>,
  campaignEntries: Record<string, CampaignProgressEntry[]>,
  testCampaigns: Set<number>,
  spendStats: Record<number, CampaignOverviewSpendStats>,
) {
  const allSpends: CampaignOverviewSpendStats[] = [];
  entries
    .filter((entry) => !testBrands.has(entry.brandId))
    .forEach((entry, index) => {
      const campaignIds = campaignEntries[entry.brandName]
        .map((campaignEntry, _) => campaignEntry.campaignId)
        .filter((campaignId) => !testCampaigns.has(campaignId));
      const campaignSpends =
        Object.keys(spendStats).length > 0
          ? campaignIds.map((campaignId) => spendStats[campaignId])
          : [];
      // If there's a monthly spend, then just sum those, if not then sum
      // only the one time spend
      const monthlySpends = campaignSpends.filter(
        (campaignSpend) => campaignSpend?.budgetType === BudgetType.Monthly,
      );
      const oneTimeSpend = campaignSpends.filter(
        (campaignSpend) => campaignSpend?.budgetType === BudgetType.Once,
      );
      const spends = monthlySpends.length > 0 ? monthlySpends : oneTimeSpend;
      // Rolling up to the aggregate, we want to see the total budget for this month only.
      const brandSpend = sumCampaignSpendStats(spends, true);
      // Consider the brand spend as monthly regardless of whether or not it truly is, for the
      // purposes of the aggregation.  The brand spend stats have already subtracted prior month
      // spending.
      brandSpend.budgetType = BudgetType.Monthly;
      allSpends.push(brandSpend);
    });

  return sumMixedCampaignSpendStats(allSpends);
}

const AllBrandProgressSumEntry = ({
  brandEntries,
  testBrands,
  campaignEntries,
  testCampaigns,
  spendStats,
  isSimple = false,
}: {
  brandEntries: CampaignProgressEntry[];
  testBrands: Set<number>;
  campaignEntries: Record<string, CampaignProgressEntry[]>;
  testCampaigns: Set<number>;
  spendStats: Record<number, CampaignOverviewSpendStats>;
  isSimple?: boolean;
}) => (
  <BrandProgressRow
    entry={sumCampaignProgressEntriesAcrossBrands(brandEntries, testBrands)}
    spendStats={sumCampaignSpendsAcrossBrands(
      brandEntries,
      testBrands,
      campaignEntries,
      testCampaigns,
      spendStats,
    )}
    isMixedSpendStats
    isSimple={isSimple}
  />
);

const BrandProgressDisplay = ({
  title,
  brandEntries,
  testBrands,
  campaignEntries,
  testCampaigns,
  spendStats,
  isSimple = false,
}: {
  title: string;
  brandEntries: CampaignProgressEntry[];
  testBrands: Set<number>;
  campaignEntries: Record<string, CampaignProgressEntry[]>;
  testCampaigns: Set<number>;
  spendStats: Record<number, CampaignOverviewSpendStats>;
  isSimple?: boolean;
}) => (
  <Paper>
    <Text size="lg">{title}</Text>
    <Table
      stickyHeader
      stickyHeaderOffset={70}
      striped
      highlightOnHover
      withTableBorder
      withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Brand</Table.Th>
          <Table.Th>Ops Approved</Table.Th>
          {!isSimple ? (
            <>
              <Table.Th>Internal Rev. No Emails</Table.Th>
              {/* <Table.Th>Email Search Requested</Table.Th> */}
              <Table.Th>Email Search Attempted</Table.Th>
              <Table.Th>Email Found</Table.Th>
            </>
          ) : null}
          <Table.Th>Internal Remaining.</Table.Th>
          <Table.Th>Internal Approval</Table.Th>
          {!isSimple ? (
            <>
              <Table.Th>Internal Reviews</Table.Th>
              <Table.Th>Internal Approval Rate</Table.Th>
            </>
          ) : null}
          <Table.Th>Brand Needs Review</Table.Th>
          <Table.Th>Client Approval</Table.Th>
          {!isSimple ? (
            <>
              <Table.Th>Client Reviews</Table.Th>
              <Table.Th>Client Approval Rate</Table.Th>
            </>
          ) : null}
          <Table.Th>Emails Sent</Table.Th>
          <Table.Th>Discussion Start</Table.Th>
          {!isSimple ? (
            <>
              <Table.Th>Negotiation Start</Table.Th>
              <Table.Th>Contracts Accepted</Table.Th>
            </>
          ) : null}
          <Table.Th>Contracts Completed</Table.Th>
          <Table.Th>All Time $$</Table.Th>
          <Table.Th>Spend</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {brandEntries.map((entry, index) => {
          const campaignIds = campaignEntries[entry.brandName].map(
            (campaignEntry, _) => campaignEntry.campaignId,
          );
          const campaignSpends =
            Object.keys(spendStats).length > 0
              ? campaignIds.map((campaignId) => spendStats[campaignId])
              : [];
          // If there's a monthly spend, then just sum those, if not then sum
          // only the one time spend
          const monthlySpends = campaignSpends.filter(
            (campaignSpend) => campaignSpend?.budgetType === BudgetType.Monthly,
          );
          const oneTimeSpend = campaignSpends.filter(
            (campaignSpend) => campaignSpend?.budgetType === BudgetType.Once,
          );
          const spends = monthlySpends.length > 0 ? monthlySpends : oneTimeSpend;
          const brandSpend = sumCampaignSpendStats(spends);
          return (
            <BrandProgressRow
              key={`${entry.brandId}`}
              entry={entry}
              spendStats={brandSpend}
              isSimple={isSimple}
            />
          );
        })}
        <AllBrandProgressSumEntry
          brandEntries={brandEntries}
          testBrands={testBrands}
          campaignEntries={campaignEntries}
          testCampaigns={testCampaigns}
          spendStats={spendStats}
          isSimple={isSimple}
        />
      </Table.Tbody>
    </Table>
  </Paper>
);

const DetailBrandProgressDisplay = ({
  brandEntries,
  testBrands,
  campaignEntries,
  testCampaigns,
  spendStats,
}: {
  brandEntries: CampaignProgressEntry[];
  testBrands: Set<number>;
  campaignEntries: Record<string, CampaignProgressEntry[]>;
  testCampaigns: Set<number>;
  spendStats: Record<number, CampaignOverviewSpendStats>;
}) => (
  <BrandProgressDisplay
    title="Detailed Brand Progress"
    brandEntries={brandEntries}
    testBrands={testBrands}
    campaignEntries={campaignEntries}
    testCampaigns={testCampaigns}
    spendStats={spendStats}
  />
);

const SimpleBrandProgressDisplay = ({
  brandEntries,
  testBrands,
  campaignEntries,
  testCampaigns,
  spendStats,
}: {
  brandEntries: CampaignProgressEntry[];
  testBrands: Set<number>;
  campaignEntries: Record<string, CampaignProgressEntry[]>;
  testCampaigns: Set<number>;
  spendStats: Record<number, CampaignOverviewSpendStats>;
}) => (
  <BrandProgressDisplay
    title="Brand Progress"
    brandEntries={brandEntries}
    testBrands={testBrands}
    campaignEntries={campaignEntries}
    testCampaigns={testCampaigns}
    spendStats={spendStats}
    isSimple
  />
);

const CampaignCreatorSetColumns = () => (
  <>
    <Table.Th>Ops Approved</Table.Th>
    <Table.Th>Internal Remaining no emails</Table.Th>
    {/* <Table.Th>Email Search Requested</Table.Th> */}
    <Table.Th>Email Search Attempted</Table.Th>
    <Table.Th>Emails Found</Table.Th>
    <Table.Th>Internal Remaining.</Table.Th>
    <Table.Th>Internal Approval</Table.Th>
    <Table.Th>Internal Reviews</Table.Th>
    <Table.Th>Internal Approval Rate</Table.Th>
    <Table.Th>Brand Needs Review</Table.Th>
    <Table.Th>Client Approval</Table.Th>
    <Table.Th>Client Reviews</Table.Th>
    <Table.Th>Client Approval Rate</Table.Th>
    <Table.Th>Emails Sent</Table.Th>
    <Table.Th>Discussion Start</Table.Th>
    <Table.Th>Negotiation Start</Table.Th>
    <Table.Th>Contracts Accepted</Table.Th>
    <Table.Th>Contracts Completed</Table.Th>
    <Table.Th>All time $$</Table.Th>
    <Table.Th>Contracts Expired</Table.Th>
  </>
);

const CampaignProgressTable = ({
  campaignEntries,
  spendStats,
}: {
  campaignEntries: CampaignProgressEntry[];
  spendStats: Record<number, CampaignOverviewSpendStats>;
}) => (
  <Table
    stickyHeader
    stickyHeaderOffset={70}
    striped
    highlightOnHover
    withTableBorder
    withColumnBorders>
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Campaign</Table.Th>
        <CampaignCreatorSetColumns />
        <Table.Th>Spend</Table.Th>
      </Table.Tr>
    </Table.Thead>
    {campaignEntries.map((entry, index) => (
      <CampaignProgressRow
        key={`campaign-${entry.brandId}-${entry.campaignId}`}
        entry={entry}
        spendStats={spendStats[entry.campaignId]}
      />
    ))}
  </Table>
);

const CreatorSetProgressTable = ({
  creatorSetEntries,
}: {
  creatorSetEntries: CampaignProgressEntry[];
}) => (
  <Table
    stickyHeader
    stickyHeaderOffset={70}
    striped
    highlightOnHover
    withTableBorder
    withColumnBorders>
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Campaign</Table.Th>
        <Table.Th>Creator Set</Table.Th>
        <CampaignCreatorSetColumns />
      </Table.Tr>
    </Table.Thead>
    {creatorSetEntries.map((entry, index) => (
      <CreatorSetProgressRow
        key={`creatorset-${entry.brandId}-${entry.campaignId}-${entry.creatorSetId}`}
        entry={entry}
      />
    ))}
  </Table>
);

const CampaignProgressSection = ({
  brandName,
  brandId,
  campaignEntries,
  creatorSetEntries,
  spendStats,
}: {
  brandName: string;
  brandId: number;
  campaignEntries: CampaignProgressEntry[];
  creatorSetEntries: CampaignProgressEntry[];
  spendStats: Record<number, CampaignOverviewSpendStats>;
}) => (
  <Paper>
    <Text size="s" id={String(brandId)}>
      {brandName}
    </Text>
    <Stack gap="sm">
      <CampaignProgressTable campaignEntries={campaignEntries} spendStats={spendStats} />
      <CreatorSetProgressTable creatorSetEntries={creatorSetEntries} />
    </Stack>
  </Paper>
);

export default function CampaignProgress({ user }: { user: User }) {
  const [isLoading, setIsLoading] = useState(false);
  const [allBrandNames, setAllBrandNames] = useState<string[]>([]);
  const [allBrandProgress, setAllBrandProgress] = useState<CampaignProgressEntry[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  // Ids of brand marked as test
  const [testBrands, setTestBrands] = useState<Set<number>>(new Set<number>());
  const [brandEntries, setBrandEntries] = useState<CampaignProgressEntry[]>([]);
  const [brandToBrandProgress, setBrandToBrandProgress] = useState<
    Record<string, CampaignProgressEntry>
  >({});
  const [brandToCampaignProgress, setBrandToCampaignProgress] = useState<
    Record<string, CampaignProgressEntry[]>
  >({});
  const [brandToCreatorSetProgress, setBrandToCreatorSetProgress] = useState<
    Record<string, CampaignProgressEntry[]>
  >({});
  const [campaigns, setCampaigns] = useState([]);
  // Ids of campaigns marked as test
  const [testCampaigns, setTestCampaigns] = useState(new Set<number>());
  const [campaignSpendStats, setCampaignSpendStats] = useState<
    Record<number, CampaignOverviewSpendStats>
  >({});

  useEffect(() => {
    setIsLoading(true);
    const abortController = new AbortController();
    getAndSetCampaigns(user, setCampaigns, abortController);
    fetchCampaignProgressData(user)
      .then((response: CampaignProgressResponse) => {
        const { success, rawProgress, brandProgress, campaignProgress } = response;
        if (success) {
          const allBrands = Array.from(new Set(brandProgress.map((entry) => entry.brandName)));
          const defaultSelectedBrands = Array.from(
            new Set(brandProgress.map((entry) => entry.brandName)),
          );
          setAllBrandNames(allBrands);
          setSelectedBrands(defaultSelectedBrands);
          setAllBrandProgress(brandProgress);

          const brandNameToEntryMap: { [key: string]: CampaignProgressEntry } =
            brandProgress.reduce((acc, obj) => ({ ...acc, [obj.brandName]: obj }), {});
          setBrandToBrandProgress(brandNameToEntryMap);

          const brandNameToCampaignProgress: { [key: string]: CampaignProgressEntry[] } =
            campaignProgress.reduce((acc: Record<string, CampaignProgressEntry[]>, obj) => {
              acc[obj.brandName] = [...(acc[obj.brandName] || []), obj];
              return acc;
            }, {});
          setBrandToCampaignProgress(brandNameToCampaignProgress);

          const brandNameToCreatorSetProgress: { [key: string]: CampaignProgressEntry[] } =
            rawProgress.reduce((acc: Record<string, CampaignProgressEntry[]>, obj) => {
              acc[obj.brandName] = [...(acc[obj.brandName] || []), obj];
              return acc;
            }, {});
          setBrandToCreatorSetProgress(brandNameToCreatorSetProgress);
        }
      })
      .finally(() => setIsLoading(false));
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    if (campaigns.length === 0) {
      return () => {};
    }

    // Campaign ids flagged as test (for test campaigns in non-test brands)
    const testCampaignIds = new Set<number>(
      campaigns.filter((campaign) => campaign.is_test_campaign).map((campaign) => campaign.id),
    );
    setTestCampaigns(testCampaignIds);

    // Test brand names, for which we assume all campaigns are test
    const testBrandIds = new Set<number>(
      campaigns
        .filter((campaign) => campaign.brand.is_test_brand)
        .map((campaign) => campaign.brand.id),
    );
    setTestBrands(testBrandIds);

    Promise.all(
      campaigns.map((campaign: Campaign) =>
        fetchCampaignOverview(campaign.hash_id, abortController, false).then(
          (progressReport: CampaignProgressReportResponse) => ({
            id: campaign.id,
            progressReport,
          }),
        ),
      ),
    ).then((results) => {
      setCampaignSpendStats((prevState) => {
        const newState = { ...prevState };
        results.forEach(({ id, progressReport }) => {
          if (progressReport == null) {
            return;
          }
          newState[id] = {
            budget: progressReport.budget,
            budgetType: progressReport.budget_type,
            spentBeforeThisMonth: progressReport.spent_before_this_month,
            stats: getCampaignAdGroupOverallStats(
              translateCampaignProgressResponse(progressReport),
            ),
          };
        });
        return newState;
      });
    });

    return () => {
      abortController.abort();
    };
  }, [campaigns]);

  const selectorOptions = allBrandNames.map((brandName) => ({
    value: brandName,
    label: brandName,
  }));

  useEffect(() => {
    // Grab the entries by the selected campaign and then set the entries
    const selectedBrandsSet = new Set(selectedBrands);
    const selectedBrandEntries = allBrandProgress.filter((entry) =>
      selectedBrandsSet.has(entry.brandName),
    );
    setBrandEntries(selectedBrandEntries);
  }, [selectedBrands]);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {!isLoading && (
        <Container fluid>
          <Stack>
            <MultiSelect
              label="Selected Campaigns"
              data={selectorOptions}
              value={selectedBrands}
              onChange={setSelectedBrands}
              clearable
              searchable
            />
            <SimpleBrandProgressDisplay
              brandEntries={brandEntries}
              testBrands={testBrands}
              campaignEntries={brandToCampaignProgress}
              testCampaigns={testCampaigns}
              spendStats={campaignSpendStats}
            />
            <Spacer height={50} />
            <DetailBrandProgressDisplay
              brandEntries={brandEntries}
              testBrands={testBrands}
              campaignEntries={brandToCampaignProgress}
              testCampaigns={testCampaigns}
              spendStats={campaignSpendStats}
            />
            {Object.keys(brandToBrandProgress).length > 0 &&
              selectedBrands.map((brandName) => (
                <CampaignProgressSection
                  key={brandToBrandProgress[brandName].brandId}
                  brandName={brandName}
                  brandId={brandToBrandProgress[brandName].brandId}
                  campaignEntries={brandToCampaignProgress[brandName]}
                  creatorSetEntries={brandToCreatorSetProgress[brandName]}
                  spendStats={campaignSpendStats}
                />
              ))}
          </Stack>
        </Container>
      )}
    </>
  );
}
