import React, { useEffect, useRef, useState } from "react";
import { Anchor, Image, Stack, Text, Skeleton, Flex, Tooltip } from "@mantine/core";

import { useInViewport } from "@mantine/hooks";

import Spacer from "components/Spacer";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { MediaEntry } from "components/MediaBlockCard";
import { timeAgo, isNotValidTimeAgo } from "utils/TimeUtils";

import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { IconBrandInstagram, IconBrandTiktok, IconBrandYoutubeFilled } from "@tabler/icons-react";

const PlatformIcon = ({ platform }: { platform: string }) => {
  switch (platform) {
    case "instagram":
      return (
        <Tooltip label="Instagram">
          <IconBrandInstagram size={16} color="var(--mantine-color-gray-6)" />
        </Tooltip>
      );
    case "tiktok":
      return (
        <Tooltip label="Tiktok">
          <IconBrandTiktok size={16} color="var(--mantine-color-gray-6)" />
        </Tooltip>
      );
    case "youtube":
      return (
        <Tooltip label="Youtube">
          <IconBrandYoutubeFilled size={16} color="var(--mantine-color-gray-6)" />
        </Tooltip>
      );
    default:
      return null;
  }
};

const fetchVideoThumbnail = async (
  videoId: string,
  platform: string,
  abortController: AbortController,
) => {
  const requestUrl = new URL(`${API_URL}/api/social/fetch_video_thumbnail`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      videoId,
      platform,
    }),
  });

  const response = await handleResult(request, abortController);
  return response;
};

interface DisplayInfo {
  height?: number;
  width?: number;
  maxVideosToDisplay: number;
}

const PLATFORM_DISPLAY_INFO_MAP: Record<string, DisplayInfo> = {
  youtube: {
    // assets seem to be 4:3
    width: 295,
    height: 217.5,
    maxVideosToDisplay: 8,
  },
  tiktok: {
    // assets seem to be 3:4
    width: 217.5,
    height: 295,
    maxVideosToDisplay: 8,
  },
  instagram: {
    // assets seem to be 9:16
    width: 165,
    height: 294,
    maxVideosToDisplay: 8,
  },
};

export default function Video({
  video,
  refetchThumbnails,
  height,
  width,
  includePlatform,
}: {
  video: MediaEntry;
  refetchThumbnails: boolean;
  // A given height we should use, otherwise use the value from the platform table
  height?: number;
  // A given height weshould use, otherwise use the value from the platform table
  width?: number;
  // Include a platform icon in the details. This only shows if the details row shows.
  includePlatform?: boolean;
}) {
  const { ref, inViewport } = useInViewport();
  const abortControllerRef = useRef(null);

  const videoHasViews = (video.views || 0) > 0;
  const videoHasTimeStamp = video.creation_timestamp !== null;

  const [refetchLoading, setRefetchLoading] = useState(false);

  const [thumbnailError, setThumbnailError] = useState(false);
  const [refetchSuccessful, setRefetchSuccessful] = useState(false);

  const showSkeleton = thumbnailError && !refetchSuccessful;

  const handleFetchThumbnail = async (abortController: AbortController, retryCount = 0) => {
    setRefetchLoading(true);

    // Maximum number of retry attempts
    const maxRetries = 5;
    // Base delay time in milliseconds
    const baseDelay = 1000;
    // Randomization factor (jitter)
    const jitter = 0.5; // 50% of the delay

    fetchVideoThumbnail(video.video_id, video.platform, abortController)
      .then((response: any) => {
        const { success } = response;
        if (success) {
          setRefetchSuccessful(true);
          setThumbnailError(false);
          setRefetchLoading(false);
        } else if (retryCount < maxRetries) {
          // Calculate exponential backoff delay with random jitter
          const delay = baseDelay * 2 ** retryCount;
          const randomJitter = delay * jitter * (Math.random() - 0.5) * 2;
          setTimeout(() => {
            handleFetchThumbnail(abortController, retryCount + 1);
          }, delay + randomJitter);
        } else {
          setRefetchLoading(false);
        }
      })
      .catch(() => {
        if (retryCount < maxRetries) {
          const delay = baseDelay * 2 ** retryCount;
          const randomJitter = delay * jitter * (Math.random() - 0.5) * 2;
          setTimeout(() => {
            handleFetchThumbnail(abortController, retryCount + 1);
          }, delay + randomJitter);
        } else {
          setRefetchLoading(false);
        }
      });
  };

  useEffect(() => {
    // Create a new AbortController only when needed
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }

    // Start fetch if conditions are met
    if (refetchThumbnails && thumbnailError && inViewport && !refetchLoading) {
      handleFetchThumbnail(abortControllerRef.current);
    }

    // Check if the video is out of the viewport to abort fetching
    if (!inViewport) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    // Cleanup function to abort fetch on unmount
    return () => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = null;
    };
  }, [video, thumbnailError, inViewport]);

  const heightToUse = height ?? PLATFORM_DISPLAY_INFO_MAP[video.platform].height;
  const widthToUse = width ?? PLATFORM_DISPLAY_INFO_MAP[video.platform].width;

  return (
    <Stack
      ref={ref}
      gap={0}
      align="flex-start"
      justify="flex-start"
      style={{
        flexBasis: "100%",
        maxWidth: `${widthToUse}px`,
        minWidth: `${widthToUse}px`,
      }}>
      <Anchor href={video.video_url} target="_blank">
        {showSkeleton ? (
          <Skeleton
            height={heightToUse}
            width={widthToUse}
            animate={refetchThumbnails && refetchLoading}
          />
        ) : (
          <Image
            style={{
              width: widthToUse,
              height: heightToUse,
              borderRadius: 8,
              // A color to use when the image does not fill the box.
              backgroundColor: height || width ? "#DEE2E6" : undefined,
            }}
            // Fit set to contain so that it doesn't fill the box if it is not sized appropriately
            fit={height || width ? "contain" : undefined}
            src={video.thumbnail}
            onError={() => setThumbnailError(true)}
          />
        )}
      </Anchor>
      <>
        <Spacer height={8} />
        <Text
          lineClamp={2}
          style={{
            width: "100%",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "155%",
          }}>
          {video.title}
        </Text>
        {videoHasViews || videoHasTimeStamp ? (
          <Flex gap={0} wrap="nowrap" align="center">
            {includePlatform && <PlatformIcon platform={video.platform} />}
            <Text
              style={{
                width: "100%",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "155%",
                color: "var(--mantine-color-gray-6)",
              }}>
              {includePlatform ? <>&nbsp;&bull;&nbsp;</> : null}
              {videoHasViews ? `${getAbbreviatedNumber(video.views)} Views` : null}
              {videoHasViews &&
              videoHasTimeStamp &&
              !isNotValidTimeAgo(video.creation_timestamp) ? (
                <>&nbsp;&bull;&nbsp;</>
              ) : null}
              {videoHasTimeStamp ? timeAgo(video.creation_timestamp) : null}
            </Text>
          </Flex>
        ) : null}
      </>
    </Stack>
  );
}
