import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ActionIcon,
  Box,
  Divider,
  Flex,
  LoadingOverlay,
  MultiSelect,
  Select,
  Stack,
  Tooltip,
} from "@mantine/core";

import { IconArrowDown, IconArrowUp, IconFilter, IconFilterOff } from "@tabler/icons-react";

import { fromISODateString } from "utils/DateUtils";

import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import LiveContentModel from "components/contracts/models/LiveContentModel";
import { SupportedPlatform } from "models/Common";
import { INTEGRATED_FORMATS, SHORT_FORM_FORMATS } from "components/contracts/common/Common";

import ContentCard from "components/contracts/content/ContentCard";
import LoadingError from "components/contracts/common/LoadingError";

import MasonryLayout from "components/contracts/content/MasonryLayout";
import { fetchContentLibrary, fetchContentLibraryForUser } from "components/contracts/common/Api";

enum SortBy {
  PUBLISHED_AT = "published_at",
  VIEWS = "views",
  LIKES = "likes",
  USAGE_RIGHTS_EXPIRATION_DATE = "usage_rights_expiration_date",
}

enum FilterBy {
  // Platform Filters
  PLATFORM_INSTAGRAM = "Instagram",
  PLATFORM_TIKTOK = "TikTok",
  PLATFORM_YOUTUBE = "YouTube",
  NONPLATFORM_UGC = "UGC",

  // Format Filters
  FORMAT_SHORT_FORM = "Short-Form",
  FORMAT_LONG_FORM = "Long-Form",

  // Partnership Type
  PARTNERSHIP_INTEGRATION = "Integration",
  PARTNERSHIP_DEDICATED = "Dedicated",

  // Usage Rights Filters
  USAGE_RIGHTS_EXPIRED = "Usage Rights Expired",
  USAGE_RIGHTS_ACTIVE = "Usage Rights Active",
  USAGE_RIGHTS_NONE = "No Usage Rights",
}

enum FilterGroup {
  PLATFORM = "Platform",
  FORMAT = "Format",
  TYPE = "Type",
  USAGE_RIGHTS = "Usage Rights",
}

const FILTER_TO_GROUP = {
  [FilterBy.PLATFORM_INSTAGRAM]: FilterGroup.PLATFORM,
  [FilterBy.PLATFORM_TIKTOK]: FilterGroup.PLATFORM,
  [FilterBy.PLATFORM_YOUTUBE]: FilterGroup.PLATFORM,
  [FilterBy.NONPLATFORM_UGC]: FilterGroup.PLATFORM,
  [FilterBy.FORMAT_SHORT_FORM]: FilterGroup.FORMAT,
  [FilterBy.FORMAT_LONG_FORM]: FilterGroup.FORMAT,
  [FilterBy.PARTNERSHIP_INTEGRATION]: FilterGroup.TYPE,
  [FilterBy.PARTNERSHIP_DEDICATED]: FilterGroup.TYPE,
  [FilterBy.USAGE_RIGHTS_EXPIRED]: FilterGroup.USAGE_RIGHTS,
  [FilterBy.USAGE_RIGHTS_ACTIVE]: FilterGroup.USAGE_RIGHTS,
  [FilterBy.USAGE_RIGHTS_NONE]: FilterGroup.USAGE_RIGHTS,
};

function getSortFn(sortBy: SortBy, sortOrder: "asc" | "desc") {
  return (a: any, b: any) => {
    let aVal;
    let bVal;
    switch (sortBy) {
      case SortBy.USAGE_RIGHTS_EXPIRATION_DATE:
        if (
          a.usage_rights_in_perpetuity ||
          (a as DeliverableVideo).deliverable?.usageRightsInPerpetuity
        ) {
          aVal = new Date(8640000000000000);
        } else {
          aVal = a[sortBy]
            ? fromISODateString(a[sortBy])
            : (a as DeliverableVideo).deliverable?.usageRightsExpirationDate || null;
        }
        if (
          b.usage_rights_in_perpetuity ||
          (b as DeliverableVideo).deliverable?.usageRightsInPerpetuity
        ) {
          bVal = new Date(8640000000000000);
        } else {
          bVal = b[sortBy]
            ? fromISODateString(b[sortBy])
            : (b as DeliverableVideo).deliverable?.usageRightsExpirationDate || null;
        }

        if (aVal === null && bVal === null) {
          // Switch to secondary sort
          aVal = a[SortBy.PUBLISHED_AT]
            ? fromISODateString(a[SortBy.PUBLISHED_AT])
            : (a as DeliverableVideo)?.dateCreated;
          bVal = b[SortBy.PUBLISHED_AT]
            ? fromISODateString(b[SortBy.PUBLISHED_AT])
            : (b as DeliverableVideo)?.dateCreated;
          break;
        }

        if (sortOrder === "asc") {
          if (aVal === null) {
            return -1;
          } else if (bVal === null) {
            return 1;
          }
        }
        if (aVal === null) {
          return 1;
        } else if (bVal === null) {
          return -1;
        }
        break;
      case SortBy.PUBLISHED_AT:
        // LiveContentModel published at dates are strings, DeliverableVideo created dates are Dates
        aVal = a[SortBy.PUBLISHED_AT]
          ? fromISODateString(a[SortBy.PUBLISHED_AT])
          : (a as DeliverableVideo)?.dateCreated;
        bVal = b[SortBy.PUBLISHED_AT]
          ? fromISODateString(b[SortBy.PUBLISHED_AT])
          : (b as DeliverableVideo)?.dateCreated;
        break;
      default:
        aVal = a[sortBy] || 0;
        bVal = b[sortBy] || 0;
        break;
    }

    if (sortOrder === "asc") {
      if (aVal > bVal) {
        return 1;
      } else if (bVal > aVal) {
        return -1;
      }
      return 0;
    }

    // sort order desc
    if (aVal > bVal) {
      return -1;
    } else if (bVal > aVal) {
      return 1;
    }
    return 0;
  };
}

const falseFn = () => false;
const trueFn = () => true;

function getLiveFilterFn(filter: FilterBy) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  switch (filter) {
    case FilterBy.PLATFORM_INSTAGRAM:
      return (content: LiveContentModel) => content.platform === SupportedPlatform.INSTAGRAM;
    case FilterBy.PLATFORM_TIKTOK:
      return (content: LiveContentModel) => content.platform === SupportedPlatform.TIKTOK;
    case FilterBy.PLATFORM_YOUTUBE:
      return (content: LiveContentModel) => content.platform === SupportedPlatform.YOUTUBE;
    case FilterBy.NONPLATFORM_UGC:
      return falseFn;
    case FilterBy.FORMAT_SHORT_FORM:
      return (content: LiveContentModel) => SHORT_FORM_FORMATS.includes(content.format);
    case FilterBy.FORMAT_LONG_FORM:
      return (content: LiveContentModel) => !SHORT_FORM_FORMATS.includes(content.format);
    case FilterBy.PARTNERSHIP_INTEGRATION:
      return (content: LiveContentModel) => INTEGRATED_FORMATS.includes(content.format);
    case FilterBy.PARTNERSHIP_DEDICATED:
      return (content: LiveContentModel) => !INTEGRATED_FORMATS.includes(content.format);
    case FilterBy.USAGE_RIGHTS_EXPIRED:
      return (content: LiveContentModel) =>
        content.usage_rights_expiration_date !== null &&
        !content.usage_rights_in_perpetuity &&
        new Date(content.usage_rights_expiration_date) < currentDate;
    case FilterBy.USAGE_RIGHTS_ACTIVE:
      return (content: LiveContentModel) =>
        content.usage_rights_in_perpetuity ||
        new Date(content.usage_rights_expiration_date) >= currentDate;
    case FilterBy.USAGE_RIGHTS_NONE:
      return (content: LiveContentModel) =>
        !content.usage_rights_in_perpetuity && content.usage_rights_expiration_date === null;
    default:
      return () => true;
  }
}

function getUgcFilterFn(filter: FilterBy) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  switch (filter) {
    case FilterBy.PLATFORM_INSTAGRAM:
      return falseFn;
    case FilterBy.PLATFORM_TIKTOK:
      return falseFn;
    case FilterBy.PLATFORM_YOUTUBE:
      return falseFn;
    case FilterBy.NONPLATFORM_UGC:
      return trueFn;
    case FilterBy.FORMAT_SHORT_FORM:
      return falseFn;
    case FilterBy.FORMAT_LONG_FORM:
      return falseFn;
    case FilterBy.PARTNERSHIP_INTEGRATION:
      return falseFn;
    case FilterBy.PARTNERSHIP_DEDICATED:
      return falseFn;
    case FilterBy.USAGE_RIGHTS_EXPIRED:
      return (content: DeliverableVideo) =>
        content.deliverable.usageRightsExpirationDate !== null &&
        !content.deliverable.usageRightsInPerpetuity &&
        new Date(content.deliverable.usageRightsExpirationDate) < currentDate;
    case FilterBy.USAGE_RIGHTS_ACTIVE:
      return (content: DeliverableVideo) =>
        content.deliverable.usageRightsInPerpetuity ||
        new Date(content.deliverable.usageRightsExpirationDate) >= currentDate;
    case FilterBy.USAGE_RIGHTS_NONE:
      // Technically impossible, UGC must have usage rights.
      return (content: DeliverableVideo) =>
        !content.deliverable.usageRightsInPerpetuity &&
        content.deliverable.usageRightsExpirationDate === null;
    default:
      return () => true;
  }
}

function SortAndFilter({
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  filters,
  setFilters,
}: {
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortOrder: "asc" | "desc";
  setSortOrder: (sortOrder: "asc" | "desc") => void;
  filters: FilterBy[];
  setFilters: (filters: FilterBy[]) => void;
}) {
  return (
    <Flex gap="xs" mx="xl">
      <Select
        leftSection={
          <Tooltip
            arrowPosition="side"
            label={`${sortOrder === "asc" ? "Ascending" : "Descending"}`}>
            <ActionIcon
              w="sm"
              mx="xs"
              variant="transparent"
              c="gray"
              onClick={() => {
                if (sortOrder === "desc") {
                  setSortOrder("asc");
                } else {
                  setSortOrder("desc");
                }
              }}>
              {sortOrder === "asc" ? (
                <IconArrowUp color="gray" size="1rem" />
              ) : (
                <IconArrowDown color="gray" size="1rem" />
              )}
            </ActionIcon>
          </Tooltip>
        }
        data={[
          { value: SortBy.PUBLISHED_AT, label: "Publish Date" },
          { value: SortBy.VIEWS, label: "Views" },
          { value: SortBy.LIKES, label: "Likes" },
          { value: SortBy.USAGE_RIGHTS_EXPIRATION_DATE, label: "Usage Rights Expiration" },
        ]}
        value={sortBy}
        onChange={setSortBy}
        w={230}
      />
      <MultiSelect
        leftSection={
          filters.length === 0 ? <IconFilterOff size="1rem" /> : <IconFilter size="1rem" />
        }
        placeholder="Add filters..."
        data={[
          {
            group: FilterGroup.PLATFORM.valueOf(),
            items: [
              { value: FilterBy.PLATFORM_INSTAGRAM, label: "Instagram" },
              { value: FilterBy.PLATFORM_TIKTOK, label: "TikTok" },
              { value: FilterBy.PLATFORM_YOUTUBE, label: "YouTube" },
              { value: FilterBy.NONPLATFORM_UGC, label: "UGC" },
            ],
          },
          {
            group: FilterGroup.FORMAT.valueOf(),
            items: [
              { value: FilterBy.FORMAT_SHORT_FORM, label: "Short-Form" },
              { value: FilterBy.FORMAT_LONG_FORM, label: "Long-Form" },
            ],
          },
          {
            group: FilterGroup.TYPE.valueOf(),
            items: [
              { value: FilterBy.PARTNERSHIP_DEDICATED, label: "Dedicated" },
              { value: FilterBy.PARTNERSHIP_INTEGRATION, label: "Integration" },
            ],
          },
          {
            group: FilterGroup.USAGE_RIGHTS.valueOf(),
            items: [
              { value: FilterBy.USAGE_RIGHTS_ACTIVE, label: "Usage Rights Active" },
              { value: FilterBy.USAGE_RIGHTS_EXPIRED, label: "Usage Rights Expired" },
              { value: FilterBy.USAGE_RIGHTS_NONE, label: "No Usage Rights" },
            ],
          },
        ]}
        value={filters}
        onChange={setFilters}
        clearable
      />
    </Flex>
  );
}

const makeContentCard = (
  content: LiveContentModel | DeliverableVideo,
  contractIdToLiveContent: Map<string, LiveContentModel[]>,
  contractIdToDeliverableVideo: Map<string, DeliverableVideo[]>,
) => {
  if (!(content instanceof DeliverableVideo)) {
    const liveContent = content as LiveContentModel;
    return (
      <ContentCard
        key={`live-${liveContent.deliverable_id}`}
        contractId={liveContent.contract_id}
        deliverableId={liveContent.deliverable_id}
        contractAmount={liveContent.contract_amount}
        contractDeliverableCount={liveContent.contract_deliverable_count}
        platform={liveContent.platform}
        format={liveContent.format}
        creatorHandle={liveContent.creator_handle}
        profileLink={liveContent.profile_link}
        liveContentUrl={liveContent.url}
        numViews={liveContent.views}
        numClicks={liveContent.clicks}
        numLikes={liveContent.likes}
        avatarUrl={liveContent.avatar_url}
        playableVideoUrl={liveContent.playable_video_url}
        rawVideoDownloadUrl={liveContent.video_download_url}
        adCode={liveContent.ad_code}
        title={liveContent.title}
        caption={liveContent.caption}
        usageRightsEndDate={liveContent.usage_rights_expiration_date}
        usageRightsInPerpetuity={liveContent.usage_rights_in_perpetuity}
        publishedAt={liveContent.published_at}
        relatedContent={contractIdToLiveContent.get(liveContent.contract_id)}
        disableClicks
      />
    );
  }
  const deliverableVideo = content as DeliverableVideo;
  return (
    <ContentCard
      key={`ugc-${deliverableVideo.deliverable.id}-${deliverableVideo.videoId}`}
      contractId={deliverableVideo.deliverable.contractId}
      deliverableId={deliverableVideo.deliverable.id}
      contractAmount={deliverableVideo.deliverable.contractAmount}
      contractDeliverableCount={deliverableVideo.deliverable.contractDeliverableCount}
      platform={null}
      format={null}
      creatorHandle={deliverableVideo.deliverable.creatorHandle}
      profileLink={deliverableVideo.deliverable.profileLink}
      liveContentUrl={null}
      numViews={0}
      numClicks={0}
      numLikes={0}
      avatarUrl={deliverableVideo.avatarUrl}
      playableVideoUrl={deliverableVideo.transcodedVideoLocation || deliverableVideo.location}
      rawVideoDownloadUrl={deliverableVideo.location}
      adCode={deliverableVideo.deliverable.adCode}
      title={deliverableVideo.title}
      caption={deliverableVideo.caption}
      usageRightsEndDate={deliverableVideo.deliverable.usageRightsExpirationDate}
      usageRightsInPerpetuity={deliverableVideo.deliverable.usageRightsInPerpetuity}
      publishedAt={deliverableVideo.videoDateCreated}
      relatedContent={contractIdToDeliverableVideo.get(deliverableVideo.deliverable.contractId)}
      disableClicks
      isUgc
    />
  );
};

export default function ContentLibrary({
  campaignId,
  showDefaultContent,
}: {
  campaignId?: string;
  showDefaultContent?: boolean;
}) {
  const { campaignHashId } = useParams<{ campaignHashId: string }>();

  if (!campaignHashId && !campaignId && !showDefaultContent) {
    return null;
  }

  const [liveLibraryState, setLiveLibraryState] = useState<LiveContentModel[]>([]);
  const [ugcLibraryState, setUgcLibraryState] = useState<DeliverableVideo[]>([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.PUBLISHED_AT);

  const [filters, setFilters] = useState<FilterBy[]>([]);

  const handleResponse = (response: any) => {
    const { success, error, liveContent, ugcContent } = response;

    if (success) {
      // Set the default sort to usage rights if there exists at least one content that has usage rights
      if (
        (ugcContent && ugcContent.length > 0) ||
        (liveContent &&
          liveContent.some(
            (elt: LiveContentModel) =>
              elt.usage_rights_expiration_date || elt.usage_rights_in_perpetuity,
          ))
      ) {
        setSortBy(SortBy.USAGE_RIGHTS_EXPIRATION_DATE);
      } else {
        setSortBy(SortBy.PUBLISHED_AT);
      }
      setLiveLibraryState(liveContent);
      setUgcLibraryState(ugcContent.map((raw: any) => DeliverableVideo.deserialize(raw)));
      setShowError(false);
      setErrorMessage("");
    } else {
      setShowError(true);
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (campaignHashId || campaignId) {
      fetchContentLibrary(campaignHashId, campaignId)
        .then(handleResponse)
        .finally(() => setLoading(false));
    } else {
      fetchContentLibraryForUser()
        .then(handleResponse)
        .finally(() => setLoading(false));
    }
  }, [campaignId]);

  // Group LiveContentModel by Contract ID
  const contractIdToLiveContent = new Map<string, LiveContentModel[]>();
  liveLibraryState.forEach((content) => {
    if (contractIdToLiveContent.has(content.contract_id)) {
      contractIdToLiveContent.get(content.contract_id)?.push(content);
    } else {
      contractIdToLiveContent.set(content.contract_id, [content]);
    }
  });

  // Group UGC DeliverableVideos by Contract ID
  const contractIdToDeliverableVideo = new Map<string, DeliverableVideo[]>();
  ugcLibraryState.forEach((content) => {
    if (contractIdToDeliverableVideo.has(content.deliverable.contractId)) {
      contractIdToDeliverableVideo.get(content.deliverable.contractId)?.push(content);
    } else {
      contractIdToDeliverableVideo.set(content.deliverable.contractId, [content]);
    }
  });

  const liveCards = liveLibraryState.filter((content) => {
    // Group filters.
    const groupedFilters = filters.reduce((acc, filter) => {
      const group = FILTER_TO_GROUP[filter];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(filter);
      return acc;
    }, {} as Record<FilterGroup, FilterBy[]>);

    // Apply filters.
    return Object.values(groupedFilters).every((group) =>
      group.some((filter) => getLiveFilterFn(filter)(content)),
    );
  });

  // Repeat the process for the UGC cards
  const ugcCards = ugcLibraryState.filter((content) => {
    // Group filters.
    const groupedFilters = filters.reduce((acc, filter) => {
      const group = FILTER_TO_GROUP[filter];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(filter);
      return acc;
    }, {} as Record<FilterGroup, FilterBy[]>);

    // Apply filters.
    return Object.values(groupedFilters).every((group) =>
      group.some((filter) => getUgcFilterFn(filter)(content)),
    );
  });

  // Final cards are the union
  const cards = [...liveCards, ...ugcCards]
    .sort(getSortFn(sortBy, sortOrder))
    .map((content) =>
      makeContentCard(content, contractIdToLiveContent, contractIdToDeliverableVideo),
    );

  return (
    <Box>
      <LoadingOverlay visible={loading} />
      {showError && <LoadingError message={errorMessage} />}
      {!showError && (
        <Stack>
          <SortAndFilter
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            filters={filters}
            setFilters={setFilters}
          />
          <Divider />
          <MasonryLayout cards={cards} />
        </Stack>
      )}
    </Box>
  );
}
