import React, { useEffect, useState } from "react";

import {
  Badge,
  Center,
  Flex,
  LoadingOverlay,
  Stack,
  Switch,
  Tabs,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

import { Notifications } from "@mantine/notifications";

import { IconListCheck } from "@tabler/icons-react";

import { getNumTasks } from "components/contracts/tasks/api/Api";
import { getTaskTypeLabel } from "components/contracts/tasks/common/Utils";

import { TaskCountType, TaskStage } from "components/contracts/tasks/models/Common";

import BaseTaskFeed from "components/contracts/tasks/BaseTaskFeed";

const SUPPORTED_TASK_TYPES = [
  TaskStage.CONTRACT_REVIEW,
  TaskStage.PRODUCT_ACCESS,
  TaskStage.CODES_LINKS,
  TaskStage.CONTENT_PRODUCTION,
  TaskStage.LIVE_VERIFICATION,
];

function NumTasksBadge({ numTasks }: { numTasks: number }) {
  if (!numTasks || numTasks === 0) {
    return null;
  }
  return (
    <Badge color="red" size="sm">
      {numTasks}
    </Badge>
  );
}

function TabLabel({
  numTasks,
  label,
  tabType,
  activeTab,
}: {
  numTasks: number;
  label: string;
  tabType: TaskStage;
  activeTab: TaskStage;
}) {
  const isSelected = tabType === activeTab;
  return (
    <Flex align="center" gap="xs">
      <NumTasksBadge numTasks={numTasks} />
      <Text size="sm" fw="500" c={isSelected ? "blue" : "dimmed"}>
        {label}
      </Text>
    </Flex>
  );
}

function EmptyState() {
  return (
    <Center style={{ height: "80vh", width: "100%" }}>
      <Stack gap="sm">
        <Center>
          <ThemeIcon size={108} variant="transparent" color="var(--mantine-color-gray-5)">
            <IconListCheck size={108} />
          </ThemeIcon>
        </Center>
        <Stack gap={4}>
          <Flex justify="center">
            <Text size="xl" fw="600" c="dimmed">
              All tasks have been completed!
            </Text>
          </Flex>
          <Flex justify="center">
            <Text size="lg" c="dimmed">
              You will be notified when you have new tasks.
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Center>
  );
}

export default function TasksView() {
  const [activeTab, setActiveTab] = useState<string | null>(TaskStage.ALL);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [testMode, setTestMode] = useState<boolean>(false);

  // TODO(albert): Add campaign and Ad Group Selector
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);

  const [taskCountMap, setTaskCountMap] = useState<Record<string, Record<TaskCountType, number>>>(
    {},
  );

  // Load the number of pending tasks
  useEffect(() => {
    const fetchTasks = async () => {
      const response = await getNumTasks({ campaignId: selectedCampaignId, testMode });
      if (response.success) {
        setTaskCountMap(response.data);
      }
    };

    setInitialLoading(true);
    fetchTasks().finally(() => {
      setInitialLoading(false);
    });

    const intervalId = setInterval(fetchTasks, 5000);

    return () => clearInterval(intervalId);
  }, [selectedCampaignId, testMode]);

  if (initialLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <>
      <Notifications />
      <Stack>
        <Flex justify="space-between" align="center">
          <Title order={2}>Tasks</Title>
          <Switch
            label="Test Mode"
            checked={testMode}
            onChange={(event) => setTestMode(event.currentTarget.checked)}
          />
        </Flex>
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value={TaskStage.ALL}>
              <TabLabel
                numTasks={0}
                label="All Tasks"
                tabType={TaskStage.ALL}
                activeTab={activeTab as TaskStage}
              />
            </Tabs.Tab>
            {SUPPORTED_TASK_TYPES.map((taskType) => (
              <Tabs.Tab key={`${taskType}-tab`} value={taskType}>
                <TabLabel
                  numTasks={taskCountMap[taskType]?.[TaskCountType.PENDING] ?? 0}
                  label={getTaskTypeLabel(taskType)}
                  tabType={taskType}
                  activeTab={activeTab as TaskStage}
                />
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel value={TaskStage.ALL}>
            <EmptyState />
          </Tabs.Panel>

          {SUPPORTED_TASK_TYPES.map((taskType) => (
            <Tabs.Panel key={`${taskType}-panel`} value={taskType}>
              {taskCountMap[taskType]?.[TaskCountType.ALL] > 0 ? (
                <BaseTaskFeed
                  testMode={testMode}
                  isActive={activeTab === taskType}
                  campaignId={selectedCampaignId}
                  taskType={taskType}
                  numTasks={taskCountMap[taskType]}
                  setNumTasks={(pendingCount: number, completedCount: number) => {
                    setTaskCountMap((prevMap) => ({
                      ...prevMap,
                      [taskType]: {
                        [TaskCountType.ALL]: pendingCount + completedCount,
                        [TaskCountType.PENDING]: pendingCount,
                        [TaskCountType.COMPLETED]: completedCount,
                      },
                    }));
                  }}
                />
              ) : (
                <EmptyState />
              )}
            </Tabs.Panel>
          ))}
        </Tabs>
      </Stack>
    </>
  );
}
