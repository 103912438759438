import React from "react";

import { Badge, Card, Divider, Flex, Group, Stack, Text, ThemeIcon } from "@mantine/core";

import {
  IconAlertCircle,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutube,
} from "@tabler/icons-react";

import { SupportedFormat, SupportedPlatform } from "models/Common";
import { SupportedFormatToLabel, TaskStatus } from "components/contracts/tasks/models/Common";
import { Task } from "components/contracts/tasks/models/Task";
import { TaskStage } from "components/contracts/tasks/models/Common";
import { toShortDateString, toPSTDateTimeStringNoYear } from "utils/DateUtils";

import CodeAndLink from "components/contracts/tasks/content/CodeAndLink";
import ContractReviewContent from "components/contracts/tasks/content/ContractReviewContent";
import ProductAccessContent from "components/contracts/tasks/content/ProductAccessContent";

import LiveContentStage from "components/contracts/tasks/stages/LiveContentStage";
import ScriptDraftStage from "components/contracts/tasks/stages/ScriptDraftStage";
import VideoDraftStage from "components/contracts/tasks/stages/VideoDraftStage";

function DueDateBadge({ deadline }: { deadline: Date }) {
  if (!deadline) {
    return null;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const deadlineDate = new Date(deadline);
  deadlineDate.setHours(0, 0, 0, 0);

  const dueDate = toShortDateString(deadline);

  if (deadlineDate.getTime() === today.getTime()) {
    return (
      <Badge color="red" variant="outline">
        Due Today {dueDate}
      </Badge>
    );
  } else if (deadlineDate.getTime() === tomorrow.getTime()) {
    return (
      <Badge color="orange" variant="outline">
        Due Tomorrow {dueDate}
      </Badge>
    );
  } else if (deadlineDate > today) {
    return (
      <Badge color="yellow" variant="outline">
        Due By {dueDate}
      </Badge>
    );
  }

  return (
    <Badge color="gray" variant="outline">
      Overdue {dueDate}
    </Badge>
  );
}

function ActionBadge({
  taskType,
  waitingForCreator,
  referralLinkStatus,
  promoCodeStatus,
}: {
  taskType: TaskStage;
  referralLinkStatus: TaskStatus;
  promoCodeStatus: TaskStatus;
  waitingForCreator?: boolean;
}) {
  if (waitingForCreator) {
    return (
      <Badge variant="light" color="yellow">
        Waiting for Creator
      </Badge>
    );
  }

  if (taskType === TaskStage.LIVE_VERIFICATION) {
    return (
      <Badge variant="light" color="red">
        Verification Required
      </Badge>
    );
  } else if (taskType === TaskStage.PRODUCT_ACCESS) {
    return (
      <Badge variant="light" color="red">
        Action Required
      </Badge>
    );
  } else if (taskType === TaskStage.CONTENT_PRODUCTION) {
    return (
      <Badge variant="light" color="red">
        Review Required
      </Badge>
    );
  } else if (taskType === TaskStage.CODES_LINKS) {
    if (referralLinkStatus !== TaskStatus.NONE && promoCodeStatus !== TaskStatus.NONE) {
      return (
        <Badge variant="light" color="red">
          Link and Promo Code Required
        </Badge>
      );
    } else if (referralLinkStatus !== TaskStatus.NONE) {
      return (
        <Badge variant="light" color="red">
          Link Required
        </Badge>
      );
    } else if (promoCodeStatus !== TaskStatus.NONE) {
      return (
        <Badge variant="light" color="red">
          Promo Code Required
        </Badge>
      );
    }
  }
  return null;
}

function TaskCardHeader({
  deliverableFormat,
  platform,
  deadline,
  taskType,
  referralLinkStatus,
  promoCodeStatus,
  waitingForCreator,
}: {
  deliverableFormat: SupportedFormat;
  platform: SupportedPlatform;
  deadline: Date;
  taskType: TaskStage;
  referralLinkStatus: TaskStatus;
  promoCodeStatus: TaskStatus;
  waitingForCreator?: boolean;
}) {
  let icon = null;
  if (platform === SupportedPlatform.INSTAGRAM) {
    icon = <IconBrandInstagram />;
  } else if (platform === SupportedPlatform.TIKTOK) {
    icon = <IconBrandTiktok />;
  } else if (platform === SupportedPlatform.YOUTUBE) {
    icon = <IconBrandYoutube />;
  }

  const label = SupportedFormatToLabel[deliverableFormat];

  return (
    <Group justify="space-between">
      <Group gap="xs">
        {icon}
        <Text fw="600" size="lg">
          {label}
        </Text>
      </Group>
      <Group gap="xs">
        <ActionBadge
          taskType={taskType}
          waitingForCreator={waitingForCreator}
          referralLinkStatus={referralLinkStatus}
          promoCodeStatus={promoCodeStatus}
        />
        {!waitingForCreator && <DueDateBadge deadline={deadline} />}
      </Group>
    </Group>
  );
}

function AutoApproveNotification({
  autoApprove,
  deadline,
}: {
  autoApprove: boolean;
  deadline: Date;
}) {
  if (!autoApprove) {
    return null;
  }

  return (
    <Card bg="var(--mantine-color-red-light)" p={4}>
      <Flex gap={4} align="center" justify="center">
        <ThemeIcon color="red" radius="xl" variant="transparent">
          <IconAlertCircle size="1.2rem" />
        </ThemeIcon>
        <Text fw="700" size="sm" c="red">
          If no action is taken, this content will be auto-approved on{" "}
          {toPSTDateTimeStringNoYear(deadline)}.
        </Text>
      </Flex>
    </Card>
  );
}

// TODO(albert): Handle unread messages
export default function TaskCard({
  taskType,
  task,
  handleCompleteTask,
  isCompletedTask,
}: {
  taskType: TaskStage;
  task: Task;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
  isCompletedTask?: boolean;
}) {
  if (taskType === TaskStage.CONTRACT_REVIEW) {
    return <ContractReviewContent taskId={task.hashId} contractReview={task.contractReview} />;
  }

  if (taskType === TaskStage.PRODUCT_ACCESS) {
    return (
      <ProductAccessContent
        taskId={task.hashId}
        productAccess={task.productAccess}
        displayName={task.displayName}
        handleCompleteTask={handleCompleteTask}
      />
    );
  }

  return (
    <Card radius="md" mt="sm" withBorder>
      <Stack gap="xs">
        {/* TODO(albert): Pull autoApprove from backend */}
        <AutoApproveNotification autoApprove={!!task.deadline} deadline={task.deadline} />
        <TaskCardHeader
          deliverableFormat={task.deliverable.format}
          platform={task.deliverable.platform}
          deadline={task.deadline}
          taskType={taskType}
          referralLinkStatus={task.referralLinkStatus}
          promoCodeStatus={task.promoCodeStatus}
          waitingForCreator={isCompletedTask}
        />
        <Divider />
        {taskType === TaskStage.CODES_LINKS && (
          <CodeAndLink
            taskId={task.hashId}
            platform={task.deliverable.platform}
            displayName={task.deliverable.creatorHandle}
            redirectUrl={task.referralLinkUrl}
            promoCode={task.promoCode}
            referralLinkStatus={task.referralLinkStatus}
            promoCodeStatus={task.promoCodeStatus}
            handleCompleteTask={handleCompleteTask}
          />
        )}
        {taskType !== TaskStage.CODES_LINKS && (
          <Stack gap="xs">
            <ScriptDraftStage
              taskId={task.hashId}
              displayName={task.deliverable.creatorHandle}
              scripts={task.deliverable.scripts}
              waitingForCreator={isCompletedTask}
              handleCompleteTask={handleCompleteTask}
            />
            <VideoDraftStage
              taskId={task.hashId}
              displayName={task.deliverable.creatorHandle}
              videoDrafts={task.deliverable.videoDrafts}
              waitingForCreator={isCompletedTask}
              handleCompleteTask={handleCompleteTask}
            />
            <LiveContentStage
              taskId={task.hashId}
              platform={task.deliverable.platform}
              displayName={task.displayName}
              liveContent={task.liveContent}
              handleCompleteTask={handleCompleteTask}
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
