import React, { useEffect, useState } from "react";

import { useDisclosure } from "@mantine/hooks";

import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Divider,
  Flex,
  Group,
  Modal,
  NumberInput,
  Radio,
  Stack,
  Text,
  Title,
  Tooltip,
  Paper,
} from "@mantine/core";

import { IconSend } from "@tabler/icons-react";

import { submitUsageRightsRequest } from "components/contracts/common/Api";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import { formatAmount } from "components/contracts/dashboard/Utils";
import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import LiveContentModel from "components/contracts/models/LiveContentModel";
import { SupportedFormat, SupportedPlatform } from "models/Common";
import { SUPPORTED_FORMATS_TO_LABELS } from "components/contracts/common/Common";

import DisplayContent from "components/contracts/content/DisplayContent";
import EngagementMetrics from "components/contracts/content/EngagementMetrics";
import PlatformLogo, { UgcLogo } from "components/contracts/common/PlatformLogo";

const CONTENT_WIDTH = 340;
const PADDING_WIDTH = 20;

function DeliverableSelector({
  platform,
  format,
  numViews,
  numLikes,
  numClicks,
  selectedForPreview,
  selectForPreview,
  selectedForUsageRights,
  selectForUsageRights,
  offerReview,
  disableCheckbox,
}: {
  platform: SupportedPlatform | null;
  format: SupportedFormat | null;
  numViews: number;
  numLikes: number;
  numClicks: number;
  selectedForPreview: boolean;
  selectForPreview: () => void;
  selectedForUsageRights: boolean;
  selectForUsageRights: (selected: boolean) => void;
  offerReview: boolean;
  disableCheckbox: boolean;
}) {
  if (offerReview && !selectedForUsageRights) {
    return null;
  }

  return (
    <Paper
      px="lg"
      py="xs"
      radius="xl"
      w={CONTENT_WIDTH}
      shadow={selectedForPreview ? "none" : "sm"}
      onClick={selectForPreview}
      withBorder
      style={{
        cursor: "pointer",
        borderColor: selectedForPreview ? "var(--mantine-color-blue-5)" : "",
        borderWidth: selectedForPreview ? 2 : "",
      }}>
      <Group justify="space-between">
        <Flex align="center" gap="lg">
          {platform ? <PlatformLogo platform={platform} /> : <UgcLogo label="UGC Video" />}
          <Stack gap={1}>
            {format && (
              <Text fw="500" size="sm">
                {SUPPORTED_FORMATS_TO_LABELS[format]}
              </Text>
            )}
            <Box ml={-4}>
              <EngagementMetrics
                numViews={numViews}
                numLikes={numLikes}
                numClicks={numClicks}
                disableClicks
              />
            </Box>
          </Stack>
        </Flex>
        <Checkbox
          disabled={offerReview || disableCheckbox}
          checked={selectedForUsageRights}
          onChange={(event) => selectForUsageRights(event.currentTarget.checked)}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
          }}
        />
      </Group>
    </Paper>
  );
}

function DeliverablePreview({ content }: { content: LiveContentModel | DeliverableVideo }) {
  let args;
  if (content instanceof DeliverableVideo) {
    // UGC
    const deliverableVideo = content as DeliverableVideo;
    args = {
      deliverableId: deliverableVideo.deliverable.id,
      playableVideoUrl: deliverableVideo.transcodedVideoLocation || deliverableVideo.location,
      publishedAt: deliverableVideo.videoDateCreated,
      numViews: 0,
      numClicks: 0,
      numLikes: 0,
    };
  } else {
    const liveContent = content as LiveContentModel;
    args = {
      deliverableId: liveContent.deliverable_id,
      playableVideoUrl: liveContent.playable_video_url,
      publishedAt: liveContent.published_at,
      numViews: liveContent.views,
      numClicks: liveContent.clicks,
      numLike: liveContent.likes,
    };
  }
  return (
    <Card withBorder p={0} shadow="xs" w={CONTENT_WIDTH} pb="xs">
      <DisplayContent
        deliverableId={args.deliverableId}
        playableVideoUrl={args.playableVideoUrl}
        publishedAt={args.publishedAt}
        numViews={args.numViews}
        numClicks={args.numClicks}
        numLikes={args.numLikes}
        title={content.title}
        caption={content.caption}
        contentWidth={CONTENT_WIDTH}
        paddingWidth={PADDING_WIDTH}
        disableClicks
      />
    </Card>
  );
}

function getSuggestedOffer({
  contractAmount,
  contractDeliverableCount,
  usageRightsDays,
  usageRightsInPerpetuity,
  numSelected,
}: {
  contractAmount: number;
  contractDeliverableCount: number;
  usageRightsDays: number;
  usageRightsInPerpetuity: boolean;
  numSelected: number;
}): number {
  let multiplier = 0;

  switch (usageRightsDays) {
    case 7:
      multiplier = 0.1;
      break;
    case 30:
      multiplier = 0.2;
      break;
    case 60:
      multiplier = 0.3;
      break;
    case 180:
      multiplier = 0.4;
      break;
    case 365:
      multiplier = 0.5;
      break;
    default:
      multiplier = usageRightsInPerpetuity ? 0.6 : 0;
      break;
  }

  const suggestedOffer =
    contractDeliverableCount * numSelected * multiplier > 0
      ? (contractAmount / contractDeliverableCount) * numSelected * multiplier
      : 0;

  // Round to two signifcant figures.
  const factor =
    suggestedOffer > 0 ? 10 ** (2 - Math.floor(Math.log10(Math.abs(suggestedOffer))) - 1) : 0;

  return factor > 0 ? Math.round(suggestedOffer * factor) / factor : 0;
}

export default function RequestUsageRights({
  contractId,
  contractAmount,
  contractDeliverableCount,
  selectedDeliverableId,
  usageRightsActive,
  icon,
  relatedContent,
  disableSuggestedOffer,
}: {
  contractId: string;
  contractAmount: number;
  contractDeliverableCount: number;
  selectedDeliverableId: string;
  usageRightsActive: boolean;
  icon: React.ReactNode;
  relatedContent: LiveContentModel[] | DeliverableVideo[];
  disableSuggestedOffer?: boolean;
}) {
  const [opened, { open, close }] = useDisclosure();

  const [usageRightsDays, setUsageRightsDays] = useState<number>(60);
  const [usageRightsInPerpetuity, setUsageRightsInPerpetuity] = useState<boolean>(false);
  const [maxOfferAmount, setMaxOfferAmount] = useState<number>(0);
  const [offerReview, setOfferReview] = useState(false);
  const [loading, setLoading] = useState(false);

  const usageRightsLabel = usageRightsActive
    ? "Request Usage Rights Extension"
    : "Request Usage Rights";

  // Track the deliverable selected for preview.
  const [deliverableSelectedForPreview, setDeliverableSelectedForPreview] =
    useState(selectedDeliverableId);

  // Track the deliverables selected for usage rights.
  const [selectedForUsageRights, setSelectedForUsageRights] = useState(
    relatedContent.length > 0 && relatedContent[0] instanceof DeliverableVideo
      ? (relatedContent as DeliverableVideo[]).reduce((acc, content) => {
          acc.set(content.deliverable.id, content.deliverable.id === selectedDeliverableId);
          return acc;
        }, new Map<string, boolean>())
      : (relatedContent as LiveContentModel[]).reduce((acc, content: LiveContentModel) => {
          acc.set(content.deliverable_id, content.deliverable_id === selectedDeliverableId);
          return acc;
        }, new Map<string, boolean>()),
  );

  const anySelectedForUsageRights = Array.from(selectedForUsageRights.values()).some(
    (selected) => selected,
  );

  const orderedRelatedContent =
    relatedContent.length > 0 && relatedContent[0] instanceof DeliverableVideo
      ? [
          (relatedContent as DeliverableVideo[]).find(
            (content) => content.deliverable.id === selectedDeliverableId,
          ),
          ...(relatedContent as DeliverableVideo[]).filter(
            (content) => content.deliverable.id !== selectedDeliverableId,
          ),
        ]
      : [
          (relatedContent as LiveContentModel[]).find(
            (content) => content.deliverable_id === selectedDeliverableId,
          ),
          ...(relatedContent as LiveContentModel[]).filter(
            (content) => content.deliverable_id !== selectedDeliverableId,
          ),
        ];

  const deliverableSelectors = orderedRelatedContent.map((content) => {
    let args: any;
    if (content instanceof DeliverableVideo) {
      args = {
        deliverableId: content.deliverable.id,
        platform: null,
        format: null,
        numViews: 0,
        numLikes: 0,
        numClicks: 0,
      };
    } else {
      args = {
        deliverableId: content.deliverable_id,
        platform: content.platform,
        format: content.format,
        numViews: content.views,
        numLikes: content.likes,
        numClicks: content.clicks,
      };
    }
    return (
      <DeliverableSelector
        key={`selector-${args.deliverableId}`}
        platform={args.platform}
        format={args.format}
        numViews={args.numViews}
        numLikes={args.numLikes}
        numClicks={args.numClicks}
        selectedForPreview={deliverableSelectedForPreview === args.deliverableId}
        selectForPreview={() => setDeliverableSelectedForPreview(args.deliverableId)}
        selectedForUsageRights={selectedForUsageRights.get(args.deliverableId)}
        selectForUsageRights={(selected) => {
          setSelectedForUsageRights((prev) => {
            const next = new Map(prev);
            next.set(args.deliverableId, selected);
            return next;
          });
        }}
        offerReview={offerReview}
        disableCheckbox={relatedContent.length === 1}
      />
    );
  });

  useEffect(() => {
    if (offerReview) {
      // If the current deliverable preview isn't selected for usage rights, switch
      // to the first deliverable in the list with usage rights to preview.
      if (!selectedForUsageRights.get(deliverableSelectedForPreview)) {
        const firstDeliverableWithUsageRights =
          relatedContent.length > 0 && relatedContent[0] instanceof DeliverableVideo
            ? (relatedContent as DeliverableVideo[]).find((content) =>
                selectedForUsageRights.get(content.deliverable.id),
              )
            : (relatedContent as LiveContentModel[]).find((content) =>
                selectedForUsageRights.get(content.deliverable_id),
              );

        if (firstDeliverableWithUsageRights) {
          setDeliverableSelectedForPreview(
            firstDeliverableWithUsageRights instanceof DeliverableVideo
              ? firstDeliverableWithUsageRights.deliverable.id
              : firstDeliverableWithUsageRights.deliverable_id,
          );
        }
      }
    }
  }, [offerReview]);

  const handleSubmitRequest = () => {
    const deliverableIds = Array.from(selectedForUsageRights.entries())
      .filter(([, selected]) => selected)
      .map(([deliverableId]) => deliverableId);

    setLoading(true);
    submitUsageRightsRequest({
      contractId,
      deliverableIds,
      usageRightsDays,
      usageRightsInPerpetuity,
      maxOfferAmount,
    })
      .then((response) => {
        const { success, error } = response;

        if (success) {
          showSuccessNotification({ message: "Request for Usage Rights submitted successfully!" });
          close();
        } else {
          showFailureNotification({ message: error });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal size="auto" p="md" opened={opened} onClose={close} withCloseButton={false} centered>
        <Flex gap="md">
          <Box mih={700}>
            <Stack justify="baseline" gap="xs">
              <Title order={4} fw="500">
                {relatedContent.length > 1 ? "Related Deliverables" : "Deliverable"}
              </Title>
              {deliverableSelectors}
              <Title order={4} fw="500">
                Usage Rights Request
              </Title>
              <Radio.Group
                value={usageRightsInPerpetuity ? "perpetuity" : usageRightsDays.toString()}
                onChange={(value) => {
                  if (value === "perpetuity") {
                    setUsageRightsInPerpetuity(true);
                    setUsageRightsDays(0);
                  } else {
                    setUsageRightsInPerpetuity(false);
                    setUsageRightsDays(Number(value));
                  }
                }}
                name="usageRightsDuration"
                label="Duration"
                withAsterisk={!offerReview}>
                <Stack gap="xs" mt="xs">
                  <Radio disabled={offerReview} value="7" label="7 Days" />
                  <Radio disabled={offerReview} value="30" label="30 Days" />
                  <Radio disabled={offerReview} value="60" label="60 Days" />
                  <Radio disabled={offerReview} value="365" label="365 Days" />
                  <Radio disabled={offerReview} value="perpetuity" label="In Perpetuity" />
                </Stack>
              </Radio.Group>
              <NumberInput
                label="Max Offer"
                description={
                  !disableSuggestedOffer && (usageRightsDays > 0 || usageRightsInPerpetuity)
                    ? `Suggested Max Offer: ${formatAmount(
                        getSuggestedOffer({
                          contractAmount,
                          contractDeliverableCount,
                          usageRightsDays,
                          usageRightsInPerpetuity,
                          numSelected: Array.from(selectedForUsageRights.values()).filter(
                            (val) => val,
                          ).length,
                        }),
                      )}`
                    : ""
                }
                withAsterisk={!offerReview}
                disabled={!(usageRightsDays || usageRightsInPerpetuity) || offerReview}
                value={maxOfferAmount / 100}
                onChange={(value: number) => {
                  setMaxOfferAmount(Math.round(value * 100));
                }}
                min={0}
                thousandSeparator=","
                prefix="$"
                hideControls
              />
              {!offerReview && (
                <Button
                  disabled={
                    !(usageRightsDays || usageRightsInPerpetuity) ||
                    !maxOfferAmount ||
                    !anySelectedForUsageRights
                  }
                  onClick={() => setOfferReview(true)}
                  color="blue">
                  Review Offer
                </Button>
              )}
              {offerReview && (
                <>
                  <Button
                    color="teal"
                    leftSection={<IconSend size="1rem" />}
                    onClick={handleSubmitRequest}
                    loading={loading}>
                    Submit Offer
                  </Button>
                  <Button variant="default" onClick={() => setOfferReview(false)}>
                    Cancel
                  </Button>
                </>
              )}
            </Stack>
          </Box>
          <Divider orientation="vertical" />
          <Center>
            <DeliverablePreview
              content={
                relatedContent.length > 0 && relatedContent[0] instanceof DeliverableVideo
                  ? (relatedContent as DeliverableVideo[]).find(
                      (content) => content.deliverable.id === deliverableSelectedForPreview,
                    )
                  : (relatedContent as LiveContentModel[]).find(
                      (content) => content.deliverable_id === deliverableSelectedForPreview,
                    )
              }
            />
          </Center>
        </Flex>
      </Modal>
      <Tooltip label={usageRightsLabel}>
        <ActionIcon variant="subtle" color="dark" size="md" onClick={open}>
          {icon}
        </ActionIcon>
      </Tooltip>
    </>
  );
}
