import React, { useEffect, useState } from "react";

import { Center, Loader, Pagination, Stack } from "@mantine/core";

import { getPagedCreatorDetails } from "components/discovery/search/Api";

import {
  ActiveTab,
  CreatorSetEntryStates,
  LoggingMetadata,
  SearchMethod,
} from "components/discovery/search/Common";
import {
  CreatorDetails,
  CreatorSetEntryState,
  CreatorSetMembershipDetails,
} from "components/discovery/Datamodels";

import { MediaEntry } from "components/MediaBlockCard";

import { SortBy } from "components/discovery/search/SortSelector";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import CreatorResultControls from "components/discovery/search/CreatorResultControls";

const DEFAULT_PAGE_SIZE = 25;

const getCreatorSetMembershipDetails = (
  creator: CreatorDetails,
  creatorSetOptions: Record<string, string>[],
  creatorSetEntryStates: CreatorSetEntryStates,
) => {
  return creatorSetOptions.map((creatorSetInfo) => {
    const creatorSetId = parseInt(creatorSetInfo.value, 10);
    const creatorId = creator.creator_id;

    return {
      creatorId,
      creatorSetId,
      name: creatorSetInfo.label,
      isSaved:
        creatorSetEntryStates[creatorSetId]?.[CreatorSetEntryState.SAVED]?.has(creatorId) ?? false,
      isActivated:
        creatorSetEntryStates[creatorSetId]?.[CreatorSetEntryState.ACCEPTED]?.has(creatorId) ??
        false,
    } as CreatorSetMembershipDetails;
  });
};

export default function SearchResults({
  sessionId,
  queryId,
  creatorIds,
  loadingResults,
  submittedQuery,
  selectedSearchMethod,
  creatorSetOptions,
  creatorSetEntryStates,
  hiddenCreatorIdsSet,
  sortBy,
  activeTab,
  activePage,
  setActivePage,
  pushNavigationState,
  handleAddCreatorSet,
  setLimitedUsage,
  setNumSearchesRemaining,
  setRateLimitExceeded,
  setCreatorSetEntryStates,
  addActivatedCreatorId,
  addSavedCreatorId,
  removeSavedCreatorId,
  hideCreatorId,
  unhideCreatorId,
  fetchRelatedCreators,
  hideCreators,
}: {
  sessionId: string;
  queryId: string;
  creatorIds: number[];
  loadingResults: boolean;
  submittedQuery: string;
  selectedSearchMethod: SearchMethod;
  creatorSetOptions: Record<string, string>[];
  creatorSetEntryStates: CreatorSetEntryStates;
  hiddenCreatorIdsSet: Set<number>;
  sortBy: SortBy;
  activeTab: ActiveTab;
  activePage: number;
  setActivePage: (value: number) => void;
  pushNavigationState: ({
    newQueryId,
    newActiveTab,
    newPageNumber,
    newSortBy,
  }: {
    newQueryId?: string;
    newActiveTab?: ActiveTab;
    newPageNumber?: number;
    newSortBy?: SortBy;
  }) => void;
  handleAddCreatorSet: (creatorSetId: number, creatorSetName: string) => void;
  setLimitedUsage: (value: boolean) => void;
  setNumSearchesRemaining: (value: number) => void;
  setRateLimitExceeded: (value: boolean) => void;
  setCreatorSetEntryStates: (value: CreatorSetEntryStates) => void;
  addActivatedCreatorId: (value: number) => void;
  addSavedCreatorId: (value: number) => void;
  removeSavedCreatorId: (value: number) => void;
  hideCreatorId: (
    value: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => void;
  unhideCreatorId: (
    value: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => void;
  fetchRelatedCreators: (value: string) => void;
  hideCreators?: boolean;
}) {
  const [currentPageCreators, setCurrentPageCreators] = useState<CreatorDetails[]>([]);
  const [currentPageRelevantVideos, setCurrentPageRelevantVideos] = useState<
    Record<number, Record<string, MediaEntry[]>>
  >({});
  const [pageLoading, setPageLoading] = useState(false);

  const totalNumPages =
    creatorIds && creatorIds.length > 0
      ? Math.floor(creatorIds.length / DEFAULT_PAGE_SIZE) +
        (creatorIds.length % DEFAULT_PAGE_SIZE === 0 ? 0 : 1)
      : 0;

  const showRelevance = selectedSearchMethod === SearchMethod.HASHTAG;

  // TODO(albert): We should probably move all of the state that this function updates
  // to the parent component. Right now, we use a useEffect that listens for changes to
  // activePage to trigger this function call. This is fragile. Instead, we should call this
  // function directly from the parent component.
  const handlePageUpdate = (newPageNumber: number) => {
    setPageLoading(true);
    getPagedCreatorDetails({
      sessionId,
      queryId,
      sortBy: sortBy.valueOf(),
      searchMethod: selectedSearchMethod,
      creatorIds,
      submittedQuery,
      pageNumber: newPageNumber,
      pageSize: DEFAULT_PAGE_SIZE,
      activeTab,
    })
      .then((response) => {
        const { creatorDetails, limitedUsage, rateLimitExceeded, numSearchesRemaining } = response;

        setLimitedUsage(limitedUsage);
        setNumSearchesRemaining(numSearchesRemaining);
        setRateLimitExceeded(rateLimitExceeded);

        if (!rateLimitExceeded) {
          setCurrentPageCreators(creatorDetails);

          if (selectedSearchMethod === SearchMethod.HASHTAG) {
            const { relevantVideos } = response;
            setCurrentPageRelevantVideos(relevantVideos);
          }
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  // Update the page when the active page changes.
  // TODO(albert): Avoid calling until page has been initialized.
  useEffect(() => {
    handlePageUpdate(activePage);
  }, [activePage, sortBy]);

  if (!loadingResults && (!creatorIds || creatorIds.length === 0)) {
    return null;
  }

  return (
    <Stack>
      {!loadingResults && !pageLoading && currentPageCreators && (
        <>
          {currentPageCreators
            .filter((creatorDetail) =>
              hideCreators ? !hiddenCreatorIdsSet.has(creatorDetail.creator_id) : true,
            )
            .map((creatorDetail) => {
              const creatorSetMembershipDetails = getCreatorSetMembershipDetails(
                creatorDetail,
                creatorSetOptions,
                creatorSetEntryStates,
              );

              const saveCreatorId = (creatorId: number, creatorSetId: number) => {
                const newCreatorSetEntryStates = { ...creatorSetEntryStates };

                // Ensure creatorSetId entry exists
                if (!newCreatorSetEntryStates[creatorSetId]) {
                  newCreatorSetEntryStates[creatorSetId] = {};
                }

                // Ensure SAVED set exists
                if (!newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED]) {
                  newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED] =
                    new Set<number>();
                }

                // Add to SAVED set
                newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED].add(creatorId);

                // Update State
                addSavedCreatorId(creatorId);
                setCreatorSetEntryStates(newCreatorSetEntryStates);
              };

              const unsaveCreatorId = (creatorId: number, creatorSetId: number) => {
                const newCreatorSetEntryStates = { ...creatorSetEntryStates };

                // Ensure creatorSetId entry exists
                if (!newCreatorSetEntryStates[creatorSetId]) {
                  newCreatorSetEntryStates[creatorSetId] = {};
                }

                // Ensure SAVED set exists
                if (!newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED]) {
                  newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED] =
                    new Set<number>();
                }

                // Remove from SAVED set
                newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED].delete(
                  creatorId,
                );

                // Check if the Creator ID is saved in any other Creator Set
                const isSavedInOtherCreatorSet = Object.values(newCreatorSetEntryStates).some(
                  (creatorSetEntryState) =>
                    creatorSetEntryState[CreatorSetEntryState.SAVED]?.has(creatorId),
                );

                // Update State
                if (!isSavedInOtherCreatorSet) {
                  removeSavedCreatorId(creatorId);
                }
                setCreatorSetEntryStates(newCreatorSetEntryStates);
              };

              const activateCreatorId = (creatorId: number, creatorSetId: number) => {
                const newCreatorSetEntryStates = { ...creatorSetEntryStates };

                // Ensure creatorSetId entry exists
                if (!newCreatorSetEntryStates[creatorSetId]) {
                  newCreatorSetEntryStates[creatorSetId] = {};
                }

                // Ensure ACCEPTED set exists
                if (!newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.ACCEPTED]) {
                  newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.ACCEPTED] =
                    new Set<number>();
                }

                // Add to ACCEPTED set
                newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.ACCEPTED].add(
                  creatorId,
                );

                // Remove from SAVED set
                newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED]?.delete(
                  creatorId,
                );

                // Check if the Creator ID is saved in any other Creator Set
                const isSavedInOtherCreatorSet = Object.values(newCreatorSetEntryStates).some(
                  (creatorSetEntryState) =>
                    creatorSetEntryState[CreatorSetEntryState.SAVED]?.has(creatorId),
                );

                // Update State
                addActivatedCreatorId(creatorId);
                if (!isSavedInOtherCreatorSet) {
                  removeSavedCreatorId(creatorId);
                }
                setCreatorSetEntryStates(newCreatorSetEntryStates);
              };

              return (
                <UnifiedCreatorRep
                  key={creatorDetail.creator_id}
                  creator={creatorDetail}
                  defaultExpandedPlatforms={
                    showRelevance &&
                    currentPageRelevantVideos &&
                    currentPageRelevantVideos[creatorDetail.creator_id].tiktok.length > 0
                      ? []
                      : ["tiktok", "youtube", "instagram"]
                  }
                  creatorSetControls={
                    <CreatorResultControls
                      loggingMetadata={
                        {
                          sessionId,
                          queryId,
                          sortBy,
                          activeTab,
                          pageNumber: activePage,
                        } as LoggingMetadata
                      }
                      creatorId={creatorDetail.creator_id}
                      creatorSetMembershipDetails={creatorSetMembershipDetails}
                      hiddenCreatorIdsSet={hiddenCreatorIdsSet}
                      handleActivate={activateCreatorId}
                      handleSave={saveCreatorId}
                      handleUnsave={unsaveCreatorId}
                      handleHide={hideCreatorId}
                      handleUnhide={unhideCreatorId}
                      // Show YT related in prod
                      // Show YT and TikTok related
                      handleSeeRelated={
                        creatorDetail?.youtube_channel?.handle ||
                        creatorDetail?.tiktok_profile?.info?.handle
                          ? () => fetchRelatedCreators(creatorDetail?.creator_id?.toString())
                          : undefined
                      }
                      handleAddCreatorSet={handleAddCreatorSet}
                    />
                  }
                  relevantVideos={
                    showRelevance ? currentPageRelevantVideos[creatorDetail.creator_id].tiktok : []
                  }
                  relevanceHashtag={submittedQuery}
                  refetchThumbnails
                />
              );
            })}
        </>
      )}
      {(loadingResults || pageLoading) && (
        <Center mt="xs">
          <Loader type="dots" />
        </Center>
      )}
      {!loadingResults && creatorIds && creatorIds.length > 0 && (
        <Center>
          <Pagination
            value={activePage}
            onChange={(newPageNumber: number) => {
              setActivePage(newPageNumber);
              pushNavigationState({
                newPageNumber,
              });
            }}
            total={totalNumPages}
          />
        </Center>
      )}
    </Stack>
  );
}
