import React, { useState } from "react";

import {
  Alert,
  Anchor,
  Button,
  Collapse,
  Card,
  CopyButton,
  Flex,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconCheck, IconCopy, IconInfoCircle, IconVideoPlus, IconX } from "@tabler/icons-react";

import { approveLiveUrl, requestLiveUrlRevision } from "components/contracts/common/Api";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import { SUPPORTED_FORMATS_TO_LABELS } from "components/contracts/common/Common";
import { SupportedFormat, SupportedPlatform } from "models/Common";
import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import { toLongDateString, toLongTimeDateString } from "utils/DateUtils";

import ReactPlayer from "react-player";

function DisputeButton({
  deliverableId,
  creatorHandle,
  formatLabel,
  disputeReason,
  setDeliverableStatus,
  setDisputeReason,
}: {
  deliverableId: string;
  creatorHandle: string;
  formatLabel: string;
  disputeReason: string;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  setDisputeReason: (reason: string) => void;
}) {
  const [requestRevisionButtonLoading, setRequestRevisionButtonLoading] = useState(false);
  const [modalOpened, { open, close }] = useDisclosure(false);

  const handleRequestRevision = () => {
    setRequestRevisionButtonLoading(true);
    requestLiveUrlRevision({ deliverableId, disputeReason })
      .then((response) => {
        const { success, status, error } = response;

        if (success) {
          setDeliverableStatus(status);
          showSuccessNotification({
            message: `Requested a revision for ${creatorHandle}'s ${formatLabel}.`,
          });
          setRequestRevisionButtonLoading(false);
        } else {
          setRequestRevisionButtonLoading(false);
          showFailureNotification({ message: error });
        }
        close();
      })
      .catch(() => {
        setRequestRevisionButtonLoading(false);
        showFailureNotification({ message: "Failed to request a revision." });
      });
  };

  return (
    <>
      <Modal
        opened={modalOpened}
        size="lg"
        onClose={close}
        title={<Text fw="600">Describe the Issue to {creatorHandle}</Text>}>
        <Stack>
          <Textarea
            minRows={3}
            autosize
            value={disputeReason}
            onChange={(event) => setDisputeReason(event.currentTarget.value)}
          />
          <Flex gap="sm" justify="right" align="center">
            <Button variant="default" color="gray" onClick={close}>
              Cancel
            </Button>
            <Button loading={requestRevisionButtonLoading} onClick={handleRequestRevision}>
              Submit
            </Button>
          </Flex>
        </Stack>
      </Modal>
      <Button
        leftSection={<IconX size="1rem" />}
        color="red"
        variant="light"
        size="xs"
        onClick={open}>
        Report Issue
      </Button>
    </>
  );
}

function LiveLinkContent({
  creatorHandle,
  formatLabel,
  liveContentUrl,
  brandName,
  reviewDeadline,
}: {
  creatorHandle: string;
  formatLabel: string;
  liveContentUrl: string;
  brandName: string;
  reviewDeadline: Date;
}) {
  return (
    <Text>
      <Text span fw="500">
        {creatorHandle}
      </Text>{" "}
      published their{" "}
      <Text span fw="500">
        {formatLabel}
      </Text>{" "}
      <Anchor target="_blank" href={liveContentUrl}>
        here
      </Anchor>
      . Please confirm the content is live and meets{" "}
      <Text span fw="500">
        {brandName}
      </Text>
      &apos;s requirements. You have until{" "}
      <Text span fw="500">
        {toLongTimeDateString(reviewDeadline)}
      </Text>{" "}
      to report an issue.
    </Text>
  );
}

function AdCode({ platform, adCode }: { platform: SupportedPlatform; adCode: string }) {
  let title = "";

  if (platform === SupportedPlatform.INSTAGRAM) {
    title = "Instagram Ad Code";
  } else if (platform === SupportedPlatform.TIKTOK) {
    title = "TikTok Spark Code";
  } else {
    return null;
  }

  return (
    <Stack gap={2}>
      <Text size="xs" pl={2} fw="500">
        {title}
      </Text>
      <Group>
        <Paper px="xs" py={4} shadow="xs" withBorder>
          <Flex gap="xs" align="center">
            <Text size="xs">{adCode}</Text>
            <CopyButton value={adCode} timeout={1000}>
              {({ copied, copy }) => (
                <Button
                  w={80}
                  size="compact-xs"
                  onClick={copy}
                  variant="light"
                  color={copied ? "teal" : "blue"}
                  rightSection={copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}>
                  {copied ? "Copied" : "Copy"}
                </Button>
              )}
            </CopyButton>
          </Flex>
        </Paper>
      </Group>
    </Stack>
  );
}

function LiveLinkAndAssetContent({
  brandName,
  creatorHandle,
  formatLabel,
  liveContentUrl,
  platform,
  assetUrl,
  adCode,
  reviewDeadline,
  usageRightsEndDate,
  usageRightsInPerpetuity,
}: {
  brandName: string;
  creatorHandle: string;
  formatLabel: string;
  liveContentUrl: string;
  platform: SupportedPlatform;
  assetUrl: string;
  adCode: string;
  reviewDeadline: Date;
  usageRightsEndDate: Date;
  usageRightsInPerpetuity: boolean;
}) {
  const hasAdCode = platform !== SupportedPlatform.YOUTUBE && adCode;

  return (
    <Stack gap="xs">
      <Text>
        <Text span fw="500">
          {creatorHandle}
        </Text>{" "}
        published their{" "}
        <Text span fw="500">
          {formatLabel}
        </Text>{" "}
        <Anchor target="_blank" href={liveContentUrl}>
          here
        </Anchor>
        . They also provided the raw video{" "}
        {hasAdCode && platform === SupportedPlatform.INSTAGRAM && (
          <Text span>
            and their{" "}
            <Text span fw="500">
              Instagram Ad Code
            </Text>
          </Text>
        )}
        {hasAdCode && platform === SupportedPlatform.TIKTOK && (
          <Text span>
            and their{" "}
            <Text span fw="500">
              TikTok Spark Code
            </Text>
          </Text>
        )}{" "}
        below. Please confirm the live content and the provided assets meet{" "}
        <Text span fw="500">
          {brandName}
        </Text>
        &apos;s requirements. You have until{" "}
        <Text span fw="500">
          {toLongTimeDateString(reviewDeadline)}
        </Text>{" "}
        to report an issue.
      </Text>
      <Stack gap="xs">
        <Group justify="center">
          <Card shadow="xs" padding={0} radius="sm" withBorder>
            <ReactPlayer url={assetUrl} muted controls />
            {hasAdCode && (
              <Group justify="center" p="xs">
                <AdCode platform={platform} adCode={adCode} />
              </Group>
            )}
            <Flex pl="xs" pb="xs" gap={2} align="center">
              <ThemeIcon size="sm" variant="subtle">
                <IconInfoCircle size="1rem" />
              </ThemeIcon>
              {usageRightsInPerpetuity ? (
                <Text size="xs">Usage Rights In Perpetuity</Text>
              ) : (
                <Text size="xs">
                  Usage Rights Expire on{" "}
                  <Text span fw="500">
                    {toLongDateString(usageRightsEndDate)}
                  </Text>
                </Text>
              )}
            </Flex>
          </Card>
        </Group>
      </Stack>
    </Stack>
  );
}

export default function LiveContentVerification({
  deliverableId,
  deliverableStatus,
  setDeliverableStatus,
  disputeReason,
  setDisputeReason,
  creatorHandle,
  brandName,
  hasUsageRights,
  liveContentUrl,
  assetUrl,
  adCode,
  platform,
  deliverableFormat,
  reviewDeadline,
  usageRightsEndDate,
  usageRightsInPerpetuity,
  hasDraftContent,
  children = [],
}: {
  deliverableId: string;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  disputeReason: string;
  setDisputeReason: (reason: string) => void;
  creatorHandle: string;
  brandName: string;
  hasUsageRights: boolean;
  liveContentUrl: string;
  assetUrl: string;
  adCode: string;
  platform: SupportedPlatform;
  deliverableFormat: SupportedFormat;
  reviewDeadline: Date;
  usageRightsEndDate: Date;
  usageRightsInPerpetuity: boolean;
  hasDraftContent: boolean;
  children?: React.ReactElement[];
}) {
  const needsVerification = deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED;
  const formatLabel = SUPPORTED_FORMATS_TO_LABELS[deliverableFormat];

  const [approveButtonLoading, setApproveButtonLoading] = useState(false);
  const [opened, { toggle }] = useDisclosure(!needsVerification);

  const handleApprove = () => {
    setApproveButtonLoading(true);
    approveLiveUrl({ deliverableId })
      .then((response) => {
        const { success, status, error } = response;

        if (success) {
          setDeliverableStatus(status);
          showSuccessNotification({
            message: `Successfully approved ${creatorHandle}'s ${formatLabel}!`,
          });
          setApproveButtonLoading(false);
        } else {
          setApproveButtonLoading(false);
          showFailureNotification({ message: error });
        }
      })
      .catch(() => {
        setApproveButtonLoading(false);
        showFailureNotification({ message: "Failed to approve live content." });
      });
  };

  return (
    <>
      {needsVerification && (
        <Alert
          my="md"
          variant="outline"
          color="red"
          radius="sm"
          title="Live Content Submitted!"
          icon={<IconVideoPlus />}>
          {!hasUsageRights && (
            <LiveLinkContent
              creatorHandle={creatorHandle}
              formatLabel={formatLabel}
              liveContentUrl={liveContentUrl}
              brandName={brandName}
              reviewDeadline={reviewDeadline}
            />
          )}
          {hasUsageRights && (
            <LiveLinkAndAssetContent
              brandName={brandName}
              creatorHandle={creatorHandle}
              formatLabel={formatLabel}
              liveContentUrl={liveContentUrl}
              platform={platform}
              assetUrl={assetUrl}
              adCode={adCode}
              reviewDeadline={reviewDeadline}
              usageRightsEndDate={usageRightsEndDate}
              usageRightsInPerpetuity={usageRightsInPerpetuity}
            />
          )}
          <Group mt="sm" justify={hasDraftContent ? "space-between" : "right"}>
            {hasDraftContent && (
              <Button size="xs" variant="default" onClick={toggle}>
                {opened ? "Hide" : "Show"} Approved Draft
              </Button>
            )}
            <Group justify="right">
              <DisputeButton
                deliverableId={deliverableId}
                creatorHandle={creatorHandle}
                formatLabel={formatLabel}
                disputeReason={disputeReason}
                setDeliverableStatus={setDeliverableStatus}
                setDisputeReason={setDisputeReason}
              />
              <Button
                leftSection={<IconCheck size="1rem" />}
                color="green"
                variant="light"
                size="xs"
                loading={approveButtonLoading}
                onClick={handleApprove}>
                Confirm Live
              </Button>
            </Group>
          </Group>
        </Alert>
      )}
      <Collapse in={opened}>{children}</Collapse>
    </>
  );
}
