import React, { useEffect, useState } from "react";

import { Anchor, Button, Center, Group, Loader, Stack, Stepper, Text } from "@mantine/core";

import { IconAbc, IconListCheck, IconUser } from "@tabler/icons-react";

import {
  markCreatorIsEnglish,
  markIsCreator,
  opsLabelCreator,
} from "admin/api/labeling/sourcingApi";
import { AdminCreatorLabelingReportIssue } from "admin/app/labeling/AdminCreatorLabelingReportIssue";
import {
  LabelingDatasetItemsResponse,
  RecommendationAttribution,
} from "components/creator_lists/LabelingUtils";
import { CreatorSet } from "components/discovery/Datamodels";

const LABELING_STEPS = 3;

const AdminCreatorLabelingInputs = ({
  creatorId,
  creatorSet,
  candidates,
  getNextCandidate,
  isLoadingCreatorDetails,
}: {
  creatorId: number;
  creatorSet: CreatorSet;
  candidates: LabelingDatasetItemsResponse;
  getNextCandidate: () => void;
  isLoadingCreatorDetails: boolean;
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [highestStepVisited, setHighestStepVisited] = useState(activeStep);

  const [attribution, setAttribution] = useState<Record<number, RecommendationAttribution[]>>({});

  useEffect(() => {
    if (candidates) {
      setAttribution(candidates.attribution);
    }
  }, [candidates]);

  const handleStepChange = (nextStep: number, pass: boolean) => {
    // save details
    if (nextStep === 1) {
      if (pass) {
        // mark english
        markCreatorIsEnglish(creatorId, true);
      } else {
        // mark not english
        markCreatorIsEnglish(creatorId, false);
        getNextCandidate();
        setActiveStep(0);
        setHighestStepVisited(0);
        return;
      }
    } else if (nextStep === 2) {
      if (pass) {
        // mark creator
        markIsCreator(creatorId, true);
      } else {
        // mark not creator
        markIsCreator(creatorId, false);
        getNextCandidate();
        setActiveStep(0);
        setHighestStepVisited(0);
        return;
      }
    } else if (nextStep === 3) {
      // label creator
      const creatorAttribution = attribution[creatorId];
      if (pass) {
        opsLabelCreator(creatorId, creatorSet.id, 2, "tiktok", creatorAttribution);
      } else {
        opsLabelCreator(creatorId, creatorSet.id, 3, "tiktok", creatorAttribution);
        getNextCandidate();
        setActiveStep(0);
        setHighestStepVisited(0);
        return;
      }
    }
    const isOutOfBounds = nextStep > LABELING_STEPS || nextStep < 0;

    if (isOutOfBounds) {
      return;
    }

    setActiveStep(nextStep);
    setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
  };

  // Allow the user to freely go back and forth between visited steps.
  const shouldAllowSelectStep = (step: number) => highestStepVisited >= step && activeStep !== step;

  if (!Number.isInteger(creatorId)) {
    return <Text>No creator selected</Text>;
  }
  if (!creatorSet) {
    return <Text>No creator set selected</Text>;
  }
  if (isLoadingCreatorDetails) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <Stack align="center" w="100%" px="md">
      <Stack h={100} w="100%">
        <Stepper
          size="xs"
          active={activeStep}
          onStepClick={setActiveStep}
          w="100%"
          styles={{ separator: { width: "100%" } }}>
          <Stepper.Step allowStepSelect={shouldAllowSelectStep(0)} icon={<IconAbc />}>
            <Group justify="center">
              <Text size="sm">Is the content in English?</Text>
            </Group>
          </Stepper.Step>
          <Stepper.Step allowStepSelect={shouldAllowSelectStep(1)} icon={<IconUser />}>
            <Group justify="center">
              <Text size="sm">
                Does this profile belong to a creator (not a brand, company, or other organization)?
              </Text>
            </Group>
          </Stepper.Step>
          <Stepper.Step allowStepSelect={shouldAllowSelectStep(2)} icon={<IconListCheck />}>
            <Group justify="center">
              <Text size="sm">Is this creator relevant to {creatorSet?.name}?</Text>
            </Group>
          </Stepper.Step>

          <Stepper.Completed>
            <Group justify="center">
              <Text size="sm">Label Saved. Click Next to continue.</Text>
            </Group>
          </Stepper.Completed>
        </Stepper>
      </Stack>

      {activeStep < LABELING_STEPS && (
        <Stack align="center" gap="xs">
          <Button w={250} onClick={() => handleStepChange(activeStep + 1, true)} color="green">
            Yes
          </Button>
          <Button w={250} onClick={() => handleStepChange(activeStep + 1, false)} color="red">
            No
          </Button>
          {activeStep > 0 && (
            <Anchor onClick={() => handleStepChange(activeStep - 1, true)} c="black" size="xs">
              Previous Question
            </Anchor>
          )}
          <AdminCreatorLabelingReportIssue
            creatorId={creatorId}
            creatorSetId={creatorSet.id}
            getNextCandidate={getNextCandidate}
          />
        </Stack>
      )}
      {activeStep === LABELING_STEPS && (
        <Button
          w={250}
          onClick={() => {
            getNextCandidate();
            setActiveStep(0);
          }}
          color="green">
          Next
        </Button>
      )}
    </Stack>
  );
};

export const AdminCreatorLabelingInputSection = ({
  creatorId,
  creatorSet,
  candidates,
  getNextCandidate,
  isLoadingCreatorDetails,
}: {
  creatorId: number;
  creatorSet: CreatorSet;
  candidates: LabelingDatasetItemsResponse;
  getNextCandidate: () => void;
  isLoadingCreatorDetails: boolean;
}) => {
  return (
    <Stack align="center" w="100%" mih={450}>
      <Text fw={700}>Creator Labeling</Text>
      <AdminCreatorLabelingInputs
        creatorId={creatorId}
        creatorSet={creatorSet}
        candidates={candidates}
        getNextCandidate={getNextCandidate}
        isLoadingCreatorDetails={isLoadingCreatorDetails}
      />
    </Stack>
  );
};

export default AdminCreatorLabelingInputSection;
