import React, { Dispatch, useEffect } from "react";

import { Box, Checkbox, Flex } from "@mantine/core";

import { DatePickerInput } from "@mantine/dates";

import {
  DateUpdateType,
  DeliverableListAction,
  DeliverableListActionType,
} from "components/contracts/common/Common";

import DeliverableTimeline, {
  getLiveDateWindow,
} from "components/contracts/contract/DeliverableTimeline";

export default function TimelineRangeSelector({
  deliverableId,
  timeline,
  editDeliverableList,
}: {
  deliverableId: number;
  timeline: DeliverableTimeline;
  editDeliverableList: Dispatch<DeliverableListAction>;
}) {
  const updateDateRange = (minDate: Date, maxDate: Date) => {
    editDeliverableList({
      type: DeliverableListActionType.UPDATE_DATE,
      payload: {
        id: deliverableId,
        dateUpdate: { newDate: minDate, dateUpdateType: DateUpdateType.MIN_LIVE },
      },
    });
    editDeliverableList({
      type: DeliverableListActionType.UPDATE_DATE,
      payload: {
        id: deliverableId,
        dateUpdate: { newDate: maxDate, dateUpdateType: DateUpdateType.MAX_LIVE },
      },
    });
  };

  useEffect(() => {
    if (timeline.editableLiveDate) {
      if (timeline.liveDate < timeline.minLiveDate || timeline.liveDate > timeline.maxLiveDate) {
        const [minDate, maxDate] = getLiveDateWindow(timeline.liveDate);
        updateDateRange(minDate, maxDate);
      }
    } else if (timeline.minLiveDate !== null || timeline.maxLiveDate !== null) {
      updateDateRange(null, null);
    }
  }, [timeline.liveDate, timeline.editableLiveDate]);

  return (
    <Flex gap="sm" align="center">
      <DatePickerInput
        disabled
        type="range"
        valueFormat="MM/DD/YYYY"
        label="Live Date Window"
        placeholder="Select Range"
        value={[timeline.minLiveDate, timeline.maxLiveDate]}
        onChange={([minDate, maxDate]) => updateDateRange(minDate, maxDate)}
      />
      <Box mt="1.5rem">
        <Checkbox
          checked={timeline.editableLiveDate}
          onChange={(event) =>
            editDeliverableList({
              type: DeliverableListActionType.UPDATE_EDITABLE_LIVE_DATE,
              payload: { id: deliverableId, editableLiveDate: event.currentTarget.checked },
            })
          }
          label="Editable Live Date"
        />
      </Box>
    </Flex>
  );
}
