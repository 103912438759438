import React, { Dispatch } from "react";

import { Box, Flex, TextInput, Title } from "@mantine/core";

import {
  DEFAULT_SUB_TITLE_SIZE,
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_TITLE_SIZE,
  MultiPlatformState,
} from "components/contracts/common/Common";

import {
  MultiPlatformAction,
  MultiPlatformActionType,
  normalizeCreatorHandle,
} from "components/contracts/contract/MultiPlatformInput";

import { SupportedPlatform, SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES } from "models/Common";

/**
 * Simple input list of handles for UGC offer lookup. UGC content doesn't really depend on these
 * handles and platforms.
 */
export default function UGCHandleInput({
  multiPlatformState,
  editMultiPlatformState,
  disabled,
}: {
  multiPlatformState: MultiPlatformState;
  editMultiPlatformState: Dispatch<MultiPlatformAction>;
  disabled: boolean;
}) {
  const creatorHandleInputs = Object.values(SupportedPlatform)
    .filter((platform) => platform !== SupportedPlatform.NONE)
    .map((platform) => (
      <TextInput
        key={`handle-${platform}`}
        id={`handle-${platform}`}
        label={SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]}
        value={multiPlatformState[platform].handle}
        disabled={disabled}
        onChange={(event) => {
          editMultiPlatformState({
            type: MultiPlatformActionType.UPDATE_HANDLE,
            payload: {
              platform,
              selected: event.currentTarget.value && event.currentTarget.value.length > 0,
              handle: normalizeCreatorHandle(event.currentTarget.value),
            },
          });
        }}
      />
    ));

  return (
    <>
      <Title order={DEFAULT_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Platform Details
      </Title>
      <Box mt="sm">
        <Title order={DEFAULT_SUB_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
          Creator Handles
        </Title>
        <Box my="sm">
          <Flex gap="sm">{creatorHandleInputs}</Flex>
        </Box>
      </Box>
    </>
  );
}
