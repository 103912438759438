import React from "react";

import { Stack } from "@mantine/core";

import OnboardingCreatorSocialsStep from "onboarding/v2/OnboardingCreatorSocialsStep";

const SettingsSocial = () => (
  <Stack align="center" gap={0}>
    <OnboardingCreatorSocialsStep />
  </Stack>
);

export default SettingsSocial;
