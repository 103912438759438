import React from "react";

import { Stack } from "@mantine/core";

import { AdminCreatorLabelingProfileHeader } from "admin/app/labeling/AdminCreatorLabelingProfileHeader";
import { AdminCreatorLabelingVideos } from "admin/app/labeling/AdminCreatorLabelingVideos";
import { CreatorInfo } from "components/creator_lists/CreatorListsUtils";
import { CreatorDetails } from "components/discovery/Datamodels";

export const AdminCreatorPlatformSection = ({
  creatorId,
  creatorInfo,
  creatorDetails,
  platform,
}: {
  creatorId: number;
  creatorInfo: CreatorInfo;
  creatorDetails: CreatorDetails;
  platform: string;
}) => {
  if (creatorInfo === null) {
    return null;
  }
  return (
    <Stack>
      <AdminCreatorLabelingProfileHeader
        creatorId={creatorId}
        creatorInfo={creatorInfo}
        platform={platform}
      />
      <AdminCreatorLabelingVideos
        creatorId={creatorId}
        creatorDetails={creatorDetails}
        creatorInfo={creatorInfo}
      />
    </Stack>
  );
};

export default AdminCreatorPlatformSection;
