import React, { useState } from "react";

import {
  Badge,
  Box,
  Button,
  Card,
  Collapse,
  Flex,
  Grid,
  Group,
  NumberInput,
  Space,
  Spoiler,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { useDisclosure, useElementSize } from "@mantine/hooks";

import { ContractBrandReviewDeliverable } from "components/contracts/brand_review/ContractBrandReviewDeliverable";
import CreatorAvatar from "components/contracts/tasks/common/CreatorAvatar";
import {
  ContractReview,
  ContractReviewDeliverable,
} from "components/contracts/tasks/models/Common";
import PlatformTable from "components/creator_lists/unified_creator_rep/PlatformTable";
import { CreatorDetails } from "components/discovery/Datamodels";

import { UsageRightsDuration, UsageRightsDurationToDays } from "models/Common";
import { useUser } from "utils/UserContext";
import { IconCheck, IconPlus, IconMinus } from "@tabler/icons-react";

const MAX_FEEDBACK_LENGTH = 5000;
const DESCRIPTION_HEIGHT = 35;

function StatusBadge() {
  return (
    <Badge variant="light" color="red" w="fit-content">
      Review Required
    </Badge>
  );
}

function CreatorHeader({ creatorDetails }: { creatorDetails: CreatorDetails }) {
  const [userProfile, userProfileLoading] = useUser();

  const isStaff = userProfile?.is_staff;

  const avatarUrl =
    creatorDetails.youtube_channel?.avatar_url ||
    creatorDetails.tiktok_profile?.info?.avatar_url ||
    creatorDetails.instagram_profile?.info?.avatar_url;
  const title =
    creatorDetails.youtube_channel?.title ||
    creatorDetails.tiktok_profile?.info?.display_name ||
    creatorDetails.instagram_profile?.info?.display_name;
  const description =
    creatorDetails.youtube_channel?.description ||
    creatorDetails.tiktok_profile?.info?.description ||
    creatorDetails.instagram_profile?.info?.biography;

  const { ref: descriptionRef, height: descriptionHeight } = useElementSize();

  return (
    <Grid grow>
      <Grid.Col span={9}>
        <CreatorAvatar
          displayName={title}
          avatarUrl={avatarUrl}
          size="lg"
          description={
            descriptionHeight > DESCRIPTION_HEIGHT ? (
              <Spoiler
                maxHeight={DESCRIPTION_HEIGHT}
                showLabel={<Text size="xs">Show more</Text>}
                hideLabel={<Text size="xs">Hide</Text>}>
                <Text ref={descriptionRef} size="xs" c="var(--mantine-color-gray-9)">
                  {description}
                </Text>
              </Spoiler>
            ) : (
              <Text ref={descriptionRef} size="xs" c="var(--mantine-color-gray-9)">
                {description}
              </Text>
            )
          }
          onClick={() => {
            if (isStaff && creatorDetails.creator_id) {
              window.open(`/admin/creator?creatorId=${creatorDetails.creator_id}`, "_blank");
            }
          }}
        />
      </Grid.Col>
      <Grid.Col span={1}>
        <Group justify="right" align="center">
          <StatusBadge />
        </Group>
      </Grid.Col>
    </Grid>
  );
}

function DeliverablePackage({
  contractReview,
  usageDuration,
  creatorAskingPrice,
  combinedRecommendedPrice,
}: {
  contractReview: ContractReview;
  usageDuration: UsageRightsDuration | null;
  creatorAskingPrice: number;
  combinedRecommendedPrice: number;
}) {
  return (
    <Card
      radius="md"
      withBorder
      styles={{
        root: {
          borderColor: "var(--mantine-color-blue-6)",
          backgroundColor: "var(--mantine-color-blue-0)",
        },
      }}>
      <Stack gap="xs">
        <Flex justify="space-between" align="center">
          <Title order={4}>Proposed Package</Title>
          <Button size="xs" leftSection={<IconCheck size="1rem" />}>
            Selected
          </Button>
        </Flex>
        <Stack gap={0}>
          <Text size="md" fw="600">
            Deliverables:
          </Text>
          <Flex gap="sm" mt={4} mb="xs">
            {contractReview.deliverables.map((deliverable) => (
              <ContractBrandReviewDeliverable
                key={`deliverable-card-${deliverable.format}`}
                format={deliverable.format}
                platform={deliverable.platform}
                usageRights={
                  usageDuration !== null ? UsageRightsDurationToDays[usageDuration] : null
                }
              />
            ))}
          </Flex>
          <Flex justify="space-between" align="center" w="200px">
            <Text size="sm">Creator Asking Price:</Text>
            <Text size="sm" fw="600">
              ${creatorAskingPrice.toLocaleString("en-US", {})}
            </Text>
          </Flex>
          <Flex justify="space-between" align="center" w="200px">
            <Text size="sm">Recommended Price:</Text>
            <Text size="sm" fw="600">
              ${combinedRecommendedPrice.toLocaleString("en-US", {})}
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Card>
  );
}

function BrandFeedback({
  brandFeedback,
  setBrandFeedback,
}: {
  brandFeedback: string;
  setBrandFeedback: (value: string) => void;
}) {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Box>
      <Button
        variant="transparent"
        color="gray"
        size="sm"
        onClick={() => {
          toggle();
          setBrandFeedback("");
        }}
        leftSection={opened ? <IconMinus size="1rem" /> : <IconPlus size="1rem" />}
        styles={{
          root: {
            padding: 0,
            height: "auto",
          },
          section: {
            marginRight: 4,
          },
        }}>
        <Text size="sm" c="dimmed">
          Add Note
        </Text>
      </Button>
      <Collapse in={opened}>
        <Textarea
          mt="xs"
          size="sm"
          placeholder="Optional: Add a note"
          value={brandFeedback}
          onChange={(e) => setBrandFeedback(e.target.value)}
          rightSection={
            <Text size="xs" ta="end" pr="4px">
              {brandFeedback.length}/{MAX_FEEDBACK_LENGTH}
            </Text>
          }
          error={brandFeedback.length > MAX_FEEDBACK_LENGTH}
          rightSectionWidth="67px"
          miw="200px"
          styles={{
            section: {
              justifyContent: "flex-end",
            },
          }}
        />
      </Collapse>
    </Box>
  );
}

function ActionRow({ taskId, contractReview }: { taskId: string; contractReview: ContractReview }) {
  // TODO(albert): Add usage duration
  const usageDuration: UsageRightsDuration | null = null;
  const creatorAskingPrice = contractReview.creator_asking_price / 100;
  const combinedRecommendedPrice = contractReview.recommended_price / 100;

  const [brandFeedback, setBrandFeedback] = useState("");
  const [maxOffer, setMaxOffer] = useState(combinedRecommendedPrice);

  return (
    <Stack gap="sm">
      <DeliverablePackage
        contractReview={contractReview}
        usageDuration={usageDuration}
        creatorAskingPrice={creatorAskingPrice}
        combinedRecommendedPrice={combinedRecommendedPrice}
      />
      <Stack gap={0}>
        <Text fw="500" size="sm">
          Enter your max price for the selected package
        </Text>
        <Text size="xs" c="dimmed">
          Once submitted, your maximum offer is final. Regardless of your max offer, we will try and
          contract the creator for the lowest rate possible.
        </Text>
        <NumberInput
          allowDecimal={false}
          hideControls
          id="brandMaxOffer"
          min={1}
          mt="xs"
          onChange={(e) => setMaxOffer(Number(e))}
          prefix="$"
          thousandSeparator=","
          value={maxOffer}
          w={160}
          error={maxOffer < combinedRecommendedPrice}
        />
        {maxOffer < combinedRecommendedPrice && (
          <Text size="xs" c="red" mt="xs" mb="-xs">
            The creator will be more likely to accept if your offer is at least $
            {combinedRecommendedPrice.toLocaleString("en-US", {})}
          </Text>
        )}
        <Space h="xs" />
        <BrandFeedback brandFeedback={brandFeedback} setBrandFeedback={setBrandFeedback} />
      </Stack>
      <Group justify="space-bewteen" grow>
        <Button
          color="red"
          fullWidth
          radius="md"
          disabled={brandFeedback.length > MAX_FEEDBACK_LENGTH}>
          Decline Creator
        </Button>
        <Button
          color="green"
          fullWidth
          radius="md"
          disabled={brandFeedback.length > MAX_FEEDBACK_LENGTH}>
          Approve Max Offer
        </Button>
      </Group>
    </Stack>
  );
}

export default function ContractReviewContent({
  taskId,
  contractReview,
}: {
  taskId: string;
  contractReview: ContractReview;
}) {
  // NOTE(albert, 11/14/24): We set this as a state variable so that the
  // PlatformTable doesn't re-render every time the ActionRow re-renders.
  // This is a hack to prevent unnecessary re-renders, since the underlying
  // has a useEffect that depends on this value.
  const [defaultExpandedPlatforms, setDefaultExpandedPlatforms] = useState<string[]>([]);

  return (
    <Card radius="md" mt="sm" withBorder>
      <Stack gap="xs">
        <CreatorHeader creatorDetails={contractReview.creator_details} />
        <PlatformTable
          creator={contractReview.creator_details}
          defaultExpandedPlatforms={defaultExpandedPlatforms}
          refetchThumbnails
        />
        <ActionRow taskId={taskId} contractReview={contractReview} />
      </Stack>
    </Card>
  );
}
