import { CreatorDetails } from "components/discovery/Datamodels";
import { API_URL } from "configs/Configs";
import { User } from "firebase/auth";
import { createRequest, createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const LABELING_DATASETS_URL = `${API_URL}/labeling/`;

export type FilterParams = {
  [key: string]: string | number | boolean | string[];
};

export type DatasetFilterDict = {
  filterName: string;
  filterParams: FilterParams;
  [key: string]: any;
};

export type GetLabelingDatasetInfoResponse = {
  existingFilters: DatasetFilterDict[];
};

export type LabelingDatasetProps = {
  review_state?: number;
  brand_safety_score?: number;
  quality_score?: number;
  is_english?: boolean;
  creator_in_videos?: boolean;
  is_creator?: boolean;
  brand_state?: number;
  score?: number;
  feedback?: string;
};

export type FilteredReasonStat = {
  reason: string;
  count: number;
  remaining: number;
};

export interface RecommendationAttribution {
  source: string;
  score: number;
  type: AttributionType;
}

export enum AttributionType {
  HASHTAG = 1,
  HASHTAG_RAW = 2,
  LABELER = 3,
}

export const ATTRIBUTION_TYPE_LABELS = {
  [AttributionType.HASHTAG]: "hashtag",
  [AttributionType.HASHTAG_RAW]: "hashtag_raw",
  [AttributionType.LABELER]: "labeler",
};

export type LabelingDatasetItemsResponse = {
  creatorDetails: CreatorDetails[];
  creatorSetIdToName: Record<number, string>;
  creatorProps: Record<number, LabelingDatasetProps>;
  filteredStats: FilteredReasonStat[];
  searchMsg: string;
  attribution: Record<number, RecommendationAttribution[]>;
};

export const getCampaignInfoForLabeling = async (
  user: User,
  brandId: number,
  creatorSetId: number,
  platform: string,
  campaignParameterId: number,
) => {
  const firebaseToken = await user.getIdToken();
  const params = new URLSearchParams();
  params.append("brandId", String(brandId));
  params.append("creatorSetId", String(creatorSetId));
  params.append("platform", platform);
  params.append("campaignFilterId", String(campaignParameterId));
  const getLabelingDatasetInfoUrl = `${LABELING_DATASETS_URL}get_campaign_info_for_labeling/?${params.toString()}`;
  const request = createRequest({ url: getLabelingDatasetInfoUrl, firebaseToken });
  const response: GetLabelingDatasetInfoResponse = await handleResult(request);
  return response;
};

export const getLabelingDatasetItems = async (
  user: User,
  brandId: number,
  campaignParameterId: number,
  creatorSetId: number,
  seeds: string[],
  searchStrategy: string,
  pageNum: number,
  pageSize: number,
  useRelevanceSort: boolean,
  relevanceText: string,
  platform: string,
  filters: DatasetFilterDict[],
  abortController: AbortController,
) => {
  const firebaseToken = await user.getIdToken();
  const requestData = {
    brandId,
    campaignParameterId,
    creatorSetId,
    seeds,
    searchStrategy,
    pageNum,
    platform,
    pageSize,
    useRelevanceSort,
    relevanceText,
    filters: filters.reduce<Record<string, FilterParams>>((acc, filter) => {
      acc[filter.filterName] = filter.filterParams;
      return acc;
    }, {}),
  };

  const labelingItemEndpoint = `${LABELING_DATASETS_URL}get_dataset_items/`;
  const request = new Request(labelingItemEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    body: JSON.stringify(requestData),
  });

  const response: LabelingDatasetItemsResponse = await handleResult(request, abortController);
  return response;
};

export interface TagLabelingDatasetCreatorParams {
  creatorId: number;
  user: User;
  archetypeId?: number;
  platform?: string;
  brandSafety?: number;
  qualityScore?: number;
  isEnglish?: boolean;
  creatorInVideo?: boolean;
  isCreator?: boolean;
  feedback?: string;
}

type TagLabelingDatasetJson = {
  creatorId: number;
  archetypeId?: number;
  platform?: string;
  relevanceState?: number;
  brandSafety?: number;
  qualityScore?: number;
  isEnglish?: boolean;
  creatorInVideo?: boolean;
  isCreator?: boolean;
  feedback?: string;
};

export const tagLabelingDatasetCreator = async (params: TagLabelingDatasetCreatorParams) => {
  const firebaseToken = await params.user.getIdToken();

  const jsonParams: Partial<TagLabelingDatasetJson> = {};
  jsonParams.creatorId = params.creatorId;
  if (params.archetypeId !== undefined) {
    jsonParams.archetypeId = params.archetypeId;
  }
  if (params.platform !== undefined) {
    jsonParams.platform = params.platform;
  }
  if (params.brandSafety !== undefined) {
    jsonParams.brandSafety = params.brandSafety;
  }
  if (params.qualityScore !== undefined) {
    jsonParams.qualityScore = params.qualityScore;
  }
  if (params.isEnglish !== undefined) {
    jsonParams.isEnglish = params.isEnglish;
  }
  if (params.creatorInVideo !== undefined) {
    jsonParams.creatorInVideo = params.creatorInVideo;
  }
  if (params.isCreator !== undefined) {
    jsonParams.isCreator = params.isCreator;
  }
  if (params.feedback !== undefined) {
    jsonParams.feedback = params.feedback;
  }
  const request = new Request(`${LABELING_DATASETS_URL}tag_dataset_creator/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(jsonParams),
  });
  const response = await handleResult(request);
  return response;
};

export interface LabelBrandApproveCreatorParams {
  creatorId: number;
  user: User;
  brandId?: number;
  campaignParamsId?: number;
  reviewState?: number;
  creatorSetId?: number;
  platform?: string;
  seeds?: string[];
  searchStrategy?: string;
  attribution?: RecommendationAttribution[];
}

type LabelBrandApproveJson = {
  creatorId: number;
  brandId?: number;
  campaignFilterId?: number;
  reviewState?: number;
  creatorSetId?: number;
  platform?: string;
  seeds?: string[];
  searchStrategy?: string;
  attribution?: RecommendationAttribution[];
};

export const labelBrandApproveCreator = async (params: LabelBrandApproveCreatorParams) => {
  const firebaseToken = await params.user.getIdToken();

  const jsonParams: Partial<LabelBrandApproveJson> = {};
  jsonParams.creatorId = params.creatorId;
  jsonParams.brandId = params.brandId;
  jsonParams.platform = params.platform;
  jsonParams.reviewState = params.reviewState;
  jsonParams.creatorSetId = params.creatorSetId;
  jsonParams.seeds = params.seeds;
  jsonParams.searchStrategy = params.searchStrategy;
  jsonParams.campaignFilterId = params.campaignParamsId;
  if (params.attribution) {
    // Convert attribution into a json map
    jsonParams.attribution = params.attribution.map((attribution) => ({
      source: attribution.source,
      score: attribution.score,
      type: attribution.type,
    }));
  }
  const request = new Request(`${LABELING_DATASETS_URL}label_brand_approve_creator/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(jsonParams),
  });
  const response = await handleResult(request);
  return response;
};

export const markNotRelevantPrecrawledSearch = async (
  seed: string,
  seedType: string,
  creatorSetId: number,
  platform: string,
  notRelevant: boolean,
) => {
  const endpoint = new URL(`${LABELING_DATASETS_URL}mark_not_relevant_precrawled_search/`);
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "POST",
    body: JSON.stringify({ seed, seedType, creatorSetId, platform, notRelevant }),
  });
  const response = await handleResult(request);
  return response;
};

export const hidePrecrawledSearch = async (
  seed: string,
  seedType: string,
  creatorSetId: number,
  platform: string,
  hidden: boolean,
) => {
  const endpoint = new URL(`${LABELING_DATASETS_URL}hide_precrawled_search/`);
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "POST",
    body: JSON.stringify({ seed, seedType, creatorSetId, platform, hidden }),
  });
  const response = await handleResult(request);
  return response;
};

export const getCrawlStatus = async (
  seed: string,
  seedType: string,
  crawlType: number,
  platform: string,
  abortController: AbortController,
) => {
  const endpoint = new URL(`${LABELING_DATASETS_URL}get_crawl_status/`);
  endpoint.searchParams.append("seed", seed);
  endpoint.searchParams.append("seedType", seedType);
  endpoint.searchParams.append("crawlType", crawlType.toString());
  endpoint.searchParams.append("platform", platform);
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const retriggerCrawl = async (
  seed: string,
  seedType: string,
  crawlType: number,
  platform: string,
) => {
  const endpoint = new URL(`${LABELING_DATASETS_URL}retrigger_crawl/`);
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "POST",
    body: JSON.stringify({ seed, seedType, platform, crawlType }),
  });
  const response = await handleResult(request);
  return response;
};

export default null;
