import React, { useState } from "react";

import { Button, Checkbox, Stack, Title } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

export const ExportDropdownContents = ({
  exportAction,
}: {
  exportAction: (forContracts: boolean, forDeliverables: boolean, forDate?: Date) => void;
}) => {
  const [exportDate, setExportDate] = useState<Date | null>(null);
  const [exportContracts, setExportContracts] = useState<boolean>(false);
  const [exportDeliverables, setExportDeliverables] = useState<boolean>(true);

  return (
    <Stack>
      <Title order={5}>Export Metrics</Title>
      <Checkbox
        label="Export contracts data"
        checked={exportContracts}
        onChange={(event) => setExportContracts(event.currentTarget.checked)}
      />
      <Checkbox
        label="Export media data"
        checked={exportDeliverables}
        onChange={(event) => setExportDeliverables(event.currentTarget.checked)}
      />
      Contracts and media will be downloaded as two separate files.
      <DatePicker value={exportDate} onChange={setExportDate} firstDayOfWeek={0} />
      <Button
        variant="light"
        disabled={!exportContracts && !exportDeliverables}
        onClick={() => {
          exportAction(exportContracts, exportDeliverables, null);
        }}>
        Export Latest Date
      </Button>
      <Button
        disabled={exportDate === null || (!exportContracts && !exportDeliverables)}
        onClick={() => {
          exportAction(exportContracts, exportDeliverables, exportDate);
        }}>
        Export Selected Date
      </Button>
    </Stack>
  );
};

export default null;
