import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { User } from "firebase/auth";

import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconCircleCheck,
  IconSettings,
} from "@tabler/icons-react";

import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Center,
  Flex,
  Group,
  Select,
  Table,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import table_classes from "css/table/table.module.css";

import { updateBrandById } from "admin/AdminBrandSettings";
import { Brand, BrandStatus, BRAND_STATUS_SORT_ORDER } from "models/Brand";

// We should probably abstract this away somewhere else.
type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}`;
    }[keyof T]
  : never;

type Leaves<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
        ? ""
        : `.${Leaves<T[K]>}`}`;
    }[keyof T]
  : never;

type BrandObjPaths = Paths<Brand>;
type BrandObjLeaves = Leaves<Brand>;
type BrandObjFields = BrandObjPaths | BrandObjLeaves;

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  alignLeft?: boolean;
}

function Th({ children, reversed, sorted, onSort, alignLeft }: ThProps) {
  let Icon: React.ElementType;

  if (sorted) {
    if (reversed) {
      Icon = IconChevronDown;
    } else {
      Icon = IconChevronUp;
    }
  } else {
    Icon = IconSelector;
  }

  return (
    <Table.Th className={table_classes.th}>
      <UnstyledButton onClick={onSort} className={table_classes.control}>
        <Flex
          align="center"
          gap="sm"
          justify={alignLeft ? "left" : "center"}
          px="xs"
          ml={alignLeft ? "xs" : 0}>
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={table_classes.icon}>
            <Icon size="0.9rem" stroke={1.5} />
          </Center>
        </Flex>
      </UnstyledButton>
    </Table.Th>
  );
}

function sortBrands(
  brands: Brand[],
  payload: { sortBy: BrandObjFields | null; reversed: boolean; search: string },
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return brands;
  }

  return [...brands].sort((a, b) => {
    let compareResult = 0;
    const aVal = get(a, sortBy);
    const bVal = get(b, sortBy);

    if (typeof aVal === "number" && typeof bVal === "number") {
      compareResult = (aVal as number) - (bVal as number);
    } else if (aVal instanceof Date && bVal instanceof Date) {
      compareResult = (aVal as Date).getTime() - (bVal as Date).getTime();
    } else {
      compareResult = aVal.toString().localeCompare(bVal.toString());
    }
    return payload.reversed ? -compareResult : compareResult;
  });
}

function BrandStatusBadge({ status }: { status: BrandStatus }) {
  let color = "gray";
  const label = BrandStatus[status] || "Unknown";

  switch (status) {
    case BrandStatus.Active:
      color = "green";
      break;
    case BrandStatus.Free:
      color = "cyan";
      break;
    case BrandStatus.Onboarding:
      color = "yellow";
      break;
    case BrandStatus.Paused:
      color = "orange";
      break;
    case BrandStatus.Completed:
      color = "blue";
      break;
    case BrandStatus.Deleted:
      color = "red";
      break;
    case BrandStatus.Archived:
      color = "grape";
      break;
    default:
      color = "gray";
      break;
  }

  return (
    <Center>
      <Badge color={color} fw={500}>
        {label}
      </Badge>
    </Center>
  );
}

function AdminBrandRow({
  user,
  brand,
  setSelectedBrandId,
}: {
  user: User;
  brand: Brand;
  setSelectedBrandId: (brandId: number) => void;
}) {
  const formatMemberEmails = (member_emails: string[]) => member_emails.join(", ");
  const [cleanBrandStatus, setCleanBrandStatus] = useState<BrandStatus>(brand.status);
  const [brandStatus, setBrandStatus] = useState<BrandStatus>(brand.status);
  const [buttonLoading, setButtonLoading] = useState(false);

  const statusOptions = BRAND_STATUS_SORT_ORDER.map((status: number) => ({
    value: status.toString(),
    label: BrandStatus[status].toString(),
  }));

  return (
    <Table.Tr key={brand.id}>
      <Table.Td>
        <Text>{brand.display_name}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{brand.brand_name}</Text>
      </Table.Td>
      <Table.Td w={150}>
        <Select
          data={statusOptions}
          value={brandStatus.toString()}
          onChange={(value) => {
            setBrandStatus(Number(value));
          }}
        />
      </Table.Td>
      <Table.Td>
        <Text>{brand.contact_email}</Text>
      </Table.Td>
      <Table.Td>
        <Text>
          {brand.member_emails?.length > 0 ? formatMemberEmails(brand.member_emails) : null}
        </Text>
      </Table.Td>
      <Table.Td>
        <Group wrap="nowrap">
          <Button
            size="sm"
            loading={buttonLoading}
            disabled={brandStatus === cleanBrandStatus}
            onClick={() => {
              updateBrandById(user, brand.id, { status: brandStatus }, setButtonLoading, () => {
                setCleanBrandStatus(brandStatus);
              });
            }}>
            Save
          </Button>
          <ActionIcon
            variant="subtle"
            component="button"
            size={32}
            onClick={() => {
              setSelectedBrandId(brand.id);
            }}>
            <IconSettings size="1.5rem" />
          </ActionIcon>
        </Group>
      </Table.Td>
    </Table.Tr>
  );
}

function AdminBrandTable({
  user,
  brands,
  setBrands,
  setSelectedBrandId,
}: {
  user: User;
  brands: Brand[];
  setBrands: (brands: Brand[]) => void;
  setSelectedBrandId: (brandId: number) => void;
}) {
  const [sortBy, setSortBy] = useState<BrandObjFields | null>("status");
  const [reverseSortDirection, setReverseSortDirection] = useState(true);

  const setSorting = (field: BrandObjFields) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setBrands(sortBrands(brands, { sortBy: field, reversed, search: "" }));
  };

  useEffect(() => {
    // default initial sort by status desc
    setBrands(sortBrands(brands, { sortBy, reversed: reverseSortDirection, search: "" }));
  }, [brands]);

  const rows = brands.map((brand) => (
    <AdminBrandRow
      key={brand.id}
      user={user}
      brand={brand}
      setSelectedBrandId={setSelectedBrandId}
    />
  ));

  return (
    <Table.ScrollContainer minWidth={700}>
      <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs" highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Th
              sorted={sortBy === "display_name"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("display_name")}
              alignLeft>
              Display Name
            </Th>
            <Th
              sorted={sortBy === "brand_name"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("brand_name")}
              alignLeft>
              Brand
            </Th>
            <Th
              sorted={sortBy === "status"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("status")}>
              Status
            </Th>
            <Table.Th fw={400}>Main Contact Email</Table.Th>
            <Table.Th fw={400}>Members</Table.Th>
            <Table.Th fw={400}>Settings</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}

export const AdminBrandList = ({
  user,
  brands,
  setSelectedBrandId,
}: {
  user: User;
  brands: Brand[];
  setSelectedBrandId: (brandId: number) => void;
}) => {
  const [brandSortedList, setBrandSortedList] = useState<Brand[]>([]);
  useEffect(() => {
    if (brands?.length > 0) {
      setBrandSortedList([...brands]);
    } else {
      setBrandSortedList([]);
    }
    return () => {};
  }, [brands]);

  return (
    <>
      <Notifications />
      <AdminBrandTable
        user={user}
        brands={brandSortedList}
        setBrands={setBrandSortedList}
        setSelectedBrandId={setSelectedBrandId}
      />
    </>
  );
};

export default AdminBrandList;
