import React, { useEffect, useState } from "react";

import { ActionIcon, Group, Tooltip } from "@mantine/core";

import { IconSwitchHorizontal } from "@tabler/icons-react";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CreatorSetSelector } from "campaigns/main/campaignPage/creator_sets/CreatorSetSelector";

export const AdminLabelingCreatorSetSelector = ({
  creatorSetId,
  setCreatorSetId,
  onChangeCreatorSetId,
}: {
  creatorSetId: number;
  setCreatorSetId: (creatorSetId: number) => void;
  onChangeCreatorSetId: (creatorSetId: number) => void;
}) => {
  const { creatorSets } = useAdminAppContext();
  const [selectedCreatorSetId, setSelectedCreatorSetId] = useState(creatorSetId);

  useEffect(() => {
    setSelectedCreatorSetId(creatorSetId);
  }, [creatorSetId]);

  if (creatorSets?.length === 0) {
    return null;
  }

  return (
    <Group gap="xs">
      <CreatorSetSelector
        creatorSets={creatorSets}
        selectedCreatorSetId={selectedCreatorSetId}
        setSelectedCreatorSetId={setSelectedCreatorSetId}
      />
      <Tooltip label="Switch Creator Set">
        <ActionIcon
          variant="subtle"
          color="gray"
          component="button"
          size={32}
          onClick={() => {
            onChangeCreatorSetId(selectedCreatorSetId);
          }}>
          <IconSwitchHorizontal size="1.5rem" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};

export default AdminLabelingCreatorSetSelector;
