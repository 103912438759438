import React, { useEffect, useState } from "react";

import {
  Anchor,
  Center,
  Flex,
  Group,
  Loader,
  Mark,
  Select,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

import { IconExternalLink } from "@tabler/icons-react";

import { getHistory } from "admin/api/labeling/sourcingApi";
import { fromISODateString } from "utils/DateUtils";
import { ReviewState } from "components/creator_lists/LabelingDatasetsListView";
import { useAdminAppContext } from "admin/app/AdminAppShell";

const AdminCreatorLabelingHistoryTableRow = ({ row }: { row: any }) => {
  if (!row) {
    return null;
  }

  const getPersonalLabel = (personalLabel: number, teamLabel: number) => {
    if (personalLabel === teamLabel) {
      if (personalLabel === ReviewState.ACCEPTED) {
        return (
          <Text fw={700} c="green">
            {String(ReviewState[personalLabel])}
          </Text>
        );
      } else if (personalLabel === ReviewState.REJECTED) {
        return (
          <Text fw={700} c="red">
            {String(ReviewState[personalLabel])}
          </Text>
        );
      }
      return <Text fw={700}>{String(ReviewState[personalLabel])}</Text>;
    }
    // If disagree, we want to highlight it
    if (personalLabel === ReviewState.ACCEPTED && teamLabel === ReviewState.REJECTED) {
      return (
        <Text fw={700} c="green">
          <Mark>{String(ReviewState[personalLabel])}</Mark>
        </Text>
      );
    } else if (personalLabel === ReviewState.REJECTED && teamLabel === ReviewState.ACCEPTED) {
      return (
        <Text fw={700} c="red">
          <Mark>{String(ReviewState[personalLabel])}</Mark>
        </Text>
      );
    }
    return <Text fw={700}>{String(ReviewState[personalLabel])}</Text>;
  };

  const getTeamLabel = (teamLabel: number) => {
    if (teamLabel === null || teamLabel === ReviewState.UNKNOWN) {
      return "-";
    }
    if (teamLabel === ReviewState.ACCEPTED) {
      return (
        <Text fw={700} c="green">
          {String(ReviewState[teamLabel])}
        </Text>
      );
    } else if (teamLabel === ReviewState.REJECTED) {
      return (
        <Text fw={700} c="red">
          {String(ReviewState[teamLabel])}
        </Text>
      );
    }
    return <Text fw={700}>{String(ReviewState[teamLabel])}</Text>;
  };

  return (
    <Table.Tr>
      <Table.Td>{fromISODateString(row.label_dt)?.toLocaleString()}</Table.Td>
      <Table.Td>{row.creator_set_name}</Table.Td>
      <Table.Td>{row.creator_name}</Table.Td>
      <Table.Td>
        <Group wrap="nowrap">
          {getPersonalLabel(row.label, row.team_label)}
          <Anchor
            href={`/admin/labeling?creatorSetId=${row.creator_set_id}&creatorId=${row.creator_id}`}
            target="_blank">
            <Flex align="center" gap={0}>
              <IconExternalLink size="1.2rem" stroke={2} />
            </Flex>
          </Anchor>
        </Group>
      </Table.Td>
      <Table.Td>{getTeamLabel(row.team_label)}</Table.Td>
    </Table.Tr>
  );
};

const AdminCreatorLabelingHistoryTable = ({
  history,
  loading,
}: {
  history: any;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <Table stickyHeader highlightOnHover w="100%">
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Date</Table.Th>
          <Table.Th>Creator Set</Table.Th>
          <Table.Th>Creator</Table.Th>
          <Table.Th>Your Label</Table.Th>
          <Table.Th>Team Label</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {history?.length > 0 &&
          history.map((row: any) => (
            <AdminCreatorLabelingHistoryTableRow
              key={`${row.creator_id}-${row.creator_set_id}`}
              row={row}
            />
          ))}
      </Table.Tbody>
    </Table>
  );
};

const AdminCreatorLabelingHistoryFilter = ({
  dateRange,
  setDateRange,
  creatorSetId,
  setCreatorSetId,
}: {
  dateRange: [Date | null, Date | null];
  setDateRange: (value: [Date | null, Date | null]) => void;
  creatorSetId: number | null;
  setCreatorSetId: (value: number | null) => void;
}) => {
  const { creatorSets } = useAdminAppContext();
  const [creatorSetOptions, setCreatorSetOptions] = useState<{ label: string; value: string }[]>(
    [],
  );

  useEffect(() => {
    setCreatorSetOptions(creatorSets.map((cs) => ({ label: cs.name, value: cs.id.toString() })));
  }, [creatorSets]);

  return (
    <Group>
      <DatePickerInput
        type="range"
        label="Date Range"
        placeholder="All"
        value={dateRange}
        onChange={setDateRange}
        clearable
      />
      <Select
        label="Creator Set"
        placeholder="All"
        data={creatorSetOptions}
        value={creatorSetId?.toString() || ""}
        onChange={(value) => setCreatorSetId(Number(value))}
        searchable
      />
    </Group>
  );
};

export const AdminCreatorLabelingHistory = () => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState<any>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [creatorSetId, setCreatorSetId] = useState<number | null>(null);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getHistory(abortController, creatorSetId, dateRange)
      .then((res) => {
        if (res != null && res?.labels?.length > 0) {
          setHistory(res.labels);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    return () => abortController.abort();
  }, [creatorSetId, dateRange]);

  return (
    <Stack justify="center" w="100%" px="md">
      <AdminCreatorLabelingHistoryFilter
        dateRange={dateRange}
        setDateRange={setDateRange}
        creatorSetId={creatorSetId}
        setCreatorSetId={setCreatorSetId}
      />
      <AdminCreatorLabelingHistoryTable history={history} loading={loading} />
    </Stack>
  );
};

export default AdminCreatorLabelingHistory;
