import { User as FirebaseUser, getAuth } from "firebase/auth";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { ContractOffer } from "models/ContractOffer";

import { API_URL } from "configs/Configs";
import { abort } from "process";

const NEGOTIATIONS_PREFIX_URL = `${API_URL}/api/contracts/negotiations`;
const CONTRACT_OFFER_URL = `${NEGOTIATIONS_PREFIX_URL}/models/contract_offer`;

const CONTRACT_OFFER_LATEST_FOR_OPPORTUNITY_URL = `${NEGOTIATIONS_PREFIX_URL}/get_latest_offer_for_opportunity`;
const CONTRACT_OFFERS_FOR_OPPORTUNITY_URL = `${NEGOTIATIONS_PREFIX_URL}/get_offers_for_opportunity`;
const CONTRACT_OFFER_SUBMIT_FOR_REVIEW_INTERNAL_URL = `${NEGOTIATIONS_PREFIX_URL}/submit_creator_offer_for_review_internal`;
const CONTRACT_OFFER_APPROVE_INTERNAL_URL = `${NEGOTIATIONS_PREFIX_URL}/approve_creator_offer_internal`;
const CONTRACT_OFFER_REJECT_INTERNAL_URL = `${NEGOTIATIONS_PREFIX_URL}/reject_creator_internal`;
const CONTRACT_OFFER_REVERT_PACKAGE_CONTRACT_URL = `${NEGOTIATIONS_PREFIX_URL}/revert_package_contract/`;
const CONTRACT_OFFER_REVERT_BRAND_CONTRACT_REVIEW_URL = `${NEGOTIATIONS_PREFIX_URL}/revert_brand_contract_review/`;

export interface ContractErrorResponse {
  status: number | string;
  result: Record<string, string>;
  ok: boolean;
  text: string;
}

export const getContractOffersForOpportunity = async (
  opportunityId: number,
  abortController: AbortController,
): Promise<ContractOffer[]> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFER_URL}/list_for_opportunity/`);
  url.searchParams.append("opportunity_id", String(opportunityId));
  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getContractOffer = async (
  contractOfferId: number,
  abortController: AbortController,
): Promise<ContractOffer> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFER_URL}/${contractOfferId}/`);
  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getContractOffersPendingInternalReview = async (abortController: AbortController) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFER_URL}/list_pending_internal`);
  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getContractOffersPendingBrandReview = async (abortController: AbortController) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFER_URL}/list_pending_brand_review`);
  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getContractOffersBrandApprovedPendingInternalReview = async (
  abortController: AbortController,
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFER_URL}/list_brand_approved_pending_internal`);
  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getContractOffersBrandRejected = async (abortController: AbortController) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFER_URL}/list_brand_rejected`);
  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getContractOffersApprovedForContract = async (abortController: AbortController) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFER_URL}/list_approved_for_contract`);
  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getLatestContractOfferForOpportunity = async (
  opportunityId: number,
  abortController: AbortController,
): Promise<ContractOffer> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFER_LATEST_FOR_OPPORTUNITY_URL}`);
  url.searchParams.append("opportunity_id", String(opportunityId));
  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getAllContractOffersForOpportunity = async (
  opportunityIds: number[],
  abortController: AbortController,
): Promise<ContractOffer[]> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CONTRACT_OFFERS_FOR_OPPORTUNITY_URL}`);
  opportunityIds.forEach((id) => {
    url.searchParams.append("opportunity_id", String(id));
  });

  const request = new Request(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const addContractOffer = async (contractOffer: ContractOffer): Promise<ContractOffer> => {
  const url = new URL(`${CONTRACT_OFFER_SUBMIT_FOR_REVIEW_INTERNAL_URL}`);
  const request = await createRequestWithFirebaseToken({
    url,
    method: "POST",
    body: JSON.stringify(contractOffer),
  });
  const response = await handleResult(request);
  return response;
};

export const approveContractOfferInternal = async (
  contractOfferId: number,
  approvedPrice: number,
  usageRightsPrice: number,
  packageId: number,
): Promise<ContractOffer> => {
  const url = new URL(`${CONTRACT_OFFER_APPROVE_INTERNAL_URL}`);
  const request = await createRequestWithFirebaseToken({
    url,
    method: "POST",
    body: JSON.stringify({
      offer_id: contractOfferId,
      price: approvedPrice,
      usage_rights_price: usageRightsPrice,
      package_id: packageId,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const rejectContractOfferInternal = async (
  contractOfferId: number,
): Promise<ContractOffer> => {
  // TODO(andrew): differentiate between rejecting offer vs just a package
  const url = new URL(`${CONTRACT_OFFER_REJECT_INTERNAL_URL}`);
  const request = await createRequestWithFirebaseToken({
    url,
    method: "POST",
    body: JSON.stringify({
      offer_id: contractOfferId,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const addInternalContractOfferNotes = async (
  contractOfferId: number,
  notes: string,
  abortController: AbortController,
): Promise<ContractOffer> => {
  const url = new URL(`${CONTRACT_OFFER_URL}/add_internal_notes/`);
  const request = await createRequestWithFirebaseToken({
    url,
    method: "POST",
    body: JSON.stringify({
      id: contractOfferId,
      notes,
    }),
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const revertContractIssued = async (deliverableId: number) => {
  const url = new URL(`${CONTRACT_OFFER_REVERT_PACKAGE_CONTRACT_URL}`);
  const request = await createRequestWithFirebaseToken({
    url,
    method: "POST",
    body: JSON.stringify({
      package_id: deliverableId,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const revertBrandContractReview = async (deliverableId: number) => {
  const url = new URL(`${CONTRACT_OFFER_REVERT_BRAND_CONTRACT_REVIEW_URL}`);
  const request = await createRequestWithFirebaseToken({
    url,
    method: "POST",
    body: JSON.stringify({
      package_id: deliverableId,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const deleteContractOffer = async (contractOfferId: number) => {
  const url = new URL(`${CONTRACT_OFFER_URL}/${contractOfferId}/`);
  const request = await createRequestWithFirebaseToken({
    url,
    method: "DELETE",
  });
  const response = await handleResult(request);
  return response;
};

export default null;
