import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { SupportedPlatform } from "models/Common";
import { SearchMethod } from "components/discovery/search/Common";

const API_PATH = "api/discovery/search";

export const getAccessibleCreatorSets = async (getCreatorIds = false, creatorSetId?: number) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/get_accessible_creatorsets`);
  requestUrl.searchParams.append("getCreatorIds", getCreatorIds.toString());
  if (creatorSetId) {
    requestUrl.searchParams.append("creatorSetId", creatorSetId.toString());
  }
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
};

export const getBrandNames = async () => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/get_brand_names`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
};

export const getCreatorSetsForBrand = async (brandId: string) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/get_creatorsets_for_brand`);
  requestUrl.searchParams.append("brandId", brandId);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
};

export const search = async ({
  sessionId,
  prevQueryId,
  searchSource,
  query,
  filters,
  searchMethod,
  searchPlatform,
}: {
  sessionId: string;
  prevQueryId: string;
  searchSource: number;
  query: string;
  filters: any;
  searchMethod: SearchMethod;
  searchPlatform: SupportedPlatform;
}) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/search`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      sessionId,
      prevQueryId,
      searchSource,
      query,
      filters,
      searchMethod,
      searchPlatform,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const fetchQueryInfo = async (sessionId: string, queryId: string) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/fetch_query_info`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      sessionId,
      queryId,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const getAutocompleteOptions = async (hashtag: string) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/get_autocomplete_options`);
  requestUrl.searchParams.append("query", hashtag);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
};

export const getPagedCreatorDetails = async ({
  sessionId,
  queryId,
  sortBy,
  searchMethod,
  creatorIds,
  submittedQuery,
  pageNumber,
  pageSize,
  activeTab,
}: {
  sessionId: string;
  queryId: string;
  sortBy: string;
  searchMethod: number;
  creatorIds: number[];
  submittedQuery: string;
  pageNumber: number;
  pageSize: number;
  activeTab: string;
}) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/get_paged_creator_details`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      sessionId,
      queryId,
      sortBy,
      searchMethod,
      creatorIds,
      submittedQuery,
      pageNumber,
      pageSize,
      activeTab,
    }),
  });

  const response = await handleResult(request);
  return response;
};

export const activateCreator = async ({
  sessionId,
  queryId,
  sortBy,
  activeTab,
  pageNumber,
  creatorId,
  creatorSetId,
  shouldLog = false,
}: {
  sessionId: string;
  queryId: string;
  sortBy: string;
  activeTab: string;
  pageNumber: number;
  creatorId: number;
  creatorSetId: number;
  shouldLog: boolean;
}) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/activate_creator`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      sessionId,
      queryId,
      sortBy,
      activeTab,
      pageNumber,
      creatorId,
      creatorSetId,
      shouldLog,
    }),
  });

  const response = await handleResult(request);
  return response;
};

export const saveCreator = async ({
  sessionId,
  queryId,
  sortBy,
  activeTab,
  pageNumber,
  creatorId,
  creatorSetId,
  shouldLog = false,
}: {
  sessionId: string;
  queryId: string;
  sortBy: string;
  activeTab: string;
  pageNumber: number;
  creatorId: number;
  creatorSetId: number;
  shouldLog: boolean;
}) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/save_creator`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      sessionId,
      queryId,
      sortBy,
      activeTab,
      pageNumber,
      creatorId,
      creatorSetId,
      shouldLog,
    }),
  });

  const response = await handleResult(request);
  return response;
};

export const unsaveCreator = async ({
  sessionId,
  queryId,
  sortBy,
  activeTab,
  pageNumber,
  creatorId,
  creatorSetId,
  shouldLog = false,
}: {
  sessionId: string;
  queryId: string;
  sortBy: string;
  activeTab: string;
  pageNumber: number;
  creatorId: number;
  creatorSetId: number;
  shouldLog: boolean;
}) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/unsave_creator`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      sessionId,
      queryId,
      sortBy,
      activeTab,
      pageNumber,
      creatorId,
      creatorSetId,
      shouldLog,
    }),
  });

  const response = await handleResult(request);
  return response;
};

export const hideCreator = async ({
  sessionId,
  queryId,
  sortBy,
  activeTab,
  pageNumber,
  creatorId,
  brandId,
  shouldLog = false,
}: {
  sessionId: string;
  queryId: string;
  sortBy: string;
  activeTab: string;
  pageNumber: number;
  creatorId: number;
  brandId: number;
  shouldLog: boolean;
}) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/hide_creator`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      sessionId,
      queryId,
      sortBy,
      activeTab,
      pageNumber,
      creatorId,
      brandId,
      shouldLog,
    }),
  });

  const response = await handleResult(request);
  return response;
};

export const unhideCreator = async ({
  sessionId,
  queryId,
  sortBy,
  activeTab,
  pageNumber,
  creatorId,
  brandId,
  shouldLog = false,
}: {
  sessionId: string;
  queryId: string;
  sortBy: string;
  activeTab: string;
  pageNumber: number;
  creatorId: number;
  brandId: number;
  shouldLog: boolean;
}) => {
  const requestUrl = new URL(`${API_URL}/${API_PATH}/unhide_creator`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      sessionId,
      queryId,
      sortBy,
      activeTab,
      pageNumber,
      creatorId,
      brandId,
      shouldLog,
    }),
  });

  const response = await handleResult(request);
  return response;
};
