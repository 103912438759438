import React, { useEffect, useState } from "react";

import { Button, Container, Group, Modal, Stack, Text, Textarea, Tooltip } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconCheck, IconEdit, IconHistory, IconNotes, IconSend } from "@tabler/icons-react";

import {
  fetchMessages,
  markMessagesLastRead,
  approveContent,
  requestContentRevision,
  sendMessageToCreator,
} from "components/contracts/common/Api";

import { ContentStatus } from "components/contracts/common/Common";

import Message, { MessageParty } from "components/contracts/models/Message";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import Script from "components/contracts/models/Script";
import VersionedScript from "components/contracts/models/VersionedScript";
import VersionedDeliverableVideo from "components/contracts/models/VersionedDeliverableVideo";

import MessageHistory from "components/contracts/common/MessageHistory";
import ViewPreviousScripts from "components/contracts/common/ViewPreviousScripts";
import ViewPreviousVideos from "components/contracts/common/ViewPreviousVideos";

const MESSAGE_LENGTH_LIMIT = 3000;

function PreviousContentModals({
  previousScripts,
  previousVideos,
  scriptsOpened,
  closeScripts,
  videosOpened,
  closeVideos,
}: {
  previousScripts: Script[];
  previousVideos: DeliverableVideo[];
  scriptsOpened: boolean;
  closeScripts: () => void;
  videosOpened: boolean;
  closeVideos: () => void;
}) {
  return (
    <>
      <Modal
        opened={scriptsOpened}
        onClose={closeScripts}
        withCloseButton={false}
        size="auto"
        maw="70%">
        <Container>
          <ViewPreviousScripts scripts={previousScripts} messageParty={MessageParty.BRAND} />
        </Container>
      </Modal>
      <Modal
        opened={videosOpened}
        onClose={closeVideos}
        withCloseButton={false}
        size="auto"
        maw="70%">
        <Container>
          <ViewPreviousVideos
            deliverableVideos={previousVideos}
            messageParty={MessageParty.BRAND}
          />
        </Container>
      </Modal>
    </>
  );
}

export function getApproveButtonText({
  isScript,
  isVideo,
  canSendMessages,
  messageText,
  forUgc = false,
}: {
  isScript: boolean;
  isVideo: boolean;
  canSendMessages: boolean;
  messageText: string;
  forUgc?: boolean;
}) {
  const messageEmpty = messageText === "";

  if (isScript) {
    if (messageEmpty) {
      return `Approve Concept`;
    }
    return `Approve Concept ${canSendMessages ? "with Message" : "with Feedback"}`;
  } else if (isVideo && !forUgc) {
    if (messageEmpty) {
      return `Approve to Go Live`;
    }
    return `Approve to Go Live ${canSendMessages ? "with Message" : "with Feedback"}`;
  }

  if (messageEmpty) {
    return `Approve`;
  }
  return `Approve ${canSendMessages ? "with Message" : "with Feedback"}`;
}

export default function ContentReviewCardActions({
  deliverableId,
  creatorHandle,
  contentReviewStatus,
  isMissingReferralLink,
  isMissingPromoCode,
  numUnreadMessages,
  setNumUnreadMessages,
  brandMessagesLastRead,
  totalNumUnreadMessages,
  setTotalNumUnreadMessages,
  clearedUnreadMessages,
  updateCardState,
  versionedScript,
  versionedDeliverableVideo,
  scriptId,
  videoId,
  showAdminOptions,
}: {
  deliverableId: string;
  creatorHandle: string;
  contentReviewStatus: ContentStatus;
  isMissingReferralLink: boolean;
  isMissingPromoCode: boolean;
  numUnreadMessages: number;
  setNumUnreadMessages: (numUnreadMessages: number) => void;
  brandMessagesLastRead: Date;
  totalNumUnreadMessages: number;
  setTotalNumUnreadMessages: (totalNumUnreadMessages: number) => void;
  clearedUnreadMessages: boolean;
  updateCardState: (reviewStatus: ContentStatus, reviewDate: Date) => void;
  versionedScript?: VersionedScript;
  versionedDeliverableVideo?: VersionedDeliverableVideo;
  scriptId?: string;
  videoId?: string;
  showAdminOptions?: boolean;
}) {
  if (
    scriptId &&
    (!versionedScript || !versionedScript.scripts || versionedScript.scripts.length === 0)
  ) {
    return null;
  }

  if (
    videoId &&
    (!versionedDeliverableVideo ||
      !versionedDeliverableVideo.deliverableVideos ||
      versionedDeliverableVideo.deliverableVideos.length === 0)
  ) {
    return null;
  }

  const previousScripts = scriptId ? versionedScript.scripts.slice(1) : versionedScript.scripts;
  const previousDeliverableVideos = videoId
    ? versionedDeliverableVideo.deliverableVideos.slice(1)
    : null;

  const [messageText, setMessageText] = useState("");
  const [approveButtonLoading, setApproveButtonLoading] = useState(false);
  const [revisionButtonLoading, setRevisionButtonLoading] = useState(false);
  const [sendMessageButtonLoading, setSendMessageButtonLoading] = useState(false);

  const actionRequired = contentReviewStatus === ContentStatus.PENDING_REVIEW;
  const canSendMessages = contentReviewStatus === ContentStatus.REVISIONS_REQUESTED;
  const hasPreviousRevisions = previousScripts?.length > 0 || previousDeliverableVideos?.length > 0;

  const [scriptsOpened, { open: openScripts, close: closeScripts }] = useDisclosure();
  const [videosOpened, { open: openVideos, close: closeVideos }] = useDisclosure();

  const [messages, setMessages] = useState<Message[]>([]);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [messageLoadingError, setMessageLoadingError] = useState(false);
  const [loadMessages, setLoadMessages] = useState(true);
  useEffect(() => {
    if (!videoId && !scriptId) {
      // There's no messages if there's no content.
      setLoadMessages(false);
      return;
    }

    fetchMessages({
      deliverableId,
      messageParty: MessageParty.BRAND.toString(),
      videoId,
      scriptId,
    })
      .then((response: any) => {
        const { success, messages: fetchedMessages } = response;
        if (success) {
          const deserializedMessages = fetchedMessages.map((message: any) => {
            const deserializedMessage = Message.deserialize(message);
            if (
              deserializedMessage.recipientType === MessageParty.BRAND &&
              deserializedMessage.senderType !== MessageParty.AUTO &&
              deserializedMessage.dateCreated > brandMessagesLastRead
            ) {
              deserializedMessage.markUnread();
            }
            return deserializedMessage;
          });
          setMessages(deserializedMessages);
          setMessagesLoaded(true);
        } else {
          setMessageLoadingError(true);
          setMessagesLoaded(true);
        }
      })
      .catch(() => {
        setMessageLoadingError(true);
        setMessagesLoaded(true);
      });
  }, []);

  // Implement function to clear unread messages state at the deliverable level.
  const clearDeliverableUnreadMessageState = () => {
    messages.forEach((message: Message) => {
      message.markRead();
    });
    setNumUnreadMessages(0);
  };

  // This is state that has been passed down by the parent. If true, it indicates
  // that unread messages have been cleared at the Contract level.
  useEffect(() => {
    if (clearedUnreadMessages) {
      clearDeliverableUnreadMessageState();
    }
  }, [clearedUnreadMessages]);

  const updateMessages = (message: Message) => {
    if (message) {
      const sentMessage = Message.deserialize(message);
      setMessages([...messages, sentMessage]);
    }
    clearDeliverableUnreadMessageState();
  };

  const handleMarkMessagesRead = () => {
    markMessagesLastRead({ deliverableId }).then((response) => {
      if (response.success) {
        setTotalNumUnreadMessages(Math.max(totalNumUnreadMessages - numUnreadMessages, 0));
        clearDeliverableUnreadMessageState();
      }
    });
  };

  const handleApproveContent = () => {
    setApproveButtonLoading(true);
    approveContent({
      deliverableId,
      messageText,
      scriptId,
      videoId,
    })
      .then((response: any) => {
        const { message, success, reviewDate, reviewStatus } = response;
        if (success) {
          updateMessages(message);
          updateCardState(reviewStatus, reviewDate);
          setMessageText("");
          showSuccessNotification({
            message: (
              <Text>
                {scriptId ? "Concept" : "Video"} by{" "}
                <Text span fw="bold">
                  {creatorHandle}
                </Text>{" "}
                approved!
              </Text>
            ),
          });
          setApproveButtonLoading(false);
        } else {
          showFailureNotification({
            message: (
              <Text>
                Unable to approve {scriptId ? "concept" : "video"} by{" "}
                <Text span fw="bold">
                  {creatorHandle}
                </Text>
                .
              </Text>
            ),
          });
          setApproveButtonLoading(false);
        }
      })
      .catch(() => {
        showFailureNotification({
          message: (
            <Text>
              Unable to approve {scriptId ? "concept" : "video"} by{" "}
              <Text span fw="bold">
                {creatorHandle}
              </Text>
              .
            </Text>
          ),
        });
        setApproveButtonLoading(false);
      });
  };

  const handleRequestRevision = () => {
    setRevisionButtonLoading(true);
    requestContentRevision({
      deliverableId,
      messageText,
      scriptId,
      videoId,
    })
      .then((response: any) => {
        const { message, success, reviewDate, reviewStatus } = response;
        if (success) {
          updateMessages(message);
          updateCardState(reviewStatus, reviewDate);
          setMessageText("");
          showSuccessNotification({
            message: (
              <Text>
                {scriptId ? "Concept" : "Video"} revision feedback sent to{" "}
                <Text span fw="bold">
                  {creatorHandle}
                </Text>{" "}
                successfully.
              </Text>
            ),
          });
          setRevisionButtonLoading(false);
        } else {
          showFailureNotification({
            message: (
              <Text>
                Unable to request revision for{" "}
                <Text span fw="bold">
                  {creatorHandle}
                </Text>
                &apos;s {scriptId ? "concept." : "video."}
              </Text>
            ),
          });
          setRevisionButtonLoading(false);
        }
      })
      .catch(() => {
        showFailureNotification({
          message: (
            <Text>
              Unable to request revision for{" "}
              <Text span fw="bold">
                {creatorHandle}
              </Text>
              &apos;s {scriptId ? "concept." : "video."}
            </Text>
          ),
        });
        setRevisionButtonLoading(false);
      });
  };

  const handleSendMessage = () => {
    setSendMessageButtonLoading(true);
    sendMessageToCreator({
      deliverableId,
      messageText,
      scriptId,
      videoId,
    })
      .then((response: any) => {
        const { message, success } = response;
        if (success) {
          updateMessages(message);
          setMessageText("");
          showSuccessNotification({
            message: (
              <Text>
                Sent message to{" "}
                <Text span fw="bold">
                  {creatorHandle}
                </Text>{" "}
                successfully!
              </Text>
            ),
          });
        } else {
          showFailureNotification({
            message: (
              <Text>
                Unable to send message to{" "}
                <Text span fw="bold">
                  {creatorHandle}
                </Text>
                .
              </Text>
            ),
          });
        }
      })
      .catch(() => {
        showFailureNotification({
          message: (
            <Text>
              Unable to send message to{" "}
              <Text span fw="bold">
                {creatorHandle}
              </Text>
              .
            </Text>
          ),
        });
      })
      .finally(() => {
        setSendMessageButtonLoading(false);
      });
  };

  const missingLinkOrPromoCodeMessage = `Provide a ${isMissingReferralLink ? "Referral Link" : ""}${
    isMissingReferralLink && isMissingPromoCode ? " and " : ""
  }${isMissingPromoCode ? "Promo Code" : ""} Before approving the content`;
  const shouldBlockReview = isMissingReferralLink || isMissingPromoCode;

  return (
    <>
      <PreviousContentModals
        previousScripts={previousScripts}
        previousVideos={previousDeliverableVideos}
        scriptsOpened={scriptsOpened}
        closeScripts={closeScripts}
        videosOpened={videosOpened}
        closeVideos={closeVideos}
      />
      {loadMessages && (
        <Stack>
          <MessageHistory
            messages={messages}
            setMessages={setMessages}
            messageViewer={MessageParty.BRAND}
            messagesLoaded={messagesLoaded}
            loadingError={messageLoadingError}
            numUnreadMessages={numUnreadMessages}
            handleMarkMessagesRead={handleMarkMessagesRead}
            showAdminOptions={showAdminOptions}
            deliverableId={deliverableId}
            scriptId={scriptId}
            videoId={videoId}
          />

          {(actionRequired || canSendMessages) && (
            <Textarea
              placeholder="Optional"
              label={
                <Text ml={1} mb={4} fw="500">
                  Send {actionRequired ? "Feedback" : "Message"} to {creatorHandle}
                </Text>
              }
              autosize
              minRows={3}
              value={messageText}
              onChange={(event) => setMessageText(event.currentTarget.value)}
              error={
                messageText.length > MESSAGE_LENGTH_LIMIT &&
                `${
                  actionRequired ? "Feedback" : "Message"
                } must not exceed ${MESSAGE_LENGTH_LIMIT} characters.`
              }
            />
          )}
          <Group justify={hasPreviousRevisions ? "space-between" : "right"}>
            <Group>
              {scriptId && previousScripts.length > 0 && (
                <Button
                  onClick={openScripts}
                  variant="default"
                  size="sm"
                  leftSection={<IconHistory size="1rem" />}>
                  View Prior Revisions
                </Button>
              )}

              {videoId && previousDeliverableVideos.length > 0 && (
                <Button
                  onClick={openVideos}
                  variant="default"
                  size="sm"
                  leftSection={<IconHistory size="1rem" />}>
                  View Prior Revisions
                </Button>
              )}
              {videoId && versionedScript?.scripts?.length > 0 && (
                <Button
                  onClick={openScripts}
                  variant="default"
                  size="sm"
                  leftSection={<IconNotes size="1rem" />}>
                  View Concept
                </Button>
              )}
            </Group>
            {(actionRequired || canSendMessages) && (
              <Group>
                {canSendMessages && (
                  <Button
                    disabled={messageText.length === 0 || messageText.length > MESSAGE_LENGTH_LIMIT}
                    loading={sendMessageButtonLoading}
                    onClick={handleSendMessage}
                    leftSection={<IconSend size="1rem" />}
                    variant="outline"
                    color="gray"
                    size="sm">
                    Send Message
                  </Button>
                )}
                {actionRequired && (
                  <Button
                    disabled={messageText.length === 0 || messageText.length > MESSAGE_LENGTH_LIMIT}
                    loading={revisionButtonLoading}
                    onClick={handleRequestRevision}
                    leftSection={<IconEdit size="1rem" />}
                    variant="light"
                    color="red"
                    size="sm">
                    Request Revision
                  </Button>
                )}
                <Tooltip label={missingLinkOrPromoCodeMessage} disabled={!shouldBlockReview}>
                  <Button
                    disabled={messageText.length > MESSAGE_LENGTH_LIMIT || shouldBlockReview}
                    loading={approveButtonLoading}
                    onClick={handleApproveContent}
                    leftSection={<IconCheck size="1rem" />}
                    variant="light"
                    color="teal"
                    size="sm">
                    {getApproveButtonText({
                      isScript: !!scriptId,
                      isVideo: !!videoId,
                      canSendMessages,
                      messageText,
                    })}
                  </Button>
                </Tooltip>
              </Group>
            )}
          </Group>
        </Stack>
      )}
    </>
  );
}
