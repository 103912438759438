import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  CopyButton,
  Flex,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";

import { IconCheck, IconCopy } from "@tabler/icons-react";

import {
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
  ProductAccessStatus,
} from "components/contracts/common/Common";

import {
  saveBrandProductAccessInfo,
  saveBrandReportIssueWithProductAccess,
} from "components/contracts/common/Api";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";
import CreatorAvatar from "components/contracts/common/CreatorAvatar";

import ViewContractLink from "components/contracts/common/ViewContractLink";
import ContractHeaderInfo from "components/contracts/review/ContractHeaderInfo";
import CollapsibleCard from "components/contracts/common/CollapsibleCard";

function getShippingAddress(creatorInput: object) {
  const address = creatorInput as {
    firstName: string;
    lastName: string;
    streetAddress: string;
    streetAddress2: string;
    city: string;
    state: string;
    zipCode: number;
  };
  return (
    <Paper shadow="xs" p="sm" radius="sm" miw={500} withBorder>
      <Text>
        {address.firstName} {address.lastName}
        <br />
        {address.streetAddress}
        <br />
        {address.streetAddress2 ? (
          <>
            {address.streetAddress2}
            <br />
          </>
        ) : null}
        {address.city}, {address.state} {address.zipCode}
      </Text>
    </Paper>
  );
}
function isValidPromoCode(promoCode: string) {
  return promoCode.length > 0 && promoCode.length <= 255;
}

export function ProductAccessBadge({
  productAccessStatus,
}: {
  productAccessStatus: ProductAccessStatus;
}) {
  let color = "gray";
  let text = "";
  switch (productAccessStatus) {
    case ProductAccessStatus.BRAND_ACTION_REQUIRED:
      color = "red";
      text = "Action Required";
      break;
    case ProductAccessStatus.CREATOR_ACTION_REQUIRED:
      color = "yellow";
      text = "Waiting for Creator Info";
      break;
    case ProductAccessStatus.CREATOR_RESUBMIT_REQUIRED:
      color = "yellow";
      text = "Waiting for Creator to resubmit";
      break;
    case ProductAccessStatus.COMPLETED:
      color = "teal";
      text = "Completed";
      break;

    default:
      color = "gray";
      text = "Not Started";
  }
  return (
    <Badge variant="light" color={color}>
      {text}
    </Badge>
  );
}

export function BrandInputResult({
  contractHashId,
  signatureFirstName,
  signatureLastName,
  status,
  setStatus,
  productAccessInfoNeededFromCreator,
  productAccessInfoToProvideToCreator,
  productAccessOtherInfoToProvideToCreator,
  productAccessBrandOutput,
  setProductAccessBrandOutput,

  disabled,
}: {
  contractHashId: string;
  signatureFirstName: string;
  signatureLastName: string;
  status: ProductAccessStatus;
  setStatus: (value: ProductAccessStatus) => void;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;
  productAccessOtherInfoToProvideToCreator: string;
  productAccessBrandOutput: string;
  setProductAccessBrandOutput: (value: string | object) => void;
  disabled?: boolean;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isIssue, setIsIssue] = useState<boolean>(false);

  const [shippingTrackingCode, setShippingTrackingCode] = useState<string>("");
  const [shippingCarrier, setShippingCarrier] = useState<string>("");
  const [issueMessage, setIssueMessage] = useState<string>("");
  const isNoneRequired =
    productAccessInfoToProvideToCreator === ProductAccessInfoToProvideToCreatorType.NONE;
  const [isComplete, setIsComplete] = useState<boolean>(status === ProductAccessStatus.COMPLETED);
  useEffect(() => {
    if (
      productAccessInfoToProvideToCreator ===
        ProductAccessInfoToProvideToCreatorType.TRACKING_CODE &&
      productAccessBrandOutput &&
      typeof productAccessBrandOutput === "object"
    ) {
      const trackingInfo = productAccessBrandOutput as {
        shippingCarrier: string;
        code: string;
        shippingUrl: string;
      };
      setShippingCarrier(trackingInfo.shippingCarrier);
      setShippingTrackingCode(trackingInfo.code);
    }
  }, []);

  const isOutputMissing = () => {
    let missing = false;
    if (
      productAccessInfoToProvideToCreator === ProductAccessInfoToProvideToCreatorType.TRACKING_CODE
    ) {
      missing = shippingTrackingCode === "" || shippingCarrier === "";
    } else if (
      typeof productAccessBrandOutput === "string" &&
      productAccessBrandOutput.length === 0
    ) {
      missing = true;
    }

    return missing;
  };

  const submitIssueToCreator = () => {
    saveBrandReportIssueWithProductAccess(contractHashId, issueMessage).then((response: any) => {
      const { success, error } = response;
      if (success) {
        setIsIssue(false);
        showSuccessNotification({
          message: (
            <Text>
              Successfully reported an issue with product access to {signatureFirstName}{" "}
              {signatureLastName}!
            </Text>
          ),
        });
        setStatus(ProductAccessStatus.CREATOR_RESUBMIT_REQUIRED);
      } else {
        showFailureNotification(error);
      }
    });
  };

  const handleSaveOutput = () => {
    setLoading(true);
    if (
      productAccessInfoToProvideToCreator === ProductAccessInfoToProvideToCreatorType.TRACKING_CODE
    ) {
      setProductAccessBrandOutput({
        shippingCarrier,
        code: shippingTrackingCode,
      });
    }
    let output: string | object = productAccessBrandOutput;
    if (
      productAccessInfoToProvideToCreator === ProductAccessInfoToProvideToCreatorType.TRACKING_CODE
    ) {
      output = {
        shippingCarrier,
        code: shippingTrackingCode,
      };
    }
    saveBrandProductAccessInfo(contractHashId, productAccessInfoToProvideToCreator, output)
      .then((response: any) => {
        const { success, error } = response;

        if (success) {
          setLoading(false);
          setStatus(ProductAccessStatus.COMPLETED);
          setIsComplete(true);
          showSuccessNotification({
            message: (
              <Text>
                Successfully saved product access info for {signatureFirstName} {signatureLastName}!
              </Text>
            ),
          });
        } else {
          setLoading(false);
          showFailureNotification(error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showFailureNotification(error);
      });
  };
  let brandComp = null;
  switch (productAccessInfoToProvideToCreator) {
    case ProductAccessInfoToProvideToCreatorType.NONE:
      brandComp = (
        <Flex gap="xs" align="flex-start" w="100%">
          <Text>Please confirm you have provided the creator with Product Access.</Text>
        </Flex>
      );
      break;
    case ProductAccessInfoToProvideToCreatorType.ACCESS_CODE:
      brandComp = (
        <Flex gap="xs" align="flex-start" w="100%">
          <TextInput
            leftSection={isComplete ? <IconCheck size="1rem" /> : null}
            label="Please provide the Access Code for the creator to use."
            value={productAccessBrandOutput}
            onChange={(event) => setProductAccessBrandOutput(event.currentTarget.value)}
            error={
              productAccessBrandOutput && !isValidPromoCode(productAccessBrandOutput)
                ? "Promo Code is too long."
                : null
            }
            // size="xs"
            placeholder="EXAMPLE10"
            w="100%"
            disabled={isComplete || disabled}
          />
        </Flex>
      );
      break;
    case ProductAccessInfoToProvideToCreatorType.TRACKING_CODE:
      brandComp = (
        <Stack gap="xs" align="flex-start" w="100%">
          <Text>
            <b>Please provide the Tracking Info for the package.</b>
          </Text>
          <TextInput
            leftSection={isComplete ? <IconCheck size="1rem" /> : null}
            label="Shipping Carrier"
            value={shippingCarrier}
            onChange={(event) => setShippingCarrier(event.currentTarget.value)}
            placeholder="UPS, FedEx, USPS, etc."
            w="100%"
            disabled={isComplete || disabled}
          />
          <TextInput
            leftSection={isComplete ? <IconCheck size="1rem" /> : null}
            label="Shipping Tracking Code"
            value={shippingTrackingCode}
            onChange={(event) => setShippingTrackingCode(event.currentTarget.value)}
            placeholder="e.g. 1Z165V640494171111"
            w="100%"
            disabled={isComplete || disabled}
          />
        </Stack>
      );
      break;
    case ProductAccessInfoToProvideToCreatorType.OTHER:
      brandComp = (
        <Stack gap="xs" align="flex-start" w="100%">
          <Textarea
            id={`textarea-${contractHashId}`}
            label="Information to provide to the creator"
            value={productAccessBrandOutput}
            onChange={(event) => setProductAccessBrandOutput(event.currentTarget.value)}
            placeholder={productAccessOtherInfoToProvideToCreator}
            rows={2}
            w="100%"
            disabled={isComplete || disabled}
          />
        </Stack>
      );
      break;
    default:
      break;
  }

  return (
    <>
      {brandComp}
      <Group gap="xs" justify="right" />
      <Group justify="right">
        {productAccessInfoNeededFromCreator !== ProductAccessInfoNeededFromCreatorType.NONE && (
          <Button disabled={isComplete || disabled} onClick={() => setIsIssue(true)} color="red">
            Report an Issue
          </Button>
        )}
        {!disabled && (
          <Button
            disabled={(!isNoneRequired && isOutputMissing()) || isComplete}
            loading={loading}
            onClick={handleSaveOutput}
            color={isComplete ? "gray" : "blue"}>
            {isNoneRequired ? "Confirm" : "Submit"}
          </Button>
        )}
      </Group>

      {isIssue && (
        <>
          <Box mt="sm">
            <Textarea
              mt="sm"
              value={issueMessage}
              onChange={(event) => setIssueMessage(event.currentTarget.value)}
              placeholder={`Let ${signatureFirstName} ${signatureLastName} know what needs to be fixed`}
              disabled={isComplete}
            />
          </Box>
          <Group justify="right" gap="xs">
            <Button disabled={isComplete} onClick={() => submitIssueToCreator()} color="red">
              Submit issue to creator
            </Button>
          </Group>
        </>
      )}
    </>
  );
}

export const CreatorInputComponent = ({
  productAccessInfoNeededFromCreator,
  signatureFirstName,
  signatureLastName,
  productAccessCreatorInput,
  productAccessOtherInfoNeededFromCreator,
  isComplete = false,
}: {
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  signatureFirstName: string;
  signatureLastName: string;
  productAccessCreatorInput: string;
  productAccessOtherInfoNeededFromCreator: string;
  isComplete?: boolean;
}) => {
  switch (productAccessInfoNeededFromCreator) {
    case ProductAccessInfoNeededFromCreatorType.SHIPPING_ADDRESS:
      return (
        <>
          <Text>
            <Text fw="500" span>
              {signatureFirstName} {signatureLastName}
            </Text>{" "}
            has provided their shipping address:
          </Text>
          <Flex gap="xs" align="flex-end" mb="sm">
            <Text id="shippingAddress" miw={620}>
              {getShippingAddress(productAccessCreatorInput as unknown as object)}
            </Text>
          </Flex>
        </>
      );
    case ProductAccessInfoNeededFromCreatorType.ACCOUNT_EMAIL_ADDRESS:
      return (
        <>
          <Text>{`${signatureFirstName} ${signatureLastName} has provided their Account Email Address:`}</Text>
          <Flex gap="xs" align="flex-end" mb="sm">
            <TextInput
              id="emailAddress"
              label="Email Address"
              value={productAccessCreatorInput}
              miw={620}
              onChange={() => {}}
              disabled={isComplete}
            />
            <Box pt="lg">
              <CopyButton value={productAccessCreatorInput} timeout={1000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                    <ActionIcon color={copied ? "teal" : "gray"} variant="subtle" onClick={copy}>
                      {copied ? <IconCheck size="1.6rem" /> : <IconCopy size="1.6rem" />}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Box>
          </Flex>
        </>
      );
    case ProductAccessInfoNeededFromCreatorType.ACCOUNT_USERNAME:
      return (
        <>
          <Text>{`${signatureFirstName} ${signatureLastName} has provided their Account Username Info:`}</Text>
          <Flex gap="xs" align="flex-end" mb="sm">
            <TextInput
              id="userName"
              label="Username"
              value={productAccessCreatorInput}
              miw={620}
              onChange={() => {}}
              disabled={isComplete}
            />
            <Box pt="lg">
              <CopyButton value={productAccessCreatorInput} timeout={1000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                    <ActionIcon color={copied ? "teal" : "gray"} variant="subtle" onClick={copy}>
                      {copied ? <IconCheck size="1.6rem" /> : <IconCopy size="1.6rem" />}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Box>
          </Flex>
        </>
      );
    case ProductAccessInfoNeededFromCreatorType.OTHER:
      return (
        <>
          <Text size="sm">
            Creator provided info for: <b>{productAccessOtherInfoNeededFromCreator}</b>
          </Text>
          <Paper shadow="xs" p="sm" radius="sm" miw={500} withBorder>
            <Flex gap="xs" align="flex-end" mb="sm">
              <Text id="customInfo">{productAccessCreatorInput}</Text>
            </Flex>
          </Paper>
        </>
      );
    default:
      return null;
  }
};

export default function BrandProductAccessInput({
  contractHeaderInfo,
  showAdminOptions,
}: {
  contractHeaderInfo: ContractHeaderInfo;
  showAdminOptions?: boolean;
}) {
  const {
    contractId,
    signatureFirstName,
    signatureLastName,
    avatarUrl,
    contractAmount,
    productAccessBrandOutput,
    productAccessCreatorInput,
    productAccessInfoNeededFromCreator,
    productAccessInfoToProvideToCreator,
    productAccessOtherInfoNeededFromCreator,
    productAccessOtherInfoToProvideToCreator,
    productAccessStatus,
  } = contractHeaderInfo;
  const displayName = `${signatureFirstName} ${signatureLastName}`;

  const [brandOutput, setBrandOutput] = useState<string>(productAccessBrandOutput);
  const [status, setStatus] = useState<ProductAccessStatus>(productAccessStatus);
  const waitingForCreator =
    [
      ProductAccessStatus.CREATOR_ACTION_REQUIRED,
      ProductAccessStatus.CREATOR_RESUBMIT_REQUIRED,
    ].indexOf(status) > -1;
  const isComplete = status === ProductAccessStatus.COMPLETED;

  useEffect(() => {}, []);

  return (
    <CollapsibleCard
      isOpen={!isComplete}
      header={
        <Group justify="space-between">
          <CreatorAvatar
            displayName={displayName}
            avatarUrl={avatarUrl}
            contractAmount={contractAmount}
          />
          <Flex justify="right" align="center" gap="xs">
            <ProductAccessBadge productAccessStatus={status} />

            {showAdminOptions && <ViewContractLink contractId={contractId} />}
          </Flex>
        </Group>
      }
      controls={null}
      content={
        [ProductAccessStatus.COMPLETED, ProductAccessStatus.BRAND_ACTION_REQUIRED].indexOf(status) >
        -1 ? (
          <Card withBorder radius="md" py="sm" px="lg">
            <Stack gap={4}>
              <CreatorInputComponent
                productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
                signatureFirstName={signatureFirstName}
                signatureLastName={signatureLastName}
                productAccessCreatorInput={productAccessCreatorInput}
                productAccessOtherInfoNeededFromCreator={productAccessOtherInfoNeededFromCreator}
                isComplete={isComplete}
              />
              <BrandInputResult
                contractHashId={contractId}
                signatureFirstName={signatureFirstName}
                signatureLastName={signatureLastName}
                status={status}
                setStatus={setStatus}
                productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
                productAccessInfoToProvideToCreator={productAccessInfoToProvideToCreator}
                productAccessOtherInfoToProvideToCreator={productAccessOtherInfoToProvideToCreator}
                productAccessBrandOutput={brandOutput}
                setProductAccessBrandOutput={setBrandOutput}
              />
            </Stack>
          </Card>
        ) : null
      }
      disabledCollapse={waitingForCreator}
    />
  );
}
