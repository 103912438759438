import React, { useEffect, useState } from "react";

import { ActionIcon, Center, Loader, Stack, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { IconMessageCheck } from "@tabler/icons-react";

import {
  fetchUploadedContentForContract,
  getContractReviewStatuses,
  markMessagesLastReadForContract,
} from "components/contracts/common/Api";

import ContractContent from "components/contracts/models/ContractContent";
import ReviewStatusProps, {
  deliverableWithContentToProps,
} from "components/contracts/review/ReviewStatusProps";
import ContentReviewCard from "components/contracts/review/ContentReviewCard";
import ReviewTimelineData from "components/contracts/models/ReviewTimelineData";
import { addDays } from "utils/DateUtils";

import ContractHeaderInfo from "components/contracts/review/ContractHeaderInfo";
import CollapsibleCard from "components/contracts/common/CollapsibleCard";
import ContractReviewHeader from "components/contracts/review/ContractReviewHeader";
import CreatorDrawer from "components/contracts/review/CreatorDrawer";
import ViewContractLink from "components/contracts/common/ViewContractLink";
import DeliverableWithContent from "components/contracts/models/DeliverableWithContent";
import { ContentStatus } from "components/contracts/common/Common";
import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

function getDeliverableIdToContentStatus(contractContent: ContractContent) {
  const deliverableIdToStatuses = new Map<string, ReviewStatusProps>();
  contractContent.deliverablesWithContent
    .filter(
      (deliverableWithContent) =>
        deliverableWithContent?.versionedScript?.scripts?.length > 0 ||
        deliverableWithContent?.versionedDeliverableVideo?.deliverableVideos?.length > 0,
    )
    .forEach((deliverableWithContent) => {
      deliverableIdToStatuses.set(
        deliverableWithContent.deliverable.id,
        deliverableWithContentToProps(deliverableWithContent),
      );
    });

  return deliverableIdToStatuses;
}

function CardControls({
  contractId,
  numUnreadMessages,
  clearMessagesLoading,
  handleClearUnreadMessages,
  showAdminOptions,
}: {
  contractId: string;
  numUnreadMessages: number;
  clearMessagesLoading: boolean;
  handleClearUnreadMessages: () => void;
  showAdminOptions?: boolean;
}) {
  if (numUnreadMessages <= 0 && !showAdminOptions) {
    return null;
  }

  return (
    <ActionIcon.Group>
      {numUnreadMessages > 0 && (
        <Tooltip
          label={`Clear ${numUnreadMessages} Unread Message${numUnreadMessages > 1 ? "s" : ""}`}>
          <ActionIcon
            variant="subtle"
            color="gray"
            loading={clearMessagesLoading}
            onClick={handleClearUnreadMessages}>
            <IconMessageCheck size="1rem" />
          </ActionIcon>
        </Tooltip>
      )}
      {showAdminOptions && <ViewContractLink contractId={contractId} />}
    </ActionIcon.Group>
  );
}

function ReviewCards({
  contractHeaderInfo,
  totalNumUnreadMessages,
  setTotalNumUnreadMessages,
  clearedUnreadMessages,
  refreshContractStatus,
  showAdminOptions,
}: {
  contractHeaderInfo: ContractHeaderInfo;
  totalNumUnreadMessages: number;
  setTotalNumUnreadMessages: (totalNumUnreadMessages: number) => void;
  clearedUnreadMessages: boolean;
  refreshContractStatus: (numUnreadMessagesToSubtract: number, callbackFn: () => void) => void;
  showAdminOptions?: boolean;
}) {
  const [contractContentState, setContractContentState] = useState<ContractContent>(null);
  const [idsToStatuses, setIdsToStatuses] = useState<Map<string, ReviewStatusProps>>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchUploadedContentForContract(contractHeaderInfo.contractId)
      .then((response) => {
        const { reviewContent, success } = response;

        if (success) {
          const deserializedContractContent = ContractContent.deserialize(reviewContent);

          setContractContentState(deserializedContractContent);
          setIdsToStatuses(getDeliverableIdToContentStatus(deserializedContractContent));
        }
      })
      .finally(() => setLoaded(true));
  }, [contractHeaderInfo.contractId]);

  const sortedDeliverablesWithContent = contractContentState?.deliverablesWithContent?.sort(
    (a, b) => {
      const getContentStatus = (deliverableWithContent: DeliverableWithContent) => {
        if (
          deliverableWithContent.deliverable.status ===
          ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED
        ) {
          return ContentStatus.PENDING_REVIEW;
        } else if (
          deliverableWithContent.deliverable.status ===
          ContractDeliverableStatus.LIVE_CONTENT_APPROVED
        ) {
          return ContentStatus.APPROVED;
        } else if (
          deliverableWithContent.deliverable.status ===
          ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED
        ) {
          return ContentStatus.REVISIONS_REQUESTED;
        }

        return (
          deliverableWithContent?.versionedDeliverableVideo?.deliverableVideos?.[0]
            ?.contentStatus || deliverableWithContent?.versionedScript?.scripts?.[0]?.status
        );
      };

      const aDeliverableStatus = a.deliverable.status;
      const bDeliverableStatus = b.deliverable.status;

      if (
        aDeliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED &&
        bDeliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED
      ) {
        if (a.deliverable.liveContentReviewDeadline < b.deliverable.liveContentReviewDeadline) {
          return -1;
        }
        return 1;
      } else if (aDeliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED) {
        return 1;
      } else if (bDeliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED) {
        return -1;
      }

      const aContentStatus = getContentStatus(a);
      const bContentStatus = getContentStatus(b);

      if (aContentStatus === bContentStatus) {
        return a.numUnreadMessages - b.numUnreadMessages;
      }

      if (aContentStatus === ContentStatus.APPROVED) {
        return -1;
      } else if (aContentStatus === ContentStatus.PENDING_REVIEW) {
        return 1;
      } else if (bContentStatus === ContentStatus.APPROVED) {
        return 1;
      } else if (bContentStatus === ContentStatus.PENDING_REVIEW) {
        return -1;
      }

      return 0;
    },
  );

  const reviewCards = sortedDeliverablesWithContent?.map((deliverableWithContent) => {
    const { deliverable } = deliverableWithContent;
    const {
      status,
      timeline,
      usageRightsDays,
      usageRightsExpirationDate,
      liveContentSubmissionDatetime,
      liveContentReviewDeadline,
    } = deliverable;
    const script = deliverableWithContent?.versionedScript?.scripts?.[0];
    const deliverableVideo =
      deliverableWithContent?.versionedDeliverableVideo?.deliverableVideos?.[0];

    let usageRightsEndDate = null;
    if (usageRightsExpirationDate) {
      usageRightsEndDate = new Date(usageRightsExpirationDate);
    } else if (usageRightsDays && usageRightsDays > 0) {
      usageRightsEndDate = addDays(
        liveContentSubmissionDatetime || timeline.liveDate,
        usageRightsDays,
      );
    }

    const reviewTimelineData = new ReviewTimelineData({
      signatureDatetime: contractContentState.signatureDatetime,
      liveDate: timeline.liveDate,
      liveContentSubmissionDate: liveContentSubmissionDatetime,
      liveContentReviewDeadline,
      usageRightsEndDate,
      scriptDueDate: timeline.scriptDate,
      scriptSubmissionDate: script?.submissionDate,
      scriptReviewDate: script?.reviewDate,
      scriptReviewStatus: script?.status,
      videoDueDate: timeline.videoDraftDate,
      videoReviewDate: deliverableVideo?.reviewDate,
      videoSubmissionDate: deliverableVideo?.dateCreated,
      videoReviewStatus: deliverableVideo?.contentStatus,
      deliverableStatus: status,
    });

    return (
      <ContentReviewCard
        key={`${deliverableWithContent.deliverable.id}-review-card`}
        brandName={contractHeaderInfo.brandName}
        versionedDeliverableVideo={deliverableWithContent.versionedDeliverableVideo}
        versionedScript={deliverableWithContent.versionedScript}
        idsToStatuses={idsToStatuses}
        setIdsToStatuses={setIdsToStatuses}
        refreshContractStatus={refreshContractStatus}
        numUnreadMessages={deliverableWithContent.numUnreadMessages}
        totalNumUnreadMessages={totalNumUnreadMessages}
        setTotalNumUnreadMessages={setTotalNumUnreadMessages}
        clearedUnreadMessages={clearedUnreadMessages}
        contentType={deliverableWithContent.pendingContentType}
        reviewTimelineData={reviewTimelineData}
        deliverable={deliverable}
        showAdminOptions={showAdminOptions}
      />
    );
  });

  return (
    <Stack gap="xs">
      {!loaded && (
        <Center inline>
          <Loader color="blue" type="dots" />
        </Center>
      )}
      {loaded && reviewCards}
    </Stack>
  );
}

export default function ContractReviewCards({
  contractHeaderInfo,
  showAdminOptions,
}: {
  contractHeaderInfo: ContractHeaderInfo;
  showAdminOptions?: boolean;
}) {
  if (contractHeaderInfo === null) {
    return null;
  }

  // Status States
  const [totalNumUnreadMessages, setTotalNumUnreadMessages] = useState(
    contractHeaderInfo.numUnreadMessages,
  );
  const [verificationRequired, setVerificationRequired] = useState(
    contractHeaderInfo.verificationRequired,
  );
  const [reviewRequired, setReviewRequired] = useState(contractHeaderInfo.reviewRequired);
  const [waitingForRevision, setWaitingForRevision] = useState(
    contractHeaderInfo.waitingForRevision,
  );
  const [waitingForContent, setWaitingForContent] = useState(contractHeaderInfo.waitingForContent);
  const [approvedToGoLive, setApprovedToGoLive] = useState(contractHeaderInfo.approvedToGoLive);
  const [isLive, setIsLive] = useState(contractHeaderInfo.isLive);
  const [liveDate, setLiveDate] = useState(contractHeaderInfo.liveDate);

  const refreshContractStatus = (numUnreadMessagesToSubtract: number, callbackFn: () => void) => {
    getContractReviewStatuses({ contractId: contractHeaderInfo.contractId })
      .then((response) => {
        const { success, reviewStatuses } = response;
        if (success) {
          setTotalNumUnreadMessages(totalNumUnreadMessages - numUnreadMessagesToSubtract);
          setVerificationRequired(reviewStatuses.verificationRequired);
          setReviewRequired(reviewStatuses.reviewRequired);
          setWaitingForRevision(reviewStatuses.waitingForRevision);
          setWaitingForContent(reviewStatuses.waitingForContent);
          setApprovedToGoLive(reviewStatuses.approvedToGoLive);
          setIsLive(reviewStatuses.isLive);
          setLiveDate(reviewStatuses.liveDate);
        }
      })
      .finally(() => callbackFn());
  };

  // Handle clearing unread messages
  const [clearMessagesLoading, setClearMessagesLoading] = useState(false);
  const [clearedUnreadMessages, setClearedUnreadMessages] = useState(false);

  const handleClearUnreadMessages = () => {
    setClearMessagesLoading(true);
    markMessagesLastReadForContract({ contractId: contractHeaderInfo.contractId })
      .then((response) => {
        if (response.success) {
          setClearedUnreadMessages(true);
          setTotalNumUnreadMessages(0);
        }
      })
      .finally(() => setClearMessagesLoading(false));
  };

  const expanded =
    contractHeaderInfo.verificationRequired ||
    contractHeaderInfo.reviewRequired ||
    contractHeaderInfo.numUnreadMessages > 0;

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <CreatorDrawer
        creatorId={contractHeaderInfo.creatorId}
        opened={opened}
        close={close}
      />
      <CollapsibleCard
        key={contractHeaderInfo.contractId}
        isOpen={expanded}
        header={
          <ContractReviewHeader
            contractHeaderInfo={contractHeaderInfo}
            totalNumUnreadMessages={totalNumUnreadMessages}
            verificationRequired={verificationRequired}
            reviewRequired={reviewRequired}
            waitingForRevision={waitingForRevision}
            waitingForContent={waitingForContent}
            approvedToGoLive={approvedToGoLive}
            isLive={isLive}
            liveDate={liveDate}
            open={open}
          />
        }
        controls={
          <CardControls
            contractId={contractHeaderInfo.contractId}
            numUnreadMessages={totalNumUnreadMessages}
            clearMessagesLoading={clearMessagesLoading}
            handleClearUnreadMessages={handleClearUnreadMessages}
            showAdminOptions={showAdminOptions}
          />
        }
        content={
          <ReviewCards
            contractHeaderInfo={contractHeaderInfo}
            totalNumUnreadMessages={totalNumUnreadMessages}
            setTotalNumUnreadMessages={setTotalNumUnreadMessages}
            clearedUnreadMessages={clearedUnreadMessages}
            refreshContractStatus={refreshContractStatus}
            showAdminOptions={showAdminOptions}
          />
        }
        lazyLoadContent={!expanded}
      />
    </>
  );
}
