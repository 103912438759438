import React, { useEffect, useState } from "react";

import { Group, Loader, Paper, Select, Stack, Text, Title } from "@mantine/core";
import { LineChart } from "@mantine/charts";

import { Brand } from "models/Brand";
import {
  getLabelingTimeseriesByBrand,
  getLabelingTimeseriesByBrandCreatorSets,
  getLabelingTimeseriesOverview,
  LabelingTimeseries,
} from "admin/api/labeling/reportingApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";

interface BrandOption {
  label: string;
  value: string;
}

const convertTimeSeriesToApprovedLineChartData = (timeseries: LabelingTimeseries[]) => {
  // Convert the timeseries data to a format that the LineChart component can use
  // This includes flattening the approved and labeled counts to have a separate field for each key in approved and labeled maps
  const lineChartData = timeseries.map((item) => {
    const flattenedData = {
      date: item.date,
      ...item.approved,
    };
    return flattenedData;
  });
  return lineChartData;
};

const convertTimeSeriesToLabeledLineChartData = (
  timeseries: LabelingTimeseries[],
  keyToNameMap: Record<string, string>,
) => {
  // Convert the timeseries data to a format that the LineChart component can use
  // This includes flattening the approved and labeled counts to have a separate field for each key in approved and labeled maps
  const lineChartData = timeseries.map((item) => {
    const flattenedData = {
      date: item.date,
      ...Object.entries(item.approved).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [`${keyToNameMap[key]} approved`]: value,
        }),
        {},
      ),
      ...Object.entries(item.labeled).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [`${keyToNameMap[key]} labeled`]: value,
        }),
        {},
      ),
    };
    return flattenedData;
  });
  return lineChartData;
};

const convertTimeSeriesToApprovalRateLineChartData = (
  timeseries: LabelingTimeseries[],
  keyToNameMap: Record<string, string>,
) => {
  return timeseries.map((item) => ({
    date: item.date,
    ...Array.from(Object.keys(item.labeled)).reduce(
      (acc, key) => ({
        ...acc,
        [keyToNameMap[key]]: getAbbreviatedNumber(
          (100 * (item.approved[key] ?? 0)) / Math.max(item.labeled[key] ?? 1, 2),
          1,
        ),
      }),
      {} as Record<string, number>,
    ),
  }));
};

const TimeSeriesReporting = ({
  timeseries,
  keyToNameMap,
  colorMap = {
    approved: false,
    labeled: false,
    approvalRate: false,
  },
}: {
  timeseries: LabelingTimeseries[];
  keyToNameMap: Record<string, string>;
  colorMap?: Record<string, boolean>;
}) => {
  if (timeseries?.length === 0) {
    return <Text>No data</Text>;
  }

  const [labeledLineChartData, setLabeledLineChartData] = useState<any[]>([]);
  const [labeledLineChartSeries, setLabeledLineChartSeries] = useState<any[]>([]);
  const [approvalRateLineChartData, setApprovalRateLineChartData] = useState<any[]>([]);
  const [approvalRateLineChartSeries, setApprovalRateLineChartSeries] = useState<any[]>([]);

  useEffect(() => {
    const labeledData = convertTimeSeriesToLabeledLineChartData(timeseries, keyToNameMap);
    setLabeledLineChartData(labeledData);
    if (labeledData.length > 0) {
      setLabeledLineChartSeries(
        Object.keys(labeledData[0])
          .filter((key) => key !== "date")
          .map((key) => {
            let color = "green";
            if (colorMap?.labeled) {
              color = `hsl(${
                (Object.keys(labeledData[0]).indexOf(key) * 360) /
                Object.keys(labeledData[0]).length
              }, 100%, 50%)`;
            } else if (key.match(/^.*labeled$/)) {
              color = "blue";
            } else if (key.match(/^.*approved$/)) {
              color = "green";
            }
            return {
              name: key,
              dataKey: key,
              color,
            };
          }),
      );
    } else {
      setLabeledLineChartSeries([]);
    }
    const approvalRateData = convertTimeSeriesToApprovalRateLineChartData(timeseries, keyToNameMap);
    setApprovalRateLineChartData(approvalRateData);
    if (approvalRateData.length > 0) {
      setApprovalRateLineChartSeries(
        Object.keys(approvalRateData[0])
          .filter((key) => key !== "date")
          .map((key) => ({
            name: key,
            dataKey: key,
            // Generate a color based on the key
            // color: ,
            color: colorMap?.approvalRate
              ? `hsl(${
                  (Object.keys(approvalRateData[0]).indexOf(key) * 360) /
                  Object.keys(approvalRateData[0]).length
                }, 100%, 50%)`
              : "orange",
          })),
      );
    } else {
      setApprovalRateLineChartSeries([]);
    }
  }, [timeseries]);

  return (
    <Group justify="space-between" wrap="nowrap">
      <Stack w="50%">
        <Title order={4}>Labeled</Title>
        <LineChart
          h={300}
          dataKey="date"
          data={labeledLineChartData}
          series={labeledLineChartSeries}
          withLegend
        />
      </Stack>
      <Stack w="50%">
        <Title order={4}>Approval Rate (%)</Title>
        <LineChart
          h={300}
          dataKey="date"
          data={approvalRateLineChartData}
          series={approvalRateLineChartSeries}
          yAxisProps={{ domain: [0, 100] }}
          withLegend
        />
      </Stack>
    </Group>
  );
};

export const AdminLabelingReportForBrandCreatorSet = ({ brandId }: { brandId: string }) => {
  const { creatorSets } = useAdminAppContext();
  const [loading, setLoading] = useState(false);
  const [labelingTimeseries, setLabelingTimeseries] = useState<LabelingTimeseries[]>([]);
  const [translatedLabelingTimeseries, setTranslatedLabelingTimeseries] = useState<
    LabelingTimeseries[]
  >([]);
  const [keyToNameMap, setKeyToNameMap] = useState<Record<string, string>>({});

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getLabelingTimeseriesByBrandCreatorSets(
      brandId,
      setLabelingTimeseries,
      abortController,
    ).finally(() => {
      setLoading(false);
    });
    return () => {
      abortController.abort();
    };
  }, [brandId]);

  useEffect(() => {
    // Convert labeling timeseries keys to readable creator set names
    const newLabelingTimeseries = labelingTimeseries.map((timeseries) => {
      return {
        ...timeseries,
        // convert the key from id to creator set name
        approved: Object.fromEntries(
          Object.entries(timeseries.approved).map(([key, value]) => [
            creatorSets.find((creatorSet) => creatorSet.id === parseInt(key, 10))?.name,
            value,
          ]),
        ),
        labeled: Object.fromEntries(
          Object.entries(timeseries.labeled).map(([key, value]) => [
            creatorSets.find((creatorSet) => creatorSet.id === parseInt(key, 10))?.name,
            value,
          ]),
        ),
      };
    });
    setTranslatedLabelingTimeseries(newLabelingTimeseries);
  }, [labelingTimeseries]);

  useEffect(() => {
    // Create a key to name map
    const newKeyToNameMap = Object.fromEntries(
      creatorSets.map((creatorSet) => [creatorSet.name, creatorSet.name]),
    );
    setKeyToNameMap(newKeyToNameMap);
  }, [creatorSets]);

  return (
    <Stack p="sm" gap="xs">
      <Title order={2}>By Creator Set</Title>
      {loading && translatedLabelingTimeseries?.length === 0 ? (
        <Loader />
      ) : (
        <TimeSeriesReporting
          timeseries={translatedLabelingTimeseries}
          keyToNameMap={keyToNameMap}
          colorMap={{
            approved: true,
            labeled: true,
            approvalRate: true,
          }}
        />
      )}
    </Stack>
  );
};

const AdminLabelingReportingByBrandSingle = ({
  brand,
  labelingTimeseries,
}: {
  brand: Brand;
  labelingTimeseries: LabelingTimeseries[];
}) => {
  const keyToNameMap = {
    [brand.id.toString()]: brand.display_name.replace(".", "[dot]").toLowerCase(),
  };
  return (
    <Stack p="sm" gap="xs">
      <Title order={2}>{brand.display_name}</Title>
      <TimeSeriesReporting timeseries={labelingTimeseries} keyToNameMap={keyToNameMap} />
    </Stack>
  );
};

const AdminLabelingReportingByBrand = () => {
  const { brands } = useAdminAppContext();

  const [selectedBrandId, setSelectedBrandId] = useState<string>("0");
  const [brandOptions, setBrandOptions] = useState<BrandOption[]>([]);

  const [loading, setLoading] = useState(false);
  const [labelingTimeseries, setLabelingTimeseries] = useState<LabelingTimeseries[]>([]);
  const [brandOrder, setBrandOrder] = useState<string[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    // Fetch labeling data for the brand
    if (brands?.length > 0) {
      getLabelingTimeseriesByBrand(
        [], // Get all brand ids
        setLabelingTimeseries,
        setBrandOrder,
        abortController,
      ).finally(() => {
        setLoading(false);
      });
      // Also set brandOptions
      const newBrandOptions = brands
        // Filter out test brands and non-verified brands
        .filter((brand) => !brand.is_test_brand && brand.is_verified)
        .map((brand) => ({ label: brand.display_name, value: brand.id.toString() }));
      // Add "All Brands" to the top
      newBrandOptions.unshift({ label: "All Brands", value: "0" });
      setBrandOptions(newBrandOptions);
    }
    return () => {
      abortController.abort();
    };
  }, [brands]);

  if (!brands || !(brands?.length > 0)) {
    return (
      <Stack p="sm" gap="xs">
        <Title order={2}>By Brand</Title>
        <Group justify="center">
          <Loader />
        </Group>
      </Stack>
    );
  }

  return (
    <Stack p="sm" gap="xs">
      <Title order={2}>By Brand</Title>
      <Select
        data={brandOptions}
        value={selectedBrandId}
        onChange={(value) => {
          setSelectedBrandId(value);
        }}
        searchable
      />
      {loading ? (
        <Group justify="center">
          <Loader />
        </Group>
      ) : null}
      {!loading &&
        brandOrder &&
        brands?.length > 0 &&
        // TODO(andrew): should we set the brandOrder to use the id instead of the name?
        // Could be issues in the future if there are multiple brands with the same display name
        brandOrder.map((brandId) => {
          // If there is a selected brand, only show that brand
          if (selectedBrandId !== "0" && brandId !== selectedBrandId) {
            return null;
          }
          const currBrand = brands.find((brand) => brand.id.toString() === brandId);
          if (currBrand) {
            // filter the labelingTimeseries to only include the currBrand
            const currBrandLabelingTimeseries: LabelingTimeseries[] = labelingTimeseries.map(
              (timeseries) => {
                return {
                  ...timeseries,
                  approved: {
                    [currBrand.id.toString()]: timeseries.approved[currBrand.id.toString()],
                  },
                  labeled: {
                    [currBrand.id.toString()]: timeseries.labeled[currBrand.id.toString()],
                  },
                };
              },
            );
            return (
              <AdminLabelingReportingByBrandSingle
                key={currBrand.id}
                brand={currBrand}
                labelingTimeseries={currBrandLabelingTimeseries}
              />
            );
          }
          return null;
        })}
      {!loading &&
        brandOrder &&
        selectedBrandId !== "0" &&
        !brandOrder.find((brandId) => brandId === selectedBrandId) && (
          <Stack p="sm" gap="xs">
            <Title order={2}>By Brand</Title>
            <Text>No data</Text>
          </Stack>
        )}
      {selectedBrandId !== "0" ? (
        <AdminLabelingReportForBrandCreatorSet brandId={selectedBrandId} />
      ) : null}
    </Stack>
  );
};

const AdminLabelingReportingOverview = () => {
  const [loading, setLoading] = useState(false);
  const [labelingTimeseries, setLabelingTimeseries] = useState<LabelingTimeseries[]>([]);
  const [translatedLabelingTimeseries, setTranslatedLabelingTimeseries] = useState<
    LabelingTimeseries[]
  >([]);
  const [keyToNameMap, setKeyToNameMap] = useState<Record<string, string>>({
    Overall: "Overall",
  });

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getLabelingTimeseriesOverview(setLabelingTimeseries, abortController).finally(() => {
      setLoading(false);
    });
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    // Convert labeling timeseries keys to readable creator set names
    const newLabelingTimeseries = labelingTimeseries.map((timeseries) => {
      return {
        ...timeseries,
        // convert the key from id to creator set name
        approved: Object.fromEntries(
          Object.entries(timeseries.approved).map(([key, value]) => ["Overall", value]),
        ),
        labeled: Object.fromEntries(
          Object.entries(timeseries.labeled).map(([key, value]) => ["Overall", value]),
        ),
      };
    });
    setTranslatedLabelingTimeseries(newLabelingTimeseries);
  }, [labelingTimeseries]);

  return (
    <Stack p="sm" gap="xs">
      <Title order={2}>Overall</Title>
      {loading && translatedLabelingTimeseries?.length === 0 ? (
        <Loader />
      ) : (
        <TimeSeriesReporting
          timeseries={translatedLabelingTimeseries}
          keyToNameMap={keyToNameMap}
          colorMap={{
            approved: true,
            labeled: true,
            approvalRate: true,
          }}
        />
      )}
    </Stack>
  );
};

const AdminLabelingReporting = () => {
  const { brands } = useAdminAppContext();

  return (
    <Paper radius="sm" p="xl">
      <AdminLabelingReportingOverview />
      <AdminLabelingReportingByBrand />
    </Paper>
  );
};

export default AdminLabelingReporting;
