import React, { useEffect, useState } from "react";

import { Button, Center, Container, Divider, Loader, Stack } from "@mantine/core";

import {
  getCodeAndLinkTaskDetails,
  getContentReviewTaskDetails,
  getContractReviewTaskDetails,
  getLiveVerificationTaskDetails,
  getProductAccessTaskDetails,
} from "components/contracts/tasks/api/Api";

import { deserializeTask, Task } from "components/contracts/tasks/models/Task";
import { getTaskTypeLabel } from "components/contracts/tasks/common/Utils";

import { TaskCountType, TaskStage } from "components/contracts/tasks/models/Common";
import ContractCard from "components/contracts/tasks/cards/ContractCard";
import ContractReviewCard from "components/contracts/tasks/cards/ContractReviewCard";
import { IconRefresh } from "@tabler/icons-react";

export default function BaseTaskFeed({
  testMode,
  isActive,
  campaignId,
  taskType,
  numTasks,
  setNumTasks,
}: {
  testMode: boolean;
  isActive: boolean;
  campaignId: string;
  taskType: TaskStage;
  numTasks: Record<TaskCountType, number>;
  setNumTasks: (pendingCount: number, completedCount: number) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [pendingTasksData, setPendingTasksData] = useState<Task[]>([]);
  const [completedTasksData, setCompletedTasksData] = useState<Task[]>([]);

  const [numNewTasks, setNumNewTasks] = useState(0);

  let fetchTasks: ((params: { campaignId?: string }) => Promise<any>) | null = null;
  let responseHandler = (response: any) => {
    setPendingTasksData(response.data.map(deserializeTask));
  };

  if (taskType === TaskStage.CONTRACT_REVIEW) {
    fetchTasks = getContractReviewTaskDetails;
  } else if (taskType === TaskStage.PRODUCT_ACCESS) {
    fetchTasks = getProductAccessTaskDetails;
  } else if (taskType === TaskStage.CODES_LINKS) {
    fetchTasks = getCodeAndLinkTaskDetails;
  } else if (taskType === TaskStage.CONTENT_PRODUCTION) {
    fetchTasks = getContentReviewTaskDetails;
    responseHandler = (response: any) => {
      const { pendingTasks, completedTasks } = response.data;
      setPendingTasksData(pendingTasks.map(deserializeTask));
      setCompletedTasksData(completedTasks.map(deserializeTask));
    };
  } else if (taskType === TaskStage.LIVE_VERIFICATION) {
    fetchTasks = getLiveVerificationTaskDetails;
  } else {
    throw new Error(`Unknown task type: ${taskType}`);
  }

  const handleFetchTasks = (useLoader: boolean) => {
    setLoading(useLoader);
    fetchTasks({ campaignId })
      .then(responseHandler)
      .finally(() => {
        setNumNewTasks(0);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isActive) {
      return;
    }
    handleFetchTasks(true);
  }, [campaignId, isActive]);

  // Show refresh button if there are new tasks.
  useEffect(() => {
    if (!isActive) {
      return;
    }
    const numPendingTasks = pendingTasksData.filter((task) => task.isTestCampaign === testMode)
      .length;
    const numCompletedTasks = completedTasksData.filter((task) => task.isTestCampaign === testMode)
      .length;

    if (
      numTasks[TaskCountType.PENDING] > numPendingTasks ||
      numTasks[TaskCountType.COMPLETED] > numCompletedTasks
    ) {
      setNumNewTasks(numTasks[TaskCountType.PENDING] - numPendingTasks);
    } else if (numTasks[TaskCountType.PENDING] < numPendingTasks) {
      setNumNewTasks(0);
      // In the case that we discover there are fewer tasks than being displayed,
      // automatically refresh to avoid an inconsistent state for the user.
      handleFetchTasks(false);
    } else {
      setNumNewTasks(0);
    }
  }, [numTasks, pendingTasksData, isActive]);

  if (loading) {
    return (
      <Center mt="xl">
        <Loader />
      </Center>
    );
  }

  return (
    <Container mt="sm">
      <Stack gap="xs">
        {numNewTasks > 0 && (
          <Button
            onClick={() => handleFetchTasks(true)}
            fullWidth
            variant="light"
            size="xs"
            leftSection={<IconRefresh size="1rem" />}>
            {`${numNewTasks} new ${getTaskTypeLabel(taskType)} ${
              numNewTasks === 1 ? " task" : " tasks"
            } available`}
          </Button>
        )}
        {pendingTasksData
          .filter((task) => task.isTestCampaign === testMode)
          .map((task) => {
            const CardComponent =
              taskType === TaskStage.CONTRACT_REVIEW ? ContractReviewCard : ContractCard;
            return (
              <CardComponent
                key={task.hashId}
                taskType={taskType}
                primaryTask={task}
                handleCompleteTask={(waitingForCreator?: boolean) => {
                  setPendingTasksData((prevTasks) =>
                    prevTasks.filter((t) => t.hashId !== task.hashId),
                  );
                  if (taskType === TaskStage.CONTENT_PRODUCTION && waitingForCreator) {
                    setCompletedTasksData((prevTasks) => [task, ...prevTasks]);
                    setNumTasks(
                      numTasks[TaskCountType.PENDING] - 1,
                      numTasks[TaskCountType.COMPLETED] + 1,
                    );
                  } else {
                    setNumTasks(
                      numTasks[TaskCountType.PENDING] - 1,
                      numTasks[TaskCountType.COMPLETED],
                    );
                  }
                }}
              />
            );
          })}
        {completedTasksData.filter((task) => task.isTestCampaign === testMode).length > 0 && (
          <Divider label="WAITING FOR CREATOR" variant="dashed" my="xs" mx="-lg" />
        )}
        {completedTasksData
          .filter((task) => task.isTestCampaign === testMode)
          .map((task) => (
            <ContractCard
              key={task.hashId}
              taskType={taskType}
              primaryTask={task}
              isCompletedTask
              handleCompleteTask={(waitingForCreator?: boolean) => {
                if (taskType === TaskStage.CONTENT_PRODUCTION && !waitingForCreator) {
                  setCompletedTasksData((prevTasks) =>
                    prevTasks.filter((t) => t.hashId !== task.hashId),
                  );
                  setNumTasks(
                    numTasks[TaskCountType.PENDING],
                    numTasks[TaskCountType.COMPLETED] - 1,
                  );
                }
              }}
            />
          ))}
      </Stack>
    </Container>
  );
}
