import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export async function getNumTasks({
  campaignId,
  testMode,
}: {
  campaignId?: string;
  testMode: boolean;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_num_tasks`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  }
  if (testMode) {
    requestUrl.searchParams.append("testMode", "true");
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function getLiveVerificationTaskDetails({ campaignId }: { campaignId?: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_live_verification_task_details`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function getContentReviewTaskDetails({ campaignId }: { campaignId?: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_content_review_task_details`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function getContractReviewTaskDetails({ campaignId }: { campaignId?: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_contract_review_task_details`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function getCodeAndLinkTaskDetails({ campaignId }: { campaignId?: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_code_and_link_task_details`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function getProductAccessTaskDetails({ campaignId }: { campaignId?: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/get_product_access_task_details`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function approveLiveUrl({ taskId }: { taskId: string }) {
  const requestUrl = new URL(`${API_URL}/api/tasks/approve_live_content`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function requestLiveUrlRevision({
  taskId,
  disputeReason,
}: {
  taskId: string;
  disputeReason: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/request_live_content_revision`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      disputeReason,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function approveContent({
  taskId,
  messageText,
}: {
  taskId: string;
  messageText: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/approve_content`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function requestContentRevision({
  taskId,
  messageText,
}: {
  taskId: string;
  messageText: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/request_content_revision`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function sendMessageToCreator({
  taskId,
  messageText,
}: {
  taskId: string;
  messageText: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/send_message_to_creator`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function addReferralLinkToDeliverable({
  taskId,
  url,
}: {
  taskId: string;
  url: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/add_referral_link_to_deliverable`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      url,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function addPromoCodeToDeliverable({
  taskId,
  promoCode,
}: {
  taskId: string;
  promoCode: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/add_promo_code_to_deliverable`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      promoCode,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function saveBrandProductAccessInfo({
  taskId,
  productAccessBrandOutput,
}: {
  taskId: string;
  productAccessBrandOutput: string | object;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/submit_product_access_info`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      productAccessBrandOutput,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function reportIssueWithProductAccessInfo({
  taskId,
  message,
}: {
  taskId: string;
  message: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/tasks/report_issue_with_product_access_info`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      taskId,
      message,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export default null;
