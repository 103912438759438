import React, { useState } from "react";

import { Badge, Flex, Tabs, Text } from "@mantine/core";

import { IconBookmarks, IconCircleCheck, IconEyeOff, IconListSearch } from "@tabler/icons-react";

import {
  ActiveTab,
  CreatorSetEntryStates,
  LoggingMetadata,
  SearchMethod,
} from "components/discovery/search/Common";
import { SortBy } from "components/discovery/search/SortSelector";
import SearchResults from "components/discovery/search/SearchResults";

export default function TabbedSearchResults({
  sessionId,
  queryId,
  unhiddenCreatorIds,
  savedCreatorIds,
  activatedCreatorIds,
  hiddenCreatorIds,
  loadingResults,
  submittedQuery,
  selectedSearchMethod,
  creatorSetOptions,
  creatorSetEntryStates,
  hiddenCreatorIdsSet,
  activeTab,
  setActiveTab,
  activePages,
  setActivePages,
  sortBy,
  handleAddCreatorSet,
  pushNavigationState,
  setRateLimitExceeded,
  setLimitedUsage,
  setNumSearchesRemaining,
  setCreatorSetEntryStates,
  addActivatedCreatorId,
  addSavedCreatorId,
  removeSavedCreatorId,
  hideCreatorId,
  unhideCreatorId,
  fetchRelatedCreators,
}: {
  sessionId: string;
  queryId: string;
  unhiddenCreatorIds: number[];
  savedCreatorIds: number[];
  activatedCreatorIds: number[];
  hiddenCreatorIds: number[];
  loadingResults: boolean;
  submittedQuery: string;
  selectedSearchMethod: SearchMethod;
  creatorSetOptions: Record<string, string>[];
  creatorSetEntryStates: CreatorSetEntryStates;
  hiddenCreatorIdsSet: Set<number>;
  sortBy: SortBy;
  activeTab: ActiveTab;
  activePages: Record<ActiveTab, number>;
  setActiveTab: (value: ActiveTab) => void;
  setActivePages: (value: Record<ActiveTab, number>) => void;
  pushNavigationState: ({
    newQueryId,
    newActiveTab,
    newPageNumber,
    newSortBy,
  }: {
    newQueryId?: string;
    newActiveTab?: ActiveTab;
    newPageNumber?: number;
    newSortBy?: SortBy;
  }) => void;
  handleAddCreatorSet: (creatorSetId: number, creatorSetName: string) => void;
  setRateLimitExceeded: (value: boolean) => void;
  setLimitedUsage: (value: boolean) => void;
  setNumSearchesRemaining: (value: number) => void;
  setCreatorSetEntryStates: (value: CreatorSetEntryStates) => void;
  addActivatedCreatorId: (value: number) => void;
  addSavedCreatorId: (value: number) => void;
  removeSavedCreatorId: (value: number) => void;
  hideCreatorId: (
    value: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => void;
  unhideCreatorId: (
    value: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => void;
  fetchRelatedCreators: (value: string) => void;
}) {
  return (
    <Tabs
      variant="outline"
      value={activeTab}
      onChange={(value: string) => {
        setActiveTab(value as ActiveTab);
        pushNavigationState({
          newActiveTab: value as ActiveTab,
          newPageNumber: activePages[value as ActiveTab],
        });
      }}>
      <Flex justify="space-between">
        <Tabs.List mb="md" w="100%">
          <Tabs.Tab value={ActiveTab.RESULTS} leftSection={<IconListSearch size="1rem" />}>
            <Text fw="500">Results</Text>
          </Tabs.Tab>
          <Tabs.Tab
            value={ActiveTab.SAVED}
            leftSection={<IconBookmarks size="1rem" />}
            disabled={savedCreatorIds.length === 0}>
            <Flex gap="xs" align="center">
              <Text fw="500">Saved</Text>
              <Badge
                size="xs"
                radius="xl"
                circle={savedCreatorIds.length < 10}
                color={savedCreatorIds.length > 0 ? "red" : "gray"}>
                {savedCreatorIds.length}
              </Badge>
            </Flex>
          </Tabs.Tab>
          <Tabs.Tab
            value={ActiveTab.ACTIVATED}
            leftSection={<IconCircleCheck size="1rem" />}
            disabled={activatedCreatorIds.length === 0}>
            <Flex gap="xs" align="center">
              <Text fw="500">Activated</Text>
              <Badge
                size="xs"
                radius="xl"
                circle={activatedCreatorIds.length < 10}
                color={activatedCreatorIds.length > 0 ? "red" : "gray"}>
                {activatedCreatorIds.length}
              </Badge>
            </Flex>
          </Tabs.Tab>
          <Tabs.Tab
            value={ActiveTab.HIDDEN}
            leftSection={<IconEyeOff size="1rem" />}
            disabled={hiddenCreatorIds.length === 0}>
            <Flex gap="xs" align="center">
              <Text fw="500">Hidden</Text>
              <Badge
                size="xs"
                radius="xl"
                circle={hiddenCreatorIds.length < 10}
                color={hiddenCreatorIds.length > 0 ? "red" : "gray"}>
                {hiddenCreatorIds.length}
              </Badge>
            </Flex>
          </Tabs.Tab>
        </Tabs.List>
      </Flex>
      <Tabs.Panel value={ActiveTab.RESULTS}>
        <SearchResults
          sessionId={sessionId}
          queryId={queryId}
          creatorIds={unhiddenCreatorIds}
          loadingResults={loadingResults}
          submittedQuery={submittedQuery}
          selectedSearchMethod={selectedSearchMethod}
          creatorSetOptions={creatorSetOptions}
          creatorSetEntryStates={creatorSetEntryStates}
          hiddenCreatorIdsSet={hiddenCreatorIdsSet}
          sortBy={sortBy}
          activeTab={activeTab}
          activePage={activePages[ActiveTab.RESULTS]}
          setActivePage={(pageNumber: number) => {
            setActivePages({
              ...activePages,
              [ActiveTab.RESULTS]: pageNumber,
            });
          }}
          pushNavigationState={pushNavigationState}
          handleAddCreatorSet={handleAddCreatorSet}
          setRateLimitExceeded={setRateLimitExceeded}
          setLimitedUsage={setLimitedUsage}
          setNumSearchesRemaining={setNumSearchesRemaining}
          setCreatorSetEntryStates={setCreatorSetEntryStates}
          addActivatedCreatorId={addActivatedCreatorId}
          addSavedCreatorId={addSavedCreatorId}
          removeSavedCreatorId={removeSavedCreatorId}
          hideCreatorId={hideCreatorId}
          unhideCreatorId={unhideCreatorId}
          fetchRelatedCreators={fetchRelatedCreators}
          hideCreators
        />
      </Tabs.Panel>
      <Tabs.Panel value={ActiveTab.SAVED}>
        <SearchResults
          sessionId={sessionId}
          queryId={queryId}
          creatorIds={savedCreatorIds}
          loadingResults={loadingResults}
          submittedQuery={submittedQuery}
          selectedSearchMethod={selectedSearchMethod}
          creatorSetOptions={creatorSetOptions}
          creatorSetEntryStates={creatorSetEntryStates}
          hiddenCreatorIdsSet={hiddenCreatorIdsSet}
          sortBy={sortBy}
          activeTab={activeTab}
          activePage={activePages[ActiveTab.SAVED]}
          setActivePage={(pageNumber: number) => {
            setActivePages({
              ...activePages,
              [ActiveTab.SAVED]: pageNumber,
            });
          }}
          pushNavigationState={pushNavigationState}
          handleAddCreatorSet={handleAddCreatorSet}
          setRateLimitExceeded={setRateLimitExceeded}
          setLimitedUsage={setLimitedUsage}
          setNumSearchesRemaining={setNumSearchesRemaining}
          setCreatorSetEntryStates={setCreatorSetEntryStates}
          addActivatedCreatorId={addActivatedCreatorId}
          addSavedCreatorId={addSavedCreatorId}
          removeSavedCreatorId={removeSavedCreatorId}
          hideCreatorId={hideCreatorId}
          unhideCreatorId={unhideCreatorId}
          fetchRelatedCreators={fetchRelatedCreators}
        />
      </Tabs.Panel>
      <Tabs.Panel value={ActiveTab.ACTIVATED}>
        <SearchResults
          sessionId={sessionId}
          queryId={queryId}
          creatorIds={activatedCreatorIds}
          loadingResults={loadingResults}
          submittedQuery={submittedQuery}
          selectedSearchMethod={selectedSearchMethod}
          creatorSetOptions={creatorSetOptions}
          creatorSetEntryStates={creatorSetEntryStates}
          hiddenCreatorIdsSet={hiddenCreatorIdsSet}
          sortBy={sortBy}
          activeTab={activeTab}
          activePage={activePages[ActiveTab.ACTIVATED]}
          setActivePage={(pageNumber: number) => {
            setActivePages({
              ...activePages,
              [ActiveTab.ACTIVATED]: pageNumber,
            });
          }}
          pushNavigationState={pushNavigationState}
          handleAddCreatorSet={handleAddCreatorSet}
          setRateLimitExceeded={setRateLimitExceeded}
          setLimitedUsage={setLimitedUsage}
          setNumSearchesRemaining={setNumSearchesRemaining}
          setCreatorSetEntryStates={setCreatorSetEntryStates}
          addActivatedCreatorId={addActivatedCreatorId}
          addSavedCreatorId={addSavedCreatorId}
          removeSavedCreatorId={removeSavedCreatorId}
          hideCreatorId={hideCreatorId}
          unhideCreatorId={unhideCreatorId}
          fetchRelatedCreators={fetchRelatedCreators}
        />
      </Tabs.Panel>
      <Tabs.Panel value={ActiveTab.HIDDEN}>
        <SearchResults
          sessionId={sessionId}
          queryId={queryId}
          creatorIds={hiddenCreatorIds}
          loadingResults={loadingResults}
          submittedQuery={submittedQuery}
          selectedSearchMethod={selectedSearchMethod}
          creatorSetOptions={creatorSetOptions}
          creatorSetEntryStates={creatorSetEntryStates}
          hiddenCreatorIdsSet={hiddenCreatorIdsSet}
          sortBy={sortBy}
          activeTab={activeTab}
          activePage={activePages[ActiveTab.HIDDEN]}
          setActivePage={(pageNumber: number) => {
            setActivePages({
              ...activePages,
              [ActiveTab.HIDDEN]: pageNumber,
            });
          }}
          handleAddCreatorSet={handleAddCreatorSet}
          pushNavigationState={pushNavigationState}
          setRateLimitExceeded={setRateLimitExceeded}
          setLimitedUsage={setLimitedUsage}
          setNumSearchesRemaining={setNumSearchesRemaining}
          setCreatorSetEntryStates={setCreatorSetEntryStates}
          addActivatedCreatorId={addActivatedCreatorId}
          addSavedCreatorId={addSavedCreatorId}
          removeSavedCreatorId={removeSavedCreatorId}
          hideCreatorId={hideCreatorId}
          unhideCreatorId={unhideCreatorId}
          fetchRelatedCreators={fetchRelatedCreators}
        />
      </Tabs.Panel>
    </Tabs>
  );
}
