import React, { ReactNode, useState } from "react";

import LoadingScreen from "components/LoadingScreen";

// eslint-disable-next-line
const FacebookProvider = ({ children }: { children: ReactNode }) => {
  const [fbInitDone, setFbInitDone] = useState(false);

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: "776328873362317", // find a good way to make this an env variable
      cookie: true,
      xfbml: true,
      version: "v20.0",
    });
    window.FB.AppEvents.logPageView();
    setFbInitDone(true);
  };

  // load facebook sdk script
  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode?.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  setTimeout(() => {
    if (!fbInitDone) {
      setFbInitDone(true);
    }
  }, 500);

  return fbInitDone ? <>{children}</> : <LoadingScreen />; //eslint-disable-line
};

export default FacebookProvider;
