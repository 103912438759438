import { API_URL } from "configs/Configs";
import { User } from "firebase/auth";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { ShortDomain } from "models/ShortDomain";

export const BRAND_URL = `${API_URL}/api/models/brands/`;

export interface Brand {
  id: number;
  date_created: Date;
  brand_name: string;
  display_name: string;
  is_test_brand: boolean;
  is_verified?: boolean;
  // This doesn't actually exist in the backend yet
  avatar_url?: string;
  brand_short_name?: string;
  take_rate?: number;
  contact_email?: string;
  billing_email?: string;
  company_blurb?: string;
  company_website?: string;
  member_emails?: string[];
  notification_emails?: string[];
  status?: number;
  send_message_email: boolean;
  send_concept_review_email: boolean;
  send_video_review_email: boolean;
  send_live_link_email: boolean;
  send_daily_digest_email: boolean;
  send_contract_review_email: boolean;
  notes?: string;
  shortdomain?: ShortDomain;
  // only set if there is an error
  message?: string;
  internal_slack_channel?: string;
  outreach_email_address?: string;
  outreach_email_provider?: number;
  outreach_email_configured?: boolean;
  outreach_email_password_set?: boolean;
  skip_onboarding?: boolean;
  onboarding_creator_activation_target?: number;
}

// Keep this in sync with campaigns.models.CampaignV2.BudgetType
export enum BrandStatus {
  Unknown = 0,
  Deleted = 1,
  Archived = 2,
  Completed = 3,
  Paused = 4,
  Onboarding = 5,
  Free = 6,
  Active = 7,
}

export const BRAND_STATUS_SORT_ORDER = [
  BrandStatus.Free,
  BrandStatus.Onboarding,
  BrandStatus.Active,
  BrandStatus.Paused,
  BrandStatus.Completed,
  BrandStatus.Deleted,
  BrandStatus.Unknown,
];

export interface BrandResponse {
  // Follows the drf json response format
  results: Brand[];
  count: number;
  next?: number | null;
  previous?: number | null;
}

export const getAndSetBrands = async (
  user: User,
  setBrands: (brands: Brand[]) => void,
  abortController: AbortController,
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(BRAND_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request, abortController);
  setBrands(response?.results || []);
};

export const getBrand = async (
  brandId: number,
  abortController: AbortController | null = null,
): Promise<Brand> => {
  const request = await createRequestWithFirebaseToken({
    url: `${BRAND_URL}${brandId}/`,
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const createBrand = async ({
  user,
  brandName,
  takeRate,
  contactEmail,
  billingEmail,
  companyBlurb,
  companyWebsite,
  memberEmails,
}: {
  user: User;
  brandName: string;
  takeRate: number;
  contactEmail: string;
  billingEmail: string;
  companyBlurb: string;
  companyWebsite: string;
  memberEmails: string[];
}): Promise<Brand> => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(BRAND_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      brand_name: brandName,
      take_rate: takeRate,
      contact_email: contactEmail,
      billing_email: billingEmail,
      company_blurb: companyBlurb,
      company_website: companyWebsite,
      member_emails: memberEmails,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export default null;
