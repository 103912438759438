import { API_URL } from "configs/Configs";

import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { BudgetType } from "models/Campaign";

export interface BudgetAndSpend {
  month: string;
  budget: number;
  completed: number;
  contracted: number;
  pending: number;
}

export interface BudgetAndSpendResponse {
  success: boolean;
  campaign_name: string;
  data: BudgetAndSpend[];
  budget_type: BudgetType;
}

export const fetchBudgetAndSpendForCampaign = async (
  campaignHashId: number,
  abortController: AbortController,
): Promise<BudgetAndSpendResponse> => {
  const requestUrl = new URL(`${API_URL}/api/budgets/get_budget_and_spend_for_campaign`);
  requestUrl.searchParams.append("campaignHashId", campaignHashId.toString());

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response: BudgetAndSpendResponse | null = await handleResult(request, abortController);

  return response;
};

export const saveBudgetsForCampaign = async (
  campaignHashId: number,
  newBudgets: Record<string, number>,
): Promise<BudgetAndSpendResponse> => {
  const requestUrl = new URL(`${API_URL}/api/budgets/save_budget_for_campaign`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      campaignHashId,
      newBudgets,
    }),
  });
  const response: BudgetAndSpendResponse | null = await handleResult(request);

  return response;
};

export const pauseCampaign = async (campaignHashId: number): Promise<BudgetAndSpendResponse> => {
  const requestUrl = new URL(`${API_URL}/api/budgets/pause_campaign`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      campaignHashId,
    }),
  });
  const response: BudgetAndSpendResponse | null = await handleResult(request);

  return response;
}


export const resumeCampaign = async (campaignHashId: number, newBudget: number): Promise<BudgetAndSpendResponse> => {
  const requestUrl = new URL(`${API_URL}/api/budgets/resume_campaign`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      campaignHashId,
      newBudget,
    }),
  });
  const response: BudgetAndSpendResponse | null = await handleResult(request);

  return response;
}

export default null;
