import React from "react";

import { Button, Code, Group, Modal, Stack, Text } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import {
  ATTRIBUTION_TYPE_LABELS,
  RecommendationAttribution,
} from "components/creator_lists/LabelingUtils";

export const AdminCreatorDebug = ({
  creatorId,
  attributions,
}: {
  creatorId: number;
  attributions: RecommendationAttribution[];
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Group justify="flex-end">
      <Modal opened={opened} onClose={close} title={<Text fw="600">Creator Debug Details</Text>}>
        <Stack>
          <Text>Creator ID: {creatorId}</Text>
          <Text fw={700} size="sm">
            Attributions:
          </Text>
          <Code block>
            {attributions?.length > 0 &&
              attributions.map((att) => (
                <Text size="xs" key={att.source}>{`${att.source} (${
                  ATTRIBUTION_TYPE_LABELS[att.type]
                }): ${att.score}`}</Text>
              ))}
          </Code>
        </Stack>
      </Modal>
      <Button variant="light" onClick={open}>
        Creator Debug Details
      </Button>
    </Group>
  );
};

export default AdminCreatorDebug;
