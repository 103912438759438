import React from "react";

import { Anchor, Flex, Stack, Text, Textarea } from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";

import { CreatorSet } from "components/discovery/Datamodels";
import { BaseRichTextEditorReadOnly } from "components/general/RichTextEditor";

export const AdminLabelingCreatorSetRequirements = ({ creatorSet }: { creatorSet: CreatorSet }) => {
  if (!creatorSet) {
    return (
      <Stack px="md">
        <Text fw={700}>Unknown Creator Set</Text>
      </Stack>
    );
  }

  return (
    <Stack px="md">
      <Stack gap="xs">
        <Text fw={700}>Creator Set Requirements</Text>
        <Anchor href={`/admin/creator_sets?creatorSetId=${creatorSet.id}`} target="_blank">
          <Flex align="center" gap={0}>
            <Text span>Creator Set Details &nbsp;</Text>
            <IconExternalLink size="1.2rem" stroke={2} />
          </Flex>
        </Anchor>
      </Stack>
      <Text fw={700}>{creatorSet.name}</Text>
      <Text>{creatorSet.description}</Text>
      <Text>{creatorSet.brand_requirements}</Text>
      {creatorSet?.example_topics && creatorSet?.example_topics !== "" && (
        <Text>{creatorSet?.example_topics} </Text>
      )}
      {creatorSet.notes && creatorSet.notes !== "" && (
        <BaseRichTextEditorReadOnly
          editorKey={`creatorSetNotes-${creatorSet.id}`}
          value={creatorSet.notes}
        />
      )}
      {creatorSet.requirements && creatorSet.requirements !== "" && (
        <BaseRichTextEditorReadOnly
          editorKey={`creatorSetRequirements-${creatorSet.id}`}
          value={creatorSet.requirements}
        />
      )}
    </Stack>
  );
};

export default AdminLabelingCreatorSetRequirements;
