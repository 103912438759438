import React, { useEffect, useState } from "react";

import { Stack } from "@mantine/core";

import { OpsDailyTasks } from "admin/app/ops/tasks/OpsDailyTasks";
import { getOpsTasks, getOpsSourcingDailyTasks } from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";

export const AdminCreatorLabelingTasks = () => {
  const [dailyTasks, setDailyTasks] = useState([]);
  const { opsUsers } = useAdminAppContext();

  useEffect(() => {
    const abortController = new AbortController();
    getOpsSourcingDailyTasks(abortController).then((taskResponse) => {
      if (taskResponse?.results && taskResponse?.results?.length > 0) {
        setDailyTasks(taskResponse.results);
        console.log(taskResponse);
      }
    });

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Stack p="md" w="100%">
      <OpsDailyTasks opsUsers={opsUsers} sourcingAllocations={dailyTasks} />
    </Stack>
  );
};

export default AdminCreatorLabelingTasks;
