import React, { useState, useEffect } from "react";
import { useSearchParams, useOutletContext } from "react-router-dom";

import { Center, ComboboxItem, Loader, Title, Stack, Flex } from "@mantine/core";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { AuthContext } from "auth/AuthContext";
import OpsCreatorSearch from "ops_team/OpsCreatorSearch";
import SummaryCreatorProfileView from "components/creator/SummaryCreatorProfileView";
import CreatorMetricsView from "components/creator/CreatorMetricsView";
import NegotiationsView from "components/creator/NegotiationsView";
import PricingCalculator from "components/creator/PricingCalculator";
import {
  CreatorDetails,
  InstagramCreatorInfo,
  TiktokCreatorInfo,
  YoutubeChannelInfo,
} from "components/discovery/Datamodels";
import { fetchCreatorSetDetails } from "components/creator_sets/CreatorSetUtils";
import {
  fetchInstagramStatsByCreatorId,
  fetchTikTokStatsByCreatorId,
  fetchYoutubeStatsByCreatorId,
  InstagramStats,
  TikTokStats,
  YoutubeStats,
} from "creators/api/fetchExtendedStats";
import { ActivePlatformInfo } from "components/creator/CreatorUtils";
import { fetchCreatorById } from "creators/api/creatorApiUtils";
import { fetchCreatorOpportunities } from "campaigns/api/fetchOpportunities";
import { CreatorV2Info } from "models/CreatorV2Info";
import { Opportunity } from "models/Opportunity";
import { fetchContractsByCreator } from "components/contracts/common/Api";
import { Contract } from "components/contracts/dashboard/Utils";
import { CreatorDemographics } from "components/creator/CreatorDemographics";
import { CreatorSourcingDebug } from "creators/debug/CreatorSourcingDebug";
import HistoricalContracts from "components/creator/HistoricalContracts";
import ContractOffer from "models/ContractOffer";
import { getAllContractOffersForOpportunity } from "components/contracts/negotiations/api/ContractOfferApi";

const CreatorProfile = () => {
  const { user } = useOutletContext<AuthContext>();
  const { campaigns } = useAdminAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [creatorId, setCreatorId] = useState<number>(null);
  const [canonicalCreatorId, setCanonicalCreatorId] = useState<number>(null);
  const [creatorInfo, setCreatorInfo] = useState<CreatorV2Info>();
  const [creatorDetails, setCreatorDetails] = useState<Record<number, CreatorDetails>>({});
  const [activePlatforms, setActivePlatforms] = useState<ActivePlatformInfo>({
    youtube_channel: null,
    tiktok_profile: null,
    instagram_profile: null,
  });
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [completedContracts, setCompletedContracts] = useState<Contract[]>([]);
  const [contractOffers, setContractOffers] = useState<ContractOffer[]>([]);

  const [tikTokStats, setTikTokStats] = useState<TikTokStats>();
  const [youtubeShortsStats, setYoutubeShortsStats] = useState<YoutubeStats>();
  const [youtubeLongStats, setYoutubeLongStats] = useState<YoutubeStats>();
  const [youtubeIntegratedStats, setYoutubeIntegratedStats] = useState<YoutubeStats>();
  const [instagramStats, setInstagramStats] = useState<InstagramStats>();

  const [showDetailModal, setDetailModal] = useState(false);
  const abortController = new AbortController();

  const activeOpportunities = opportunities.filter(
    (opp) => opp.active_state === 2 || opp.id === 200081,
  );
  const activeCampaigns: ComboboxItem[] = activeOpportunities.map((opp) => {
    return {
      value: opp.ad_group.campaign_id.toString(),
      label: opp.ad_group.campaign_name,
    } as ComboboxItem;
  });
  const listOpportunityIds = opportunities.map((opp) => opp.id);

  useEffect(() => {
    if (searchParams.get("showDetails") === "true") {
      setDetailModal(true);
    }
    if (searchParams.has("creatorId")) {
      setCreatorId(parseInt(searchParams.get("creatorId"), 10));
    }
  }, []);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    if (creatorId !== null) {
      // Grab the canonical creator_id ==> then set the creatorId to be that canonical
      fetchCreatorById(user, creatorId, abortController).then((creator) => {
        setCanonicalCreatorId(creator?.id);
        setCreatorInfo(creator);
        if (creator && creator.id !== null) {
          updatedSearchParams.set("creatorId", String(creator.id));
        }
        setSearchParams(updatedSearchParams);
      });

      // Fetch opportunities
      fetchCreatorOpportunities(creatorId, abortController).then((oppResp) => {
        if (oppResp !== null) {
          setOpportunities(oppResp);
        } else if (oppResp === null) {
          setOpportunities([]);
        }
      });

      // fetch completed contracts
      fetchContractsByCreator(creatorId, abortController).then((res) => {
        if (res.success) {
          setCompletedContracts(res.contracts);
        } else if (!res.success) {
          setCompletedContracts([]);
        }
      });
    }

    return () => {
      abortController.abort();
    };
  }, [creatorId]);

  useEffect(() => {
    // fetch all (inactive and active) contract offers for creator here
    if (listOpportunityIds.length === 0) return;
    getAllContractOffersForOpportunity(listOpportunityIds, abortController).then((res) => {
      if (res !== null) {
        setContractOffers(res);
      } else if (res === null) {
        setContractOffers([]);
      }
    });
  }, [opportunities]);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (showDetailModal === true) {
      updatedSearchParams.set("showDetails", "true");
    } else if (updatedSearchParams.get("showDetails") !== null) {
      updatedSearchParams.delete("showDetails");
    }
    setSearchParams(updatedSearchParams);
  }, [showDetailModal]);

  useEffect(() => {
    fetchCreatorSetDetails(
      [canonicalCreatorId],
      creatorDetails,
      setCreatorDetails,
      abortController,
    );

    if (canonicalCreatorId) {
      // fetch prediction metrics and set state
      fetchTikTokStatsByCreatorId(user, canonicalCreatorId, setTikTokStats, abortController);
      // fetch prediction metrics for yt shorts
      fetchYoutubeStatsByCreatorId({
        requestUser: user,
        creatorId: canonicalCreatorId,
        isShorts: true,
        setYoutubeStats: setYoutubeShortsStats,
        abortController,
      });
      // fetch for yt long form
      fetchYoutubeStatsByCreatorId({
        requestUser: user,
        creatorId: canonicalCreatorId,
        isShorts: false,
        setYoutubeStats: setYoutubeLongStats,
        abortController,
      });
      // fetch integrated yt stats
      fetchYoutubeStatsByCreatorId({
        requestUser: user,
        creatorId: canonicalCreatorId,
        isShorts: false,
        setYoutubeStats: setYoutubeIntegratedStats,
        abortController,
        isIntegration: true,
      });
      // fetch ig stats
      fetchInstagramStatsByCreatorId(user, canonicalCreatorId, setInstagramStats, abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [canonicalCreatorId]);

  useEffect(() => {
    // get active platforms only
    if (creatorDetails) {
      const creator = creatorDetails[canonicalCreatorId];
      if (creator) {
        const activeSocials = Object.fromEntries(
          Object.entries(creator).filter(
            ([platform, stats]) =>
              ["instagram_profile", "tiktok_profile", "youtube_channel"].includes(platform) &&
              stats !== null,
          ),
        );
        const activeSocialsInfo: ActivePlatformInfo = {
          youtube_channel: (activeSocials.youtube_channel as YoutubeChannelInfo) ?? null,
          tiktok_profile: (activeSocials.tiktok_profile as TiktokCreatorInfo) ?? null,
          instagram_profile: (activeSocials.instagram_profile as InstagramCreatorInfo) ?? null,
        };

        setActivePlatforms(activeSocialsInfo);
      }
    }
  }, [creatorDetails]);

  return (
    <Stack mx="lg">
      <Title>Creator Profile Page</Title>
      <Flex justify="center">
        <OpsCreatorSearch setCreatorId={setCreatorId} />
      </Flex>
      {creatorDetails?.[canonicalCreatorId] ? (
        <>
          <SummaryCreatorProfileView
            key={canonicalCreatorId}
            creatorId={canonicalCreatorId}
            baseCreator={creatorDetails[canonicalCreatorId]}
            tikTokStats={tikTokStats}
            youtubeLongStats={youtubeLongStats}
            youtubeShortsStats={youtubeShortsStats}
            instagramStats={instagramStats}
            defaultExpandedPlatforms={[]}
            showDetailModal={showDetailModal}
            setShowDetailModal={setDetailModal}
          />
          <CreatorMetricsView
            tikTokStats={tikTokStats}
            youtubeLongStats={youtubeLongStats}
            youtubeIntegratedStats={youtubeIntegratedStats}
            youtubeShortsStats={youtubeShortsStats}
            instagramStats={instagramStats}
          />
          <NegotiationsView
            creatorId={canonicalCreatorId}
            creatorInfo={creatorInfo}
            activeOpportunities={activeOpportunities}
            opportunities={opportunities}
            campaigns={campaigns}
            activePlatforms={activePlatforms}
          />
          <PricingCalculator
            creatorInfo={creatorInfo}
            activePlatforms={activePlatforms}
            opportunities={opportunities}
            activeOpportunities={activeOpportunities}
            campaigns={campaigns}
            activeCampaigns={activeCampaigns}
            setOpportunities={setOpportunities}
            completedContracts={completedContracts}
          />
          <HistoricalContracts
            completedContracts={completedContracts}
            opportunity={opportunities}
            contractOffers={contractOffers}
          />
          <CreatorSourcingDebug creatorId={canonicalCreatorId} />
          <CreatorDemographics creatorId={canonicalCreatorId} />
        </>
      ) : (
        <Center>
          <Loader />
        </Center>
      )}
    </Stack>
  );
};

export default CreatorProfile;
