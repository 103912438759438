import React, { useEffect, useState } from "react";

import {
  Anchor,
  Card,
  Center,
  Container,
  Flex,
  List,
  Loader,
  LoadingOverlay,
  Paper,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { Notifications } from "@mantine/notifications";

import {
  getAccessibleCreatorSets,
  getBrandNames,
  getCreatorSetsForBrand,
  fetchQueryInfo,
  hideCreator,
  search,
  unhideCreator,
} from "components/discovery/search/Api";

import { CreatorSetEntryState } from "components/discovery/Datamodels";
import { SupportedPlatform } from "models/Common";

import {
  CreatorSetEntryStates,
  LoggingMetadata,
  SearchMethod,
  SearchSource,
} from "components/discovery/search/Common";
import {
  CountryFilterOption,
  CountryCodeToFilterValue,
  FilterConfigs,
  FilterType,
} from "components/discovery/search/FilterConstants";

import { ActiveTab } from "components/discovery/search/Common";
import AllRecommendedHashtags, {
  RecommendedHashtag,
} from "components/discovery/search/AllRecommendedHashtags";
import AutocompleteInput, { parseQuery } from "components/discovery/search/AutocompleteInput";
import SearchFilters from "components/discovery/search/SearchFilters";
import SortSelector, { SortBy } from "components/discovery/search/SortSelector";
import TabbedSearchResults from "components/discovery/search/TabbedSearchResults";
import RateLimitProgress from "components/discovery/search/RateLimitProgress";

const MAX_MULTIPLIER = 10_000;
const MIN_CONTAINER_WIDTH = 1_000;

function DisplayCreatorSets({
  creatorSetOptions,
  creatorSetsLoading,
}: {
  creatorSetOptions: string[];
  creatorSetsLoading: boolean;
}) {
  if (creatorSetsLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  if (!creatorSetOptions || creatorSetOptions.length === 0) {
    return null;
  }

  return (
    <Stack gap="xs">
      <Title fw="500" order={3}>
        Creator Sets
      </Title>
      <List>
        {creatorSetOptions.map((creatorSetOption: any) => (
          <List.Item key={creatorSetOption.value}>
            <Text>{creatorSetOption.label}</Text>
          </List.Item>
        ))}
      </List>
    </Stack>
  );
}

const parseCreatorSetEntryStates = (response: any) => {
  const deserializedCreatorSetToCreators: CreatorSetEntryStates = {};
  Object.entries(response).forEach(([creatorSetId, creatorSetEntries]) => {
    const deserializedCreatorSetEntries: Partial<Record<CreatorSetEntryState, Set<number>>> = {};

    Object.entries(creatorSetEntries).forEach(([entryState, memberCreatorIds]) => {
      const stateKey = Number(entryState) as CreatorSetEntryState;
      if (Array.isArray(memberCreatorIds)) {
        deserializedCreatorSetEntries[stateKey] = new Set<number>(memberCreatorIds);
      }
    });

    deserializedCreatorSetToCreators[Number(creatorSetId)] =
      deserializedCreatorSetEntries as Record<CreatorSetEntryState, Set<number>>;
  });
  return deserializedCreatorSetToCreators;
};

export default function CreatorSetSearch({ showDebug = false }: { showDebug?: boolean }) {
  // Global State
  const [initialPageLoading, setInitialPageLoading] = useState(true);
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [creatorSetsLoading, setCreatorSetsLoading] = useState(false);
  const [creatorSetOptions, setCreatorSetOptions] = useState([]);
  const [creatorSetEntryStates, setCreatorSetEntryStates] = useState<CreatorSetEntryStates>(null);

  const [searchBoxValue, setSearchBoxValue] = useState(null);
  const [submittedQuery, setSubmittedQuery] = useState(null);
  const [submittedQueryPlatform, setSubmittedQueryPlatform] = useState(null);
  const [selectedSearchMethod, setSelectedSearchMethod] = useState(SearchMethod.HASHTAG);

  const [loadingResults, setLoadingResults] = useState(false);

  // Rate Limit Status
  const [limitedUsage, setLimitedUsage] = useState(false);
  const [rateLimitExceeded, setRateLimitExceeded] = useState(false);
  const [numSearchesRemaining, setNumSearchesRemaining] = useState(0);

  // Session State
  const [sessionId, setSessionId] = useState(null);
  const [queryId, setQueryId] = useState(null);

  // Tabbed State
  const [creatorIds, setCreatorIds] = useState([]);
  const [hiddenCreatorIds, setHiddenCreatorIds] = useState([]);
  const [unhiddenCreatorIds, setUnhiddenCreatorIds] = useState([]);
  const [activatedCreatorIds, setActivatedCreatorIds] = useState([]);
  const [savedCreatorIds, setSavedCreatorIds] = useState([]);

  const [hiddenCreatorIdsSet, setHiddenCreatorIdsSet] = useState<Set<number>>(new Set());

  // Recommended Hashtags
  const [allHashtags, setAllHashtags] = useState<RecommendedHashtag[]>([]);

  // Filter Values
  const [selectedCountries, setSelectedCountries] = useState<CountryFilterOption[]>([]);
  const [followersRange, setFollowersRange] = useState<[number, number]>([
    FilterConfigs[FilterType.FOLLOWERS].defaultMin,
    FilterConfigs[FilterType.FOLLOWERS].defaultMax,
  ]);
  const [medianViewsRange, setMedianViewsRange] = useState<[number, number]>([
    FilterConfigs[FilterType.MEDIAN_VIEWS].defaultMin,
    FilterConfigs[FilterType.MEDIAN_VIEWS].defaultMax,
  ]);
  const [recentlyActive, setRecentlyActive] = useState(false);
  const [selectedPrices, setSelectedPrices] = useState<{ [key: number]: boolean }>({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const [numActiveFilters, setNumActiveFilters] = useState(null);

  // Sorting Options
  const [sortBy, setSortBy] = useState(SortBy.RELEVANCE);
  const [relevanceSortedCreators, setRelevanceSortedCreators] = useState([]);
  const [frequencySortedCreators, setFrequencySortedCreators] = useState([]);

  // Active Tab and Pages
  const [activeTab, setActiveTab] = useState(ActiveTab.RESULTS);
  const [activePages, setActivePages] = useState<Record<ActiveTab, number>>({
    [ActiveTab.RESULTS]: 1,
    [ActiveTab.SAVED]: 1,
    [ActiveTab.ACTIVATED]: 1,
    [ActiveTab.HIDDEN]: 1,
  });

  // Get all selected prices with a true value
  const getSelectedPrices = () => {
    return Object.keys(selectedPrices)
      .filter((price) => selectedPrices[Number(price)])
      .map(Number);
  };

  // Construct search filters.
  const getSearchFilters = () => {
    const filters: Record<string, any> =
      numActiveFilters > 0
        ? {
            medianViews: {
              minMedianViews: medianViewsRange[0],
              maxMedianViews:
                medianViewsRange[1] === FilterConfigs[FilterType.MEDIAN_VIEWS].max
                  ? medianViewsRange[1] * MAX_MULTIPLIER
                  : medianViewsRange[1],
            },
            followerCount: {
              minFollowerCount: followersRange[0],
              maxFollowerCount:
                followersRange[1] === FilterConfigs[FilterType.FOLLOWERS].max
                  ? followersRange[1] * MAX_MULTIPLIER
                  : followersRange[1],
            },
            countryFilter: {
              country: selectedCountries.map((country) => CountryCodeToFilterValue[country]),
            },
            priceFilter: {
              prices: getSelectedPrices(),
            },
          }
        : {};

    // We choose 4 here so the creator's content fills the entire creator rep.
    if (recentlyActive) {
      filters.recencyPosted = { recencyDays: 90, minRecencyPosted: 4 };
    }

    return filters;
  };

  const handleClearAllFilters = () => {
    const filterTypes = {
      [FilterType.FOLLOWERS]: setFollowersRange,
      [FilterType.MEDIAN_VIEWS]: setMedianViewsRange,
    };
    Object.keys(filterTypes).forEach((filterType) => {
      const { min, max } = FilterConfigs[filterType as FilterType];
      filterTypes[filterType as FilterType]([min, max]);
    });
    setSelectedPrices({ 1: false, 2: false, 3: false, 4: false });
    setSelectedCountries([]);
  };

  const setDefaultFilters = () => {
    setFollowersRange([
      FilterConfigs[FilterType.FOLLOWERS].defaultMin,
      FilterConfigs[FilterType.FOLLOWERS].defaultMax,
    ]);
    setMedianViewsRange([
      FilterConfigs[FilterType.MEDIAN_VIEWS].defaultMin,
      FilterConfigs[FilterType.MEDIAN_VIEWS].defaultMax,
    ]);
    setSelectedCountries([]);
    setSelectedPrices({ 1: false, 2: false, 3: false, 4: false });
  };

  const resetActivePages = () => {
    setActivePages({
      [ActiveTab.RESULTS]: 1,
      [ActiveTab.SAVED]: 1,
      [ActiveTab.ACTIVATED]: 1,
      [ActiveTab.HIDDEN]: 1,
    });
  };

  // Clear Current Creator IDs
  const handleClearCreatorIds = () => {
    setCreatorIds([]);
    setHiddenCreatorIds([]);
    setUnhiddenCreatorIds([]);
    setActivatedCreatorIds([]);
    setSavedCreatorIds([]);
  };

  // Update Retrieved Creator IDs
  const handleSetCreatorIds = (newCreatorIds: number[]) => {
    if (!creatorSetEntryStates) {
      setCreatorIds(newCreatorIds);
      return;
    }

    const activatedIds = Object.values(creatorSetEntryStates).reduce((acc, entries) => {
      if (entries[CreatorSetEntryState.ACCEPTED]) {
        return new Set([...acc, ...Array.from(entries[CreatorSetEntryState.ACCEPTED])]);
      }
      return acc;
    }, new Set<number>());

    const savedIds = Object.values(creatorSetEntryStates).reduce((acc, entries) => {
      if (entries[CreatorSetEntryState.SAVED]) {
        return new Set([...acc, ...Array.from(entries[CreatorSetEntryState.SAVED])]);
      }
      return acc;
    }, new Set<number>());

    const activatedResults = newCreatorIds.filter((id) => activatedIds.has(id));
    const savedResults = newCreatorIds.filter((id) => savedIds.has(id));
    const remainingResults = newCreatorIds.filter(
      (id) => !activatedIds.has(id) && !savedIds.has(id),
    );

    const hiddenResults = remainingResults.filter((id) => hiddenCreatorIdsSet.has(id));
    const unhiddenResults = remainingResults.filter((id) => !hiddenCreatorIdsSet.has(id));

    setCreatorIds(remainingResults);
    setHiddenCreatorIds(hiddenResults);
    setUnhiddenCreatorIds(unhiddenResults);
    setActivatedCreatorIds(activatedResults);
    setSavedCreatorIds(savedResults);
  };

  const pushNavigationState = ({
    newQueryId,
    newActiveTab,
    newPageNumber,
    newSortBy,
    newBrandId,
  }: {
    newQueryId?: string;
    newActiveTab?: ActiveTab;
    newPageNumber?: number;
    newSortBy?: SortBy;
    newBrandId?: string;
  }) => {
    const params = new URLSearchParams();

    const pageNumber = newPageNumber ?? activePages[activeTab];
    if (pageNumber !== 1) {
      params.set("p", pageNumber.toString());
    }

    const tab = newActiveTab || activeTab;
    if (tab !== ActiveTab.RESULTS) {
      params.set("tab", tab);
    }

    const sort = newSortBy || sortBy;
    if (sort !== SortBy.RELEVANCE) {
      params.set("sort", sort);
    }

    if (newQueryId) {
      params.set("q", newQueryId);
    } else if (queryId) {
      params.set("q", queryId);
    }

    if (showDebug && newBrandId) {
      params.set("brand", newBrandId);
    } else if (selectedBrand) {
      params.set("brand", selectedBrand);
    }

    window.history.pushState({}, "", `${window.location.pathname}?${params}`);
  };

  // Determine the Query type, then submit the appropriate search request.
  // Infers the Platform and SearchMethod from the query if not provided.
  const handleSubmit = (
    rawQuery: string,
    searchSource: SearchSource,
    querySearchMethod: SearchMethod = null,
  ) => {
    const [query, platform] = parseQuery(rawQuery);
    const filters = getSearchFilters();

    setLoadingResults(true);
    setSubmittedQuery(query);

    let resolvedSearchMethod = querySearchMethod;
    if (resolvedSearchMethod !== null) {
      setSelectedSearchMethod(resolvedSearchMethod);
    } else if (query.startsWith("#")) {
      resolvedSearchMethod = SearchMethod.HASHTAG;
      setSelectedSearchMethod(resolvedSearchMethod);
    } else if (query.startsWith("@")) {
      resolvedSearchMethod = SearchMethod.PLATFORM;
      setSelectedSearchMethod(resolvedSearchMethod);
    } else {
      resolvedSearchMethod = SearchMethod.KEYWORD;
      setSelectedSearchMethod(resolvedSearchMethod);
    }

    // TODO(albert): Once we build a close-up view for related searches,
    // don't clear the search box.
    if (resolvedSearchMethod === SearchMethod.CREATOR) {
      setSearchBoxValue("");
    } else {
      setSearchBoxValue(query);
    }

    let resolvedSearchPlatform = platform;
    if (searchSource === SearchSource.FILTER) {
      resolvedSearchPlatform = submittedQueryPlatform;
    } else if (resolvedSearchPlatform !== null) {
      setSubmittedQueryPlatform(resolvedSearchPlatform);
    } else if (resolvedSearchMethod === SearchMethod.HASHTAG) {
      resolvedSearchPlatform = SupportedPlatform.TIKTOK;
      setSubmittedQueryPlatform(resolvedSearchPlatform);
    } else if (resolvedSearchMethod === SearchMethod.CREATOR) {
      resolvedSearchPlatform = null;
      setSubmittedQueryPlatform(resolvedSearchPlatform);
    } else {
      resolvedSearchPlatform = submittedQueryPlatform;
    }

    search({
      sessionId,
      prevQueryId: queryId,
      searchSource,
      query,
      filters,
      searchMethod: resolvedSearchMethod,
      searchPlatform: resolvedSearchPlatform,
    })
      .then((response) => {
        const { success, searchMethod, recommendedHashtags, displayQuery, queryPlatform } =
          response;

        setSelectedSearchMethod(searchMethod);

        // Update search query
        if (searchMethod === SearchMethod.CREATOR) {
          setSearchBoxValue(displayQuery);
        }

        // Update the query platform
        if (queryPlatform) {
          setSubmittedQueryPlatform(queryPlatform);
        }

        // Set Session Information
        setSessionId(response.sessionId);
        setQueryId(response.queryId);

        if (success) {
          handleClearCreatorIds();
          setAllHashtags(recommendedHashtags);

          pushNavigationState({
            newQueryId: response.queryId,
            newPageNumber: 1,
            newActiveTab: ActiveTab.RESULTS,
          });

          if (searchMethod === SearchMethod.HASHTAG.valueOf()) {
            const { relevanceSortedCreatorIds, frequencySortedCreatorIds } = response;

            setRelevanceSortedCreators(relevanceSortedCreatorIds);
            setFrequencySortedCreators(frequencySortedCreatorIds);
          } else if (
            searchMethod === SearchMethod.PLATFORM.valueOf() ||
            searchMethod === SearchMethod.CREATOR.valueOf()
          ) {
            const { relatedCreatorIds } = response;

            setRelevanceSortedCreators([]);
            setFrequencySortedCreators([]);
            handleSetCreatorIds(relatedCreatorIds);
          } else if (searchMethod === SearchMethod.KEYWORD.valueOf()) {
            const { relevanceSortedCreatorIds } = response;

            handleSetCreatorIds(relevanceSortedCreatorIds);
          }
        } else {
          console.error("Failed to search for creators.");
          window.history.pushState({}, "", `${window.location.pathname}`);
        }
      })
      .finally(() => {
        setLoadingResults(false);
        resetActivePages();
      });
  };

  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search);

      const encodedQueryId = params.get("q");
      const encodedActiveTab = params.get("tab");
      const encodedPageNumber = params.get("p");
      const encodedSortBy = params.get("sort");
      const encodedBrandId = params.get("brand");

      if (showDebug && encodedBrandId) {
        setSelectedBrand(encodedBrandId);
      }

      const updateLocalState = () => {
        if (encodedActiveTab) {
          setActiveTab(encodedActiveTab as ActiveTab);
        } else {
          setActiveTab(ActiveTab.RESULTS);
        }

        if (encodedPageNumber) {
          setActivePages({
            ...activePages,
            [activeTab]: Number(encodedPageNumber),
          });
        } else {
          setActivePages({
            ...activePages,
            [activeTab]: 1,
          });
        }

        if (encodedSortBy) {
          setSortBy(encodedSortBy as SortBy);
        } else {
          setSortBy(SortBy.RELEVANCE);
        }
      };

      if (encodedQueryId) {
        if (encodedQueryId !== queryId) {
          setLoadingResults(true);
          fetchQueryInfo(sessionId, encodedQueryId)
            .then((response) => {
              if (response.success) {
                const {
                  query,
                  searchMethod,
                  searchPlatform,
                  relevanceSortedResults,
                  frequencySortedResults,
                  filters,
                  recommendedHashtags,
                } = response.queryInfo;

                if (filters && Object.keys(filters).length > 0) {
                  setSelectedCountries(filters.countryFilter?.country ?? []);
                  setFollowersRange([
                    filters.followerCount.minFollowerCount,
                    filters.followerCount.maxFollowerCount > FilterConfigs[FilterType.FOLLOWERS].max
                      ? FilterConfigs[FilterType.FOLLOWERS].max
                      : filters.followerCount.maxFollowerCount,
                  ]);
                  setMedianViewsRange([
                    filters.medianViews.minMedianViews,
                    filters.medianViews.maxMedianViews > FilterConfigs[FilterType.MEDIAN_VIEWS].max
                      ? FilterConfigs[FilterType.MEDIAN_VIEWS].max
                      : filters.medianViews.maxMedianViews,
                  ]);
                  setSelectedPrices({
                    1: filters.priceFilter.prices.includes(1),
                    2: filters.priceFilter.prices.includes(2),
                    3: filters.priceFilter.prices.includes(3),
                    4: filters.priceFilter.prices.includes(4),
                  });
                } else {
                  handleClearAllFilters();
                }

                // NOTE(albert): This is a bit hacky, but we do this becasue sometimes we need
                // to generate a new Session ID and Query ID when the user visits a URL directly.
                // This is to ensure we're not breaking any invariants on the backend with the
                // logging framework.
                if (response.currentSessionId !== sessionId) {
                  setSessionId(response.currentSessionId);
                }
                if (response.currentQueryId !== queryId) {
                  const newParams = new URLSearchParams(window.location.search);
                  newParams.set("q", response.currentQueryId);
                  window.history.replaceState({}, "", `${window.location.pathname}?${newParams}`);
                  setQueryId(response.currentQueryId);
                }

                handleClearCreatorIds();
                setAllHashtags(recommendedHashtags);
                setSelectedSearchMethod(searchMethod);
                setSearchBoxValue(query);
                setSubmittedQuery(query);
                setSubmittedQueryPlatform(searchPlatform);

                if (searchMethod === SearchMethod.HASHTAG.valueOf()) {
                  setRelevanceSortedCreators(relevanceSortedResults);
                  setFrequencySortedCreators(frequencySortedResults);
                } else if (
                  searchMethod === SearchMethod.PLATFORM.valueOf() ||
                  searchMethod === SearchMethod.CREATOR.valueOf()
                ) {
                  setFrequencySortedCreators([]);
                  handleSetCreatorIds(relevanceSortedResults);
                } else if (searchMethod === SearchMethod.KEYWORD.valueOf()) {
                  handleSetCreatorIds(relevanceSortedResults);
                }
              }
            })
            .finally(() => {
              setLoadingResults(false);
            });
        } else {
          updateLocalState();
        }
      } else {
        setDefaultFilters();
        setQueryId(null);
        setSessionId(null);
        setSelectedSearchMethod(null);
        setSearchBoxValue("");
        setSubmittedQueryPlatform(null);
        setSubmittedQuery(null);
        handleClearCreatorIds();
        setAllHashtags([]);
        setRelevanceSortedCreators([]);
        setFrequencySortedCreators([]);
      }
    };

    handlePopState();
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [queryId]);

  // Handle Redirect to Search Page
  useEffect(() => {
    const isUnauthSearch = localStorage.getItem("unauth-search") === "true";
    if (isUnauthSearch && creatorSetEntryStates) {
      localStorage.removeItem("unauth-search");
      const searchQuery = localStorage.getItem("unauth-search-q");
      localStorage.removeItem("unauth-search-q");
      handleSubmit(searchQuery, SearchSource.DISCOVER_LANDING_PAGE);
    }
  }, [creatorSetEntryStates]);

  // Update Sort Order
  useEffect(() => {
    if (selectedSearchMethod === SearchMethod.HASHTAG) {
      if (sortBy === SortBy.RELEVANCE) {
        handleSetCreatorIds(relevanceSortedCreators);
      } else if (sortBy === SortBy.FREQUENCY) {
        handleSetCreatorIds(frequencySortedCreators);
      }
    }
  }, [sortBy, selectedSearchMethod, relevanceSortedCreators, frequencySortedCreators]);

  // Add Activated Creator
  const addActivatedCreatorId = (creatorId: number) => {
    // Remove from Saved if needed
    if (!activatedCreatorIds.includes(creatorId)) {
      setActivatedCreatorIds([creatorId, ...activatedCreatorIds]);
    }
  };

  // Add Saved Creator
  const addSavedCreatorId = (creatorId: number) => {
    if (!savedCreatorIds.includes(creatorId)) {
      setSavedCreatorIds([creatorId, ...savedCreatorIds]);
    }
  };

  // Remove Saved Creator
  const removeSavedCreatorId = (creatorId: number) => {
    setSavedCreatorIds(savedCreatorIds.filter((id) => id !== creatorId));
  };

  // Hide Creator
  const hideCreatorId = (
    creatorId: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => {
    setLoading(true);
    hideCreator({
      sessionId: loggingMetadata?.sessionId,
      queryId: loggingMetadata?.queryId,
      sortBy: loggingMetadata?.sortBy,
      activeTab: loggingMetadata?.activeTab,
      pageNumber: loggingMetadata?.pageNumber,
      creatorId,
      brandId: selectedBrand,
      shouldLog: loggingMetadata !== undefined,
    })
      .then((response) => {
        if (response.success) {
          setHiddenCreatorIds([creatorId, ...hiddenCreatorIds]);
          setHiddenCreatorIdsSet(new Set([...hiddenCreatorIdsSet, creatorId]));
        }
      })
      .finally(() => setLoading(false));
  };

  // Unhide Creator
  const unhideCreatorId = (
    creatorId: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => {
    setLoading(true);
    unhideCreator({
      sessionId: loggingMetadata?.sessionId,
      queryId: loggingMetadata?.queryId,
      sortBy: loggingMetadata?.sortBy,
      activeTab: loggingMetadata?.activeTab,
      pageNumber: loggingMetadata?.pageNumber,
      creatorId,
      brandId: selectedBrand,
      shouldLog: loggingMetadata !== undefined,
    })
      .then((response) => {
        if (response.success) {
          setHiddenCreatorIds(hiddenCreatorIds.filter((id) => id !== creatorId));
          setHiddenCreatorIdsSet(
            new Set([...hiddenCreatorIdsSet].filter((id) => id !== creatorId)),
          );
          setUnhiddenCreatorIds([creatorId, ...unhiddenCreatorIds]);
        }
      })
      .finally(() => setLoading(false));
  };

  // Initial Fetch
  if (showDebug) {
    useEffect(() => {
      getBrandNames()
        .then((response) => {
          const { results } = response;
          setBrandOptions(results);
        })
        .finally(() => setInitialPageLoading(false));
    }, []);

    useEffect(() => {
      setCreatorSetOptions([]);
      setCreatorSetEntryStates(null);

      if (selectedBrand && selectedBrand.length > 0) {
        setCreatorSetsLoading(true);
        getCreatorSetsForBrand(selectedBrand)
          .then((response) => {
            const { results, creatorSetToCreators, allHiddenCreatorIds } = response;
            setCreatorSetOptions(results);
            setHiddenCreatorIdsSet(new Set(allHiddenCreatorIds));

            const deserializedCreatorSetToCreators =
              parseCreatorSetEntryStates(creatorSetToCreators);
            setCreatorSetEntryStates(deserializedCreatorSetToCreators);
          })
          .finally(() => setCreatorSetsLoading(false));
      }
    }, [selectedBrand]);
  } else {
    useEffect(() => {
      getAccessibleCreatorSets()
        .then((response) => {
          const { results, creatorSetToCreators, allHiddenCreatorIds } = response;
          setCreatorSetOptions(results);
          setHiddenCreatorIdsSet(new Set(allHiddenCreatorIds));

          const deserializedCreatorSetToCreators = parseCreatorSetEntryStates(creatorSetToCreators);
          setCreatorSetEntryStates(deserializedCreatorSetToCreators);
        })
        .finally(() => setInitialPageLoading(false));
    }, []);
  }

  return (
    <>
      <Notifications />
      <Container miw={MIN_CONTAINER_WIDTH}>
        <LoadingOverlay visible={initialPageLoading} />
        <Paper radius="sm" p="lg">
          <Stack>
            <RateLimitProgress
              numSearchesRemaining={numSearchesRemaining}
              enforceLimit={limitedUsage}
            />
            {showDebug && (
              <Select
                data={brandOptions}
                placeholder="Select a Brand..."
                value={selectedBrand}
                onChange={(value) => {
                  setSelectedBrand(value);
                  pushNavigationState({ newBrandId: value});
                }}
                searchable
              />
            )}
            {showDebug && selectedBrand && (
              <DisplayCreatorSets
                creatorSetOptions={creatorSetOptions}
                creatorSetsLoading={creatorSetsLoading}
              />
            )}
            {((showDebug && selectedBrand) || !showDebug) && (
              <Center>
                <Stack>
                  <Flex gap="xs" align="flex-start" justify="center">
                    <AutocompleteInput
                      loadingResults={loadingResults}
                      searchBoxValue={searchBoxValue}
                      setSearchBoxValue={setSearchBoxValue}
                      handleSubmit={(query: string) =>
                        handleSubmit(query, SearchSource.AUTOCOMPLETE)
                      }
                    />
                    <SearchFilters
                      selectedCountries={selectedCountries}
                      setSelectedCountries={setSelectedCountries}
                      followersRange={followersRange}
                      setFollowersRange={setFollowersRange}
                      medianViewsRange={medianViewsRange}
                      setMedianViewsRange={setMedianViewsRange}
                      selectedPrices={selectedPrices}
                      setSelectedPrices={setSelectedPrices}
                      recentlyActive={recentlyActive}
                      setRecentlyActive={setRecentlyActive}
                      handleClearAllFilters={handleClearAllFilters}
                      handleSubmit={() => handleSubmit(searchBoxValue, SearchSource.FILTER)}
                      numActiveFilters={numActiveFilters}
                      setNumActiveFilters={setNumActiveFilters}
                      enabled={searchBoxValue !== null && searchBoxValue.length > 0}
                    />
                    {selectedSearchMethod === SearchMethod.HASHTAG && (
                      <SortSelector
                        sortBy={sortBy}
                        setSortBy={(newSortBy: SortBy) => {
                          setSortBy(newSortBy);
                          resetActivePages();
                          pushNavigationState({ newSortBy, newPageNumber: 1 });
                        }}
                      />
                    )}
                  </Flex>
                  {!rateLimitExceeded && (
                    <AllRecommendedHashtags
                      allHashtags={allHashtags}
                      hashtagsLoading={loadingResults}
                      handleSubmit={(query: string) =>
                        handleSubmit(query, SearchSource.RECOMMENDED_HASHTAG)
                      }
                    />
                  )}
                </Stack>
              </Center>
            )}
            {!rateLimitExceeded && creatorIds.length > 0 && !loadingResults && (
              <TabbedSearchResults
                sessionId={sessionId}
                queryId={queryId}
                unhiddenCreatorIds={unhiddenCreatorIds}
                savedCreatorIds={savedCreatorIds}
                activatedCreatorIds={activatedCreatorIds}
                hiddenCreatorIds={hiddenCreatorIds}
                loadingResults={loadingResults}
                submittedQuery={submittedQuery}
                selectedSearchMethod={selectedSearchMethod}
                creatorSetOptions={creatorSetOptions}
                creatorSetEntryStates={creatorSetEntryStates}
                hiddenCreatorIdsSet={hiddenCreatorIdsSet}
                activeTab={activeTab}
                activePages={activePages}
                setActiveTab={setActiveTab}
                setActivePages={setActivePages}
                sortBy={selectedSearchMethod === SearchMethod.PLATFORM ? SortBy.RELEVANCE : sortBy}
                pushNavigationState={pushNavigationState}
                handleAddCreatorSet={(creatorSetId: number, creatorSetName: string) => {
                  setCreatorSetOptions([
                    { value: creatorSetId, label: creatorSetName },
                    ...creatorSetOptions,
                  ]);
                  setCreatorSetEntryStates({
                    ...creatorSetEntryStates,
                    [creatorSetId]: {
                      [CreatorSetEntryState.ACCEPTED]: new Set(),
                      [CreatorSetEntryState.SAVED]: new Set(),
                    },
                  });
                }}
                setLimitedUsage={setLimitedUsage}
                setRateLimitExceeded={setRateLimitExceeded}
                setNumSearchesRemaining={setNumSearchesRemaining}
                setCreatorSetEntryStates={setCreatorSetEntryStates}
                addActivatedCreatorId={addActivatedCreatorId}
                addSavedCreatorId={addSavedCreatorId}
                removeSavedCreatorId={removeSavedCreatorId}
                hideCreatorId={hideCreatorId}
                unhideCreatorId={unhideCreatorId}
                fetchRelatedCreators={(query: string) =>
                  handleSubmit(query, SearchSource.RELATED_CREATOR, SearchMethod.CREATOR)
                }
              />
            )}
            {rateLimitExceeded && (
              <Card withBorder>
                <Center>
                  <Stack justify="center" gap="xs" align="center">
                    <Text fw="500" size="xl">
                      Usage Limit Exceeded
                    </Text>
                    <Anchor href="https://brands.1stcollab.com/pricing" target="_blank">
                      <Text fw="500" size="md">
                        See Pricing
                      </Text>
                    </Anchor>
                  </Stack>
                </Center>
              </Card>
            )}
          </Stack>
        </Paper>
      </Container>
    </>
  );
}
