import { User as FirebaseUser } from "firebase/auth";
import { API_URL } from "configs/Configs";

import {
  CampaignAdGroupOverview,
  CampaignOverviewResult,
  CampaignProgressReportResponse,
} from "models/Campaign";
import {
  createRequestWithFirebaseToken,
  createRequestBlobWithFirebaseToken,
  downloadFileFromBlob,
  handleResult,
} from "utils/ApiUtils";
import { toISODateString } from "utils/DateUtils";

const CAMPAIGN_OVERVIEW_URL = `${API_URL}/api/campaigns/progress_report`;
const BRAND_MEDIA_CSV_URL = `${API_URL}/api/campaigns/export_media_for_brand`;
const BRAND_CONTRACTS_CSV_URL = `${API_URL}/api/campaigns/export_contracts_for_brand`;

const DUMMY_CAMPAIGN_OVERVIEW = [
  {
    adGroup: {
      id: 1,
      name: "Dummy ad group here",
    },
    totalBudgetSpent: 1234,
    monthlyBudget: 133,
    monthlyBudgetSpent: 20.32,
    monthlyBudgetAllocated: 49.1,
    numConversions: 32,
    numLiveContent: 21,
    cpm: 21.3,
  },
  {
    adGroup: {
      id: 2,
      name: "No monthly budget",
    },
    totalBudgetSpent: 1234,
    monthlyBudget: null,
    monthlyBudgetSpent: 20.32,
    monthlyBudgetAllocated: 49.1,
    numConversions: 32,
    numLiveContent: 21,
    cpm: 21.3,
  },
  {
    adGroup: {
      id: 3,
      name: "Allocated same as spent",
    },
    totalBudgetSpent: 1234,
    monthlyBudget: 100,
    monthlyBudgetSpent: 12.23,
    monthlyBudgetAllocated: 12.23,
    numConversions: 32,
    numLiveContent: 21,
    cpm: 21.3,
  },
  {
    adGroup: {
      id: 4,
      name: "Spent entire monthly budget",
    },
    totalBudgetSpent: 1234,
    monthlyBudget: 100,
    monthlyBudgetSpent: 100,
    monthlyBudgetAllocated: 100,
    numConversions: 32,
    numLiveContent: 21,
    cpm: 21.3,
  },
];

export interface CampaignAdGroupOverallStats {
  totalSpent: number;
  totalOnAndOffPlatformSpend?: number;
  totalBudgetSpent: number;
  totalBudgetAllocated: number;
  monthlySpent: number;
  monthlyBudgetSpent: number;
  monthlyBudgetAllocated: number;
  numLiveContent: number;
  views: number;
  cpm: number | null;
  clicks: number;
  cpc: number | null;
}

export const translateCampaignProgressResponse = (
  response: CampaignProgressReportResponse,
): CampaignAdGroupOverview[] => {
  if (response == null || !response.success) {
    return [];
  }
  const result: CampaignAdGroupOverview[] = [];
  Object.keys(response.progress).forEach((key) => {
    const adGroupId = parseInt(key, 10);
    const adGroup = response.progress[adGroupId];
    result.push({
      adGroup: {
        id: adGroup.id,
        name: adGroup.name,
      },
      totalBudget: adGroup.budget,
      totalBudgetSpent: adGroup.contract_completed,
      totalSpent: adGroup.completed,
      totalBudgetAllocated: adGroup.contracted,
      monthlyBudget: adGroup.monthly_budget,
      monthlySpent: adGroup.monthly_completed,
      monthlyBudgetSpent: adGroup.monthly_contract_completed,
      monthlyBudgetAllocated: adGroup.monthly_contracted,
      numLiveContent: adGroup.live,
      views: adGroup.views,
      cpm: adGroup.completed && adGroup.views ? (1000 * adGroup.completed) / adGroup.views : null,
      clicks: adGroup.clicks,
      cpc: adGroup.completed && adGroup.clicks ? adGroup.completed / adGroup.clicks : null,
    });
  });

  return result;
};

export const getCampaignAdGroupOverallStats = (
  adGroupOverviews: CampaignAdGroupOverview[],
  totalOffPlatformSpend = 0,
): CampaignAdGroupOverallStats => {
  const totalBudgetSpent = adGroupOverviews.reduce(
    (acc, adGroupOverview) => acc + adGroupOverview.totalBudgetSpent,
    0,
  );
  const totalSpent = adGroupOverviews.reduce(
    (acc, adGroupOverview) => acc + adGroupOverview.totalSpent,
    0,
  );

  const totalOnAndOffPlatformSpend = totalBudgetSpent + totalOffPlatformSpend;
  const totalBudgetAllocated = adGroupOverviews.reduce(
    (acc, adGroupOverview) => acc + adGroupOverview.totalBudgetAllocated,
    0,
  );
  const monthlyBudgetSpent = adGroupOverviews.reduce(
    (acc, adGroupOverview) => acc + adGroupOverview.monthlyBudgetSpent,
    0,
  );
  const monthlySpent = adGroupOverviews.reduce(
    (acc, adGroupOverview) => acc + adGroupOverview.monthlySpent,
    0,
  );
  const monthlyBudgetAllocated = adGroupOverviews.reduce(
    (acc, adGroupOverview) => acc + adGroupOverview.monthlyBudgetAllocated,
    0,
  );
  const numLiveContent = adGroupOverviews.reduce(
    (acc, adGroupOverview) => acc + adGroupOverview.numLiveContent,
    0,
  );
  const views = adGroupOverviews.reduce((acc, adGroupOverview) => acc + adGroupOverview.views, 0);
  const cpm = totalSpent && views > 0 ? (1000 * totalSpent) / views : null;
  const clicks = adGroupOverviews.reduce((acc, adGroupOverview) => acc + adGroupOverview.clicks, 0);
  const cpc = totalSpent && clicks > 0 ? totalSpent / clicks : null;

  return {
    totalSpent,
    totalOnAndOffPlatformSpend,
    totalBudgetSpent,
    totalBudgetAllocated,
    monthlyBudgetSpent,
    monthlySpent,
    monthlyBudgetAllocated,
    numLiveContent,
    views,
    cpm,
    clicks,
    cpc,
  };
};

export const fetchCampaignOverview = async (
  campaignHashId: number,
  abortController: AbortController,
  includeMetrics = true,
): Promise<CampaignProgressReportResponse> => {
  const requestUrl = new URL(CAMPAIGN_OVERVIEW_URL);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());
  requestUrl.searchParams.append("include_metrics", includeMetrics.toString());

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response: CampaignProgressReportResponse | null = await handleResult(
    request,
    abortController,
  );

  if (response) {
    return response;
  }
  return null;
};

export const getBrandMetricsCsv = async (exportContracts: boolean, exportDeliverables: boolean, date?: Date) => {
  if (exportDeliverables) {
    const campaignMediaUrl = new URL(BRAND_MEDIA_CSV_URL);
    if (date != null) {
      campaignMediaUrl.searchParams.append("date", toISODateString(date));
    }
    const request = await createRequestBlobWithFirebaseToken({ url: campaignMediaUrl });

    try {
      const response = await fetch(request);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const blob = await response.blob();
      downloadFileFromBlob({
        blob,
        filename: `all-campaigns - media - ${date ? toISODateString(date) : "latest"}.csv`,
      });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }
  if (exportContracts) {
    const campaignMediaUrl = new URL(BRAND_CONTRACTS_CSV_URL);
    if (date != null) {
      campaignMediaUrl.searchParams.append("date", toISODateString(date));
    }
    const request = await createRequestBlobWithFirebaseToken({ url: campaignMediaUrl });

    try {
      const response = await fetch(request);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const blob = await response.blob();
      downloadFileFromBlob({
        blob,
        filename: `all-campaigns - contracts - ${date ? toISODateString(date) : "latest"}.csv`,
      });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }
};

export default null;
