import React, { useEffect, useState } from "react";
import { IconAlertCircle } from "@tabler/icons-react";

import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Group,
  Radio,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

import { SupportedPlatform } from "models/Common";
import { getAllUsageRightsRequests } from "components/contracts/common/Api";
import {
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_TITLE_SIZE,
  UsageRightsRequestStatus,
} from "components/contracts/common/Common";
import UsageRightsSelector from "components/contracts/contract/UsageRightsSelector";
import LiveContentModel from "components/contracts/models/LiveContentModel";
import {
  DeliverableVideo,
  deserializeDeliverableVideo,
} from "components/contracts/tasks/models/Common";

import { formatCurrencyAmount } from "utils/AnalyticsUtils";

export interface UsageRightsRequestDetails {
  id: number;
  dateCreated: Date;
  brandName: string;
  campaignId: number;
  campaignName: string;
  contractId: number;
  status: UsageRightsRequestStatus;
  maxBrandOfferAmount: number;
  usageRightsDays: number;
  usageRightsInPerpetuity?: boolean;
  // The newest approved live content associated with the given deliverable (if any)
  liveContent?: LiveContentModel;
  // If UGC, this will be the video for it
  // TODO(chris): actually implement this
  deliverableVideo?: DeliverableVideo;
}

function deserializeUsageRightsRequest(data: any): UsageRightsRequestDetails {
  return {
    id: data.id,
    dateCreated: new Date(data.dateCreated),
    brandName: data.brandName,
    campaignId: data.campaignId,
    campaignName: data.campaignName,
    contractId: data.contractId,
    status: data.status,
    maxBrandOfferAmount: data.maxBrandOfferAmount,
    usageRightsDays: data.usageRightsDays,
    usageRightsInPerpetuity: data.usageRightsInPerpetuity,
    liveContent: data.liveContent,
    // Optional, so we can't guarantee it exists to be deserialized
    deliverableVideo: data.deliverableVideo
      ? deserializeDeliverableVideo(data.deliverableVideo)
      : null,
  };
}

const UsageRightsRequestRadioCard = ({
  usageRightsRequest,
  value,
}: {
  usageRightsRequest: UsageRightsRequestDetails;
  value: string;
}) => {
  return (
    <Radio.Card radius="md" value={value}>
      <Group wrap="nowrap" align="flex-start" p="md">
        <Radio.Indicator />
        <Stack>
          <Text inherit>Date created: {usageRightsRequest.dateCreated.toString()}</Text>
          <Text inherit>Brand: {usageRightsRequest.brandName}</Text>
          <Text inherit>Campaign: {usageRightsRequest.campaignName}</Text>
          <Text inherit>
            Max offer: {formatCurrencyAmount(usageRightsRequest.maxBrandOfferAmount)}
          </Text>
          <Text inherit>
            Requested days:{" "}
            {usageRightsRequest.usageRightsInPerpetuity
              ? "in perpetuity"
              : usageRightsRequest.usageRightsDays}
          </Text>
          <Text inherit>
            Profile URL:{" "}
            {usageRightsRequest.liveContent?.profile_link && (
              <Anchor href={usageRightsRequest.liveContent.profile_link} target="_blank">
                {usageRightsRequest.liveContent.profile_link}
              </Anchor>
            )}
          </Text>
          <Text inherit>
            Content URL:{" "}
            {usageRightsRequest.liveContent?.url && (
              <Anchor href={usageRightsRequest.liveContent.url} target="_blank">
                {usageRightsRequest.liveContent.url}
              </Anchor>
            )}
          </Text>
        </Stack>
      </Group>
    </Radio.Card>
  );
};

export const UsageRightsDeliverableInput = ({
  dueDate,
  setDueDate,
  usageRightsDays,
  setUsageRightsDays,
  requireAdCode,
  setRequireAdCode,
  setUsageRightsDetails,
}: {
  dueDate: Date | null;
  setDueDate: (newDate: Date | null) => void;
  usageRightsDays: string | number;
  setUsageRightsDays: (newUsageRightsDays: string | number) => void;
  requireAdCode: boolean;
  setRequireAdCode: (newRequireAdCode: boolean) => void;
  setUsageRightsDetails: (details: UsageRightsRequestDetails) => void;
}) => {
  const [videoLink, setVideoLink] = useState<string>("");
  const [usageRightsRequests, setUsageRightsRequests] = useState<UsageRightsRequestDetails[]>([]);
  const [noUsageRightsFound, setNoUsageRightsFound] = useState<boolean>(false);
  const [selectedUsageRightsRequestIdx, setSelectedUsageRightsRequestIdx] = useState<number | null>(
    null,
  );

  useEffect(() => {
    // When the selected usage rights index changes, we want to extract the important info from the usage rights request
    const usageRightsRequest = usageRightsRequests.at(selectedUsageRightsRequestIdx);
    if (usageRightsRequest) {
      setUsageRightsDetails(usageRightsRequest);
    } else {
      setUsageRightsDetails(null);
    }
  }, [selectedUsageRightsRequestIdx]);

  return (
    <>
      <Title order={DEFAULT_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Deliverable
      </Title>
      <Stack mb="md">
        <TextInput
          label="Link to Video Post"
          value={videoLink}
          onChange={(event) => setVideoLink(event.currentTarget.value)}
        />
        <Button
          w={200}
          onClick={() => {
            if (videoLink) {
              getAllUsageRightsRequests(videoLink).then((response) => {
                if (response?.success && response?.usageRightsRequests.length > 0) {
                  setUsageRightsRequests(
                    response.usageRightsRequests.map((urr: any) =>
                      deserializeUsageRightsRequest(urr),
                    ),
                  );
                  setNoUsageRightsFound(false);
                } else {
                  setUsageRightsRequests([]);
                  setNoUsageRightsFound(true);
                }
              });
            }
          }}>
          Find usage rights request
        </Button>
        {usageRightsRequests?.length > 0 && (
          <Radio.Group
            value={selectedUsageRightsRequestIdx?.toString()}
            onChange={(value) => {
              setSelectedUsageRightsRequestIdx(Number(value));
            }}
            label="Select a usage rights request for this contract">
            {usageRightsRequests.map((usageRightsRequest, index) => (
              <UsageRightsRequestRadioCard
                key={usageRightsRequest.id}
                value={index.toString()}
                usageRightsRequest={usageRightsRequest}
              />
            ))}
          </Radio.Group>
        )}
        {noUsageRightsFound && (
          <Alert
            variant="light"
            color="red"
            title="No usage rights requests found"
            icon={<IconAlertCircle />}>
            No usage rights requests found for that video link. Please revise or create a usage
            rights request via the content library.
          </Alert>
        )}
        <DatePickerInput
          label="Final Video File Due Date"
          firstDayOfWeek={0}
          value={dueDate}
          onChange={setDueDate}
        />
        <UsageRightsSelector
          deliverableId={0}
          platform={SupportedPlatform.NONE}
          usageRightsDays={usageRightsDays}
          setUsageRightsDays={setUsageRightsDays}
        />
        <Title order={6}>Ad Code</Title>
        <Checkbox
          label="Require Spark Code / Partnership Ad Code"
          checked={requireAdCode}
          onChange={(event) => setRequireAdCode(event.currentTarget.checked)}
        />
      </Stack>
    </>
  );
};
