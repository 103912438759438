import React, { useEffect, Dispatch, Fragment } from "react";

import { ActionIcon, Box, Button, Flex, Title } from "@mantine/core";

import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";

import {
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_TITLE_SIZE,
  DeliverableListAction,
  DeliverableListActionType,
} from "components/contracts/common/Common";

import { DeliverableConfigs } from "components/contracts/models/Deliverable";
import { DeliverableList } from "components/contracts/contract/DeliverableListInput";
import UGCDeliverableInput from "components/contracts/contract/UGCDeliverableInput";
import { encodeDeliverablesConfigs } from "components/contracts/deliverables/DeliverablesUrlUtils";
import { useSearchParams } from "react-router-dom";

export default function UGCDeliverableListInput({
  deliverableList,
  editDeliverableList,
  addDeliverableWithValidation,
  isValidatingDeliverable,
}: {
  deliverableList: DeliverableList;
  editDeliverableList: Dispatch<DeliverableListAction>;
  addDeliverableWithValidation: () => void;
  isValidatingDeliverable: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  // TO DO: this just loops through all the deliverables again if its state changed
  // find better way to only update the diff (entry that was updated)
  useEffect(() => {
    if (deliverableList.entries) {
      const updateSearchParams = new URLSearchParams(searchParams.toString());
      const newDeliverableConfigs: DeliverableConfigs[] = [];
      deliverableList.entries.forEach((entry) => {
        const { timeline, usageRightsDays, usageRightsInPerpetuity } = entry.deliverable;
        const updateDeliverableConfigs = {
          include_script_review: timeline.requiresScriptReview,
          include_usage_rights: usageRightsDays > 0 || usageRightsInPerpetuity,
          usage_rights_duration: usageRightsDays,
          usage_rights_in_perpetuity: usageRightsInPerpetuity,
        } as DeliverableConfigs;
        newDeliverableConfigs.push(updateDeliverableConfigs);
      });

      const encodedUrl = encodeDeliverablesConfigs(newDeliverableConfigs);
      updateSearchParams.set("deliverables", encodedUrl);
      setSearchParams(updateSearchParams.toString());
    }
  }, [deliverableList]);

  const deliverables = deliverableList.entries.map(({ id, deliverable }, index) => (
    <Fragment key={id}>
      <Box my="sm">
        <Flex gap="sm" align="center">
          <ActionIcon
            key={`remove-deliverable-${id}`}
            color="gray"
            variant="subtle"
            onClick={() => {
              editDeliverableList({
                type: DeliverableListActionType.REMOVE_DELIVERABLE,
                payload: { id },
              });
            }}>
            <IconCircleMinus size="1.5rem" />
          </ActionIcon>
          <Box>
            <Title order={DEFAULT_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
              Deliverable #{index + 1}
            </Title>
            <Box mt="sm">
              <UGCDeliverableInput
                deliverable={deliverable}
                deliverableId={id}
                editDeliverableList={editDeliverableList}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Fragment>
  ));
  return (
    <>
      {deliverables}
      <Box mb="sm">
        <Button
          loading={isValidatingDeliverable}
          variant="light"
          onClick={addDeliverableWithValidation}
          leftSection={<IconCirclePlus size="1rem" />}>
          Add Deliverable
        </Button>
      </Box>
    </>
  );
}
