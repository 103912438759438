import { ActionIcon, Container, Flex, Popover, Table, Title, Tooltip } from "@mantine/core";

import { IconDownload } from "@tabler/icons-react";

import {
  fetchCampaignOverview,
  getCampaignAdGroupOverallStats,
  translateCampaignProgressResponse,
} from "campaigns/api/fetchCampaignOverview";
import Spacer from "components/Spacer";
import {
  BudgetType,
  Campaign,
  CampaignAdGroup,
  CampaignAdGroupOverview,
  CampaignProgressReportResponse,
} from "models/Campaign";
import React, { useEffect, useState } from "react";
import { getAbbreviatedNumber, formatCurrencyAmount } from "utils/AnalyticsUtils";
import { SpendBar } from "components/campaign/dashboard/CampaignOverviewComponents";
import { ExportDropdownContents } from "campaigns/main/campaignPage/ExportDropdownContents";
import { getCampaignMediaCsv } from "components/creator_lists/CreatorListsUtils";
import { useClickOutside, useDisclosure } from "@mantine/hooks";

const AdGroupOverview = ({
  adGroupOverview,
  budgetType,
  campaignTracksClicks,
}: {
  adGroupOverview: CampaignAdGroupOverview;
  budgetType: BudgetType;
  campaignTracksClicks: boolean;
}) => (
  <Table.Tr key={adGroupOverview.adGroup.id}>
    <Table.Td>{adGroupOverview.adGroup.id === -1 ? "-" : adGroupOverview.adGroup.name}</Table.Td>
    <Table.Td>{formatCurrencyAmount(adGroupOverview.totalSpent)}</Table.Td>
    <Table.Td>{getAbbreviatedNumber(adGroupOverview.views)}</Table.Td>
    <Table.Td>
      {adGroupOverview.cpm ? `${formatCurrencyAmount(adGroupOverview.cpm)}` : "None"}
    </Table.Td>
    {campaignTracksClicks ? (
      <Table.Td>{getAbbreviatedNumber(adGroupOverview.clicks)}</Table.Td>
    ) : undefined}
    {campaignTracksClicks ? (
      <Table.Td>
        {adGroupOverview.cpc ? `${formatCurrencyAmount(adGroupOverview.cpc)}` : "None"}
      </Table.Td>
    ) : undefined}
    <Table.Td>{adGroupOverview.numLiveContent}</Table.Td>
  </Table.Tr>
);

const OffPlatformOverview = ({
  adGroupOverview,
  campaignTracksClicks,
}: {
  adGroupOverview: CampaignAdGroupOverview;
  campaignTracksClicks: boolean;
}) => (
  <Table.Tr key={adGroupOverview.adGroup.id}>
    <Table.Td>{formatCurrencyAmount(adGroupOverview.totalSpent)}</Table.Td>
    <Table.Td>{getAbbreviatedNumber(adGroupOverview.views)}</Table.Td>
    <Table.Td>
      {adGroupOverview.cpm ? `${formatCurrencyAmount(adGroupOverview.cpm)}` : "None"}
    </Table.Td>
    {campaignTracksClicks ? (
      <Table.Td>{getAbbreviatedNumber(adGroupOverview.clicks)}</Table.Td>
    ) : undefined}
    {campaignTracksClicks ? (
      <Table.Td>
        {adGroupOverview.cpc ? `${formatCurrencyAmount(adGroupOverview.cpc)}` : "None"}
      </Table.Td>
    ) : undefined}
    <Table.Td>{adGroupOverview.numLiveContent}</Table.Td>
  </Table.Tr>
);

const CampaignAdGroupContainer = ({
  campaignHashId,
  campaignTitle,
  campaignTracksClicks,
  campaignOverview,
  adGroupOverviews,
}: {
  campaignHashId: number;
  campaignTitle: string;
  campaignTracksClicks: boolean;
  campaignOverview: CampaignProgressReportResponse;
  adGroupOverviews: CampaignAdGroupOverview[];
}) => {
  // Overall
  const overallStats = getCampaignAdGroupOverallStats(
    adGroupOverviews,
    campaignOverview.spent_off_platform,
  );
  const {
    totalSpent,
    totalBudgetSpent,
    totalBudgetAllocated,
    monthlyBudgetSpent,
    monthlyBudgetAllocated,
    numLiveContent,
    views,
    cpm,
    clicks,
    cpc,
  } = overallStats;

  const [downloading, setDownloading] = useState(false);

  const [exportMenuIsOpen, { close: closeExportMenu, open: openExportMenu }] = useDisclosure(false);
  const ref = useClickOutside(() => {
    closeExportMenu();
  }, ["mouseup", "touchend", "keydown"]);

  return (
    <Container
      w="100%"
      bg="var(--mantine-color-white)"
      pt={18}
      pb={18}
      pl={24}
      pr={24}
      style={{
        borderRadius: 20,
      }}>
      <Flex direction="column" gap={0}>
        <Flex justify="space-between">
          <Title order={4}>Campaign Overview</Title>
          <Popover withArrow opened={exportMenuIsOpen} width={300}>
            <Popover.Target>
              <Tooltip label="Export Metrics" disabled={exportMenuIsOpen}>
                <ActionIcon
                  variant="subtle"
                  onClick={() => {
                    if (exportMenuIsOpen) {
                      closeExportMenu();
                    } else {
                      openExportMenu();
                    }
                  }}
                  loading={downloading}>
                  <IconDownload size="1.3rem" />
                </ActionIcon>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown ref={ref}>
              <ExportDropdownContents
                exportAction={(exportContracts, exportDeliverables, exportDate) => {
                  setDownloading(true);
                  if (exportDate) {
                    getCampaignMediaCsv(
                      campaignHashId,
                      exportContracts,
                      exportDeliverables,
                      campaignTitle,
                      exportDate,
                    ).finally(() => setDownloading(false));
                  } else {
                    getCampaignMediaCsv(
                      campaignHashId,
                      exportContracts,
                      exportDeliverables,
                      campaignTitle,
                    ).finally(() => setDownloading(false));
                  }
                  closeExportMenu();
                }}
              />
            </Popover.Dropdown>
          </Popover>
        </Flex>
        <Spacer height={20} />
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Total Spend</Table.Th>
              <Table.Th>
                {campaignOverview.budget_type > 0 ? "Monthly Budget" : "Total Budget"}
              </Table.Th>
              <Table.Th>Views</Table.Th>
              <Table.Th>CPM</Table.Th>
              {campaignTracksClicks ? <Table.Th>Clicks</Table.Th> : undefined}
              {campaignTracksClicks ? <Table.Th>CPC</Table.Th> : undefined}
              <Table.Th>Live Content</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr key={0}>
              <Table.Td>{formatCurrencyAmount(totalSpent)}</Table.Td>
              <Table.Td>
                <SpendBar
                  budget={100 * campaignOverview.budget}
                  spent={campaignOverview.budget_type > 0 ? monthlyBudgetSpent : totalBudgetSpent}
                  allocated={
                    campaignOverview.budget_type > 0 ? monthlyBudgetAllocated : totalBudgetAllocated
                  }
                />
              </Table.Td>
              <Table.Td>{getAbbreviatedNumber(views)}</Table.Td>
              <Table.Td>{cpm ? `${formatCurrencyAmount(cpm)}` : "None"}</Table.Td>
              {campaignTracksClicks ? (
                <Table.Td>{getAbbreviatedNumber(clicks)}</Table.Td>
              ) : undefined}
              {campaignTracksClicks ? (
                <Table.Td>{cpc ? `${formatCurrencyAmount(cpc)}` : "None"}</Table.Td>
              ) : undefined}
              <Table.Td>{numLiveContent}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Flex>
    </Container>
  );
};

const AdGroupOverviewContainer = ({
  overviews,
  adGroup,
  budgetType,
  campaignTracksClicks,
}: {
  overviews: CampaignAdGroupOverview[];
  adGroup: CampaignAdGroup;
  budgetType: BudgetType;
  campaignTracksClicks: boolean;
}) => {
  if (!overviews || overviews.length <= 1) {
    return null;
  }
  let filteredOverviews = overviews;
  if (adGroup) {
    // filter overviews by adGroup
    filteredOverviews = overviews.filter((overview) => overview.adGroup.id === adGroup.id);
  }
  return (
    <Container
      w="100%"
      bg="var(--mantine-color-white)"
      pt={18}
      pb={18}
      pl={24}
      pr={24}
      style={{
        borderRadius: 20,
      }}>
      <Flex direction="column" gap={0}>
        <Title order={4}>
          Ad Group Overview {adGroup ? null : `[${filteredOverviews.length} Ad Groups]`}
        </Title>
        <Spacer height={20} />
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Ad group</Table.Th>
              <Table.Th>Total Spent</Table.Th>
              <Table.Th>Views</Table.Th>
              <Table.Th>CPM</Table.Th>
              {campaignTracksClicks ? <Table.Th>Clicks</Table.Th> : undefined}
              {campaignTracksClicks ? <Table.Th>CPC</Table.Th> : undefined}
              <Table.Th>Live Content</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {filteredOverviews.map((adGroupOverview) => (
              <AdGroupOverview
                adGroupOverview={adGroupOverview}
                key={adGroupOverview.adGroup.id}
                budgetType={budgetType}
                campaignTracksClicks={campaignTracksClicks}
              />
            ))}
          </Table.Tbody>
        </Table>
      </Flex>
    </Container>
  );
};

const OffPlatformOverviewContainer = ({
  overview,
  budgetType,
  campaignTracksClicks,
}: {
  overview: CampaignAdGroupOverview;
  budgetType: BudgetType;
  campaignTracksClicks: boolean;
}) => {
  if (!overview) {
    return null;
  }
  return (
    <Container
      w="100%"
      bg="var(--mantine-color-white)"
      pt={18}
      pb={18}
      pl={24}
      pr={24}
      style={{
        borderRadius: 20,
      }}>
      <Flex direction="column" gap={0}>
        <Title order={4}>Off-Platform</Title>
        <Spacer height={20} />
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Total Spent</Table.Th>
              <Table.Th>Views</Table.Th>
              <Table.Th>CPM</Table.Th>
              {campaignTracksClicks ? <Table.Th>Clicks</Table.Th> : undefined}
              {campaignTracksClicks ? <Table.Th>CPC</Table.Th> : undefined}
              <Table.Th>Live Content</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <OffPlatformOverview
              // Not an actual ad group, but has the same stats as one
              adGroupOverview={overview}
              campaignTracksClicks={campaignTracksClicks}
            />
          </Table.Tbody>
        </Table>
      </Flex>
    </Container>
  );
};

const CampaignOverview = ({
  campaign,
  adGroup,
  tracksClicks = false,
}: {
  campaign: Campaign;
  adGroup: CampaignAdGroup;
  tracksClicks: boolean;
}) => {
  const [campaignOverview, setCampaignOverview] = useState<CampaignProgressReportResponse | null>(
    null,
  );
  const [adGroupOverviewsWithOffPlatform, setAdGroupOverviewsWithOffPlatform] = useState<
    CampaignAdGroupOverview[] | null
  >(null);
  const [adGroupOverviewsWithoutOffPlatform, setAdGroupOverviewsWithoutOffPlatform] = useState<
    CampaignAdGroupOverview[] | null
  >(null);
  const [offPlatformOverview, setOffPlatformOverview] = useState<CampaignAdGroupOverview | null>(
    null,
  );

  useEffect(() => {
    const abortController = new AbortController();

    // reset on campaign change so we don't see the previous campaign's content while new campaigns overview is loading
    setCampaignOverview(null);
    setAdGroupOverviewsWithOffPlatform(null);
    setAdGroupOverviewsWithoutOffPlatform(null);

    fetchCampaignOverview(campaign.hash_id, abortController).then((campaignProgress) => {
      setCampaignOverview(campaignProgress);
      const translatedAdGroupOverviews = translateCampaignProgressResponse(campaignProgress);
      setAdGroupOverviewsWithOffPlatform(translatedAdGroupOverviews);
      // Separate out the off platform "ad group" for special consideration
      const actualAdGroupOverviews = translatedAdGroupOverviews.filter(
        (adGroupOverview) => adGroupOverview.adGroup.id !== -1,
      );
      const offPlatformAdGroups = translatedAdGroupOverviews.filter(
        (adGroupOverview) => adGroupOverview.adGroup.id === -1,
      );
      setAdGroupOverviewsWithoutOffPlatform(actualAdGroupOverviews);
      setOffPlatformOverview(offPlatformAdGroups.at(0) || null);
    });

    return () => {
      abortController.abort();
    };
  }, [campaign.hash_id]);

  if (!adGroupOverviewsWithOffPlatform) {
    return null;
  }

  const numAdGroups = adGroupOverviewsWithOffPlatform?.length || 0;
  if (numAdGroups === 0) {
    return null;
  }

  return (
    <>
      {adGroup != null ? null : (
        <CampaignAdGroupContainer
          campaignHashId={campaign.hash_id}
          campaignTitle={campaign.title}
          campaignTracksClicks={tracksClicks}
          campaignOverview={campaignOverview}
          adGroupOverviews={adGroupOverviewsWithOffPlatform}
        />
      )}
      {adGroupOverviewsWithoutOffPlatform &&
      adGroupOverviewsWithoutOffPlatform.length + (offPlatformOverview ? 1 : 0) > 1 ? (
        <AdGroupOverviewContainer
          overviews={adGroupOverviewsWithoutOffPlatform}
          adGroup={adGroup}
          budgetType={campaignOverview.budget_type}
          campaignTracksClicks={tracksClicks}
        />
      ) : null}
      {!adGroup && offPlatformOverview && (
        <OffPlatformOverviewContainer
          overview={offPlatformOverview}
          budgetType={campaignOverview.budget_type}
          campaignTracksClicks={tracksClicks}
        />
      )}
    </>
  );
};

export default CampaignOverview;
