import { Progress, Tooltip } from "@mantine/core";
import { CampaignAdGroupOverallStats } from "campaigns/api/fetchCampaignOverview";
import { BudgetType } from "models/Campaign";
import React from "react";
import { formatCurrencyAmount } from "utils/AnalyticsUtils";

export interface CampaignOverviewSpendStats {
  budget: number;
  budgetType: BudgetType;
  stats: CampaignAdGroupOverallStats;
  spent?: number;
  allocated?: number;
  spentBeforeThisMonth?: number;
}

/**
 * Sum provided campaign stats.
 * @param stats The list of stats to be summed.
 * @param budgetThisMonthOnly True iff the resuting budget should remove any amounts spent in prior
 *   months. This is especially important for one-time budgeted campaigns that may span months.
 * @returns A single aggregated CampaignOverviewSpendStats
 */
export const sumCampaignSpendStats = (
  stats: CampaignOverviewSpendStats[],
  budgetThisMonthOnly = false,
): CampaignOverviewSpendStats => {
  const totalBudget = budgetThisMonthOnly
    ? stats.reduce((acc, curr) => acc + (curr.budget - (curr.spentBeforeThisMonth ?? 0)), 0)
    : stats.reduce((acc, curr) => acc + curr.budget, 0);
  const totalStats = stats.reduce(
    (acc, curr) => {
      acc.totalSpent += curr.stats.totalSpent;
      acc.totalBudgetSpent += curr.stats.totalBudgetSpent;
      acc.totalBudgetAllocated += curr.stats.totalBudgetAllocated;
      acc.monthlySpent += curr.stats.monthlySpent;
      acc.monthlyBudgetSpent += curr.stats.monthlyBudgetSpent;
      acc.monthlyBudgetAllocated += curr.stats.monthlyBudgetAllocated;
      acc.numLiveContent += curr.stats.numLiveContent;
      acc.views += curr.stats.views;
      acc.cpm += curr.stats.cpm;
      acc.clicks += curr.stats.clicks;
      acc.cpc += curr.stats.cpc;
      return acc;
    },
    {
      totalSpent: 0,
      totalBudgetSpent: 0,
      totalBudgetAllocated: 0,
      monthlySpent: 0,
      monthlyBudgetSpent: 0,
      monthlyBudgetAllocated: 0,
      numLiveContent: 0,
      views: 0,
      cpm: 0,
      clicks: 0,
      cpc: 0,
    },
  );
  return {
    budget: totalBudget,
    budgetType: stats.length > 0 ? stats[0].budgetType : 0,
    stats: totalStats,
  };
};

export const SpendBar = ({
  budget,
  spent,
  allocated,
}: {
  budget: number | null;
  spent: number | null;
  allocated: number | null;
}) => {
  const spentPercent =
    budget > 0 ? ((spent || 0) / budget) * 100 : ((spent || 0) / Math.max(allocated, 1)) * 100;
  const allocatedPercent =
    budget > 0
      ? ((allocated - spent || 0) / budget) * 100
      : ((allocated - spent || 0) / (allocated || 1)) * 100;
  const remaining = Math.max(100 - (spentPercent + allocatedPercent), 0);

  return (
    <Progress.Root size="xl">
      <Tooltip label={`Spent - ${formatCurrencyAmount(spent)} (${spentPercent.toFixed(2)}%)`}>
        <Progress.Section value={spentPercent} color="cyan">
          <Progress.Label>Spent</Progress.Label>
        </Progress.Section>
      </Tooltip>

      <Tooltip
        label={`Contracted - ${formatCurrencyAmount(
          allocated - spent || 0,
        )} (${allocatedPercent.toFixed(2)}%)`}>
        <Progress.Section value={allocatedPercent} color="pink">
          <Progress.Label>Contracted</Progress.Label>
        </Progress.Section>
      </Tooltip>
      <Tooltip
        label={`Remaining - ${formatCurrencyAmount((budget || allocated) - (allocated || 0))}`}>
        <Progress.Section value={remaining} color="gray">
          <Progress.Label>Remaining</Progress.Label>
        </Progress.Section>
      </Tooltip>
    </Progress.Root>
  );
};
