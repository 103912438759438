import React, { useEffect, useState } from "react";

import { Anchor, Badge, Table, Text, Tooltip } from "@mantine/core";

import { getCreatorSetSourcingDetails } from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CreatorSet, CreatorSetStatus } from "components/discovery/Datamodels";
import { BrandStatus } from "models/Brand";
import { formatCurrencyAmount, getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { fromISODateString } from "utils/DateUtils";

const getBudgetUtilizationColor = (utilization: number) => {
  if (utilization < 20.0) {
    return "red";
  } else if (utilization < 40.0) {
    return "orange";
  } else if (utilization < 60.0) {
    return "yellow";
  }
  return "green";
};

const getBudgetCell = (budget: number, spend: number) => {
  if (budget > 0) {
    const utilization = (100 * spend) / budget;
    return (
      <>
        {formatCurrencyAmount(spend)} / {formatCurrencyAmount(budget)} (
        <Text span fw={700} inherit c={getBudgetUtilizationColor(utilization)}>
          {getAbbreviatedNumber(utilization, 2)}%
        </Text>
        )
      </>
    );
  }
  return "N/A";
};

const getOpsApprovalRateText = (rate: number) => {
  if (!rate) {
    return "N/A";
  } else if (rate > 0.2) {
    return (
      <Text inherit span c="green">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.1) {
    return (
      <Text inherit span c="yellow">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.05) {
    return (
      <Text inherit span c="orange">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  }
  return (
    <Text inherit span c="red">
      {getAbbreviatedNumber(rate * 100, 2)}%
    </Text>
  );
};

const getOpsApprovalRateCell = (sourcingDetails: any) => {
  return (
    <>
      {getOpsApprovalRateText(sourcingDetails?.ops_approval_rate_7d)} |{" "}
      {getOpsApprovalRateText(sourcingDetails?.ops_approval_rate_30d)} |{" "}
      {getOpsApprovalRateText(sourcingDetails?.ops_approval_rate_90d)}
    </>
  );
};

const getBrandApprovalRateText = (rate: number) => {
  if (!rate) {
    return "N/A";
  } else if (rate > 0.5) {
    return (
      <Text inherit span c="green">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.25) {
    return (
      <Text inherit span c="yellow">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.1) {
    return (
      <Text inherit span c="orange">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  }
  return (
    <Text inherit span c="red">
      {getAbbreviatedNumber(rate * 100, 2)}%
    </Text>
  );
};

const getBrandApprovalRateCell = (sourcingDetails: any) => {
  return (
    <>
      {getBrandApprovalRateText(sourcingDetails?.brand_approval_rate_7d)} |{" "}
      {getBrandApprovalRateText(sourcingDetails?.brand_approval_rate_30d)} |{" "}
      {getBrandApprovalRateText(sourcingDetails?.brand_approval_rate_90d)}
    </>
  );
};

const getInternalApprovalRateText = (rate: number) => {
  if (!rate) {
    return "N/A";
  } else if (rate > 0.9) {
    return (
      <Text inherit span c="green">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.75) {
    return (
      <Text inherit span c="yellow">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.5) {
    return (
      <Text inherit span c="orange">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  }
  return (
    <Text inherit span c="red">
      {getAbbreviatedNumber(rate * 100, 2)}%
    </Text>
  );
};

const getInternalApprovalRateCell = (sourcingDetails: any) => {
  return (
    <>
      {getInternalApprovalRateText(sourcingDetails?.internal_approval_rate_7d)} |{" "}
      {getInternalApprovalRateText(sourcingDetails?.internal_approval_rate_30d)} |{" "}
      {getInternalApprovalRateText(sourcingDetails?.internal_approval_rate_90d)}
    </>
  );
};

const getBrandApprovedCell = (sourcingDetails: any) => {
  if (sourcingDetails?.approved > 100) {
    return (
      <Text fw={700} inherit span c="green">
        {sourcingDetails?.approved}
      </Text>
    );
  } else if (sourcingDetails?.approved > 10) {
    return (
      <Text fw={700} inherit span c="yellow">
        {sourcingDetails?.approved}
      </Text>
    );
  } else if (sourcingDetails?.approved > 0) {
    return (
      <Text fw={700} inherit span c="orange">
        {sourcingDetails?.approved}
      </Text>
    );
  }
  return (
    <Text fw={700} inherit span c="red">
      0
    </Text>
  );
};

const getNeedsBrandReviewCell = (sourcingDetails: any) => {
  if (sourcingDetails?.need_brand_review > 100) {
    return (
      <Text fw={700} inherit span c="green">
        {sourcingDetails?.need_brand_review}
      </Text>
    );
  } else if (sourcingDetails?.need_brand_review > 50) {
    return (
      <Text fw={700} inherit span c="yellow">
        {sourcingDetails?.need_brand_review}
      </Text>
    );
  } else if (sourcingDetails?.need_brand_review > 0) {
    return (
      <Text fw={700} inherit span c="orange">
        {sourcingDetails?.need_brand_review}
      </Text>
    );
  }
  return (
    <Text fw={700} inherit span c="red">
      0
    </Text>
  );
};

const getNeedsInternalReviewCell = (sourcingDetails: any) => {
  if (sourcingDetails?.need_internal_review > 100) {
    return (
      <Text fw={700} inherit span c="green">
        {sourcingDetails?.need_internal_review}
      </Text>
    );
  } else if (sourcingDetails?.need_internal_review > 50) {
    return (
      <Text fw={700} inherit span c="yellow">
        {sourcingDetails?.need_internal_review}
      </Text>
    );
  } else if (sourcingDetails?.need_internal_review > 0) {
    return (
      <Text fw={700} inherit span c="orange">
        {sourcingDetails?.need_internal_review}
      </Text>
    );
  }
  return (
    <Text fw={700} inherit span c="red">
      0
    </Text>
  );
};

export const CreatorSetSourcingRow = ({ creatorSet }: { creatorSet: CreatorSet }) => {
  const [sourcingDetails, setSourcingDetails] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    if (creatorSet?.id) {
      getCreatorSetSourcingDetails(creatorSet.id, abortController).then((resp) => {
        if (resp) {
          setSourcingDetails(resp);
        }
      });
    }
    return () => {
      abortController.abort();
    };
  }, [creatorSet]);
  return (
    <Table.Tr>
      <Table.Td>
        <Anchor href={`/admin/brands?brandId=${creatorSet.brand.id}`} target="_blank">
          {creatorSet.brand.brand_name}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor href={`/admin/creator_sets?creatorSetId=${creatorSet.id}`} target="_blank">
          {creatorSet.name}
        </Anchor>
      </Table.Td>
      <Table.Td>{creatorSet.sourcing_priority}</Table.Td>
      <Table.Td>
        {creatorSet.ad_groups?.length > 0 &&
          creatorSet.ad_groups.map((adGroup) => (
            <Tooltip
              key={`creative-brief-adgroup=${adGroup.id}-tooltip`}
              label={`(${adGroup.campaign_name}) ${adGroup.name}`}>
              <Badge variant="light" key={`creator-set-adgroup=${adGroup.id}`}>
                {adGroup.name}
              </Badge>
            </Tooltip>
          ))}
      </Table.Td>
      <Table.Td>
        {fromISODateString(sourcingDetails?.enable_recommendations_timestamp)?.toDateString()}
      </Table.Td>
      <Table.Td>
        {getBudgetCell(sourcingDetails?.this_month_budget, sourcingDetails?.this_month_spend)}
      </Table.Td>
      <Table.Td>
        {getBudgetCell(sourcingDetails?.next_month_budget, sourcingDetails?.next_month_spend)}
      </Table.Td>
      <Table.Td>{getBrandApprovedCell(sourcingDetails)}</Table.Td>
      <Table.Td>{getBrandApprovalRateCell(sourcingDetails)}</Table.Td>
      <Table.Td>{getNeedsBrandReviewCell(sourcingDetails)}</Table.Td>
      <Table.Td>{getInternalApprovalRateCell(sourcingDetails)}</Table.Td>
      <Table.Td>{getNeedsInternalReviewCell(sourcingDetails)}</Table.Td>
      <Table.Td>{sourcingDetails?.need_enrichment}</Table.Td>
      <Table.Td>{sourcingDetails?.missing_email}</Table.Td>
      <Table.Td>{getOpsApprovalRateCell(sourcingDetails)}</Table.Td>
    </Table.Tr>
  );
};

export const CreatorSetSourcingTable = ({ creatorSets }: { creatorSets: CreatorSet[] }) => {
  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Brand</Table.Th>
          <Table.Th>Creator Set</Table.Th>
          <Table.Th>Priority</Table.Th>
          <Table.Th>Campaigns</Table.Th>
          <Table.Th>Recommendations Enabled Date</Table.Th>
          <Table.Th>This Month Budget</Table.Th>
          <Table.Th>Next Month Budget</Table.Th>
          <Table.Th>Lifetime Brand Approved</Table.Th>
          <Table.Th>Brand Approval Rate [7d | 30d | 90d]</Table.Th>
          <Table.Th>Needs Brand Review</Table.Th>
          <Table.Th>Internal Approval Rate [7d | 30d | 90d]</Table.Th>
          <Table.Th>Needs Internal Review</Table.Th>
          <Table.Th>Needs Email Enrichment</Table.Th>
          <Table.Th>Missing Email</Table.Th>
          <Table.Th>Ops Approval Rate [7d | 30d | 90d]</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {creatorSets.map((creatorSet) => (
          <CreatorSetSourcingRow key={creatorSet.id} creatorSet={creatorSet} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export const CreatorSetSourcingSummary = () => {
  const { creatorSets } = useAdminAppContext();
  const [growCreatorSets, setGrowCreatorSets] = useState([]);

  useEffect(() => {
    if (creatorSets) {
      // Filter by GROW and Brand Status
      const filteredCreatorSets = creatorSets.filter(
        (creatorSet) =>
          creatorSet.status === CreatorSetStatus.GROW &&
          [BrandStatus.Active, BrandStatus.Onboarding].includes(creatorSet.brand.status),
      );
      const brandMinPriorityMap = new Map<number, number>();
      filteredCreatorSets.forEach((creatorSet) => {
        const brandId = creatorSet.brand.id;
        const priority = creatorSet.sourcing_priority;
        if (!brandMinPriorityMap.has(brandId) || priority < brandMinPriorityMap.get(brandId)) {
          brandMinPriorityMap.set(brandId, priority);
        }
      });
      // sort by brand with min priority, then by priority
      filteredCreatorSets.sort((a, b) => {
        const aPriority = brandMinPriorityMap.get(a.brand.id) || a.sourcing_priority;
        const bPriority = brandMinPriorityMap.get(b.brand.id) || b.sourcing_priority;
        if (aPriority !== bPriority) {
          return aPriority - bPriority;
        }
        // sort by brand name
        if (a.brand.brand_name !== b.brand.brand_name) {
          return a.brand.brand_name.localeCompare(b.brand.brand_name);
        }
        return a.sourcing_priority - b.sourcing_priority;
      });
      setGrowCreatorSets(filteredCreatorSets);
    }
  }, [creatorSets]);

  return <CreatorSetSourcingTable creatorSets={growCreatorSets} />;
};

export default CreatorSetSourcingSummary;
