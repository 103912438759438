import { MantineColor } from "@mantine/core";

// Keep this in sync with Platform in common/constants.py
export enum SupportedPlatform {
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
  YOUTUBE = "youtube",
  NONE = "none",
}

export enum UsageRightsDuration {
  SEVEN_DAYS = 0,
  THIRTY_DAYS = 1,
  SIXTY_DAYS = 2,
  ONE_YEAR = 3,
}

export enum RecurrenceFrequency {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export enum RecurrenceType {
  FIXED = "fixed",
  INDEFINITE = "indefinite",
}

export const SUPPORTED_PLATFORMS_TO_HANDLES: { [value in SupportedPlatform]: string } = {
  [SupportedPlatform.INSTAGRAM]: "instagramHandle",
  [SupportedPlatform.TIKTOK]: "tiktokHandle",
  [SupportedPlatform.YOUTUBE]: "youtubeHandle",
  [SupportedPlatform.NONE]: null,
};

export const SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES: { [value in SupportedPlatform]: string } = {
  [SupportedPlatform.INSTAGRAM]: "Instagram",
  [SupportedPlatform.TIKTOK]: "TikTok",
  [SupportedPlatform.YOUTUBE]: "YouTube",
  [SupportedPlatform.NONE]: "",
};

// Keep in sync with DeliverableFormat in campaigns/models.py
export enum SupportedFormat {
  INSTAGRAM_DEDICATED_REEL = "instagram_dedicated_reel",
  TIKTOK_DEDICATED_VIDEO = "tiktok_dedicated_video",
  YOUTUBE_30S_INTEGRATED_VIDEO = "youtube_30s_integrated_video",
  YOUTUBE_60S_INTEGRATED_VIDEO = "youtube_60s_integrated_video",
  YOUTUBE_90S_INTEGRATED_VIDEO = "youtube_90s_integrated_video",
  // YOUTUBE_HALF_INTEGRATED_VIDEO = "youtube_half_integrated_video",
  YOUTUBE_DEDICATED_VIDEO = "youtube_dedicated_video",
  YOUTUBE_SHORT = "youtube_short",
  UGC = "ugc",
}

export const UsageRightsDurationToDays: { [value in UsageRightsDuration]: number } = {
  [UsageRightsDuration.SEVEN_DAYS]: 7,
  [UsageRightsDuration.THIRTY_DAYS]: 30,
  [UsageRightsDuration.SIXTY_DAYS]: 60,
  [UsageRightsDuration.ONE_YEAR]: 365,
};

export const UsageRightsDurationMultiplier: { [value in UsageRightsDuration]: number } = {
  [UsageRightsDuration.SEVEN_DAYS]: 0.1,
  [UsageRightsDuration.THIRTY_DAYS]: 0.2,
  [UsageRightsDuration.SIXTY_DAYS]: 0.3,
  [UsageRightsDuration.ONE_YEAR]: 1,
};

export const SUPPORTED_PLATFORMS_TO_USAGE_RIGHTS_DAYS: {
  [value in SupportedPlatform]: Array<number>;
} = {
  [SupportedPlatform.INSTAGRAM]: [7, 30, 60, 365],
  [SupportedPlatform.TIKTOK]: [7, 30, 60, 365],
  [SupportedPlatform.YOUTUBE]: [7, 30, 60, 365],
  [SupportedPlatform.NONE] : [7, 30, 60, 365],
};

export const UsageRightsColors: Record<string, MantineColor> = {
  0: "blue",
  1: "green",
  2: "blue",
};

export enum SupportedFormatIntegers {
  INSTAGRAM_DEDICATED_REEL = 11,

  TIKTOK_DEDICATED_VIDEO = 21,

  YOUTUBE_30S_INTEGRATED_VIDEO = 30,
  YOUTUBE_60S_INTEGRATED_VIDEO = 31,
  YOUTUBE_90S_INTEGRATED_VIDEO = 32,
  // YOUTUBE_HALF_INTEGRATED_VIDEO = 33,
  YOUTUBE_DEDICATED_VIDEO = 33,
  YOUTUBE_SHORT = 34,
}

export const SupportedFormatNameToInteger: { [value in SupportedFormat]: number } = {
  instagram_dedicated_reel: 11,
  tiktok_dedicated_video: 21,
  youtube_30s_integrated_video: 30,
  youtube_60s_integrated_video: 31,
  youtube_90s_integrated_video: 32,
  // youtube_half_integrated_video: 33,
  youtube_dedicated_video: 33,
  youtube_short: 34,
  ugc: 401,
};

export type SupportedFormatTypes = keyof typeof SupportedFormat;

export enum Language {
  GLOBAL = "global",
  ENGLISH = "en",
  SPANISH = "es",
  FRENCH = "fr",
  GERMAN = "de",
  ITALIAN = "it",
  DUTCH = "nl",
  PORTUGUESE = "pt",
  RUSSIAN = "ru",
  JAPANESE = "ja",
  KOREAN = "ko",
  CHINESE = "zh",
  ARABIC = "ar",
  TURKISH = "tr",
  HINDI = "hi",
  OTHER = "other",
}

export enum Country {
  GLOBAL = "Global",
  UNITED_STATES = "US",
  UNITED_KINGDOM = "GB",
  CANADA = "CA",
  MEXICO = "MX",
  AUSTRALIA = "AU",
  INDIA = "IN",
  JAPAN = "JP",
  OTHER = "Other",
}

// keep in sync with DeliverablePackage/ContractOffer
// contracts/models.py
export enum ContractOfferStatus {
  DRAFT = 0,
  NEEDS_INTERNAL_REVIEW = 1,
  NEEDS_BRAND_REVIEW = 2,
  INTERNAL_APPROVED_OFFER = 3,
  BRAND_APPROVED_OFFER = 4,
  BRAND_REJECTED_OFFER = 7,
  INTERNAL_APPROVED_BRAND_OFFER = 6,
  REJECTED = 5,
  CONTRACT_ISSUED = 8,
  CLOSED = 9,
}

export const ContractStatusColors: Record<ContractOfferStatus, string> = {
  [ContractOfferStatus.DRAFT]: "blue",
  [ContractOfferStatus.NEEDS_INTERNAL_REVIEW]: "yellow",
  [ContractOfferStatus.NEEDS_BRAND_REVIEW]: "yellow",
  [ContractOfferStatus.BRAND_APPROVED_OFFER]: "yellow",
  [ContractOfferStatus.INTERNAL_APPROVED_BRAND_OFFER]: "yellow",
  [ContractOfferStatus.INTERNAL_APPROVED_OFFER]: "yellow",
  [ContractOfferStatus.REJECTED]: "red",
  [ContractOfferStatus.BRAND_REJECTED_OFFER]: "red",
  [ContractOfferStatus.CONTRACT_ISSUED]: "green",
  [ContractOfferStatus.CLOSED]: "gray",
};

export default null;
