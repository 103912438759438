import React from "react";

import {
  ActionIcon,
  Box,
  Card,
  Center,
  Collapse,
  Container,
  Flex,
  Grid,
  Group,
  Paper,
  Text,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconChevronRight } from "@tabler/icons-react";

import { toShortDateString } from "utils/DateUtils";

export function Chevron({ opened, toggle }: { opened: boolean; toggle?: () => void }) {
  return (
    <ActionIcon variant="transparent" onClick={toggle} color="gray">
      <IconChevronRight
        size="1rem"
        style={{
          transform: opened ? "rotate(-90deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease",
        }}
      />
    </ActionIcon>
  );
}

export default function DeliverableStep({
  isOpen,
  label,
  submissionDate,
  icon,
  content,
}: {
  isOpen: boolean;
  label: string;
  submissionDate: Date;
  icon: React.ReactNode;
  content: React.ReactNode;
}) {
  const [opened, { toggle }] = useDisclosure(isOpen);

  return (
    <Card p={0} radius="md">
      <Grid>
        <Grid.Col span="content">
          <Center h="100%" w="100%" onClick={toggle} style={{ cursor: "pointer" }}>
            <Box mr="-xs">
              <Chevron opened={opened} toggle={toggle} />
            </Box>
          </Center>
        </Grid.Col>
        <Grid.Col span="auto">
          <Paper shadow="none" radius={0} onClick={toggle} style={{ cursor: "pointer" }}>
            <Group justify="space-between" align="center">
              <Flex align="center" gap="xs">
                {icon}
                <Text fw="600">{label}</Text>
              </Flex>
              <Text size="sm" c="dimmed">
                Submitted on {toShortDateString(submissionDate)}
              </Text>
            </Group>
          </Paper>
        </Grid.Col>
      </Grid>

      <Collapse in={opened}>
        <Container px="sm" py="sm">
          {opened ? content : null}
        </Container>
      </Collapse>
    </Card>
  );
}
