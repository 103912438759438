import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

const GET_SELF_DAILY_PROGRESS = `${API_URL}/api/labeling/progress/self/daily/`;
const GET_TEAM_DAILY_PROGRESS = `${API_URL}/api/labeling/progress/team/daily/`;
export interface DailyProgress {
  total: number;
  labeled: number;
}

export interface DailyReport {
  agreement: number;
  num_labeled: number;
}

export interface TeamDailyReport {
  // Key is user key
  [key: string]: DailyReport;
}

export interface TeamDailyReportResponse {
  // Key is date
  [key: string]: TeamDailyReport;
}

export const getDailyProgress = async (
  setDailyProgress: (dailyProgress: DailyProgress) => void,
  abortController?: AbortController,
) => {
  const request = await createRequestWithFirebaseToken({
    url: GET_SELF_DAILY_PROGRESS,
  });
  const response = await handleResult(request, abortController);
  if (response) {
    setDailyProgress(response);
  }
  return response;
};

export const getTeamProgress = async (
  setTeamProgress: (teamProgress: TeamDailyReportResponse) => void,
  abortController?: AbortController,
) => {
  const request = await createRequestWithFirebaseToken({
    url: GET_TEAM_DAILY_PROGRESS,
  });
  const response = await handleResult(request, abortController);
  if (response) {
    setTeamProgress(response);
  }
  return response;
};

export default null;
