import {
  DateUpdateType,
  SHORT_FORM_FORMATS,
  SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES,
} from "components/contracts/common/Common";
import { DateTypes, computeNonHolidayBusinessDayAdjustment } from "utils/DateUtils";
import { SupportedFormat, SupportedPlatform } from "models/Common";

enum DateMilestone {
  ANALYTICS = "analytics",
  LIVE = "live",
  APPROVAL = "approval",
  VIDEO_DRAFT = "videoDraft",
  SCRIPT_REVISION = "scriptRevision",
  SCRIPT = "script",
}

function truncateDate(date: Date): Date {
  const truncatedDate = new Date(date);
  truncatedDate.setHours(0, 0, 0, 0);
  return truncatedDate;
}

export function getLiveDateWindow(liveDate: Date): [Date, Date] {
  const currentDate = new Date();

  // Set minDate to the first day of the liveDate month
  const minDate = new Date(liveDate.getFullYear(), liveDate.getMonth(), 1);

  // If the current date is greater than minDate, use the current date
  if (currentDate > minDate) {
    minDate.setTime(currentDate.getTime());
  }

  // Set maxDate to the last day of the liveDate month
  const maxDate = new Date(liveDate.getFullYear(), liveDate.getMonth() + 1, 0);

  return [minDate, maxDate];
}

export default class DeliverableTimeline {
  format: SupportedFormat;

  liveDate: Date;

  minLiveDate: Date;

  maxLiveDate: Date;

  scriptDate: Date;

  scriptRevisionDate: Date;

  videoDraftDate: Date;

  approvalDate: Date;

  analyticsDate: Date;

  requiresScriptReview: boolean;

  requiresVideoReview: boolean;

  editableLiveDate: boolean;

  constructor({
    format,
    liveDate,
    minLiveDate,
    maxLiveDate,
    scriptDate,
    scriptRevisionDate,
    videoDraftDate,
    approvalDate,
    analyticsDate,
    requiresScriptReview,
    requiresVideoReview,
    editableLiveDate,
  }: {
    format: SupportedFormat;
    liveDate: Date;
    minLiveDate: Date;
    maxLiveDate: Date;
    scriptDate: Date;
    scriptRevisionDate: Date;
    videoDraftDate: Date;
    approvalDate: Date;
    analyticsDate: Date;
    requiresScriptReview: boolean;
    requiresVideoReview: boolean;
    editableLiveDate: boolean;
  }) {
    this.format = format;
    this.liveDate = liveDate && truncateDate(liveDate);
    this.minLiveDate = minLiveDate && truncateDate(minLiveDate);
    this.maxLiveDate = maxLiveDate && truncateDate(maxLiveDate);
    this.scriptDate = scriptDate && truncateDate(scriptDate);
    this.scriptRevisionDate = scriptRevisionDate && truncateDate(scriptRevisionDate);
    this.videoDraftDate = videoDraftDate && truncateDate(videoDraftDate);
    this.approvalDate = approvalDate && truncateDate(approvalDate);
    this.analyticsDate = analyticsDate && truncateDate(analyticsDate);
    this.requiresScriptReview = requiresScriptReview;
    this.requiresVideoReview = requiresVideoReview;
    this.editableLiveDate = editableLiveDate;
  }

  private static DefaultTimelineDeltas = {
    [DateMilestone.ANALYTICS]: [DateMilestone.LIVE, 1, DateTypes.MONTHS],
    [DateMilestone.LIVE]: [DateMilestone.LIVE, 0, DateTypes.DAYS],
    [DateMilestone.APPROVAL]: [DateMilestone.LIVE, -1, DateTypes.DAYS],
    [DateMilestone.VIDEO_DRAFT]: [DateMilestone.APPROVAL, -1, DateTypes.WEEKS],
    [DateMilestone.SCRIPT_REVISION]: [DateMilestone.VIDEO_DRAFT, -1, DateTypes.WEEKS],
    [DateMilestone.SCRIPT]: [DateMilestone.SCRIPT_REVISION, -1, DateTypes.DAYS],
  } as Record<DateMilestone, [DateMilestone, number, DateTypes]>;

  private static ShortFormTimelineDeltas = {
    [DateMilestone.ANALYTICS]: [DateMilestone.LIVE, 1, DateTypes.MONTHS],
    [DateMilestone.LIVE]: [DateMilestone.LIVE, 0, DateTypes.DAYS],
    [DateMilestone.APPROVAL]: [DateMilestone.LIVE, -1, DateTypes.DAYS],
    [DateMilestone.VIDEO_DRAFT]: [DateMilestone.APPROVAL, -3, DateTypes.DAYS],
    [DateMilestone.SCRIPT_REVISION]: [DateMilestone.VIDEO_DRAFT, -3, DateTypes.DAYS],
    [DateMilestone.SCRIPT]: [DateMilestone.SCRIPT_REVISION, -1, DateTypes.DAYS],
  } as Record<DateMilestone, [DateMilestone, number, DateTypes]>;

  // The UGC timeline sets the approval date to the live date.  Analytics don't really make sense for
  // UGC, so we set that to live as well.
  private static UGCTimelineDeltas = {
    [DateMilestone.ANALYTICS]: [DateMilestone.LIVE, 0, DateTypes.DAYS],
    [DateMilestone.LIVE]: [DateMilestone.LIVE, 0, DateTypes.DAYS],
    [DateMilestone.APPROVAL]: [DateMilestone.LIVE, 0, DateTypes.DAYS],
    [DateMilestone.VIDEO_DRAFT]: [DateMilestone.APPROVAL, -3, DateTypes.DAYS],
    [DateMilestone.SCRIPT_REVISION]: [DateMilestone.VIDEO_DRAFT, -3, DateTypes.DAYS],
    [DateMilestone.SCRIPT]: [DateMilestone.SCRIPT_REVISION, -1, DateTypes.DAYS],
  } as Record<DateMilestone, [DateMilestone, number, DateTypes]>;

  // Computes an adjusted timeline based on the current live date.
  public adjustTimeline = ({
    baseLiveDate,
    isShortForm,
    isUgc,
    staticLiveDateWindow,
  }: {
    baseLiveDate?: Date;
    isShortForm?: boolean;
    isUgc?: boolean;
    staticLiveDateWindow?: boolean;
  }) => {
    const newLiveDate = baseLiveDate || this.liveDate;

    const newDates = {
      [DateMilestone.ANALYTICS]: this.analyticsDate,
      [DateMilestone.LIVE]: newLiveDate,
      [DateMilestone.APPROVAL]: this.approvalDate,
      [DateMilestone.VIDEO_DRAFT]: this.videoDraftDate,
      [DateMilestone.SCRIPT_REVISION]: this.scriptRevisionDate,
      [DateMilestone.SCRIPT]: this.scriptDate,
    };

    let timelineDeltas = DeliverableTimeline.DefaultTimelineDeltas;
    if (isUgc) {
      timelineDeltas = DeliverableTimeline.UGCTimelineDeltas;
    } else if (isShortForm) {
      timelineDeltas = DeliverableTimeline.ShortFormTimelineDeltas;
    }

    Object.keys(timelineDeltas).forEach((newDateMilestone) => {
      const [refDateMilestone, adjustment, adjustmentType] =
        timelineDeltas[newDateMilestone as DateMilestone];
      newDates[newDateMilestone as DateMilestone] = computeNonHolidayBusinessDayAdjustment(
        newDates[refDateMilestone as DateMilestone],
        adjustment,
        adjustmentType,
      );
    });

    const [newMinLiveDate, newMaxLiveDate] = staticLiveDateWindow
      ? [this.minLiveDate, this.maxLiveDate]
      : getLiveDateWindow(newLiveDate);

    return new DeliverableTimeline({
      format: this.format,
      liveDate: newDates[DateMilestone.LIVE],
      minLiveDate: this.editableLiveDate ? newMinLiveDate : null,
      maxLiveDate: this.editableLiveDate ? newMaxLiveDate : null,
      scriptDate: this.requiresScriptReview ? newDates[DateMilestone.SCRIPT] : null,
      scriptRevisionDate: this.requiresScriptReview
        ? newDates[DateMilestone.SCRIPT_REVISION]
        : null,
      videoDraftDate: this.requiresVideoReview ? newDates[DateMilestone.VIDEO_DRAFT] : null,
      approvalDate: this.requiresVideoReview ? newDates[DateMilestone.APPROVAL] : null,
      analyticsDate: newDates[DateMilestone.ANALYTICS],
      requiresScriptReview: this.requiresScriptReview,
      requiresVideoReview: this.requiresVideoReview,
      editableLiveDate: this.editableLiveDate,
    });
  };

  public adjustTimelineForPlatformAndFormat(platform: SupportedPlatform, format: SupportedFormat) {
    const timelineAdjustment = SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES[platform].find(
      (v) => v.value === format,
    ).timeline;
    const newLiveDate = computeNonHolidayBusinessDayAdjustment(
      new Date(),
      timelineAdjustment.adjustment,
      timelineAdjustment.adjustmentType,
    );
    return this.adjustTimeline({
      baseLiveDate: newLiveDate,
      isUgc: format === SupportedFormat.UGC,
      isShortForm: SHORT_FORM_FORMATS.includes(format),
    });
  }

  public updateDate({
    dateUpdateType,
    newDate,
    format,
  }: {
    dateUpdateType: DateUpdateType;
    newDate: Date;
    format: SupportedFormat;
  }) {
    const newDateTruncated = newDate && truncateDate(newDate);
    switch (dateUpdateType) {
      case DateUpdateType.ALL:
        return this.adjustTimeline({
          baseLiveDate: newDate,
          isUgc: format === SupportedFormat.UGC,
          isShortForm: SHORT_FORM_FORMATS.includes(format),
        });
      case DateUpdateType.ANALYTICS:
        return new DeliverableTimeline({
          ...this,
          analyticsDate: newDateTruncated,
        });
      case DateUpdateType.APPROVAL:
        return new DeliverableTimeline({
          ...this,
          approvalDate: newDateTruncated,
        });
      case DateUpdateType.MIN_LIVE:
        return new DeliverableTimeline({
          ...this,
          minLiveDate: newDateTruncated,
        });
      case DateUpdateType.MAX_LIVE:
        return new DeliverableTimeline({
          ...this,
          maxLiveDate: newDateTruncated,
        });
      case DateUpdateType.LIVE:
        return new DeliverableTimeline({
          ...this,
          liveDate: newDateTruncated,
        });
      case DateUpdateType.SCRIPT:
        return new DeliverableTimeline({
          ...this,
          scriptDate: newDateTruncated,
        });
      case DateUpdateType.SCRIPT_REVISION:
        return new DeliverableTimeline({
          ...this,
          scriptRevisionDate: newDateTruncated,
        });
      case DateUpdateType.VIDEO_DRAFT:
        return new DeliverableTimeline({
          ...this,
          videoDraftDate: newDateTruncated,
        });
      default:
        return this;
    }
  }

  public updateEditableLiveDate(editableLiveDate: boolean) {
    return new DeliverableTimeline({
      ...this,
      editableLiveDate,
    });
  }

  public updateRequiresScriptReview(requiresScriptReview: boolean) {
    return new DeliverableTimeline({
      ...this,
      requiresScriptReview,
    });
  }

  public updateRequiresVideoReview(requiresVideoReview: boolean) {
    return new DeliverableTimeline({
      ...this,
      requiresVideoReview,
    });
  }

  public static build(): DeliverableTimeline {
    return new DeliverableTimeline({
      format: null,
      minLiveDate: new Date(),
      maxLiveDate: new Date(),
      liveDate: new Date(),
      scriptDate: new Date(),
      scriptRevisionDate: new Date(),
      videoDraftDate: new Date(),
      approvalDate: new Date(),
      analyticsDate: new Date(),
      requiresScriptReview: true,
      requiresVideoReview: true,
      editableLiveDate: true,
    });
  }

  public validate() {
    let valid = true;
    if (this.format === SupportedFormat.UGC) {
      valid = valid && this.requiresScriptReview !== null && this.requiresVideoReview !== null;
    } else {
      valid =
        this.liveDate !== null &&
        this.analyticsDate !== null &&
        this.requiresScriptReview !== null &&
        this.requiresVideoReview !== null;
      valid = valid && this.liveDate <= this.analyticsDate;
    }

    if (this.requiresScriptReview) {
      valid = valid && this.scriptDate !== null && this.scriptRevisionDate !== null;
      valid =
        valid &&
        this.scriptDate <= this.scriptRevisionDate &&
        this.scriptRevisionDate <= this.videoDraftDate &&
        this.scriptDate >= new Date();
    }

    if (this.requiresVideoReview) {
      valid = valid && this.videoDraftDate !== null && this.approvalDate !== null;
      valid =
        valid &&
        this.videoDraftDate <= this.approvalDate &&
        this.approvalDate <= this.liveDate &&
        this.videoDraftDate >= new Date();
    }

    if (this.format !== SupportedFormat.UGC) {
      if (this.editableLiveDate) {
        valid = valid && this.minLiveDate !== null && this.maxLiveDate !== null;
        valid = valid && this.minLiveDate <= this.maxLiveDate;
        valid = valid && this.liveDate >= this.minLiveDate && this.liveDate <= this.maxLiveDate;
      } else {
        valid = valid && this.minLiveDate === null && this.maxLiveDate === null;
      }
    }

    return valid;
  }
}
