import React from "react";

import { Button, Modal, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { LabelingDatasetFilterInfoDisplay } from "components/creator_lists/LabelingDatasetsListView";
import { FilteredReasonStat } from "components/creator_lists/LabelingUtils";

export const AdminCreatorFunnelDebug = ({
  filteredStats,
}: {
  filteredStats: FilteredReasonStat[];
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Stack align="center">
      <Modal opened={opened} onClose={close}>
        <LabelingDatasetFilterInfoDisplay filteredStats={filteredStats} />
      </Modal>
      <Button variant="light" color="gray" onClick={open} w={150}>
        Funnel Debug
      </Button>
    </Stack>
  );
};

export default AdminCreatorFunnelDebug;
