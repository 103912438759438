import { API_URL } from "configs/Configs";
import {
  CreatorSetItem,
  CreatorSetEntryItem,
  CreatorSetEntryState,
  CreatorDetails,
  CreatorSet,
} from "components/discovery/Datamodels";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { BrandState } from "components/creator_lists/CreatorListsUtils";

export const CREATOR_SETS_URL = `${API_URL}/creatorsets/`;
const CREATOR_DETAILS_URL = `${CREATOR_SETS_URL}get_creator_details/`;
const LIVE_CREATOR_DETAILS_URL = `${API_URL}/api/models/creator_campaigns/get_live_creator_details/`;

export enum CreatorSetStateIndex {
  NEEDS_REVIEW = 0,
  ACCEPT = 1,
  REJECT = 2,
  WORKED_WITH = 3,
}

export type CreatorSetInternalReviewProps = {
  internal_feedback: string;
  internal_review_enqueue_ts: string; // ISO date string
  internal_review_enqueuer: string; // email
  internal_review_platform: string;
  brand_notes: string; // brand feedback
};

export type CreatorSetCreatorProps = {
  brand_safety_score: number;
  quality_score: number;
  is_english: boolean;
  creator_in_videos: boolean;
  is_creator: boolean;
};

export type CreatorSetEntriesResponse = {
  creator_set_entries: CreatorSetEntryItem[];
  creator_set_internal_review_props?: Record<number, CreatorSetInternalReviewProps>;
  creator_props?: Record<number, CreatorSetCreatorProps>;
  success: boolean;
  message: string;
};

export type CreatorSetItemsResponse = {
  success: boolean;
  message: string;
  creator_sets: CreatorSetItem[];
};

export type CreatorSetDetailsResponse = {
  success: boolean;
  message: string;
  creator_details: Record<number, CreatorDetails>;
};

export type BrandCreatorSetDict = {
  brand_id: number;
  brand_name: string;
  creatorsets: CreatorSet[];
  creatorset_enqueuers: Record<number, string[]>;
};

export type BrandCreatorSetResponse = {
  brand_creatorsets: Record<number, BrandCreatorSetDict>;
};

export type CreatorSetMap = Record<number, CreatorSetItem>;

export const fetchCreatorSetItems = async (
  abortController: AbortController,
): Promise<CreatorSetItem[]> => {
  const creatorSetInfoEndpoint = `${CREATOR_SETS_URL}get_creator_sets_info/`;
  const request = await createRequestWithFirebaseToken({ url: creatorSetInfoEndpoint });

  const response: CreatorSetItemsResponse = await handleResult(request, abortController);
  if (response?.creator_sets != null) {
    return response.creator_sets;
  }
  return [];
};

const getDetailsEndpoint = (
  isLive: boolean,
  campaignId: number,
  creatorIds: number[],
  timeWindow: number = null,
) => {
  const baseUrl = isLive ? LIVE_CREATOR_DETAILS_URL : CREATOR_DETAILS_URL;
  const searchParams = new URLSearchParams();
  if (isLive) {
    searchParams.append("campaign_id", campaignId.toString());
  }
  if (timeWindow) {
    searchParams.append("days_live", timeWindow.toString());
  }
  searchParams.append("creatorIds", creatorIds.join(","));
  return `${baseUrl}?${searchParams.toString()}`;
};

export const fetchCreatorSetDetails = async (
  requestedCreatorSetIds: number[],
  creatorSetDetails: Record<number, CreatorDetails>,
  setCreatorSetDetails: (creatorSetDetails: Record<number, CreatorDetails>) => void,
  abortController: AbortController,
  isLive = false,
  campaignId: number = null,
  timeWindow: number = null,
) => {
  // make sure the requestedCreatorSetIds are not in the creatorSetDetails
  const creatorSetDetailsIds = requestedCreatorSetIds.filter(
    (id) => creatorSetDetails[id] === undefined,
  );

  if (creatorSetDetailsIds.length === 0) {
    return;
  }

  const detailsEndpoint = getDetailsEndpoint(isLive, campaignId, creatorSetDetailsIds, timeWindow);
  const request = await createRequestWithFirebaseToken({ url: detailsEndpoint });

  const response: CreatorSetDetailsResponse = await handleResult(request, abortController);
  if (response?.creator_details === undefined) {
    return;
  }
  // combine the response with the existing creatorSetDetails
  const newCreatorSetDetails = { ...creatorSetDetails, ...response.creator_details };
  setCreatorSetDetails(newCreatorSetDetails);
};

export const fetchBrandCreatorSetData = async (abortController: AbortController) => {
  // Create a URL object and set the query parameters on it
  const url = new URL(`${CREATOR_SETS_URL}get_brand_creatorset_data`);

  // Use url.toString() to get the full URL string with query parameters
  const request = await createRequestWithFirebaseToken({ url: url.toString() });
  const response: BrandCreatorSetResponse = await handleResult(request, abortController);
  return response;
};

export interface UpdateReviewCreatorSetParams {
  creatorId: number;
  creatorSetId: number;
  brandState?: BrandState;
  notes?: string;
}

export const activateMultipleCreators = async ({
  creatorSetId,
  creatorIds,
}: {
  creatorSetId: number;
  creatorIds: number[];
}) => {
  const reqBody = JSON.stringify({
    creatorIds: creatorIds.join(","),
    creatorSetId,
  });

  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/creatorsets/activate_multiple_creators/?creatorSetId=${encodeURIComponent(
      creatorSetId,
    )}`,
    method: "POST",
    body: reqBody,
  });
  const response = await handleResult(request);
  return response;
};

export const updateReviewCreatorSetEntry = async ({
  creatorId,
  creatorSetId,
  entryState,
}: {
  creatorId: number;
  creatorSetId: number;
  entryState?: CreatorSetEntryState;
}) => {
  const reqBody = JSON.stringify({
    creatorId,
    creatorSetId,
    entryState,
  });

  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/creatorsets/update_review_creatorset_entry/?creatorSetId=${encodeURIComponent(
      creatorSetId,
    )}`,
    method: "POST",
    body: reqBody,
  });
  const response = await handleResult(request);
  return response;
};

export const updateCreatorSetMetadata = async ({
  creatorId,
  creatorSetId,
  notes,
}: {
  creatorId: number;
  creatorSetId: number;
  notes?: string;
}) => {
  const reqBody = JSON.stringify({
    creatorId,
    creatorSetId,
    notes,
  });

  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/creatorsets/update_creatorset_entry_metadata/?creatorSetId=${encodeURIComponent(
      creatorSetId,
    )}`,
    method: "POST",
    body: reqBody,
  });
  const response = await handleResult(request);
  return response;
};

export const addCreatorToExistingPartners = async ({
  youtubeHandle,
  instagramHandle,
  tiktokHandle,
  firstName,
  lastName,
  email,
}: {
  youtubeHandle?: string;
  instagramHandle?: string;
  tiktokHandle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}) => {
  const reqBody = JSON.stringify({
    youtubeHandle,
    instagramHandle,
    tiktokHandle,
    firstName,
    lastName,
    email,
  });
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/creatorsets/add_existing_partner/`,
    method: "POST",
    body: reqBody,
  });
  const response = await handleResult(request);
  return response;
};

export const addCreatorToCreatorSet = async ({
  creatorSetId,
  youtubeHandle,
  instagramHandle,
  tiktokHandle,
  firstName,
  lastName,
  email,
  creatorSetState,
}: {
  creatorSetId: number;
  youtubeHandle?: string;
  instagramHandle?: string;
  tiktokHandle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  creatorSetState?: number;
}) => {
  const reqBody = JSON.stringify({
    creatorSetId,
    youtubeHandle,
    instagramHandle,
    tiktokHandle,
    firstName,
    lastName,
    email,
    creatorSetState,
  });
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/creatorsets/add_creator_to_creator_set/`,
    method: "POST",
    body: reqBody,
  });
  const response = await handleResult(request);
  return response;
};

export const getCreatorSetMapping = (
  creatorSets: CreatorSetItem[],
): Record<number, CreatorSetItem> => {
  const mapping: CreatorSetMap = {};
  if (creatorSets === null) {
    return mapping;
  }
  creatorSets.forEach((creatorSet) => {
    mapping[creatorSet.id] = creatorSet;
  });
  return mapping;
};

interface SetInternalReviewStateParams {
  creatorId: number;
  originCreatorSetId: number;
  targetCreatorSetId: number;
  internalReviewState?: number;
  brandSafety?: number;
  qualityScore?: number;
  isEnglish?: boolean;
  creatorInVideo?: boolean;
  isCreator?: boolean;
  internalFeedback?: string;
}

type InternalReviewJson = {
  creatorId: number;
  originCreatorSetId: number;
  targetCreatorSetId: number;
  internalReviewState?: number;
  brandSafety?: number;
  qualityScore?: number;
  isEnglish?: boolean;
  creatorInVideo?: boolean;
  isCreator?: boolean;
  internalFeedback?: string;
};

export const setInternalReviewCreatorSetState = async (params: SetInternalReviewStateParams) => {
  const jsonParams: Partial<InternalReviewJson> = {};
  jsonParams.creatorId = params.creatorId;
  jsonParams.originCreatorSetId = params.originCreatorSetId;
  jsonParams.targetCreatorSetId = params.targetCreatorSetId;
  if (params.internalReviewState !== undefined) {
    jsonParams.internalReviewState = params.internalReviewState;
  }
  if (params.brandSafety !== undefined) {
    jsonParams.brandSafety = params.brandSafety;
  }
  if (params.qualityScore !== undefined) {
    jsonParams.qualityScore = params.qualityScore;
  }
  if (params.isEnglish !== undefined) {
    jsonParams.isEnglish = params.isEnglish;
  }
  if (params.creatorInVideo !== undefined) {
    jsonParams.creatorInVideo = params.creatorInVideo;
  }
  if (params.isCreator !== undefined) {
    jsonParams.isCreator = params.isCreator;
  }
  if (params.internalFeedback !== undefined) {
    jsonParams.internalFeedback = params.internalFeedback;
  }

  const request = await createRequestWithFirebaseToken({
    url: `${CREATOR_SETS_URL}update_internal_review_entry/`,
    method: "POST",
    body: JSON.stringify(jsonParams),
  });
  const response = await handleResult(request);
  return response;
};

export const chunk = <T,>(array: T[], size: number): T[][] => {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);
  return [head, ...chunk(tail, size)];
};

export default null;
