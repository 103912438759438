import React, { useEffect, useState } from "react";

import {
  AppShell,
  Avatar,
  Burger,
  Button,
  Flex,
  Group,
  MantineStyleProp,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconBuildingCommunity,
  IconCash,
  IconChartLine,
  IconHome,
  IconLayoutDashboard,
  IconListLetters,
  IconPlus,
  IconPremiumRights,
  IconProgress,
  IconSearch,
  IconWallet,
  IconWindmill,
  IconUsers,
  IconUserSearch,
  IconUsersGroup,
  IconAdjustmentsHorizontal,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import Spacer from "components/Spacer";
import { User } from "models/User";

// copied from "campaigns/navbar/main/CompanyAndUserHeader"
const CompanyAndUserHeader = ({
  user,
  mobileOpened,
  desktopOpened,
  toggleMobile,
  toggleDesktop,
}: {
  user: User;
  mobileOpened: boolean;
  desktopOpened: boolean;
  toggleMobile: () => void;
  toggleDesktop: () => void;
}) => {
  const companyName = user.brand?.brand_name || user.name;
  if (!desktopOpened) {
    return (
      <Group justify="center" wrap="nowrap">
        <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
        <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
      </Group>
    );
  }
  return (
    <Group justify="space-between" wrap="nowrap">
      <Group gap={0} wrap="nowrap">
        <Avatar w={48} h={48} src={user.brand?.avatar_url} style={{ flex: "none" }} />
        <Spacer width={12} />
        <Stack gap={0}>
          {companyName ? (
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "155%",
              }}>
              {companyName}
            </Text>
          ) : null}
          <Text
            lineClamp={1}
            style={{
              fontSize: "11px",
              fontWeight: "400",
              lineHeight: "155%",
            }}>
            {user.email}
          </Text>
        </Stack>
        <Group h="100%" px="md">
          <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
          <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
        </Group>
      </Group>
    </Group>
  );
};

const dataMinusHome = [
  { link: "/admin/create_contract", label: "Create Contract", icon: IconPlus, adminOnly: false },
  { link: "/admin/dashboard", label: "Dashboard", icon: IconLayoutDashboard, adminOnly: true },
  {
    link: "/admin/onboarding_status",
    label: "Onboarding Status",
    icon: IconProgress,
    adminOnly: true,
  },
  { link: "/admin/metrics/cash_flow", label: "Cash Flow", icon: IconCash, adminOnly: true },
  { link: "/admin/metrics/budget", label: "Budgets", icon: IconWallet, adminOnly: true },
  { link: "/admin/metrics", label: "Metrics", icon: IconChartLine, adminOnly: true },
  { link: "/admin/brands", label: "Brands", icon: IconBuildingCommunity, adminOnly: false },
  { link: "/admin/campaigns", label: "Campaigns", icon: IconListLetters, adminOnly: false },
  { link: "/admin/creator_sets", label: "Creator Sets", icon: IconUsers, adminOnly: false },
  { link: "/admin/creator/search", label: "Creator Search", icon: IconSearch, adminOnly: false },
  {
    link: "/admin/campaign_parameters",
    label: "Campaign Parameters",
    icon: IconAdjustmentsHorizontal,
    adminOnly: false,
  },
  { link: "/admin/ops", label: "Ops Team", icon: IconUsersGroup, adminOnly: false },
  { link: "/admin/negotiations", label: "Negotiations", icon: IconPremiumRights, adminOnly: false },
  { link: "/admin/discover", label: "Discover", icon: IconUserSearch, adminOnly: false },
  {
    link: "/admin/recommendations",
    label: "Recommendations",
    icon: IconWindmill,
    adminOnly: false,
  },
];

const data = [
  { link: "/admin", label: "Home", icon: IconHome, adminOnly: false },
  ...dataMinusHome,
];

function getActiveFromUrl() {
  const url = window.location.pathname;
  const active = dataMinusHome.find((item) => url.startsWith(item.link));
  return active ? active.label : "Home";
}

const AdminNavbarCollapsed = ({
  userProfile,
  mobileOpened,
  desktopOpened,
  toggleMobile,
  toggleDesktop,
  style,
  active,
  setActive,
  navigate,
}: {
  userProfile: User;
  mobileOpened: boolean;
  desktopOpened: boolean;
  toggleMobile: () => void;
  toggleDesktop: () => void;
  style?: MantineStyleProp;
  active: string;
  setActive: (active: string) => void;
  navigate: (...args: any) => void;
}) => {
  const links = data.map((item) => {
    if (!userProfile.is_staff && item.adminOnly) {
      return null;
    }
    if (item.label === active) {
      return (
        <Button
          size="md"
          justify="center"
          fullWidth
          key={item.label}
          variant="filled"
          onClick={() => {
            setActive(item.label);
            navigate(
              {
                pathname: item.link,
                search: "",
              },
              { replace: true },
            );
          }}>
          <item.icon
            style={{
              color: "var(--mantine-color-blue-1)",
              width: "rem(25px)",
              height: "rem(25px)",
            }}
            stroke={1.5}
          />
        </Button>
      );
    }
    return (
      <Button
        size="md"
        justify="center"
        fullWidth
        key={item.label}
        variant="subtle"
        onClick={() => {
          setActive(item.label);
          navigate({
            pathname: item.link,
            search: "",
          });
        }}>
        <item.icon
          style={{
            color: "var(--mantine-color-blue)",
            width: "rem(25px)",
            height: "rem(25px)",
          }}
          stroke={1.5}
        />
      </Button>
    );
  });

  return (
    <Flex
      direction="column"
      justify="space-between"
      h="100%"
      style={{
        ...style,
      }}>
      <AppShell.Section pr="var(--mantine-spacing-md)" p="md">
        <CompanyAndUserHeader
          user={userProfile}
          mobileOpened={mobileOpened}
          desktopOpened={desktopOpened}
          toggleMobile={toggleMobile}
          toggleDesktop={toggleDesktop}
        />
      </AppShell.Section>
      <AppShell.Section grow my="md" component={ScrollArea}>
        <Stack px="md" gap={0}>
          {links}
        </Stack>
      </AppShell.Section>
    </Flex>
  );
};

export const AdminNavbar = ({
  userProfile,
  mobileOpened,
  desktopOpened,
  toggleMobile,
  toggleDesktop,
  closeDesktop,
  style,
}: {
  userProfile: User;
  mobileOpened: boolean;
  desktopOpened: boolean;
  toggleMobile: () => void;
  toggleDesktop: () => void;
  closeDesktop: () => void;
  style?: MantineStyleProp;
}) => {
  const [active, setActive] = useState(getActiveFromUrl());
  const navigate = useNavigate();

  useEffect(() => {
    setActive(getActiveFromUrl());

    // Also, if we are under /labeling, we should toggle the navbar
    if (window.location.pathname.startsWith("/admin/labeling")) {
      closeDesktop();
    }
  }, [window.location.pathname]);

  if (!desktopOpened) {
    return (
      <AdminNavbarCollapsed
        userProfile={userProfile}
        mobileOpened={mobileOpened}
        desktopOpened={desktopOpened}
        toggleMobile={toggleMobile}
        toggleDesktop={toggleDesktop}
        style={style}
        active={active}
        setActive={setActive}
        navigate={navigate}
      />
    );
  }

  const links = data.map((item) => {
    if (!userProfile.is_staff && item.adminOnly) {
      return null;
    }
    if (item.label === active) {
      return (
        <Button
          size="md"
          justify="flex-start"
          fullWidth={desktopOpened}
          key={item.label}
          variant="filled"
          onClick={() => {
            setActive(item.label);
            navigate(
              {
                pathname: item.link,
                search: "",
              },
              { replace: true },
            );
          }}
          leftSection={
            <item.icon
              style={{
                color: "var(--mantine-color-blue-1)",
                marginRight: "var(--mantine-spacing-sm)",
                width: "rem(25px)",
                height: "rem(25px)",
              }}
              stroke={1.5}
            />
          }>
          {desktopOpened ? (
            <Text fw={500} size="sm">
              {item.label}
            </Text>
          ) : null}
        </Button>
      );
    }
    return (
      <Button
        size="md"
        justify="flex-start"
        fullWidth={desktopOpened}
        key={item.label}
        variant="subtle"
        onClick={() => {
          setActive(item.label);
          navigate({
            pathname: item.link,
            search: "",
          });
        }}
        leftSection={
          <item.icon
            style={{
              color: "var(--mantine-color-blue)",
              marginRight: "var(--mantine-spacing-sm)",
              width: "rem(25px)",
              height: "rem(25px)",
            }}
            stroke={1.5}
          />
        }>
        {desktopOpened ? (
          <Text fw={500} size="sm">
            {item.label}
          </Text>
        ) : null}
      </Button>
    );
  });

  return (
    <Flex
      direction="column"
      justify="space-between"
      h="100%"
      style={{
        ...style,
      }}>
      <AppShell.Section pr="var(--mantine-spacing-md)" p="md">
        <CompanyAndUserHeader
          user={userProfile}
          mobileOpened={mobileOpened}
          desktopOpened={desktopOpened}
          toggleMobile={toggleMobile}
          toggleDesktop={toggleDesktop}
        />
      </AppShell.Section>
      <AppShell.Section grow my="md" component={ScrollArea}>
        <Stack px="md" gap={0}>
          {links}
        </Stack>
      </AppShell.Section>
    </Flex>
  );
};

export default AdminNavbar;
