import React, { useEffect, useState } from "react";

import { useUser } from "utils/UserContext";
import { Anchor, Avatar, Badge, Center, Flex, Image, Text, Stack } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";

import { CreatorInfo } from "components/creator_lists/CreatorListsUtils";
import Spacer from "components/Spacer";
import { useElementSize } from "@mantine/hooks";

import tiktok_icon from "social/tiktok/assets/tiktok_auth_button_icon.svg";
import instagram_icon from "social/fb/assets/simple_bw_instagram_icon.svg";
import youtube_icon from "social/google/assets/youtube_black_white_icon.svg";

const PlatformDetails = ({
  platform,
  platformInfo,
}: {
  platform: string;
  platformInfo: CreatorInfo;
}) => {
  let platformImage = null;
  switch (platform) {
    case "tiktok":
      platformImage = tiktok_icon;
      break;
    case "youtube":
      platformImage = youtube_icon;
      break;
    case "instagram":
      platformImage = instagram_icon;
      break;
    default:
      platformImage = null;
  }
  return (
    <Flex direction="row" gap={0} align="end">
      <Center w="20px" h="20px">
        <Image src={platformImage} style={{ width: 20, height: 20 }} />
      </Center>
      <Spacer width={8} />
      <Anchor href={platformInfo.profile_link} target="_blank" c="black">
        <Flex align="end" gap={4}>
          <Text
            span
            style={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "155%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}>
            {platformInfo.handle}
          </Text>
          <IconExternalLink size="1.2rem" stroke={2} />
        </Flex>
      </Anchor>
    </Flex>
  );
};

const CreatorHeader = ({
  archetype,
  price,
  creatorInfo,
  includeLinkToProfile = false,
  creatorId = null,
  creatorSetControls,
  platform = null,
}: {
  archetype: string | React.ReactNode | null;
  price?: number | null;
  creatorInfo: CreatorInfo;
  includeLinkToProfile?: boolean;
  creatorId?: number | null;
  creatorSetControls?: React.ReactNode;
  platform?: string | null;
}) => {
  let archetypeComp = null;
  if (archetype) {
    archetypeComp = archetype;
  }
  if (typeof archetype === "string" && archetype !== "") {
    archetypeComp = (
      <Badge variant="light" color="gray">
        {archetype}
      </Badge>
    );
  }
  const [userProfile, userProfileLoading] = useUser();
  const { ref: headerRef, width: headerWidth } = useElementSize();
  const { ref: descriptionRef, height: descriptionHeight } = useElementSize();
  // whether or not the description will run over 1 line
  const [canDescriptionBeExpanded, setCanDescriptionBeExpanded] = useState(false);
  // whether or not user has explicitly decided to expand entire description
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(false);

  const isStaff = userProfile?.is_staff;

  useEffect(() => {
    const descriptionScrollHeight = descriptionRef?.current?.scrollHeight || 0;
    const descriptionClientHeight = descriptionRef?.current?.clientHeight || 0;
    if (descriptionClientHeight === 0 || descriptionScrollHeight === 0) {
      setCanDescriptionBeExpanded(false);
      return;
    }
    if (descriptionScrollHeight / descriptionClientHeight > 1) {
      setCanDescriptionBeExpanded(true);
    } else {
      setCanDescriptionBeExpanded(false);
    }
  }, [headerWidth, descriptionHeight]);

  return (
    <Flex gap={0} ref={headerRef}>
      {includeLinkToProfile && isStaff && creatorId ? (
        <Avatar
          component="a"
          src={creatorInfo.avatar_url}
          href={`/admin/creator?creatorId=${creatorId}`}
          alt={creatorInfo.title}
          size="lg"
        />
      ) : (
        <Avatar src={creatorInfo.avatar_url} alt={creatorInfo.title} size="lg" />
      )}
      <Spacer width={12} />
      <Flex direction="column" style={{ flexGrow: 1 }}>
        <Flex justify="space-between">
          <Flex direction="column" style={{ flexGrow: 1 }}>
            <Flex justify="space-between">
              {includeLinkToProfile && isStaff && creatorId ? (
                <Anchor
                  c="black"
                  underline="hover"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "155%",
                  }}
                  href={`/admin/creator?creatorId=${creatorId}`}>
                  {creatorInfo.title}
                </Anchor>
              ) : (
                <Text
                  lineClamp={1}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "155%",
                  }}>
                  {creatorInfo.title}
                </Text>
              )}
            </Flex>
            <Text
              c="var(--mantine-color-gray-6)"
              lineClamp={1}
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "155%",
              }}>
              {creatorInfo.country}
            </Text>
          </Flex>
          {creatorSetControls}
        </Flex>
        <Flex gap={0} direction="row" w={creatorSetControls ? "90%" : "100%"}>
          <Text
            ref={descriptionRef}
            c="var(--mantine-color-gray-9)"
            lineClamp={!descriptionIsExpanded ? 1 : undefined}
            style={{
              fontSize: "12px",
              lineHeight: "155%",
              wordBreak: descriptionIsExpanded ? "break-word" : "break-all",
            }}>
            {creatorInfo.description}
            {descriptionIsExpanded ? (
              <>
                &nbsp;
                <Anchor
                  style={{
                    fontSize: "12px",
                    lineHeight: "155%",
                  }}
                  onClick={() => setDescriptionIsExpanded(false)}>
                  See less
                </Anchor>
              </>
            ) : null}
          </Text>
          {canDescriptionBeExpanded && !descriptionIsExpanded ? (
            <>
              <Spacer width={2} />
              <Anchor
                style={{
                  fontSize: "12px",
                  lineHeight: "155%",
                  whiteSpace: "nowrap",
                }}
                onClick={() => setDescriptionIsExpanded(true)}>
                See more
              </Anchor>
            </>
          ) : null}
        </Flex>
      </Flex>
      <Stack align="end" gap="xs">
        {archetypeComp}
        {price ? (
          <Badge variant="light" color="blue">
            ${price / 100}
          </Badge>
        ) : null}
      </Stack>
      {platform && <PlatformDetails platform={platform} platformInfo={creatorInfo} />}
    </Flex>
  );
};

export default CreatorHeader;
