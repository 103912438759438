import { TaskStage } from "components/contracts/tasks/models/Common";

export const getTaskTypeLabel = (taskType: TaskStage) => {
  if (taskType === TaskStage.CONTRACT_REVIEW) {
    return "Contract Review";
  } else if (taskType === TaskStage.PRODUCT_ACCESS) {
    return "Product Access";
  } else if (taskType === TaskStage.CODES_LINKS) {
    return "Codes & Links";
  } else if (taskType === TaskStage.CONTENT_PRODUCTION) {
    return "Content Production";
  } else if (taskType === TaskStage.LIVE_VERIFICATION) {
    return "Live Verification";
  }
  return taskType;
};

export default null;
