import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Text,
  TextField,
  Link,
  Heading,
  Spinner,
  Datapoint,
  Box,
  Dropdown,
  SelectList,
} from "gestalt";
import { Anchor, Stack } from "@mantine/core";
import "gestalt/dist/gestalt.css";
import { useNavigate } from "react-router-dom";
import { signInWithCustomToken, User as FirebaseUser } from "firebase/auth";

import { auth } from "auth/firebaseAuthHelpers";
import { handleResult } from "utils/ApiUtils";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import {
  ADMIN_URL,
  fetchCampaignData,
  getCampaignSelectList,
  addCreatorToCreatorOutreachReq,
} from "admin/AdminUtils";
import { User } from "models/User";
import { useUser } from "utils/UserContext";
import Spacer from "components/Spacer";
import { getAdminUser } from "admin/AdminLayout";

interface SummaryStatsResponse {
  registered_users: number;
  num_ig_profiles: number;
  num_youtube_profiles: number;
  num_tiktok_profiles: number;
}

const deleteTestUsers = async (user: FirebaseUser, setDeleted: (deletedCount: string) => void) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${ADMIN_URL}delete_test_users`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const result = await handleResult(request);
};

export const loginAsUser = async (
  user: FirebaseUser,
  username: string,
  updateUser: (newProps: Partial<User>) => void,
  navigate: (input: string) => void,
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${ADMIN_URL}login_as_user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      requested_username: username,
    }),
  });
  const result = await handleResult(request);
  const firebaseIdToken = await signInWithCustomToken(auth, result.customToken);
  if (firebaseIdToken) {
    navigate(`/${result.username}`);
  }
};

const disableChannel = async (user: FirebaseUser, channelId: string, reason: string) => {
  if (reason !== "is_not_creator" && reason !== "low_quality") {
    console.log(`invalid reason ${reason}`);
    return;
  }
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${ADMIN_URL}disable_channel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      channel_id: channelId,
      reason,
    }),
  });
  const result = await handleResult(request);
};

const DeleteTestUsersButton = ({ user }: { user: FirebaseUser }) => {
  const [deletedText, setDeleted] = useState(null);

  return (
    <Flex gap={4} alignItems="center">
      <Button
        text="Delete Test Users"
        size="md"
        onClick={() => deleteTestUsers(user, setDeleted)}
      />
      {deletedText && <Text>{deletedText}</Text>}
    </Flex>
  );
};

const LoginAsUserForm = ({ user }: { user: FirebaseUser }) => {
  const [username, setUsername] = useState("");
  const [currentUser, isLoading, updateUser] = useUser();
  const navigate = useNavigate();

  return (
    <Box alignItems="end" direction="row" display="flex">
      <Box width={300}>
        <TextField
          id="login_username"
          onChange={({ value }) => {
            setUsername(value);
          }}
          label="Login Username"
          size="lg"
        />
      </Box>
      <Spacer width={16} />
      <Box paddingY={1}>
        <Button
          text="Login As User"
          color="blue"
          onClick={() => {
            loginAsUser(user, username, updateUser, navigate);
          }}
        />
      </Box>
    </Box>
  );
};

const getSummaryStats = async (
  user: FirebaseUser,
  setStats: (summaryStats: SummaryStatsResponse) => void,
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${ADMIN_URL}overall_user_stats`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const summaryStats: SummaryStatsResponse = await handleResult(request);
  setStats(summaryStats);
};

const SummaryStats = ({ user }: { user: FirebaseUser }) => {
  const [summaryStats, setSummaryStats] = useState(null);

  useEffect(() => {
    getSummaryStats(user, setSummaryStats);
  }, []);

  const statsView = summaryStats ? (
    <Flex flex="grow" justifyContent="center" gap={12}>
      <Datapoint
        title="Registered Users"
        value={getAbbreviatedNumber(summaryStats.registered_users)}
      />
      <Datapoint
        title="TikTok Profiles"
        value={getAbbreviatedNumber(summaryStats.num_tiktok_profiles)}
      />
      <Datapoint
        title="YouTube Profiles"
        value={getAbbreviatedNumber(summaryStats.num_youtube_profiles)}
      />
      <Datapoint
        title="Instagram Profiles"
        value={getAbbreviatedNumber(summaryStats.num_ig_profiles)}
      />
    </Flex>
  ) : (
    <Spinner show accessibilityLabel="Summary Stats Loading" />
  );
  return (
    <Flex direction="column" gap={4}>
      <Heading size="400">Overall Stats</Heading>
      <Link href="/admin/user_table"> View All Users</Link>
      {statsView}
    </Flex>
  );
};

const Links = () => (
  <Flex direction="column">
    <Heading size="400">Links</Heading>
    <Stack>
      <Anchor href="/admin/campaigns">Campaign Settings</Anchor>
      <Anchor href="/admin/brands">Brand Settings</Anchor>
      <Anchor href="/admin/dashboard">Internal Dashboards</Anchor>
    </Stack>
    <ul>
      <li>
        <Link href="/admin/user_edit">Edit User</Link>
      </li>
      <li>
        <Link href="/admin/instagram_profiles">Instagram Profiles</Link>
      </li>
      <li>
        <Link href="/admin/related_creators/backend_logs">Related Creators Logs</Link>
      </li>
    </ul>
  </Flex>
);

const Utilities = ({ user }: { user: FirebaseUser }) => (
  <Flex direction="column" gap={4}>
    <Heading size="400">Utilities</Heading>
    <DeleteTestUsersButton user={user} />
  </Flex>
);

const LoginAsUser = ({ user }: { user: FirebaseUser }) => (
  <Flex direction="column" gap={4}>
    <Heading size="400">Login As User</Heading>
    <LoginAsUserForm user={user} />
  </Flex>
);

const DisableChannel = ({ user }: { user: FirebaseUser }) => {
  const [currentUser, isLoading, updateUser] = useUser();
  const [channelId, setChannelId] = useState("");
  const [reason, setReason] = useState(null);
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line
  // @ts-ignore
  const onSelect = ({ item }) => setReason(item);
  const anchorRef = React.useRef(null);

  return (
    <Flex direction="column">
      <Heading size="400">Disable Channel</Heading>
      <Box alignItems="end" direction="row" display="flex">
        <Box width={300}>
          <TextField
            id="disable_channel"
            onChange={({ value }) => {
              setChannelId(value);
            }}
            label="Channel ID to disable"
            size="lg"
          />
        </Box>
        <Box>
          <Button
            accessibilityControls="action-variant-dropdown-example"
            accessibilityExpanded={open}
            accessibilityHaspopup
            iconEnd="arrow-down"
            onClick={() => setOpen((prevVal) => !prevVal)}
            ref={anchorRef}
            selected={open}
            size="lg"
            text={reason ? reason.label : "Reason"}
          />
          {open && (
            <Dropdown
              anchor={anchorRef.current}
              id="action-variant-dropdown-example"
              onDismiss={() => setOpen(false)}>
              <Dropdown.Item
                onSelect={onSelect}
                option={{ value: "is_not_creator", label: "Not a creator" }}
                selected={reason}
              />
              <Dropdown.Item
                onSelect={onSelect}
                option={{ value: "low_quality", label: "Low Quality" }}
                selected={reason}
              />
            </Dropdown>
          )}
        </Box>
        <Spacer width={16} />
        <Box paddingY={1}>
          <Button
            text="Disable Channel"
            color="blue"
            onClick={() => {
              disableChannel(user, channelId, reason.value);
            }}
          />
        </Box>
      </Box>
    </Flex>
  );
};

const AddCreatorToCreatorOutreach = ({ user }: { user: FirebaseUser }) => {
  const [campaignData, setCampaignData] = useState({});
  const [campaignId, setCampaignId] = useState(0);
  const [emailAddress, setEmailAddress] = useState("");
  const [contactFirstname, setContactFirstname] = useState("");
  const [profileLink, setProfileLink] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    // make a call using fetchCampaignData and set the
    // campaignData state to be the dictionary in CampaignArchetypeResponse
    fetchCampaignData(abortController).then((data) => {
      if (data?.campaigns != null) {
        setCampaignData(data.campaigns);
      }
    });
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Flex direction="column">
      <Heading size="400">Add Creator for Close outreach</Heading>
      <Box alignItems="end" direction="row" display="flex">
        <Box width={300}>
          <Spacer width={16} />
          <SelectList
            id="campaign_name"
            onChange={({ value }) => {
              // Split the string by commas
              setCampaignId(Number(value));
            }}
            label="Campaign Name"
            size="lg">
            {getCampaignSelectList(campaignData)}
          </SelectList>
          <Spacer width={16} />
          <Box width={300}>
            <TextField
              id="email_address"
              onChange={({ value }) => {
                setEmailAddress(value);
              }}
              label="Email address to outreach"
              size="lg"
            />
          </Box>
          <Spacer width={16} />
          <Box width={300}>
            <TextField
              id="contact_firstname"
              onChange={({ value }) => {
                setContactFirstname(value);
              }}
              label="First name of recipient"
              size="lg"
            />
          </Box>
          <Spacer width={16} />
          <Box width={300}>
            <TextField
              id="profile_link"
              onChange={({ value }) => {
                setProfileLink(value);
              }}
              label="Profile link"
              size="lg"
            />
          </Box>
          <Spacer width={16} />
          <Box paddingY={1}>
            <Button
              text="Add user for creator outreach"
              color="blue"
              onClick={() => {
                addCreatorToCreatorOutreachReq(
                  user,
                  campaignId,
                  emailAddress,
                  contactFirstname,
                  profileLink,
                )
                  .then((_) => {
                    setResponseMessage("Success: creator will be in close later");
                  })
                  .catch((error) => {
                    const parsed = JSON.parse(error.message);
                    setResponseMessage(parsed.result.error);
                  });
              }}
            />
          </Box>
          <Box>
            <Text>{responseMessage}</Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

const Admin = () => {
  const user: FirebaseUser = getAdminUser();
  return (
    <Flex direction="column" gap={12}>
      <Text>Hello 1stCollab Admin!</Text>
      <Links />
      <Utilities user={user} />
      <LoginAsUser user={user} />
      <DisableChannel user={user} />
      <AddCreatorToCreatorOutreach user={user} />
    </Flex>
  );
};

export default Admin;
