import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  CloseButton,
  Combobox,
  Group,
  InputBase,
  Tooltip,
  useCombobox,
} from "@mantine/core";

import { IconSwitchHorizontal } from "@tabler/icons-react";

import { useAppSelector } from "reduxStore/hooks";
import { User } from "models/User";
import { getBrandUsers, loginAsUser } from "admin/api/adminApi";
import { useUser } from "utils/UserContext";

function userTolabel(user: User): string {
  return `${user.name} (${user.email})`;
}

const BrandUserSelector = ({
  users,
  selectedUserId,
  setSelectedUserId,
  placeholder = null,
}: {
  users: User[];
  selectedUserId: string;
  setSelectedUserId: (userId: string) => void;
  placeholder?: string;
}) => {
  const [search, setSearch] = useState("");
  const [cleared, setCleared] = useState(false);

  const userData = users.map((user: User) => ({
    value: user.key,
    label: userTolabel(user),
  }));

  const [userKeyToLabel, setUserKeyToLabel] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (users?.length > 0) {
      const newUserKeyToLabel: { [key: string]: string } = {};
      users.forEach((user: User) => {
        newUserKeyToLabel[user.key] = userTolabel(user);
      });
      setUserKeyToLabel(newUserKeyToLabel);
    }
  }, [users]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const shouldFilterOptions = userData.every((item) => item.label !== search);
  const filteredOptions =
    shouldFilterOptions && search?.length > 0
      ? userData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
      : userData;
  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.value} key={item.label}>
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setSelectedUserId(val);
        setSearch(val ? userKeyToLabel[val] : val);
        combobox.closeDropdown();
      }}>
      <Combobox.Target>
        <InputBase
          rightSection={
            setSelectedUserId !== null ? (
              <CloseButton
                size="sm"
                onMouseDown={(event: { preventDefault: () => void }) => event.preventDefault()}
                onClick={() => {
                  setCleared(true);
                }}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={setSelectedUserId === null ? "none" : "all"}
          value={search}
          onChange={(event: { currentTarget: { value: React.SetStateAction<string> } }) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(selectedUserId ? userKeyToLabel[selectedUserId] : "");
          }}
          placeholder={placeholder || "Select a User"}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export const LoginAsUserSelector = () => {
  const user = useAppSelector((state) => state.me.user);
  const [updateUser] = useUser();

  // We only render this for admin accounts
  if (!user.is_staff) {
    return null;
  }

  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const onSuccess = () => {
    // refresh the page
    window.location.reload();
  };

  // Fetch verified brand users
  useEffect(() => {
    const abortController = new AbortController();
    if (user?.is_staff) {
      getBrandUsers(setUsers, abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [user]);

  return (
    <Group gap="xs">
      <BrandUserSelector
        users={users}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        placeholder="Switch Account"
      />
      <Tooltip label="Switch Account">
        <ActionIcon
          variant="subtle"
          color="gray"
          component="button"
          size={32}
          onClick={() => loginAsUser(selectedUserId, onSuccess)}>
          <IconSwitchHorizontal size="1.5rem" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};

export default LoginAsUserSelector;
