import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { User as FirebaseUser } from "firebase/auth";

import {
  Button,
  Checkbox,
  Container,
  Fieldset,
  Flex,
  Group,
  Highlight,
  Modal,
  NativeSelect,
  NumberInput,
  Paper,
  SegmentedControl,
  Select,
  Stack,
  Switch,
  Table,
  TagsInput,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications, Notifications } from "@mantine/notifications";

import { IconHelp } from "@tabler/icons-react";

import { API_URL } from "configs/Configs";
import { Brand, BrandStatus, getBrand } from "models/Brand";
import { handleResult } from "utils/ApiUtils";

import { getUnlinkedDomains } from "admin/api/linksApi";
import { AdminBrandCampaigns } from "admin/app/brand/AdminBrandCampaigns";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import CustomTagsInput from "admin/CustomTagsInput";
import {
  createCampaignParameterByBrandId,
  fetchCampaignParameterByBrandId,
} from "campaigns/api/fetchCampaignParameters";
import {
  createCreativeBriefByBrandId,
  CreativeBriefSettingsResponse,
  fetchCreativeBriefsByBrandId,
} from "campaigns/api/fetchCreativeBriefs";
import {
  createCreatorSetByBrandId,
  CreatorSetResponse,
  fetchCreatorSetsByBrandId,
} from "campaigns/api/fetchCreatorSets";
import { CreativeBriefTableRow } from "campaigns/main/campaignPage/creative_briefs/CreativeBriefTableRow";
import { CreatorSetTable } from "campaigns/main/campaignPage/creator_sets/CreatorSetTable";
// eslint-disable-next-line import/no-named-as-default
import CampaignParameterTableRow from "components/campaignParameters/CampaignParameterTableRow";
import { CreatorSet } from "components/discovery/Datamodels";
import { CampaignParameterResponse, CampaignParametersV2 } from "models/Campaign";
import { CreativeBrief } from "models/CreativeBrief";
import { ShortDomain } from "models/ShortDomain";

enum NavItem {
  BASIC_INFO = "basic_info",
  CREATIVE_BRIEFS = "creative_briefs",
  CREATOR_SETS = "creator_sets",
  CAMPAIGN_PARAMETERS = "campaign_parameters",
  CAMPAIGNS = "campaigns",
}

export const updateBrandById = async (
  firebaseUser: FirebaseUser,
  brandId: number,
  updates: { [key: string]: any },
  setButtonLoading: Dispatch<SetStateAction<boolean>>,
  close: () => void,
) => {
  setButtonLoading(true);
  const firebaseToken = await firebaseUser.getIdToken();
  // convert update to raw fields
  const translatedUpdates: { [key: string]: any } = {
    id: brandId,
  };

  Object.entries(updates).forEach(([key, value]) => {
    if (key === "displayName") {
      translatedUpdates.display_name = value;
    } else if (key === "brandName") {
      translatedUpdates.brand_name = value;
    } else if (key === "isTestBrand") {
      translatedUpdates.is_test_brand = value;
    } else if (key === "isVerified") {
      translatedUpdates.is_verified = value;
    } else if (key === "companyBlurb") {
      translatedUpdates.company_blurb = value;
    } else if (key === "contactEmail") {
      translatedUpdates.contact_email = value;
    } else if (key === "billingEmail") {
      translatedUpdates.billing_email = value;
    } else if (key === "takeRate") {
      translatedUpdates.take_rate = value;
    } else if (key === "memberEmails") {
      translatedUpdates.member_emails = value;
    } else if (key === "notificationEmails") {
      translatedUpdates.notification_emails = value;
    } else if (key === "companyWebsite") {
      translatedUpdates.company_website = value;
    } else if (key === "status") {
      translatedUpdates.status = value;
    } else if (key === "messageEmailSetting") {
      translatedUpdates.send_message_email = value;
    } else if (key === "conceptReviewEmailSetting") {
      translatedUpdates.send_concept_review_email = value;
    } else if (key === "videoReviewEmailSetting") {
      translatedUpdates.send_video_review_email = value;
    } else if (key === "liveLinkEmailSetting") {
      translatedUpdates.send_live_link_email = value;
    } else if (key === "dailyDigestEmailSetting") {
      translatedUpdates.send_daily_digest_email = value;
    } else if (key === "contractReviewEmailSetting") {
      translatedUpdates.send_contract_review_email = value;
    } else if (key === "notes") {
      translatedUpdates.notes = value;
    } else if (key === "internalSlackChannel") {
      translatedUpdates.internal_slack_channel = value;
    } else if (key === "subdomain") {
      translatedUpdates.shortdomain_id = value;
    }
  });

  const request = new Request(`${API_URL}/api/models/brands/${brandId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(translatedUpdates),
  });
  try {
    const response = await handleResult(request);
    if (response != null && response?.message != null) {
      notifications.show({
        color: "red",
        title: "Error with updating the brand!",
        message: `${response.message}`,
        autoClose: false,
      });
    }
    if (response != null && response.id != null) {
      notifications.show({
        title: "Brand updated successfully!",
        message: "Brand updated.",
        autoClose: false,
      });
    } else {
      notifications.show({
        color: "red",
        title: "Error with updating the brand!",
        message: "Unknown error.",
        autoClose: false,
      });
    }
  } catch (e) {
    notifications.show({
      color: "red",
      title: "Error with updating the brand!",
      message: e.message,
      autoClose: false,
    });
  } finally {
    setButtonLoading(false);
    close();
  }
};

function BrandChangeConfirmation({
  firebaseUser,
  brandId,
  formValues,
  initialValues,
  formatValue,
  onSubmit,
  close,
}: {
  firebaseUser: FirebaseUser;
  brandId: number;
  formValues: { [key: string]: any };
  initialValues: { [key: string]: any };
  formatValue: (key: string, value: any) => string;
  onSubmit: (
    firebaseUser: FirebaseUser,
    brandId: number,
    updates: { [key: string]: any },
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    close: () => void,
  ) => void;
  close: () => void;
}) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const updates: { [key: string]: any } = {
    id: brandId,
  };

  const valuesEqual = (key: string, initialValue: any, value: any) => {
    if (key === "memberEmails") {
      return initialValue.join(", ") === value.join(", ");
    }
    if (key === "notificationEmails") {
      return initialValue.join(", ") === value.join(", ");
    }
    return initialValue === value;
  };

  const diffs: JSX.Element[] = [];
  Object.entries(formValues).forEach(([key, value]) => {
    if (!valuesEqual(key, initialValues[key], value)) {
      diffs.push(
        <Table.Tr key={`field-changed-${key}`}>
          <Table.Td>{key}</Table.Td>
          <Table.Td>{formatValue(key, initialValues[key])}</Table.Td>
          <Table.Td>{formatValue(key, value)}</Table.Td>
        </Table.Tr>,
      );
      updates[key] = value;
    }
  });

  return (
    <Stack>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Field Name</Table.Th>
            <Table.Th>Previous Value</Table.Th>
            <Table.Th>Updated Value</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{diffs}</Table.Tbody>
      </Table>
      <Flex justify="center">
        <Button
          loading={buttonLoading}
          onClick={() => onSubmit(firebaseUser, brandId, updates, setButtonLoading, close)}>
          Submit
        </Button>
      </Flex>
    </Stack>
  );
}

function AdminBrandBasicInfoItems({
  user,
  brand,
  shortDomains,
  readOnly = false,
}: {
  user: FirebaseUser;
  brand: Brand;
  shortDomains: ShortDomain[];
  readOnly?: boolean;
}) {
  if (!brand) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);

  const shortDomainData = shortDomains
    .filter(
      (shortDomain: ShortDomain) =>
        shortDomain.brand_id == null || shortDomain.brand_id === brand.id,
    )
    .map((shortDomain: ShortDomain) => ({
      label: shortDomain.domain,
      value: shortDomain.id.toString(),
    }));

  const initialObj = {
    displayName: brand != null ? brand?.display_name : "",
    brandName: brand != null ? brand?.brand_name : "",
    isTestBrand: brand != null ? brand?.is_test_brand : false,
    isVerified: brand != null ? brand?.is_verified : false,
    companyBlurb: brand != null ? brand?.company_blurb : "",
    contactEmail: brand != null ? brand?.contact_email : "",
    billingEmail: brand != null ? brand?.billing_email : "",
    takeRate: brand != null ? brand?.take_rate : 0.2,
    companyWebsite: brand != null ? brand?.company_website : "",
    notes: brand?.notes || "",
    memberEmails: brand != null ? brand?.member_emails : [],
    // eslint-disable-next-line no-nested-ternary
    notificationEmails:
      brand != null && brand?.notification_emails != null ? brand?.notification_emails : [],
    status: brand != null ? brand?.status : BrandStatus.Unknown.toString(),
    messageEmailSetting: brand != null ? brand?.send_message_email : false,
    conceptReviewEmailSetting: brand != null ? brand?.send_concept_review_email : false,
    videoReviewEmailSetting: brand != null ? brand?.send_video_review_email : false,
    liveLinkEmailSetting: brand != null ? brand?.send_live_link_email : false,
    dailyDigestEmailSetting: brand != null ? brand?.send_daily_digest_email : false,
    contractReviewEmailSetting: brand != null ? brand?.send_contract_review_email : false,
    subdomain: brand?.shortdomain ? brand?.shortdomain.id.toString() : null,
    internalSlackChannel: brand?.internal_slack_channel || "",
    outreachEmailAddress: brand?.outreach_email_address || "",
    outreachEmailConfigured: brand?.outreach_email_configured || false,
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const form = useForm({
    initialValues: initialObj,
  });

  useEffect(() => {
    const updatedObj = {
      displayName: brand != null ? brand?.display_name : "",
      brandName: brand != null ? brand?.brand_name : "",
      isTestBrand: brand != null ? brand?.is_test_brand : false,
      isVerified: brand != null ? brand?.is_verified : false,
      companyBlurb: brand != null ? brand?.company_blurb : "",
      contactEmail: brand != null ? brand?.contact_email : "",
      billingEmail: brand != null ? brand?.billing_email : "",
      takeRate: brand != null ? brand?.take_rate : 0.2,
      companyWebsite: brand != null ? brand?.company_website : "",
      notes: brand?.notes || "",
      memberEmails: brand != null ? brand?.member_emails : [],
      notificationEmails:
        brand != null && brand?.notification_emails != null ? brand?.notification_emails : [],
      status: brand != null ? brand?.status : BrandStatus.Unknown.toString(),
      messageEmailSetting: brand != null ? brand?.send_message_email : false,
      conceptReviewEmailSetting: brand != null ? brand?.send_concept_review_email : false,
      videoReviewEmailSetting: brand != null ? brand?.send_video_review_email : false,
      liveLinkEmailSetting: brand != null ? brand?.send_live_link_email : false,
      dailyDigestEmailSetting: brand != null ? brand?.send_daily_digest_email : false,
      contractReviewEmailSetting: brand != null ? brand?.send_contract_review_email : false,
      subdomain: brand?.shortdomain ? brand?.shortdomain.id.toString() : null,
      internalSlackChannel: brand?.internal_slack_channel || "",
      outreachEmailAddress: brand?.outreach_email_address || "",
      outreachEmailConfigured: brand?.outreach_email_configured || false,
    };
    setInitialValues(updatedObj);
    form.setValues(updatedObj);
    form.resetDirty();
  }, [brand]);

  const validNotificationEmails = (emails: string[]) => {
    if (emails.length === 0) {
      return true;
    }
    const { memberEmails } = form.values;
    return emails.every((email: string) => memberEmails.includes(email));
  };
  const validSlackChannel = (channel: string) => {
    return channel === "" || channel.startsWith("#");
  };
  const formatValue = (key: string, value: any) => {
    if (typeof value === "boolean") {
      return value.toString();
    }
    if (key === "memberEmails") {
      return value.join(", ");
    } else if (key === "subdomain") {
      return (
        shortDomains.find((shortDomain) => shortDomain.id === parseInt(value, 10))?.domain || "None"
      );
    }
    return value;
  };

  // confirm verification modal
  const openVerifyBrandModal = () =>
    modals.openConfirmModal({
      title: "Please confirm Brand Verification",
      children: (
        <Stack>
          <Text size="sm">Please confirm that you want to verify this Brand.</Text>
          <Highlight
            highlight="DO NOT VERIFY the brand if there is another instance of the brand that is already
              verified.">
            DO NOT VERIFY the brand if there is another instance of the brand that is already
            verified. Instead, we should move all users from this instance to the verified instance.
          </Highlight>
        </Stack>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      // eslint-disable-next-line no-console
      onCancel: () => console.log("Cancel"),
      onConfirm: () => form.setFieldValue("isVerified", true),
    });

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Basic Campaign Info</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <BrandChangeConfirmation
            firebaseUser={user}
            brandId={brand.id}
            formValues={form.values}
            initialValues={initialValues}
            formatValue={formatValue}
            onSubmit={updateBrandById}
            close={close}
          />
        </Stack>
      </Modal>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: "0 0 50%", padding: "6px" }}>
          <Stack gap={12}>
            <Switch
              label="Is Test Brand"
              checked={form.values.isTestBrand}
              {...form.getInputProps("isTestBrand")}
              disabled={readOnly}
            />
            <Group>
              <TextInput
                label={`Brand Display Name (${brand.id})`}
                required
                placeholder="Display Name"
                w={300}
                {...form.getInputProps("displayName")}
                disabled={readOnly}
              />
              <TextInput
                label="Brand Name (internal-only)"
                required
                placeholder="Test brand name"
                w={300}
                {...form.getInputProps("brandName")}
                disabled
              />
            </Group>
            <Switch
              label="Is Verified"
              checked={form.values.isVerified}
              onChange={() => {
                if (!form.values.isVerified) {
                  openVerifyBrandModal();
                } else {
                  form.setFieldValue("isVerified", false);
                }
              }}
              disabled={readOnly}
            />
            <NativeSelect
              w={300}
              label="Status"
              required
              data={Object.keys(BrandStatus)
                .filter((k) => typeof BrandStatus[k as any] === "number")
                .map((key) => ({ label: key, value: BrandStatus[key as any] }))}
              {...form.getInputProps("status")}
              disabled={readOnly}
            />
            <Textarea
              label="Company Blurb"
              autosize
              maw="80%"
              {...form.getInputProps("companyBlurb")}
              disabled={readOnly}
            />
            <TextInput
              w={300}
              label="Company Website"
              required
              placeholder="https://www.1stcollab.com"
              {...form.getInputProps("companyWebsite")}
              disabled={readOnly}
            />
            <TextInput
              w={300}
              label="Outreach Email Address"
              placeholder="1stcollab@1stcollab.com"
              {...form.getInputProps("outreachEmailAddress")}
              disabled={readOnly}
            />
            <Checkbox
              label="Outreach Email Configured"
              checked={form.values.outreachEmailConfigured}
              {...form.getInputProps("outreachEmailConfigured")}
              disabled
            />
            <Textarea
              label="Notes (internal)"
              autosize
              maw="80%"
              {...form.getInputProps("notes")}
              description="Internal notes for the brand"
              disabled={readOnly}
            />
            <Textarea
              label="Internal Slack Channel(internal)"
              autosize
              maw="80%"
              {...form.getInputProps("internalSlackChannel")}
              description="Used to send slack updates to the channel"
              disabled={readOnly}
              error={
                validSlackChannel(form.values.internalSlackChannel)
                  ? null
                  : "Slack Channel Requires prefix with #. Example: #internal-rex"
              }
            />
            <TextInput
              w={100}
              label="Take Rate"
              required
              placeholder="0.0 - 1.0"
              description="0.0 - 1.0"
              {...form.getInputProps("takeRate")}
              disabled={readOnly}
            />
            <Select
              description="1stCollab subdomain (ex: test.1stcollab.com). Currently, this is set internally."
              w={300}
              label="Subdomain"
              data={shortDomainData}
              {...form.getInputProps("subdomain")}
              disabled={readOnly}
            />
          </Stack>
        </div>
        <div style={{ flex: "0 0 50%", padding: "6px" }}>
          <Stack gap={12}>
            <TextInput
              w={300}
              label="Contact Email"
              required
              placeholder="contact@1stcollab.com"
              {...form.getInputProps("contactEmail")}
              disabled={readOnly}
            />
            <CustomTagsInput
              label="Member Emails"
              // w={400}
              disabled={readOnly}
              splitChars={[",", " "]}
              {...form.getInputProps("memberEmails")}
            />
            <TextInput
              w={300}
              label="Billing Email"
              placeholder="billing@1stcollab.com"
              {...form.getInputProps("billingEmail")}
              disabled={readOnly}
            />
            <TagsInput
              label={
                <Group gap={0}>
                  <Text size="sm" fw={500}>
                    Notification Emails&nbsp;
                  </Text>
                  <Tooltip label="Additional emails for notifications. Contact Email will be included by default.">
                    <IconHelp size="12px" />
                  </Tooltip>
                </Group>
              }
              {...form.getInputProps("notificationEmails")}
              splitChars={[",", " "]}
              error={
                validNotificationEmails(form.values.notificationEmails)
                  ? null
                  : "Notification Emails need to exist in Member Emails"
              }
              disabled={readOnly}
            />

            <Fieldset
              legend={
                <Group gap={0}>
                  <Text size="sm" fw={500}>
                    Email Notification Settings&nbsp;
                  </Text>
                  <Tooltip label="If checked, means we will send the type of message.">
                    <IconHelp size="12px" />
                  </Tooltip>
                </Group>
              }>
              <Switch
                style={{ paddingBottom: "5px" }}
                label="Send Daily digest email"
                checked={form.values.dailyDigestEmailSetting}
                {...form.getInputProps("dailyDigestEmailSetting")}
                disabled={readOnly}
              />
              <Switch
                style={{ paddingBottom: "5px" }}
                label="Send Contract Review email"
                checked={form.values.contractReviewEmailSetting}
                {...form.getInputProps("contractReviewEmailSetting")}
                disabled={readOnly}
              />
              <Switch
                style={{ paddingBottom: "5px" }}
                label="Send Submits a concept for review email"
                checked={form.values.conceptReviewEmailSetting}
                {...form.getInputProps("conceptReviewEmailSetting")}
                disabled={readOnly}
              />
              <Switch
                style={{ paddingBottom: "5px" }}
                label="Send Submits a video for review email"
                checked={form.values.videoReviewEmailSetting}
                {...form.getInputProps("videoReviewEmailSetting")}
                disabled={readOnly}
              />
              <Switch
                style={{ paddingBottom: "5px" }}
                label="Send Submits a live link email"
                checked={form.values.liveLinkEmailSetting}
                {...form.getInputProps("liveLinkEmailSetting")}
                disabled={readOnly}
              />
              <Switch
                label="Send Sends a message email"
                checked={form.values.messageEmailSetting}
                {...form.getInputProps("messageEmailSetting")}
                disabled={readOnly}
              />
            </Fieldset>
          </Stack>
        </div>
      </div>
      <Flex justify="center">
        <Button
          onClick={() => {
            open();
          }}
          disabled={!form.isDirty()}>
          Save
        </Button>
      </Flex>
    </>
  );
}

const AddCreativeBrief = ({
  firebaseUser,
  brandId,
  creativeBriefs,
  setCreativeBriefs,
}: {
  firebaseUser: FirebaseUser;
  brandId: number;
  creativeBriefs: CreativeBrief[];
  setCreativeBriefs: (creativeBriefs: CreativeBrief[]) => void;
}) => {
  const [creativeBriefName, setCreativeBriefName] = useState<string>("");
  return (
    <>
      <Flex direction="column" gap={12}>
        <TextInput
          label="Creative Brief Name"
          required
          placeholder="creative brief name"
          onChange={(event) => {
            setCreativeBriefName(event.currentTarget.value);
          }}
          /* eslint-disable react/jsx-props-no-spreading */
        />
      </Flex>
      <Flex justify="center">
        <Button
          onClick={() => {
            createCreativeBriefByBrandId(
              firebaseUser,
              brandId,
              creativeBriefName,
              new AbortController(),
            ).then((creativeBrief: CreativeBrief) => {
              setCreativeBriefs([...creativeBriefs, creativeBrief]);
            });
          }}>
          Add
        </Button>
      </Flex>
    </>
  );
};

const AddCreatorSet = ({
  firebaseUser,
  brand,
  creatorSets,
  setCreatorSets,
}: {
  firebaseUser: FirebaseUser;
  brand: Brand;
  creatorSets: CreatorSet[];
  setCreatorSets: (creatorSets: CreatorSet[]) => void;
}) => {
  const [creatorSetName, setCreatorSetName] = useState<string>("");
  const setContextCreatorSets = useAdminAppContext()?.setCreatorSets;

  return (
    <>
      <Flex direction="column" gap={12}>
        <TextInput
          w={400}
          label="Creator Set Name"
          required
          placeholder="creator set name"
          onChange={(event) => {
            setCreatorSetName(event.currentTarget.value);
          }}
          /* eslint-disable react/jsx-props-no-spreading */
        />
      </Flex>
      <Flex justify="center">
        <Button
          onClick={() => {
            createCreatorSetByBrandId(firebaseUser, brand.id, creatorSetName).then(
              (creatorSet: CreatorSet) => {
                setCreatorSets([...creatorSets, creatorSet]);
                setContextCreatorSets([...creatorSets, creatorSet]);
              },
            );
          }}>
          Add
        </Button>
      </Flex>
    </>
  );
};

const AddCampaignParameter = ({
  firebaseUser,
  brandId,
  campaignParameters,
  setCampaignParameters,
}: {
  firebaseUser: FirebaseUser;
  brandId: number;
  campaignParameters: CampaignParametersV2[];
  setCampaignParameters: (campaignParameters: CampaignParametersV2[]) => void;
}) => {
  const [campaignParameterName, setCampaignParameterName] = useState<string>("");

  return (
    <>
      <Flex direction="column" gap={12}>
        <TextInput
          w={400}
          label="Campaign Parameter Name"
          required
          placeholder="campaign parameter name"
          onChange={(event) => {
            setCampaignParameterName(event.currentTarget.value);
          }}
          /* eslint-disable react/jsx-props-no-spreading */
        />
      </Flex>
      <Flex justify="center">
        <Button
          onClick={() => {
            createCampaignParameterByBrandId(firebaseUser, brandId, campaignParameterName).then(
              (campaignParameter: CampaignParametersV2) => {
                setCampaignParameters([...campaignParameters, campaignParameter]);
              },
            );
          }}>
          Add
        </Button>
      </Flex>
    </>
  );
};

function AdminCampaignParameterItems({
  firebaseUser,
  brandId,
  readOnly = false,
}: {
  firebaseUser: FirebaseUser;
  brandId: number;
  readOnly?: boolean;
}) {
  // List out all the creativeBriefs - there's no modification here available yet.
  // Fetch creative briefs
  const [campaignParameters, setCampaignParameters] = useState<CampaignParametersV2[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (brandId) {
      fetchCampaignParameterByBrandId(firebaseUser, brandId, abortController).then(
        (campaignParametersResponse: CampaignParameterResponse) => {
          if (campaignParametersResponse) {
            setCampaignParameters(campaignParametersResponse.results);
          }
        },
      );
    }
    return () => {
      abortController.abort();
    };
  }, [brandId]);

  return (
    <Stack gap={12}>
      <Table withTableBorder withColumnBorders>
        <Table.Tr>
          <Table.Th>Id</Table.Th>
          <Table.Th>Name</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
        <Table.Tbody>
          {campaignParameters?.length > 0 &&
            campaignParameters.map((params: CampaignParametersV2) => (
              <CampaignParameterTableRow
                key={`campaign-parameter-${params.id}`}
                campaignParameter={params}
                readOnly={readOnly}
                allowDelete
              />
            ))}
        </Table.Tbody>
      </Table>
      {!readOnly && (
        <AddCampaignParameter
          firebaseUser={firebaseUser}
          brandId={brandId}
          campaignParameters={campaignParameters}
          setCampaignParameters={setCampaignParameters}
        />
      )}
    </Stack>
  );
}

function AdminCreativeBriefItems({
  firebaseUser,
  brandId,
  readOnly = false,
}: {
  firebaseUser: FirebaseUser;
  brandId: number;
  readOnly?: boolean;
}) {
  // List out all the creativeBriefs - there's no modification here available yet.
  // Fetch creative briefs
  const [creativeBriefs, setCreativeBriefs] = useState<CreativeBrief[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (brandId) {
      fetchCreativeBriefsByBrandId(brandId, abortController).then(
        (creativeBriefResponse: CreativeBriefSettingsResponse) => {
          if (creativeBriefResponse) {
            setCreativeBriefs(creativeBriefResponse.results);
          }
        },
      );
    }
    return () => {
      abortController.abort();
    };
  }, [brandId]);

  return (
    <Stack gap={12}>
      <Table withTableBorder withColumnBorders>
        <Table.Tr>
          <Table.Th>Id</Table.Th>
          <Table.Th>Name</Table.Th>
          <Table.Th>Internal URL</Table.Th>
          <Table.Th>External URL</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th w={400}>Linked Ad Groups</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
        <Table.Tbody>
          {creativeBriefs?.length > 0 &&
            creativeBriefs.map((brief: CreativeBrief) => (
              <CreativeBriefTableRow
                key={`creative-brief-${brief.id}`}
                brandId={brandId}
                creativeBrief={brief}
                readOnly={readOnly}
              />
            ))}
        </Table.Tbody>
      </Table>
      {!readOnly && (
        <AddCreativeBrief
          firebaseUser={firebaseUser}
          brandId={brandId}
          creativeBriefs={creativeBriefs}
          setCreativeBriefs={setCreativeBriefs}
        />
      )}
    </Stack>
  );
}

export default function AdminBrandSettings({
  user,
  // TODO(andrew): refactor this so it can take in a brandId or Brand obj
  brandId,
  searchParams,
  setSearchParams,
}: {
  user: FirebaseUser;
  brandId: number;
  searchParams?: URLSearchParams | null;
  setSearchParams?: (searchParams: URLSearchParams) => void | null;
}) {
  const { userProfile } = useAdminAppContext();
  const readOnly = !userProfile.is_staff;
  const [brand, setBrand] = useState<Brand | null>(null);
  const [creatorSets, setCreatorSets] = useState<CreatorSet[]>([]);
  const [unlinkedDomains, setUnlinkedDomains] = useState<ShortDomain[]>([]);

  // Selected NavItem
  const [navItem, setNavItem] = useState(null);

  useEffect(() => {
    // short circuit if no navItem is selected to prevent double fetch
    if (navItem === null) {
      return;
    }
    const abortController = new AbortController();
    getBrand(brandId).then((resp) => {
      if (resp) {
        setBrand(resp);
      }
    });

    fetchCreatorSetsByBrandId(brandId, abortController).then(
      (creatorSetResponse: CreatorSetResponse) => {
        if (creatorSetResponse) {
          setCreatorSets(creatorSetResponse.results);
        }
      },
    );

    getUnlinkedDomains(user, setUnlinkedDomains, abortController);
  }, [user, brandId, navItem]);

  // parse search settings
  useEffect(() => {
    // First check if there are any changes to the searchParams
    const searchNavItem = searchParams.get("navItem");
    if (searchNavItem !== null) {
      if (searchNavItem !== navItem) {
        setNavItem(searchNavItem);
      }
    } else if (searchNavItem === null || searchNavItem === "") {
      // Fallback to basic info
      setNavItem(NavItem.BASIC_INFO.valueOf());
    }
  }, [searchParams]);

  useEffect(() => {
    const currSearchParamNavItem = searchParams.get("navItem");
    let changed = false;
    // update the searchParams
    if (
      navItem === NavItem.BASIC_INFO.valueOf() &&
      // ok to be null
      (currSearchParamNavItem !== null || currSearchParamNavItem !== NavItem.BASIC_INFO.valueOf())
    ) {
      searchParams.set("navItem", NavItem.BASIC_INFO.valueOf());
      changed = true;
    } else if (
      navItem === NavItem.CREATIVE_BRIEFS.valueOf() &&
      currSearchParamNavItem !== NavItem.CREATIVE_BRIEFS.valueOf()
    ) {
      searchParams.set("navItem", NavItem.CREATIVE_BRIEFS.valueOf());
      changed = true;
    } else if (
      navItem === NavItem.CREATOR_SETS.valueOf() &&
      currSearchParamNavItem !== NavItem.CREATOR_SETS.valueOf()
    ) {
      searchParams.set("navItem", NavItem.CREATOR_SETS.valueOf());
      changed = true;
    } else if (
      navItem === NavItem.CAMPAIGN_PARAMETERS.valueOf() &&
      currSearchParamNavItem !== NavItem.CAMPAIGN_PARAMETERS.valueOf()
    ) {
      searchParams.set("navItem", NavItem.CAMPAIGN_PARAMETERS.valueOf());
      changed = true;
    } else if (
      navItem === NavItem.CAMPAIGNS.valueOf() &&
      currSearchParamNavItem !== NavItem.CAMPAIGNS.valueOf()
    ) {
      searchParams.set("navItem", NavItem.CAMPAIGNS.valueOf());
      changed = true;
    }
    if (changed) {
      setSearchParams(searchParams);
    }
  }, [navItem]);

  return (
    <>
      <Notifications />
      <Paper p="md">
        <Container size="s">
          <Stack mb="sm" gap="xs">
            <SegmentedControl
              color="blue"
              value={navItem}
              onChange={(value) => setNavItem(value)}
              data={[
                { label: "Basic Info", value: NavItem.BASIC_INFO.valueOf() },
                { label: "Creator Sets", value: NavItem.CREATOR_SETS.valueOf() },
                { label: "Creative Briefs", value: NavItem.CREATIVE_BRIEFS.valueOf() },
                { label: "Campaign Parameters", value: NavItem.CAMPAIGN_PARAMETERS.valueOf() },
                { label: "Campaigns", value: NavItem.CAMPAIGNS.valueOf() },
              ]}
            />
          </Stack>
        </Container>
        <Stack gap={24}>
          {!navItem ||
            (navItem === NavItem.BASIC_INFO.valueOf() && (
              <AdminBrandBasicInfoItems
                user={user}
                brand={brand}
                shortDomains={unlinkedDomains}
                readOnly={readOnly}
              />
            ))}
          {navItem === NavItem.CREATIVE_BRIEFS.valueOf() && (
            <AdminCreativeBriefItems firebaseUser={user} brandId={brandId} readOnly={readOnly} />
          )}
          {navItem === NavItem.CREATOR_SETS.valueOf() && (
            <Stack gap={8}>
              <CreatorSetTable creatorSets={creatorSets} readOnly={readOnly} />
              {!readOnly && (
                <AddCreatorSet
                  firebaseUser={user}
                  brand={brand}
                  creatorSets={creatorSets}
                  setCreatorSets={setCreatorSets}
                />
              )}
            </Stack>
          )}
          {navItem === NavItem.CAMPAIGN_PARAMETERS.valueOf() && (
            <AdminCampaignParameterItems
              firebaseUser={user}
              brandId={brandId}
              readOnly={readOnly}
            />
          )}
          {navItem === NavItem.CAMPAIGNS.valueOf() && (
            <AdminBrandCampaigns
              user={user}
              brand={brand}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              readOnly={readOnly}
            />
          )}
        </Stack>
      </Paper>
    </>
  );
}
