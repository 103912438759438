import React, { ReactNode } from "react";

import { Box, Flex, Group, Text } from "@mantine/core";

import { SUPPORTED_FORMATS_TO_LABELS } from "components/contracts/common/Common";
import { SupportedFormat, SupportedPlatform } from "models/Common";

import PlatformLogo from "components/contracts/common/PlatformLogo";

export default function DeliverableCardHeader({
  platform,
  format,
  subHeading,
}: {
  platform: SupportedPlatform;
  format: SupportedFormat;
  subHeading: ReactNode;
}) {
  return (
    <Group justify="left" align="flex-start">
      <Flex mt="4px">
        <PlatformLogo platform={platform} />
      </Flex>
      <Box>
        <Text fw="500">{SUPPORTED_FORMATS_TO_LABELS[format]}</Text>
        {subHeading}
      </Box>
    </Group>
  );
}
