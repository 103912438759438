import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "configs/Configs";
import { Campaign, CampaignListResponse } from "models/Campaign";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export interface CampaignsState {
  allCampaigns: { [id: number]: Campaign };
}

const initialState: CampaignsState = {
  allCampaigns: {}, // empty
};

export interface GetCampaignsPayloadAction {
  aborted: boolean;
  campaigns: Campaign[];
}

export interface UpdateCampaignStatusPayloadAction {
  campaignHashId: number;
  newStatus: number;
}

export interface UpdateCurrentBudgetPayloadAction {
  campaignHashId: number;
  newBudget: number;
}

export const getCampaigns = createAsyncThunk(
  "campaigns/fetch",
  async (abortController: AbortController): Promise<GetCampaignsPayloadAction> => {
    const request = await createRequestWithFirebaseToken({
      url: `${API_URL}/api/models/campaigns/`,
    });
    const response: CampaignListResponse = await handleResult(request, abortController);
    if (response == null) {
      return {
        aborted: abortController.signal.aborted,
        campaigns: [],
      };
    }
    return {
      aborted: false,
      campaigns: response.results,
    };
  },
);

const updateStatusForCampaign = (state: CampaignsState, campaignHashId: number, newStatus: number) => {
  const { allCampaigns } = state;
  const campaign = allCampaigns[campaignHashId];
  if (campaign != null) {
    campaign.status = newStatus;
  }
};

const updateCurrentBudgetForCampaign = (state: CampaignsState, campaignHashId: number, newBudget: number) => {
  const { allCampaigns } = state;
  const campaign = allCampaigns[campaignHashId];
  if (campaign != null) {
    campaign.current_budget = newBudget;
  }
};

/* eslint-disable no-param-reassign */
const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    updateCampaignStatus: (state, { payload }: { payload: UpdateCampaignStatusPayloadAction }) => {
      updateStatusForCampaign(state, payload.campaignHashId, payload.newStatus);
    },
    updateCurrentBudget: (state, { payload }: { payload: UpdateCurrentBudgetPayloadAction }) => {
      updateCurrentBudgetForCampaign(state, payload.campaignHashId, payload.newBudget);
    }
  },
  extraReducers(builder) {
    builder.addCase("me/logout", (state) => {
      return initialState;
    });
    builder.addCase(getCampaigns.fulfilled, (state, { payload }) => {
      if (!payload.aborted) {
        const newCampaigns = Object.fromEntries(
          payload.campaigns.map((campaign) => [campaign.hash_id, campaign]),
        );
        const existingCampaigns = state.allCampaigns;
        state.allCampaigns = { ...existingCampaigns, ...newCampaigns };
      }
    });
  },
});
/* eslint-enable no-param-reassign */

export const {
  updateCampaignStatus,
  updateCurrentBudget,
} = campaignsSlice.actions;
export default campaignsSlice.reducer;
