import React from "react";
import { Card } from "@mantine/core";
import { CreatorDetails } from "components/discovery/Datamodels";
import { getCreatorInfoFromDetails } from "components/creator_lists/CreatorListsUtils";
import Spacer from "components/Spacer";
import CreatorHeader from "components/discovery/search/CreatorHeader";
import PlatformTable from "components/creator_lists/unified_creator_rep/PlatformTable";
import { MediaEntry } from "components/MediaBlockCard";
import RelevantVideos from "components/creator_lists/unified_creator_rep/RelevantVideos";

const UnifiedCreatorRep = ({
  archetype = null,
  price = null,
  creator,
  defaultExpandedPlatforms,
  useLiveMedia = false,
  actionRow = null,
  useScrollOverflow = true,
  creatorSetControls,
  relevantVideos,
  relevanceHashtag,
  refetchThumbnails = false,
  disableShadow,
}: {
  archetype?: string | React.ReactNode | null;
  price?: number | null;
  creator: CreatorDetails;
  defaultExpandedPlatforms: string[];
  useLiveMedia?: boolean;
  actionRow?: React.ReactNode;
  useScrollOverflow?: boolean;
  creatorSetControls?: React.ReactNode;
  relevantVideos?: MediaEntry[];
  relevanceHashtag?: string;
  refetchThumbnails?: boolean;
  disableShadow?: boolean;
}) => {
  // TODO (victoria): EW this is so gross
  // for each field, we try to populate that information in order of platform priority
  const allPlatormCreatorDetails = (creator.platform_priority ?? ["all"]).map((platform) =>
    getCreatorInfoFromDetails(creator, platform),
  );
  const creatorFromDetails = {
    profile_link: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.profile_link)
      ?.profile_link,
    handle: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.handle)?.handle,
    avatar_url: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.avatar_url)
      ?.avatar_url,
    num_subscribers: allPlatormCreatorDetails.find(
      (platformDetails) => platformDetails?.num_subscribers,
    )?.num_subscribers,
    title: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.title)?.title,
    country: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.country)?.country,
    views_med: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.views_med)
      ?.views_med,
    engagement_rate: allPlatormCreatorDetails.find(
      (platformDetails) => platformDetails?.engagement_rate,
    )?.engagement_rate,
    comment_rate: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.comment_rate)
      ?.comment_rate,
    comments_med: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.comments_med)
      ?.comments_med,
    likes_med: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.likes_med)
      ?.likes_med,
    total_vids: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.total_vids)
      ?.total_vids,
    all_time_videos: allPlatormCreatorDetails.find(
      (platformDetails) => platformDetails?.all_time_videos,
    )?.all_time_videos,
    vibe_score: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.vibe_score)
      ?.vibe_score,
    videos: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.videos)?.videos,
    description: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.description)
      ?.description,
  };

  const overflowStyle = useScrollOverflow ? { overflow: "scroll" } : {};
  const unifiedActionCard = useScrollOverflow ? (
    <Card.Section bg="var(--mantine-color-gray-1)" h="60px">
      {actionRow}
    </Card.Section>
  ) : (
    actionRow
  );

  return (
    <Card
      shadow={disableShadow ? "none" : "sm"}
      padding="lg"
      radius="md"
      withBorder
      style={overflowStyle}>
      <CreatorHeader
        creatorId={creator.creator_id}
        creatorInfo={creatorFromDetails}
        archetype={archetype}
        price={price}
        creatorSetControls={creatorSetControls}
        includeLinkToProfile
      />
      <Spacer height={16} />
      <RelevantVideos
        relevantVideos={relevantVideos}
        hashtag={relevanceHashtag}
        refetchThumbnails={refetchThumbnails}
      />
      <PlatformTable
        creator={creator}
        defaultExpandedPlatforms={defaultExpandedPlatforms}
        useLiveMedia={useLiveMedia}
        refetchThumbnails={refetchThumbnails}
      />
      {actionRow !== null ? unifiedActionCard : null}
    </Card>
  );
};

export default UnifiedCreatorRep;
