import React from "react";

import { Tooltip } from "@mantine/core";

import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutube,
  IconVideo,
} from "@tabler/icons-react";

import { SupportedPlatform } from "models/Common";

const DEFAULT_SIZE = 22;

/**
 * TODO(chris): Deprecate this once UGC becomes an official platform.
 */
export const UgcLogo = ({ size, label }: { size?: number; label?: string }) => {
  const icon = <IconVideo size={size || DEFAULT_SIZE} color="black" />;
  return (
    <Tooltip label={label} disabled={!label}>
      {icon}
    </Tooltip>
  );
};

export default function PlatformLogo({
  platform,
  size,
  label,
}: {
  platform: SupportedPlatform;
  size?: number;
  label?: string;
}) {
  let icon = null;

  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      icon = <IconBrandInstagram size={size || DEFAULT_SIZE} color="black" />;
      break;
    case SupportedPlatform.TIKTOK:
      icon = <IconBrandTiktok size={size || DEFAULT_SIZE} color="black" />;
      break;
    case SupportedPlatform.YOUTUBE:
      icon = <IconBrandYoutube size={size || DEFAULT_SIZE} color="black" />;
      break;
    case SupportedPlatform.NONE:
      icon = <IconVideo size={size || DEFAULT_SIZE} color="black" />;
      break;
    default:
      return null;
  }

  return (
    <Tooltip label={label} disabled={!label}>
      {icon}
    </Tooltip>
  );
}
