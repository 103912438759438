import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import {
  LabelingDatasetItemsResponse,
  RecommendationAttribution,
} from "components/creator_lists/LabelingUtils";

export const LABELING_URL = `${API_URL}/labeling/`;

export const getCreatorSetCandidates = async (
  creatorSetId: number,
  abortController: AbortController,
) => {
  const params = new URLSearchParams();
  params.append("creatorSetId", creatorSetId.toString());
  const labelingItemEndpoint = `${LABELING_URL}creator_set_candidates/?${params.toString()}`;
  const request = await createRequestWithFirebaseToken({
    url: labelingItemEndpoint,
    method: "GET",
  });

  const response: LabelingDatasetItemsResponse = await handleResult(request, abortController);
  return response;
};

export const filterCreatorSetCandidates = async (
  creatorSetId: number,
  creatorIds: number[],
  abortController: AbortController,
) => {
  const endpoint = `${LABELING_URL}filter_creator_set_candidates/`;
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "POST",
    body: JSON.stringify({
      creatorIds,
      creatorSetId,
    }),
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const markCreatorIsEnglish = async (creatorId: number, isEnglish: boolean) => {
  const endpoint = `${LABELING_URL}is_english/`;
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "POST",
    body: JSON.stringify({
      creatorId,
      isEnglish,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const markIsCreator = async (creatorId: number, isCreator: boolean) => {
  const endpoint = `${LABELING_URL}is_creator/`;
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "POST",
    body: JSON.stringify({
      creatorId,
      isCreator,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const opsLabelCreator = async (
  creatorId: number,
  creatorSetId: number,
  reviewState: number,
  platform: string,
  attribution: RecommendationAttribution[],
) => {
  const jsonParams: any = {};
  jsonParams.creatorId = creatorId;
  jsonParams.platform = platform;
  jsonParams.reviewState = reviewState;
  jsonParams.creatorSetId = creatorSetId;
  // TODO(andrew): fix this and pass it through
  jsonParams.searchStrategy = "tiktok_precrawled_blended_hashtags";
  if (attribution) {
    // Convert attribution into a json map
    jsonParams.attribution = attribution.map((att) => ({
      source: att.source,
      score: att.score,
      type: att.type,
    }));
  }
  const request = await createRequestWithFirebaseToken({
    url: `${LABELING_URL}label_brand_approve_creator/`,
    method: "POST",
    body: JSON.stringify(jsonParams),
  });
  const response = await handleResult(request);
  return response;
};

export const reportIssue = async (creatorId: number, creatorSetId: number, issue: string) => {
  const endpoint = `${LABELING_URL}report_issue/`;
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "POST",
    body: JSON.stringify({
      creatorId,
      creatorSetId,
      issue,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const getHistory = async (
  abortController: AbortController,
  creatorSetId?: number,
  dateRange?: [Date | null, Date | null],
) => {
  const params = new URLSearchParams();
  if (creatorSetId) {
    params.append("creatorSetId", creatorSetId.toString());
  }
  if (dateRange) {
    if (dateRange[0]) {
      params.append("startDate", dateRange[0].toISOString());
    }
    if (dateRange[1]) {
      params.append("endDate", dateRange[1].toISOString());
    }
  }
  const endpoint = `${LABELING_URL}history/?${params.toString()}`;
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

export default null;
