import React, { useState } from "react";

import { Anchor, Button, Flex, Group, Stack, Text, Textarea } from "@mantine/core";

import { IconFlag } from "@tabler/icons-react";

import { reportIssue } from "admin/api/labeling/sourcingApi";

export const AdminCreatorLabelingReportIssue = ({
  creatorId,
  creatorSetId,
  getNextCandidate,
}: {
  creatorId: number;
  creatorSetId: number;
  getNextCandidate: () => void;
}) => {
  const [issue, setIssue] = useState("");
  const [showIssueInput, setShowIssueInput] = useState(false);

  return (
    <Stack align="center" gap={showIssueInput ? "xs" : 0}>
      <Textarea
        placeholder="Report an issue"
        value={issue}
        onChange={(e) => setIssue(e.target.value)}
        h={showIssueInput ? "auto" : 0}
        w="100%"
        styles={{
          root: {
            visibility: showIssueInput ? "visible" : "hidden",
          },
        }}
      />
      <Group
        h={showIssueInput ? "auto" : 0}
        styles={{
          root: {
            visibility: showIssueInput ? "visible" : "hidden",
          },
        }}>
        <Button
          variant="subtle"
          size="xs"
          color="gray"
          onClick={() => setShowIssueInput(false)}
          hidden={!showIssueInput}>
          Cancel
        </Button>
        <Button
          variant="outline"
          size="xs"
          color="gray"
          onClick={() => {
            reportIssue(creatorId, creatorSetId, issue);
            getNextCandidate();
            setShowIssueInput(false);
          }}
          hidden={!showIssueInput}>
          Report and Skip Creator
        </Button>
      </Group>
      <Anchor onClick={() => setShowIssueInput(true)} c="gray" size="xs" hidden={showIssueInput}>
        <Flex align="center" gap="xs">
          <IconFlag size={14} />
          <Text span>Report an Issue</Text>
        </Flex>
      </Anchor>
    </Stack>
  );
};

export default AdminCreatorLabelingReportIssue;
