import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Box, Flex, Heading, Text, Divider, Spinner } from "gestalt";
import "gestalt/dist/gestalt.css";

import { AuthContext } from "auth/AuthContext";
import {
  getInstagramProfileInsights,
  InstagramProfileResponse,
  INSTAGRAM_PROFILE_URL,
} from "social/fb/InstagramUtils";
import {
  YoutubeProfileResponse,
  getYoutubeProfileInsights,
  YOUTUBE_PROFILE_URL,
} from "social/google/YoutubeUtils";
import {
  getTiktokProfileInsights,
  TikTokProfileResponse,
  TIKTOK_PROFILE_URL,
} from "social/tiktok/TikTokUtils";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
// import {
//   normalizeAgeAudienceEntryResponse,
//   normalizeCountryAudienceEntryResponse,
//   normalizeGenderAudienceEntryResponse,
// } from "pages/profile/AudienceUtils";
import {
  AudienceResponse,
  getLatestMediaUrl,
  PlatformInsightsResponse,
  StatsEntryResponse,
} from "social/utils";
import StatsCard from "components/StatsCard";
import FlatModule from "components/FlatModule";
import { BrandIcons, Platforms, PLATFORM_TO_PLATFORM_NAME } from "components/BrandIcons";
import {
  MediaBlockCard,
  MediaEntry,
  PlatformConstants,
  PLATFORM_INFO,
  PLATFORM_INFO_MAP,
} from "components/MediaBlockCard";
import { isMobile } from "react-device-detect";
import { useOutletContext } from "react-router-dom";
import { User } from "firebase/auth";
import { getFirstProfileByKey, getProfileResponse } from "social/ApiUtils";
import DemographicsCard, { InfoCard } from "pages/profile/DemographicsCard";

export interface MetricGrouping {
  group: string;
  period: string;
  metrics: string[];
  // If any of these metrics here are 0, we will not show the metric grouping.
  zero_metrics: string[];
}

const isValidStats = (platformInsights: PlatformInsightsResponse) =>
  platformInsights && platformInsights?.stats && platformInsights?.stats.length > 0;

export const calculateMaxWidthBlockCard = (platformConstants: PlatformConstants[]) => {
  const maxWidths = platformConstants.map((p) => p.numVideosDisplayLimit * (p.iframeWidth + 20));
  return Math.max(...maxWidths);
};

const getAndSetMedia = async (
  requestUser: User,
  platformName: string,
  profile: InstagramProfileResponse | YoutubeProfileResponse | TikTokProfileResponse,
  base_profile_url: string,
  setMediaResponse: (media: MediaEntry[]) => void,
) => {
  const firebaseToken = await requestUser?.getIdToken();
  // Youtube no longer uses socialUsername
  if (platformName === "youtube" && profile !== null) {
    const youtubeProfile = profile as YoutubeProfileResponse;
    const medias =
      youtubeProfile && youtubeProfile.channel_id
        ? await getProfileResponse(
            firebaseToken,
            getLatestMediaUrl(base_profile_url, youtubeProfile.user),
          )
        : null;
    setMediaResponse(medias);
  } else {
    const medias =
      profile && profile.socialUsername
        ? await getProfileResponse(
            firebaseToken,
            getLatestMediaUrl(base_profile_url, profile.socialUsername),
          )
        : null;
    setMediaResponse(medias);
  }
};

const urlForPlatform = (platformName: string) => {
  switch (platformName) {
    case "instagram":
      return INSTAGRAM_PROFILE_URL;
    case "youtube":
      return YOUTUBE_PROFILE_URL;
    case "tiktok":
      return TIKTOK_PROFILE_URL;
    default:
      return INSTAGRAM_PROFILE_URL;
  }
};

export const RecentMedia = ({
  projectedWidth,
  mediaTitle,
  platformName,
  socialProfile,
}: {
  projectedWidth: number;
  mediaTitle: string;
  platformName: Platforms;
  socialProfile?: InstagramProfileResponse | YoutubeProfileResponse | TikTokProfileResponse;
}) => {
  const requestUser: User = useOutletContext<AuthContext>()?.user;
  const [media, setMedia] = useState(null);

  useEffect(() => {
    if (socialProfile) {
      getAndSetMedia(
        requestUser,
        platformName,
        socialProfile,
        urlForPlatform(platformName),
        setMedia,
      );
    }
  }, [socialProfile]);

  if (media === null) {
    // media is still loading, so media state is still default init state of null
    return (
      <Flex minWidth={projectedWidth} flex="grow" justifyContent="center">
        <Spinner show accessibilityLabel="Media Block Spinner" />
      </Flex>
    );
  } else if (media.length === 0) {
    // no media could be fetched or api had an error.
    return null;
  }
  return (
    <MediaBlockCard
      title={mediaTitle}
      platformConstants={PLATFORM_INFO_MAP.get(platformName)}
      medias={media.slice(0, PLATFORM_INFO_MAP.get(platformName).numVideosDisplayLimit)}
    />
  );
};

const isMetricGroupStatsHaveZero = (metricGroup: MetricGrouping, stats: StatsEntryResponse[]) => {
  if (!stats) return true;
  const zeroMetrics = metricGroup.zero_metrics;
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < zeroMetrics.length; i++) {
    const zeroMetric = zeroMetrics[i];
    const stat = stats.find((s) => s.metric === zeroMetric);
    if (stat && stat.value === 0) {
      return true;
    }
  }
  return false;
};

export const MediaPlatformCard = ({
  platformName,
  numFollowerText,
  numFollowers,
  statsGroup,
  metricGrouping,
  mediaTitle,
  projectedWidth,
  socialProfile,
}: {
  platformName: Platforms;
  numFollowerText: string;
  numFollowers: number;
  statsGroup: StatsEntryResponse[];
  metricGrouping: MetricGrouping[];
  mediaTitle: string;
  projectedWidth: number;
  socialProfile?: InstagramProfileResponse | YoutubeProfileResponse | TikTokProfileResponse;
}) => (
  <FlatModule>
    <Flex gap={6} flex="grow" direction="column">
      <Flex alignItems="center" gap={4} justifyContent="start" flex="grow">
        <BrandIcons platform={platformName} size="xl" />
        <Heading size="500">{PLATFORM_TO_PLATFORM_NAME[platformName]}</Heading>
      </Flex>
      <FlatModule>
        <Flex direction="column" gap={4}>
          <Text color="subtle" size="200">
            {numFollowerText}
          </Text>
          <Text size="400" weight="bold">
            {numFollowers.toLocaleString("en-US")}
          </Text>
        </Flex>
      </FlatModule>
      <Flex direction="column" gap={6}>
        {metricGrouping.map((mg) =>
          !isMetricGroupStatsHaveZero(mg, statsGroup) ? (
            <FlatModule key={mg.group}>
              <Flex direction="column" gap={3}>
                <Text weight="bold" size="400">
                  {mg.group}
                </Text>
                <Text color="subtle" size="100">
                  {mg.period}
                </Text>
                <Divider />
                <StatsCard stats={statsGroup} metric_names={mg.metrics} />
              </Flex>
            </FlatModule>
          ) : null,
        )}
      </Flex>
      <RecentMedia
        projectedWidth={projectedWidth}
        mediaTitle={mediaTitle}
        platformName={platformName}
        socialProfile={socialProfile}
      />
    </Flex>
  </FlatModule>
);

const PlatformFollowers = ({
  platform,
  followerCount,
  subscriberText,
  profileLink,
}: {
  platform: Platforms;
  followerCount: number;
  subscriberText: string;
  profileLink: string;
}) => (
  <a href={profileLink} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
    <Flex gap={4} alignItems="center">
      <BrandIcons platform={platform} size="l" />
      <Text size="400" weight="bold">
        {getAbbreviatedNumber(followerCount)} {subscriberText}
      </Text>
    </Flex>
  </a>
);

const FollowersCard = ({
  totalFollowers,
  instagramFollowers,
  youtubeFollowers,
  tiktokFollowers,
  instagramLink,
  youtubeLink,
  tiktokLink,
}: {
  totalFollowers: number;
  instagramFollowers: number;
  youtubeFollowers: number;
  tiktokFollowers: number;
  instagramLink: string;
  youtubeLink: string;
  tiktokLink: string;
}) => (
  <Flex direction="column" justifyContent="center" height="100%" gap={6}>
    {instagramFollowers ? (
      <PlatformFollowers
        platform="instagram"
        followerCount={instagramFollowers}
        subscriberText="followers"
        profileLink={instagramLink}
      />
    ) : null}
    {youtubeFollowers ? (
      <PlatformFollowers
        platform="youtube"
        followerCount={youtubeFollowers}
        subscriberText="subscribers"
        profileLink={youtubeLink}
      />
    ) : null}
    {tiktokFollowers ? (
      <PlatformFollowers
        platform="tiktok"
        followerCount={tiktokFollowers}
        subscriberText="followers"
        profileLink={tiktokLink}
      />
    ) : null}
  </Flex>
);

export const OverviewCard = ({
  isClaimed,
  instagramProfile,
  instagramAudience,
  youtubeProfile,
  youtubeAudience,
  tiktokProfile,
}: {
  isClaimed: boolean;
  instagramProfile: InstagramProfileResponse;
  instagramAudience: AudienceResponse;
  youtubeProfile: YoutubeProfileResponse;
  youtubeAudience: AudienceResponse;
  tiktokProfile: TikTokProfileResponse;
}) => {
  const followersInfoRef = useRef(null);
  const [graphWidth, setGraphWidth] = useState(360);

  useEffect(() => {
    const newGraphWidth = (followersInfoRef?.current?.scrollWidth || 360) - 48; // 24 horizontal padding in info card * 2
    setGraphWidth(newGraphWidth);
  }, [followersInfoRef]);

  const igFollowers = instagramProfile?.numFollowers || 0;
  const ytSubscribers = youtubeProfile?.num_subscribers || 0;
  const tiktokFollowers = tiktokProfile?.follower_count || 0;
  const totalFollowers = igFollowers + ytSubscribers + tiktokFollowers;

  // // passing in undefined to intentionally remove youtube data....for now
  // // TODO also change these to show just YouTube data if no Instagram data is available.
  // const ageAudience = normalizeAgeAudienceEntryResponse(
  //   igFollowers,
  //   instagramAudience?.age_bucket,
  //   ytSubscribers,
  //   undefined,
  //   // youtubeAudience?.age_bucket,
  // );

  // const countryAudience = normalizeCountryAudienceEntryResponse(
  //   igFollowers,
  //   instagramAudience?.audience_country,
  //   ytSubscribers,
  //   undefined,
  //   // youtubeAudience?.country,
  // );

  // const genderAudience = normalizeGenderAudienceEntryResponse(
  //   igFollowers,
  //   instagramAudience?.gender,
  //   ytSubscribers,
  //   undefined,
  //   // youtubeAudience?.gender,
  // );

  // const renamedGenderAudience = genderAudience?.map((entry) => {
  //   const newEntry = { ...entry };
  //   if (entry.audience === "M") {
  //     newEntry.audience = "Male";
  //   } else if (entry.audience === "F") {
  //     newEntry.audience = "Female";
  //   } else if (entry.audience === "U") {
  //     newEntry.audience = "Unspecified";
  //   }
  //   return newEntry;
  // });

  const instagramProfileLink = instagramProfile
    ? `https://www.instagram.com/${instagramProfile.socialUsername}`
    : null;
  const youtubeProfileLink = youtubeProfile
    ? `https://www.youtube.com/channel/${youtubeProfile.channel_id}`
    : null;
  const tiktokProfileLink = tiktokProfile ? tiktokProfile.profile_deep_link : null;

  const cardWidth = isMobile ? "100%" : "48%";
  return (
    <Flex direction="column" flex="grow" gap={6}>
      <Box
        display="flex"
        flex="grow"
        justifyContent="between"
        wrap
        dangerouslySetInlineStyle={{ __style: { gap: 24 } }}>
        <Box ref={followersInfoRef} width={cardWidth} display="flex">
          <InfoCard title="Audience" width="100%" justifyContent="between">
            <FollowersCard
              totalFollowers={totalFollowers}
              instagramFollowers={instagramProfile?.numFollowers}
              youtubeFollowers={youtubeProfile?.num_subscribers}
              tiktokFollowers={tiktokProfile?.follower_count}
              instagramLink={instagramProfileLink}
              youtubeLink={youtubeProfileLink}
              tiktokLink={tiktokProfileLink}
            />
          </InfoCard>
        </Box>
        <DemographicsCard
          cardWidth={cardWidth}
          graphWidth={graphWidth}
          audienceName="country"
          instagramAudience={instagramAudience?.audience_country}
          youtubeAudience={null}
          isClaimed={isClaimed}
        />
      </Box>
      <Box
        display="flex"
        flex="grow"
        justifyContent="between"
        wrap
        dangerouslySetInlineStyle={{ __style: { gap: 24 } }}>
        <DemographicsCard
          cardWidth={cardWidth}
          graphWidth={graphWidth}
          audienceName="age"
          instagramAudience={instagramAudience?.age_bucket}
          youtubeAudience={null}
          isClaimed={isClaimed}
        />
        <DemographicsCard
          cardWidth={cardWidth}
          graphWidth={graphWidth}
          audienceName="gender"
          instagramAudience={instagramAudience?.gender}
          youtubeAudience={null}
          isClaimed={isClaimed}
        />
      </Box>
    </Flex>
  );
};

type SocialProfileType = InstagramProfileResponse | YoutubeProfileResponse | TikTokProfileResponse;

const fetchProfile = async (
  platformUrl: string,
  requestUser: User,
  profileKey: string,
  setSocialProfile: (profile: SocialProfileType) => void,
) => {
  const firebaseToken = await requestUser?.getIdToken();
  const socialProfile = await getFirstProfileByKey(firebaseToken, profileKey, platformUrl);
  console.log(socialProfile);
  setSocialProfile(socialProfile);
};

const fetchSocialStats = async (
  requestUser: User,
  socialProfile: SocialProfileType,
  getProfileInsights: (
    username: string,
    firebaseToken: string,
  ) => Promise<PlatformInsightsResponse>,
  setStats: (stats: PlatformInsightsResponse) => void,
) => {
  const firebaseToken = await requestUser?.getIdToken();
  console.log(socialProfile);
  if (socialProfile === null) {
    const socialAudienceStats: PlatformInsightsResponse = null;
    setStats(socialAudienceStats);
  } else if ("channel_id" in socialProfile) {
    const youtubeProfile = socialProfile as YoutubeProfileResponse;
    console.log("youtubeProfile", youtubeProfile);
    // TODO(andrew): should we switch this to channel id?
    const socialAudienceStats = socialProfile
      ? await getProfileInsights(youtubeProfile.user, firebaseToken)
      : null;
    setStats(socialAudienceStats);
  } else {
    const socialAudienceStats = socialProfile
      ? await getProfileInsights(socialProfile.socialUsername, firebaseToken)
      : null;
    setStats(socialAudienceStats);
  }
};

export const OverallProfile = ({
  profileKey,
  isClaimed,
}: {
  profileKey: string;
  isClaimed: boolean;
}) => {
  const requestUser: User = useOutletContext<AuthContext>()?.user;

  const [instagramProfile, setInstagramProfile] = useState(null);
  const [instagramStats, setInstagramStats] = useState(null);

  const [youtubeProfile, setYoutubeProfile] = useState(null);
  const [youtubeStats, setYoutubeStats] = useState(null);

  const [tiktokProfile, setTiktokProfile] = useState(null);
  const [tiktokStats, setTiktokStats] = useState(null);

  const availablePlatforms = [
    isValidStats(instagramStats) ? "instagram" : undefined,
    isValidStats(youtubeStats) ? "youtube" : undefined,
    isValidStats(tiktokStats) ? "tiktok" : undefined,
  ].filter((platform) => platform);

  const projectedMaxWidth = calculateMaxWidthBlockCard(
    PLATFORM_INFO.filter((p) => availablePlatforms.includes(p.platformName)),
  );

  useEffect(() => {
    fetchProfile(INSTAGRAM_PROFILE_URL, requestUser, profileKey, setInstagramProfile);
    fetchProfile(YOUTUBE_PROFILE_URL, requestUser, profileKey, setYoutubeProfile);
    fetchProfile(TIKTOK_PROFILE_URL, requestUser, profileKey, setTiktokProfile);
  }, []);

  // get Instagram info
  useEffect(() => {
    fetchSocialStats(requestUser, instagramProfile, getInstagramProfileInsights, setInstagramStats);
  }, [instagramProfile]);

  // get YouTube info
  useEffect(() => {
    fetchSocialStats(requestUser, youtubeProfile, getYoutubeProfileInsights, setYoutubeStats);
  }, [youtubeProfile]);

  // get TikTok info
  useEffect(() => {
    fetchSocialStats(requestUser, tiktokProfile, getTiktokProfileInsights, setTiktokStats);
  }, [tiktokProfile]);

  return (
    <Flex gap={12} direction="column">
      <OverviewCard
        isClaimed={isClaimed}
        instagramProfile={instagramProfile}
        instagramAudience={instagramStats?.audience}
        youtubeProfile={youtubeProfile}
        youtubeAudience={youtubeStats?.audience}
        tiktokProfile={tiktokProfile}
      />
      {instagramStats && (
        <MediaPlatformCard
          platformName="instagram"
          numFollowerText="Followers"
          numFollowers={instagramProfile?.numFollowers || 0}
          statsGroup={instagramStats?.stats}
          metricGrouping={[
            {
              group: "In Feed",
              period: "Post Published In The Last 28 Days",
              metrics: ["num_posts", "post_median_reach", "post_median_likes"],
              zero_metrics: ["num_posts", "post_median_reach"],
            },
            {
              group: "Reels",
              period: "Reels Published In The Last 28 Days",
              metrics: ["num_reels", "reels_median_reach", "reels_median_likes"],
              zero_metrics: ["num_reels", "reels_median_reach"],
            },
          ]}
          mediaTitle="Latest posts"
          projectedWidth={projectedMaxWidth}
          socialProfile={instagramProfile}
        />
      )}
      {youtubeStats && (
        <MediaPlatformCard
          platformName="youtube"
          numFollowerText="Subscribers"
          numFollowers={youtubeProfile?.num_subscribers || 0}
          statsGroup={youtubeStats?.stats}
          metricGrouping={[
            {
              group: "Videos",
              period: "Videos Published In The Last 28 Days",
              metrics: ["views", "likes", "total_videos", "average_views", "average_likes"],
              zero_metrics: ["views", "total_videos"],
            },
            {
              group: "Shorts",
              period: "Shorts Published In The Last 28 Days",
              metrics: [
                "shorts_views",
                "shorts_likes",
                "total_shorts",
                "average_shorts_views",
                "average_shorts_likes",
                "average_shorts_comments",
              ],
              zero_metrics: ["shorts_views", "total_shorts"],
            },
          ]}
          mediaTitle="Latest Content"
          projectedWidth={projectedMaxWidth}
          socialProfile={youtubeProfile}
        />
      )}
      {tiktokStats && (
        <MediaPlatformCard
          platformName="tiktok"
          numFollowerText="Followers"
          numFollowers={tiktokProfile?.follower_count || 0}
          statsGroup={tiktokStats?.stats}
          metricGrouping={[
            {
              group: "Videos",
              period: "Videos Published In The Last 28 Days",
              metrics: [
                "views",
                "likes",
                "shares",
                "comments",
                "total_videos",
                "median_views",
                "median_likes",
              ],
              zero_metrics: ["views", "total_videos"],
            },
          ]}
          mediaTitle="Latest videos"
          projectedWidth={projectedMaxWidth}
          socialProfile={tiktokProfile}
        />
      )}
    </Flex>
  );
};
