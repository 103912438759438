import React, { Dispatch, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, Checkbox, Flex, Loader, Stack, Title } from "@mantine/core";

import { DatePickerInput } from "@mantine/dates";

import { IconCalendar } from "@tabler/icons-react";

import { addNonHolidayBusinessDays } from "utils/DateUtils";

import {
  DateUpdateType,
  DeliverableListAction,
  DeliverableListActionType,
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_SUB_TITLE_SIZE,
} from "components/contracts/common/Common";

import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";
import { DATE_FORMAT, MIN_DATE_WIDTH } from "components/contracts/contract/TimelineSelector";

import { SupportedFormat, SupportedPlatform } from "models/Common";

/**
 * UGC timeline selector that has no notion of live or analytics dates and assumes that video review
 * is required.
 */
export default function UGCTimelineSelector({
  deliverableId,
  platform,
  format,
  timeline,
  editDeliverableList,
}: {
  deliverableId: number;
  platform: SupportedPlatform;
  format: SupportedFormat;
  timeline: DeliverableTimeline;
  editDeliverableList: Dispatch<DeliverableListAction>;
}) {
  const [defaultsConfigured, setDefaultsConfigured] = useState(false);
  // Search Params to check if we need to set default for requiresScriptReview
  const [searchParams, setSearchParams] = useSearchParams();

  // Set default for requiresScriptReview
  useEffect(() => {
    if (!searchParams.get("deliverables")) {
      editDeliverableList({
        type: DeliverableListActionType.UPDATE_REQUIRES_SCRIPT_REVIEW,
        payload: {
          id: deliverableId,
          requiresScriptReview:
            platform === SupportedPlatform.YOUTUBE && format !== SupportedFormat.YOUTUBE_SHORT,
        },
      });
    }
    editDeliverableList({
      type: DeliverableListActionType.UPDATE_DATE,
      payload: {
        id: deliverableId,
        dateUpdate: { newDate: null, dateUpdateType: DateUpdateType.ALL },
      },
    });
    setDefaultsConfigured(true);
  }, [format]);

  if (!defaultsConfigured) {
    return <Loader />;
  }

  const icon = <IconCalendar size="1rem" stroke={1.5} />;

  return (
    <Stack>
      <Title td="underline" order={DEFAULT_SUB_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Required Reviews
      </Title>
      <Checkbox
        ml="sm"
        checked={timeline.requiresScriptReview}
        onChange={(event) => {
          editDeliverableList({
            type: DeliverableListActionType.UPDATE_REQUIRES_SCRIPT_REVIEW,
            payload: { id: deliverableId, requiresScriptReview: event.currentTarget.checked },
          });
          editDeliverableList({
            type: DeliverableListActionType.UPDATE_DATE,
            payload: {
              id: deliverableId,
              dateUpdate: { newDate: null, dateUpdateType: DateUpdateType.ALL },
            },
          });
        }}
        label="Script Review"
      />
      <Checkbox
        ml="sm"
        checked={timeline.requiresVideoReview}
        // All UGC videos require video "review" (i.e., approval)
        disabled
        label="Video Review"
      />
      <Title td="underline" order={DEFAULT_SUB_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Timeline
      </Title>
      <Flex align="center">
        <Button
          key={`campaignLiveDate-${deliverableId}`}
          variant="light"
          onClick={() =>
            editDeliverableList({
              type: DeliverableListActionType.UPDATE_DATE,
              payload: {
                id: deliverableId,
                dateUpdate: { newDate: null, dateUpdateType: DateUpdateType.ALL },
              },
            })
          }>
          Use Default Timeline
        </Button>
      </Flex>
      <Flex gap="sm">
        {timeline.requiresScriptReview && (
          <DatePickerInput
            id={`campaignScriptDate-${deliverableId}`}
            label="Script Due Date"
            valueFormat={DATE_FORMAT}
            leftSection={icon}
            value={timeline.scriptDate}
            minDate={addNonHolidayBusinessDays(new Date(), 1)}
            onChange={(value) =>
              editDeliverableList({
                type: DeliverableListActionType.UPDATE_DATE,
                payload: {
                  id: deliverableId,
                  dateUpdate: { newDate: value, dateUpdateType: DateUpdateType.SCRIPT },
                },
              })
            }
            miw={MIN_DATE_WIDTH}
          />
        )}
        {timeline.requiresScriptReview && (
          <DatePickerInput
            id={`campaignRevisionDate-${deliverableId}`}
            label="Script Revision Due Date"
            valueFormat={DATE_FORMAT}
            leftSection={icon}
            value={timeline.scriptRevisionDate}
            minDate={addNonHolidayBusinessDays(timeline.scriptDate, 1)}
            onChange={(value) =>
              editDeliverableList({
                type: DeliverableListActionType.UPDATE_DATE,
                payload: {
                  id: deliverableId,
                  dateUpdate: { newDate: value, dateUpdateType: DateUpdateType.SCRIPT_REVISION },
                },
              })
            }
            miw={MIN_DATE_WIDTH}
          />
        )}
        <DatePickerInput
          id={`campaignVideoDraftDate-${deliverableId}`}
          label="Video Draft Due Date"
          valueFormat={DATE_FORMAT}
          leftSection={icon}
          value={timeline.videoDraftDate}
          minDate={addNonHolidayBusinessDays(timeline.scriptRevisionDate || new Date(), 1)}
          onChange={(value) =>
            editDeliverableList({
              type: DeliverableListActionType.UPDATE_DATE,
              payload: {
                id: deliverableId,
                dateUpdate: { newDate: value, dateUpdateType: DateUpdateType.VIDEO_DRAFT },
              },
            })
          }
          miw={MIN_DATE_WIDTH}
        />
        <DatePickerInput
          id={`campaignApprovalDate-${deliverableId}`}
          label="Final Video Due Date"
          valueFormat={DATE_FORMAT}
          leftSection={icon}
          value={timeline.approvalDate}
          minDate={addNonHolidayBusinessDays(timeline.videoDraftDate, 1)}
          onChange={(value) =>
            editDeliverableList({
              type: DeliverableListActionType.UPDATE_DATE,
              payload: {
                id: deliverableId,
                dateUpdate: { newDate: value, dateUpdateType: DateUpdateType.APPROVAL },
              },
            })
          }
          miw={MIN_DATE_WIDTH}
        />
      </Flex>
    </Stack>
  );
}
