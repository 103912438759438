import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Divider, Group, Paper, Stack } from "@mantine/core";

import { AdminLabelingHeader } from "admin/app/labeling/AdminLabelingHeader";
import { AdminCreatorLabeling } from "admin/app/labeling/AdminCreatorLabeling";
import { AdminCreatorLabelingHistory } from "admin/app/labeling/AdminCreatorLabelingHistory";
import { AdminCreatorLabelingTeamReport } from "admin/app/labeling/AdminCreatorLabelingTeamReport";
import { AdminCreatorLabelingTasks } from "admin/app/labeling/AdminCreatorLabelingTasks";

export const AdminLabeling = () => {
  const [syncProgress, setSyncProgress] = useState(true);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  // parse active tab from search params
  useEffect(() => {
    const tabParam = searchParams.get("tab");
    if (tabParam) {
      if (tabParam !== activeTab) {
        setActiveTab(tabParam);
      }
    } else {
      setActiveTab(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (activeTab) {
      if (activeTab !== searchParams.get("tab")) {
        searchParams.set("tab", activeTab);
        setSearchParams(searchParams);
      }
    }
  }, [activeTab]);

  return (
    <Paper>
      <Stack gap={0}>
        <Stack gap={0}>
          <AdminLabelingHeader
            syncProgress={syncProgress}
            setSyncProgress={setSyncProgress}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <Divider />
        </Stack>
        <Group>
          {(!activeTab || activeTab === "creator-labeling") && (
            <AdminCreatorLabeling
              syncProgress={syncProgress}
              setSyncProgress={setSyncProgress}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          )}
          {activeTab === "history" && <AdminCreatorLabelingHistory />}
          {activeTab === "team" && <AdminCreatorLabelingTeamReport />}
          {activeTab === "tasks" && <AdminCreatorLabelingTasks />}
        </Group>
      </Stack>
    </Paper>
  );
};

export default AdminLabeling;
