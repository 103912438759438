import React from "react";

import { Anchor, Box, Container, List, Text } from "@mantine/core";

import {
  Addendum,
  CompensationSection,
  DEFAULT_LIST_SPACING,
  DEFAULT_SECTION_PADDING,
  FW_BOLD,
  SpacedDivider,
  TermsSection,
} from "components/contracts/contract/CampaignContractComponents";
import header from "components/contracts/contract/TitleHeader";

import { SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES, SupportedPlatform } from "models/Common";

const UsageRightsCampaignSection = ({
  brandName,
  dueDate,
}: {
  brandName: string;
  dueDate: Date;
}) => {
  const dueDateText = (
    <Text>
      <Text span fw={FW_BOLD}>
        Due Date:{" "}
      </Text>
      <Text span>{dueDate && dueDate.toDateString()}</Text>
    </Text>
  );

  return (
    <Box>
      {header("Campaign", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <Text span fw={FW_BOLD}>
              Brand:{" "}
            </Text>
            <Text span>{brandName}</Text>
          </List.Item>
          <List.Item>{dueDateText}</List.Item>
        </List>
      </Container>
    </Box>
  );
};

const DeliverableDetails = ({
  platform,
  liveContentUrl,
  usageRightsDays,
  usageRightsInPerpetuity,
  creatorFirstName,
  creatorHandle,
  dueDate,
}: {
  platform: SupportedPlatform;
  liveContentUrl: string;
  usageRightsDays: number;
  usageRightsInPerpetuity: boolean;
  creatorFirstName: string;
  creatorHandle: string;
  dueDate: Date;
}) => {
  const platformName = SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform];
  let codeName = "";
  let helpLink = null;
  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      codeName = "partnership ad code";
      helpLink = (
        <Text span>
          If you need help with generating the Instagram partnership ad code, take a look at our
          guide{" "}
          <Anchor
            href="https://help.1stcollab.com/en/articles/9493801-i-m-being-asked-to-submit-an-instagram-partnership-ad-code-how-do-i-do-that"
            target="_blank">
            here
          </Anchor>
          .
        </Text>
      );
      break;
    case SupportedPlatform.TIKTOK:
      codeName = "spark code";
      helpLink = (
        <Text span>
          If you need help with generating the TikTok spark code, take a look at our guide{" "}
          <Anchor
            href="https://help.1stcollab.com/en/articles/9493798-i-m-being-asked-to-submit-a-tiktok-spark-code-how-do-i-do-that"
            target="_blank">
            here
          </Anchor>
          .
        </Text>
      );
      break;
    default:
      codeName = "partnership code";
      break;
  }

  return (
    <>
      <Box p={DEFAULT_SECTION_PADDING}>
        {header("Deliverables", 3)}
        <Container mx="xs">
          <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
            <List.Item>
              <Text span>
                High-quality copy of the video that was used to create the post available at:{" "}
                <Anchor href={liveContentUrl} target="_blank">
                  {liveContentUrl}
                </Anchor>
              </Text>
              <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                <List.Item>
                  <Text span>
                    Please submit the highest-quality copy of this video that you have available.
                  </Text>
                </List.Item>
                <List.Item>
                  <Text span>
                    Video should exactly match the video that you posted (for example, it should
                    include your edits), EXCEPT:
                  </Text>
                  <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                    <List.Item>
                      <Text span>
                        If you used copyrighted music, please submit a copy of the video without the
                        music.
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text span>
                        If you used subtitles, text-to-speech voiceovers, or other special effects
                        within the Instagram editor, please submit a copy of the video without those
                        effects.
                      </Text>
                    </List.Item>
                  </List>
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Text span>
                {platformName} {codeName} for the video above ({" "}
                <Anchor href={liveContentUrl} target="_blank">
                  {liveContentUrl}
                </Anchor>
                ), valid{" "}
                {usageRightsInPerpetuity ? "in perpetuity" : `for at least ${usageRightsDays} days`}
                .
              </Text>
              <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                <List.Item>
                  <Text span>
                    The {usageRightsInPerpetuity ? "usage rights" : `${usageRightsDays} day period`}{" "}
                    should begin on the day you submit your deliverables. Please do not general a
                    code and then submit your deliverables on a later day. Generate the code and
                    immediately submit it.
                  </Text>
                </List.Item>
                {helpLink && (
                  <List.Item>
                    <Text span>{helpLink}</Text>
                  </List.Item>
                )}
              </List>
            </List.Item>
          </List>
        </Container>
      </Box>
      <Box p={DEFAULT_SECTION_PADDING}>
        <Container mx="xs">
          <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
            <List.Item>
              <Text span>
                {creatorFirstName.length > 0 ? creatorFirstName : creatorHandle} submits required
                deliverables by:{" "}
                <Text span fw={FW_BOLD}>
                  {dueDate.toDateString()}
                </Text>
              </Text>
            </List.Item>
          </List>
        </Container>
      </Box>
    </>
  );
};

/**
 * Most of this is shared with UGC campaigns, but is duplicated here to make future specialization easier.
 */
const UsageRightsSection = ({
  brandName,
  platform,
}: {
  brandName: string;
  platform: SupportedPlatform;
}) => {
  const platformName = SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform];
  let codeName = "";
  let helpLink = null;
  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      codeName = "partnership ad code";
      helpLink =
        "https://help.1stcollab.com/en/articles/9493801-i-m-being-asked-to-submit-an-instagram-partnership-ad-code-how-do-i-do-that";
      break;
    case SupportedPlatform.TIKTOK:
      codeName = "spark code";
      helpLink =
        "https://help.1stcollab.com/en/articles/9493798-i-m-being-asked-to-submit-a-tiktok-spark-code-how-do-i-do-that";
      break;
    default:
      codeName = "partnership code";
      break;
  }

  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Usage Rights", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <Text span>
              &quot;Usage rights&quot; gives {brandName} the right to re-use your video. (Most
              often, they&apos;ll use the video in and/or recut it for social media paid ads).
              Don&apos;t worry; you still retain ownership of the video! What you&apos;re doing is
              granting {brandName} a license to use, distribute and display your video for
              promotional purposes across any media. This license is:
            </Text>
            <List withPadding type="unordered" spacing={DEFAULT_LIST_SPACING}>
              <List.Item>
                <Text span>
                  Worldwide (meaning: they can use your content anywhere in the world),
                </Text>
              </List.Item>
              <List.Item>
                <Text span>
                  Non-exclusive (meaning: you can still use the content or let other people use it
                  too-{brandName} doesn&apos;t have sole rights), and
                </Text>
              </List.Item>
              <List.Item>
                <Text span>
                  Royalty-free (meaning: {brandName} doesn&apos;t have to pay you extra money every
                  time they use the content).
                </Text>
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            <Text span>
              {brandName} also has the right to edit, modify, crop, or adapt the video as needed,
              provided that these edits do not misrepresent your original intent or opinion.
            </Text>
          </List.Item>
          <List.Item>
            <Text span> To make this all happen:</Text>
            <List withPadding type="unordered" spacing={DEFAULT_LIST_SPACING}>
              <List.Item>
                <Text span>The copy of the video that you upload will be sent to {brandName}.</Text>
              </List.Item>
              <List.Item>
                <Text span>
                  The {platformName} {codeName} that you submit will also be sent to {brandName}.
                  They&apos;ll use this code to boost your video on {platformName} and run it as an
                  ad; for more on what that means, check out{" "}
                  <Anchor href={helpLink} target="_blank">
                    this article
                  </Anchor>
                  .
                </Text>
              </List.Item>
            </List>
          </List.Item>
        </List>
      </Container>
    </Box>
  );
};

const UsageRightsCampaignContract = ({
  creatorFirstName,
  brandName,
  contractAmount,
  platform,
  liveContentUrl,
  requestedDays,
  requestedInPerpetuity,
  creatorHandle,
  dueDate,
  addendum,
}: {
  creatorFirstName: string;
  brandName: string;
  contractAmount: number;
  platform: SupportedPlatform;
  liveContentUrl: string;
  requestedDays: number;
  requestedInPerpetuity: boolean;
  creatorHandle: string;
  dueDate: Date;
  addendum: string;
}) => {
  return (
    <Box>
      <UsageRightsCampaignSection brandName={brandName} dueDate={dueDate} />
      <SpacedDivider />
      <DeliverableDetails
        platform={platform}
        liveContentUrl={liveContentUrl}
        usageRightsDays={requestedDays}
        usageRightsInPerpetuity={requestedInPerpetuity}
        creatorFirstName={creatorFirstName}
        creatorHandle={creatorHandle}
        dueDate={dueDate}
      />
      <SpacedDivider />
      <TermsSection />
      <SpacedDivider />
      <CompensationSection
        contractAmount={contractAmount}
        bonusAmount={0}
        bonusCondition={null}
        willGoLive={false}
      />
      <SpacedDivider />
      <UsageRightsSection brandName={brandName} platform={platform} />
      <SpacedDivider />
      {addendum !== null && addendum.length > 0 && (
        <>
          <SpacedDivider />
          <Addendum addendum={addendum} />
        </>
      )}
    </Box>
  );
};

export default UsageRightsCampaignContract;
