import React from "react";

import { ThemeIcon } from "@mantine/core";

import { IconVideoPlus } from "@tabler/icons-react";

import { SupportedPlatform } from "models/Common";
import { LiveContent } from "components/contracts/tasks/models/Common";

import DeliverableStep from "components/contracts/tasks/cards/DeliverableStep";
import LiveContentDisplay from "components/contracts/tasks/content/LiveContentDisplay";

export default function LiveContentStage({
  taskId,
  platform,
  displayName,
  liveContent,
  handleCompleteTask,
}: {
  taskId: string;
  platform: SupportedPlatform;
  displayName: string;
  liveContent: LiveContent;
  handleCompleteTask: () => void;
}) {
  if (!liveContent) {
    return null;
  }

  return (
    <DeliverableStep
      isOpen
      label="Live Video"
      submissionDate={liveContent.dateCreated}
      icon={
        <ThemeIcon radius="xl" size="1.4rem" color="red">
          <IconVideoPlus size="0.9rem" />
        </ThemeIcon>
      }
      content={
        <LiveContentDisplay
          taskId={taskId}
          liveContent={liveContent}
          platform={platform}
          displayName={displayName}
          handleCompleteTask={handleCompleteTask}
        />
      }
    />
  );
}
