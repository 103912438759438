import React, { Dispatch } from "react";

import { Group, Select, Stack, Title } from "@mantine/core";

import {
  DeliverableListAction,
  DeliverableListActionType,
  MultiPlatformState,
  SUPPORTED_FORMATS_TO_PLATFORMS,
  SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES,
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_SUB_TITLE_SIZE,
} from "components/contracts/common/Common";

import Deliverable from "components/contracts/models/Deliverable";
import TimelineSelector from "components/contracts/contract/TimelineSelector";
import UsageRightsSelector from "components/contracts/contract/UsageRightsSelector";

import { SupportedFormat, SupportedPlatform } from "models/Common";

function FormatSelector({
  deliverableId,
  deliverableFormat,
  multiPlatformState,
  editDeliverableList,
}: {
  deliverableId: number;
  deliverableFormat?: SupportedFormat;
  multiPlatformState: MultiPlatformState;
  editDeliverableList: Dispatch<DeliverableListAction>;
}) {
  const selectedPlatforms = Object.entries(multiPlatformState).filter(
    ([_, { selected }]) => selected,
  );
  const supportedFormatsAndLabels = selectedPlatforms.reduce((acc, [platform, _]) => {
    const platformFormats =
      SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES[platform as SupportedPlatform];
    return acc.concat(platformFormats);
  }, [] as { label: string; value: SupportedFormat }[]);

  const handleSelect = (value: string) => {
    const id = deliverableId;
    const format = value as SupportedFormat;
    const platform = SUPPORTED_FORMATS_TO_PLATFORMS[format];
    const creatorHandle = multiPlatformState[platform].handle;
    const { profileLink } = multiPlatformState[platform];

    editDeliverableList({
      type: DeliverableListActionType.SET_INITIAL_FIELDS,
      payload: { id, format, platform, creatorHandle, profileLink },
    });
  };
  return (
    <Stack>
      <Title td="underline" order={DEFAULT_SUB_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Format
      </Title>
      <Group>
        <Select
          id={`formatSelector-${deliverableId}}`}
          placeholder="Select Format"
          data={supportedFormatsAndLabels}
          value={deliverableFormat}
          onChange={handleSelect}
          miw={250}
        />
      </Group>
    </Stack>
  );
}

export default function DeliverableInput({
  multiPlatformState,
  deliverable,
  deliverableId,
  editDeliverableList,
}: {
  multiPlatformState: MultiPlatformState;
  deliverable: Deliverable;
  deliverableId: number;
  editDeliverableList: Dispatch<DeliverableListAction>;
}) {
  return (
    <Stack mb="md">
      <FormatSelector
        deliverableId={deliverableId}
        deliverableFormat={deliverable.format}
        multiPlatformState={multiPlatformState}
        editDeliverableList={editDeliverableList}
      />

      {deliverable.format && (
        <>
          <TimelineSelector
            deliverableId={deliverableId}
            platform={deliverable.platform}
            format={deliverable.format}
            timeline={deliverable.timeline}
            editDeliverableList={editDeliverableList}
          />
          <UsageRightsSelector
            deliverableId={deliverableId}
            platform={deliverable.platform}
            usageRightsDays={
              deliverable.usageRightsInPerpetuity ? "perpetuity" : deliverable.usageRightsDays
            }
            setUsageRightsDays={(value) =>
              editDeliverableList({
                type: DeliverableListActionType.UPDATE_USAGE_RIGHTS_DAYS,
                payload: {
                  id: deliverableId,
                  usageRightsDays: value === "perpetuity" ? 0 : Number(value),
                  usageRightsInPerpetuity: value === "perpetuity",
                },
              })
            }
          />
        </>
      )}
    </Stack>
  );
}
