import React, { useEffect, useState } from "react";
import {
  Anchor,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  NumberInput,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import { IconUpload } from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";

import { Box } from "gestalt";
import "gestalt/dist/gestalt.css";

import Spacer from "components/Spacer";
import LayoutBase from "components/LayoutBase";

import { API_URL } from "configs/Configs";
import { useAppSelector } from "reduxStore/hooks";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import {
  getLiveCreatorSetEntries,
  LiveCreatorProps,
} from "components/creator_lists/CreatorListsUtils";
import { CreatorDetails, CreatorSetEntryItem, Platform } from "components/discovery/Datamodels";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { showSuccessNotification, showFailureNotification } from "components/common/Notifications";
import { useInputState } from "@mantine/hooks";
import { useCreatorSetPaginate } from "components/creator_sets/CreatorSetCommons";

const ADD_CREATOR_CAMPAIGN_URL = `${API_URL}/api/campaigns/add_creator/`;
const GET_LIVE_URL_PLATFORM_URL = `${API_URL}/api/campaigns/get_live_url_platform`;

export type AddCreatorCampaignResponse = {
  success: boolean;
  message: string;
  creator_id?: number;
  creator_details?: CreatorDetails[];
};

export const addCreatorCampaign = async (campaignId: number, linkUrl: string, price: number) => {
  const request = await createRequestWithFirebaseToken({
    url: ADD_CREATOR_CAMPAIGN_URL,
    method: "POST",
    body: JSON.stringify({ linkUrl, state: 5, campaignId, price }),
  });
  const response: AddCreatorCampaignResponse = await handleResult(request);
  return response;
};

export const getLiveUrlPlatform = async (linkUrl: string) => {
  const requestUrl = new URL(GET_LIVE_URL_PLATFORM_URL);
  requestUrl.searchParams.append("linkUrl", linkUrl);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
};

const PLATFORM_TO_TEXT = new Map<string, string>([
  ["youtube", "YouTube"],
  ["instagram", "Instagram"],
  ["tiktok", "TikTok"],
]);

const fetchLiveCreatorSetEntries = async (
  campaignId: number,
  setLoaded: (loaded: boolean) => void,
  adGroupId?: number,
  timeWindow?: number,
) => {
  setLoaded(false);
  const candidates = await getLiveCreatorSetEntries(campaignId, adGroupId, timeWindow);
  setLoaded(true);
  return candidates;
};

const LiveCreatorFilters = ({
  timeWindow,
  setTimeWindow,
}: {
  timeWindow: string;
  setTimeWindow: (timeWindow: string) => void;
}) => {
  // Time window options (7d, 28d, 90d, all time)
  const timeWindowOptions = [
    { value: "0", label: "All Time" },
    { value: "7", label: "Past 7d" },
    { value: "28", label: "Past 28d" },
    { value: "90", label: "Past 90d" },
  ];

  return (
    <Stack gap="xs">
      <Text size="sm" fw={500} mt={3}>
        Days Live
      </Text>
      <Select
        // color="blue"
        data={timeWindowOptions}
        value={timeWindow}
        onChange={setTimeWindow}
      />
    </Stack>
  );
};

const LiveCampaignCreatorResults = ({
  liveCreatorSetEntries,
  setLiveCreatorSetEntries,
  liveCreatorProps,
  campaignId,
  timeWindow = "0",
}: {
  liveCreatorSetEntries: CreatorSetEntryItem[];
  setLiveCreatorSetEntries: (creatorSetEntries: CreatorSetEntryItem[]) => void;
  liveCreatorProps: Record<number, LiveCreatorProps>;
  campaignId: number;
  timeWindow?: string;
}) => {
  const [creatorSetDetailsMap, setCreatorSetDetailsMap] = useState<Record<number, CreatorDetails>>(
    {},
  );
  const { creatorSetPageDetails, EntryPagniator, TopOfEntries } = useCreatorSetPaginate(
    liveCreatorSetEntries,
    creatorSetDetailsMap,
    setCreatorSetDetailsMap,
    25,
    true,
    campaignId,
    timeWindow,
  );

  // NOTE(kevin): We should rename this file to CreatorSetLiveResultsView where we are showing the live
  // creators from creatorsets in a campaign
  return (
    <Stack gap="md">
      <TopOfEntries />
      {creatorSetPageDetails.map((entryWithDetail, index) => {
        const creatorProps = liveCreatorProps[entryWithDetail.creator_details.creator_id];
        const { creatorset_name: creatorSetName = null, price = null } = creatorProps ?? {};
        return (
          <React.Fragment key={`entry-${entryWithDetail.creator_details.creator_id}`}>
            <UnifiedCreatorRep
              creator={entryWithDetail.creator_details}
              key={`live-result-${entryWithDetail.creator_details.creator_id}`}
              defaultExpandedPlatforms={["youtube", "tiktok", "instagram"]}
              archetype={creatorSetName}
              price={price}
              useLiveMedia
            />
          </React.Fragment>
        );
      })}
      <Spacer height={16} />
      <EntryPagniator />
    </Stack>
  );
};

export const CampaignCreatorCandidatesLiveView = () => {
  const { selectedCampaign, selectedAdGroup } = useCampaignAppContext();
  const currentUser = useAppSelector((state) => state.me.user);
  const isStaff = currentUser?.is_staff || false;
  const [brandId, setBrandId] = useState<number>(null);
  const [linkUrl, setLinkUrl] = useInputState("");
  const [parseError, setParseError] = useState(false);
  const [validating, setValidating] = useState(false);

  const [parsedPlatform, setParsedPlatform] = useState<Platform>(null);
  const [submitting, setSubmitting] = useState(false);

  const [liveCreatorSetEntries, setLiveCreatorSetEntries] = useState<CreatorSetEntryItem[]>([]);
  const [liveCreatorProps, setLiveCreatorProps] = useState<Record<number, LiveCreatorProps>>({});
  const [loaded, setLoaded] = useState(false);

  // Live Media Metadata
  const [price, setPrice] = useInputState<number>(null);

  const [opened, { open, close }] = useDisclosure(false);

  // Filter constants
  // Time Window (as str)
  const [timeWindow, setTimeWindow] = useState("0");

  useEffect(() => {
    if (!selectedCampaign) {
      return;
    }
    const timeWindowInt = timeWindow === "0" ? null : Number(timeWindow);
    fetchLiveCreatorSetEntries(
      Number(selectedCampaign.hash_id),
      setLoaded,
      selectedAdGroup ? Number(selectedAdGroup.id) : null,
      timeWindowInt,
    ).then((response) => {
      setLiveCreatorSetEntries(response.creator_set_entries);
      setLiveCreatorProps(response.live_creator_props);
    });
    setBrandId(selectedCampaign?.brand?.id);
  }, [selectedCampaign, timeWindow]);

  const handleSubmit = () => {
    setSubmitting(true);
    addCreatorCampaign(selectedCampaign.hash_id, linkUrl, price)
      .then((response) => {
        if (response == null || !response.success) {
          setPrice(null);
          showFailureNotification({
            message: (
              <Text size="sm">
                Error adding {PLATFORM_TO_TEXT.get(parsedPlatform)}{" "}
                <Anchor size="sm" href={linkUrl} target="_blank">
                  media
                </Anchor>{" "}
                to{" "}
                <Text size="sm" span fw="500">
                  {selectedCampaign.title}
                </Text>
                .
              </Text>
            ),
            title: "Error Adding Live Media",
          });
        } else {
          setPrice(null);
          setLinkUrl("");
          showSuccessNotification({
            message: (
              <Text size="sm">
                Successfully added live {PLATFORM_TO_TEXT.get(parsedPlatform)}{" "}
                <Anchor size="sm" href={linkUrl} target="_blank">
                  media
                </Anchor>{" "}
                to{" "}
                <Text size="sm" span fw="500">
                  {selectedCampaign.title}
                </Text>
                !
              </Text>
            ),
            title: "Successfully Added Live Media",
          });
          // After successfully adding live media, refetch the entries from the server to update.
          // TODO(chris): It would be better to simply update our local state, or use
          // the response data to update. Refreshing the whole thing is not fast.
          const timeWindowInt = timeWindow === "0" ? null : Number(timeWindow);
          fetchLiveCreatorSetEntries(
            Number(selectedCampaign.hash_id),
            setLoaded,
            selectedAdGroup ? Number(selectedAdGroup.id) : null,
            timeWindowInt,
          ).then((fetchResponse) => {
            setLiveCreatorSetEntries(fetchResponse.creator_set_entries);
            setLiveCreatorProps(fetchResponse.live_creator_props);
          });
        }
      })
      .finally(() => {
        close();
        setSubmitting(false);
      });
  };

  const validateAndOpenModal = () => {
    setParseError(false);
    if (!linkUrl || linkUrl.length === 0) {
      setParseError(true);
    } else {
      setValidating(true);
      getLiveUrlPlatform(linkUrl)
        .then((response) => {
          const { success, platform } = response;
          if (success) {
            setParsedPlatform(platform as Platform);
            open();
          } else {
            setParseError(true);
          }
        })
        .finally(() => setValidating(false));
    }
  };

  return (
    <Box>
      <Modal size="sm" opened={opened} onClose={close} title={<Text fw={500}>Add Live Media</Text>}>
        <Stack>
          <Text size="sm">
            Enter additional details for{" "}
            <Anchor href={linkUrl} target="_blank">
              {PLATFORM_TO_TEXT.get(parsedPlatform)}
            </Anchor>{" "}
            media...
          </Text>
          <NumberInput
            label="Price"
            size="sm"
            value={price ? price / 100 : null}
            placeholder="Optional..."
            onChange={(value: number) => setPrice(Math.round(value * 100))}
            min={0}
            max={Math.round(price / 100)}
            decimalScale={2}
            fixedDecimalScale
            decimalSeparator="."
            thousandSeparator=","
            hideControls
            prefix="$"
          />
          <Group justify="right" gap="xs">
            <Button size="xs" variant="default" onClick={close}>
              Cancel
            </Button>
            <Button
              leftSection={<IconUpload size="1rem" />}
              size="xs"
              onClick={handleSubmit}
              loading={submitting}>
              Submit
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Notifications />
      <Paper p="md">
        <Stack justify="center">
          <Box display="flex" direction="row" justifyContent="center">
            <Title order={3} fw="600">
              Live Creators
            </Title>
          </Box>
          <Group justify="center" align="flex-end" wrap="nowrap">
            <Flex direction="row" align="flex-end" justify="center" gap={8} miw="80%">
              <TextInput
                label="Manually import live media"
                placeholder="Enter a URL..."
                w={370}
                value={linkUrl}
                onChange={(event) => {
                  setParseError(false);
                  setLinkUrl(event.currentTarget.value);
                }}
                error={
                  parseError && "Invalid URL. Please enter an Instagram, TikTok, or YouTube URL."
                }
              />
              <Button onClick={validateAndOpenModal} loading={validating}>
                Submit
              </Button>
            </Flex>
          </Group>
          {isStaff || brandId === 18 ? (
            <>
              <Divider my="sm" />
              <Group justify="flex-end" align="center" wrap="nowrap">
                <LiveCreatorFilters timeWindow={timeWindow} setTimeWindow={setTimeWindow} />
              </Group>
            </>
          ) : null}
        </Stack>
      </Paper>
      <LayoutBase>
        {!loaded ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%">
            <Loader />
          </Box>
        ) : (
          <Box direction="column" display="flex" alignItems="center">
            <LiveCampaignCreatorResults
              liveCreatorSetEntries={liveCreatorSetEntries}
              setLiveCreatorSetEntries={setLiveCreatorSetEntries}
              liveCreatorProps={liveCreatorProps}
              campaignId={selectedCampaign.hash_id}
              timeWindow={timeWindow}
            />
          </Box>
        )}
      </LayoutBase>
    </Box>
  );
};

export default CampaignCreatorCandidatesLiveView;
