import React, { useEffect, useState } from "react";

import { Box, Group, Stack } from "@mantine/core";
import { fetchMessages } from "components/contracts/common/Api";
import EmbeddedVideoPlayer from "components/contracts/common/EmbeddedVideoPlayer";
import MessageHistory from "components/contracts/common/MessageHistory";

import Message, { MessageParty } from "components/contracts/models/Message";

import { DeliverableVideo } from "components/contracts/tasks/models/Common";
import ContentReviewActions from "components/contracts/tasks/content/ContentReviewActions";

export default function VideoDraftContentDisplay({
  taskId,
  displayName,
  deliverableVideo,
  isLatestVideoDraft,
  handleCompleteTask,
}: {
  taskId: string;
  displayName: string;
  deliverableVideo: DeliverableVideo;
  isLatestVideoDraft: boolean;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
}) {
  const [pastMessages, setPastMessages] = useState<Message[]>([]);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [messageLodingError, setMessageLoadingError] = useState(false);

  useEffect(() => {
    fetchMessages({
      deliverableId: deliverableVideo.deliverableId,
      messageParty: MessageParty.BRAND.toString(),
      videoId: deliverableVideo.videoId,
    })
      .then((response: any) => {
        const { success, messages } = response;
        if (success) {
          const deserializedMessages = messages.map((message: any) => Message.deserialize(message));
          setPastMessages(deserializedMessages);
          setMessagesLoaded(true);
        } else {
          setMessageLoadingError(true);
        }
      })
      .catch(() => {
        setMessageLoadingError(true);
      });
  }, []);

  return (
    <Stack gap="xs">
      <Group justify="center">
        <EmbeddedVideoPlayer
          url={deliverableVideo.videoLocation}
          title={deliverableVideo.title}
          caption={deliverableVideo.caption}
          showRawLink={!deliverableVideo.isTranscoded}
          integrationTimestamp={deliverableVideo.integrationTimestamp}
          isIntegration={false}
        />
      </Group>
      <Stack gap="xs">
        <MessageHistory
          messages={pastMessages}
          setMessages={setPastMessages}
          messageViewer={MessageParty.BRAND}
          messagesLoaded={messagesLoaded}
          loadingError={messageLodingError}
        />
        {isLatestVideoDraft && (
          <ContentReviewActions
            taskId={taskId}
            displayName={displayName}
            video={deliverableVideo}
            contentStatus={deliverableVideo.status}
            isMissingReferralLink={false}
            isMissingPromoCode={false}
            handleCompleteTask={handleCompleteTask}
          />
        )}
      </Stack>
    </Stack>
  );
}
