import {
  InstagramCreatorInfo,
  PlatformLinkClicks,
  TiktokCreatorInfo,
  YoutubeChannelInfo,
} from "components/discovery/Datamodels";
import { SupportedPlatform, SupportedFormat, SUPPORTED_PLATFORMS_TO_HANDLES } from "models/Common";
import { SUPPORTED_FORMATS_TO_PLATFORMS } from "components/contracts/common/Common";

export enum PlatformInfo {
  INSTAGRAM = "instagram_profile",
  TIKTOK = "tiktok_profile",
  YOUTUBE = "youtube_channel",
}

export type PlatformInfoKey = keyof typeof PlatformInfo;

export interface ActivePlatformInfo {
  youtube_channel?: YoutubeChannelInfo | null;
  tiktok_profile?: TiktokCreatorInfo | null;
  instagram_profile?: InstagramCreatorInfo | null;
}

export const getHandlesFromPlatformInfo = (
  checkedPlatformList: Array<SupportedFormat>,
  platformInfo: ActivePlatformInfo,
) => {
  const seenPlatforms: Record<SupportedPlatform, boolean> = {
    [SupportedPlatform.INSTAGRAM]: null,
    [SupportedPlatform.TIKTOK]: null,
    [SupportedPlatform.YOUTUBE]: null,
    [SupportedPlatform.NONE]: null,
  };
  const platformHandles: Record<string, string> = {
    instagramHandle: null,
    tiktokHandle: null,
    youtubeHandle: null,
  };

  checkedPlatformList.forEach((format) => {
    const platformName = SUPPORTED_FORMATS_TO_PLATFORMS[format];
    if (!seenPlatforms[platformName]) {
      seenPlatforms[platformName] = true;
    }
  });

  Object.entries(platformInfo)
    .filter(([key, __]) => {
      const platformName = key.split("_")[0] as SupportedPlatform;
      return seenPlatforms[platformName];
    })
    .forEach(([platform, value]) => {
      let platformHandle = "";
      let handle = null;
      const strippedPlatform = platform.split("_")[0];
      if (strippedPlatform.includes("instagram") || strippedPlatform.includes("tiktok")) {
        handle = value as InstagramCreatorInfo | TiktokCreatorInfo;
        platformHandle = handle?.info?.handle;
        platformHandle = "@".concat(platformHandle);
      } else {
        handle = value as YoutubeChannelInfo;
        platformHandle = handle?.handle;
      }
      const platformHandleKey =
        SUPPORTED_PLATFORMS_TO_HANDLES[strippedPlatform as SupportedPlatform];
      platformHandles[platformHandleKey] = platformHandle;
    });
  return platformHandles;
};
