import React from "react";

import { Avatar, Anchor, Badge, Card, Flex, Group, Stack, Text } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import {
  IconFileText,
  IconLink,
  IconLockOpen,
  IconVideo,
  IconVideoPlus,
} from "@tabler/icons-react";

import { TaskStage } from "components/contracts/tasks/models/Common";
import { Task } from "components/contracts/tasks/models/Task";
import CreatorDrawer from "components/contracts/review/CreatorDrawer";

import CreatorAvatar from "components/contracts/tasks/common/CreatorAvatar";
import TaskCard from "components/contracts/tasks/cards/TaskCard";

export function TaskTypeBadge({ taskType }: { taskType: TaskStage }) {
  if (taskType === TaskStage.LIVE_VERIFICATION) {
    return (
      <Badge leftSection={<IconVideoPlus size={16} />} variant="light">
        Live Verification
      </Badge>
    );
  } else if (taskType === TaskStage.CONTENT_PRODUCTION) {
    return (
      <Badge leftSection={<IconVideo size={16} />} variant="light">
        Content Production
      </Badge>
    );
  } else if (taskType === TaskStage.CODES_LINKS) {
    return (
      <Badge leftSection={<IconLink size={16} />} variant="light">
        Codes & Links
      </Badge>
    );
  } else if (taskType === TaskStage.PRODUCT_ACCESS) {
    return (
      <Badge leftSection={<IconLockOpen size={16} />} variant="light">
        Product Access
      </Badge>
    );
  } else if (taskType === TaskStage.CONTRACT_REVIEW) {
    return (
      <Badge leftSection={<IconFileText size={16} />} variant="light">
        Contract Review
      </Badge>
    );
  }
  return null;
}

// TODO(albert): Add a struct for the primary task displayed in the card
// and the related tasks.
export default function ContractCard({
  primaryTask,
  taskType,
  handleCompleteTask,
  isCompletedTask,
}: {
  primaryTask: Task;
  taskType: TaskStage;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
  isCompletedTask?: boolean;
}) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <CreatorDrawer creatorId={primaryTask.creatorId} opened={opened} close={close} />
      <Card p="sm" radius="md" withBorder bg="#F8F9FA">
        <Group justify="space-between">
          <CreatorAvatar
            displayName={primaryTask.displayName}
            avatarUrl={primaryTask.avatarUrl}
            description={
              <Anchor href={primaryTask.contractUrl} target="_blank">
                <Text size="xs" fw="500">
                  View Contract
                </Text>
              </Anchor>
            }
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              open();
            }}
          />
          <TaskTypeBadge taskType={taskType} />
        </Group>
        <TaskCard
          task={primaryTask}
          taskType={taskType}
          isCompletedTask={isCompletedTask}
          handleCompleteTask={handleCompleteTask}
        />
      </Card>
    </>
  );
}
