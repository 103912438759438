import React, { useRef } from "react";

import { Anchor, Box, Container, List, Text } from "@mantine/core";

import { ContractStatus } from "components/contracts/common/Common";

import {
  Addendum,
  CompensationSection,
  DEFAULT_LIST_SPACING,
  DEFAULT_SECTION_PADDING,
  FW_BOLD,
  RepeatCondition,
  SingleDeliverableLiveDate,
  SpacedDivider,
  TermsSection,
} from "components/contracts/contract/CampaignContractComponents";
import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";
import header from "components/contracts/contract/TitleHeader";
import Deliverable from "components/contracts/models/Deliverable";

import {
  SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES,
  RecurrenceFrequency,
  RecurrenceType,
} from "models/Common";

const UGCUsageRightsSection = ({
  brandName,
  multiDeliverable,
}: {
  brandName: string;
  multiDeliverable: boolean;
}) => {
  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Usage Rights", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <Text span>
              &quot;Usage rights&quot; gives {brandName} the right to re-use your video
              {multiDeliverable && "s"}. (Most often, they&apos;ll use the video
              {multiDeliverable && "s"} in and/or recut it for social media paid ads). Don&apos;t
              worry; you still retain ownership of the video{multiDeliverable && "s"}! What
              you&apos;re doing is granting {brandName} a license to use, distribute and display
              your video{multiDeliverable && "s"} for promotional purposes across any media. This
              license is:
            </Text>
            <List withPadding type="unordered" spacing={DEFAULT_LIST_SPACING}>
              <List.Item>
                <Text span>
                  Worldwide (meaning: they can use your content anywhere in the world),
                </Text>
              </List.Item>
              <List.Item>
                <Text span>
                  Non-exclusive (meaning: you can still use the content or let other people use it
                  too-{brandName} doesn&apos;t have sole rights), and
                </Text>
              </List.Item>
              <List.Item>
                <Text span>
                  Royalty-free (meaning: {brandName} doesn&apos;t have to pay you extra money every
                  time they use the content).
                </Text>
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            <Text span>
              {brandName} also has the right to edit, modify, crop, or adapt the video
              {multiDeliverable && "s"} as needed, provided that these edits do not misrepresent
              your original intent or opinion.
            </Text>
          </List.Item>
          <List.Item>
            <Text span>
              As a part of this, the final copy of the video{multiDeliverable && "s"} that you
              upload will be sent to {brandName}.
            </Text>
          </List.Item>
        </List>
      </Container>
    </Box>
  );
};

function UGCCampaignSection({
  contractStatus,
  brandName,
  sortOrder,
  deliverables,
  updateDeliverableTimeline,
}: {
  contractStatus: ContractStatus;
  brandName: string;
  sortOrder: number[];
  deliverables: Deliverable[];
  updateDeliverableTimeline: (deliverableId: string, newTimeline: DeliverableTimeline) => void;
}) {
  const liveDateText = (
    <SingleDeliverableLiveDate
      contractStatus={contractStatus}
      brandName={brandName}
      liveDateHeading="Final Content Due Date: "
      deliverable={deliverables[sortOrder[0]]}
      updateDeliverableTimeline={updateDeliverableTimeline}
    />
  );

  return (
    <Box>
      {header("Campaign", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <Text span fw={FW_BOLD}>
              Brand:{" "}
            </Text>
            <Text span>{brandName}</Text>
          </List.Item>
          <List.Item>{liveDateText}</List.Item>
        </List>
      </Container>
    </Box>
  );
}

function DeliverableDetails({
  deliverable,
  brandName,
  creatorFirstName,
  creativeBriefUrl,
  numberOfDeliverables,
}: {
  deliverable: Deliverable;
  brandName: string;
  creatorFirstName: string;
  creativeBriefUrl: string;
  numberOfDeliverables: number;
}) {
  return (
    <>
      <Box p={DEFAULT_SECTION_PADDING}>
        {header("Deliverables", 3)}
        <Container mx="xs">
          <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
            <List.Item>
              <Text span>
                {numberOfDeliverables} video{numberOfDeliverables > 1 ? "s" : ""}, created by{" "}
                {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle} that
                follows the requirements and guidelines stated in the Creative Brief for this
                campaign{" "}
                <Anchor href={creativeBriefUrl} target="_blank">
                  here
                </Anchor>
                .
              </Text>
            </List.Item>
            <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
              <List.Item>
                <Text span>
                  Video should be original and created solely by the creator or their team.
                </Text>
              </List.Item>
              <List.Item>
                <Text span>
                  Video should not infringe on any third-party intellectual property rights (e.g.,
                  using licensed music).
                </Text>
              </List.Item>
              <List.Item>
                <Text span>
                  Reminder: This contract is exclusively for user-generated content
                  (&quot;UGC&quot;). You are not required to, and you should not, post this video on
                  any of your socials.
                </Text>
              </List.Item>
            </List>
            <List.Item>
              Usage rights{" "}
              {deliverable.usageRightsInPerpetuity
                ? "in perpetuity"
                : `for ${deliverable.usageRightsDays} days`}
              , starting on the date that {brandName} approves the final version of the video.
            </List.Item>
          </List>
        </Container>
      </Box>
      <Box p={DEFAULT_SECTION_PADDING}>
        {header("Timeline", 3)}
        <Container mx="xs">
          <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
            {deliverable.timeline.requiresScriptReview && (
              <List.Item>
                <Text span>
                  {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle}{" "}
                  submits video concept {"(< 200 words) "}
                  by:{" "}
                  <Text span fw={FW_BOLD}>
                    {deliverable.timeline.scriptDate.toDateString()}
                  </Text>
                </Text>
              </List.Item>
            )}
            {deliverable.timeline.requiresScriptReview && (
              <List.Item>
                <Text span>
                  {brandName} provides concept feedback by:{" "}
                  <Text span fw={FW_BOLD}>
                    {deliverable.timeline.scriptRevisionDate.toDateString()}
                  </Text>
                </Text>
              </List.Item>
            )}
            <List.Item>
              <Text span>
                {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle} submits
                {SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[deliverable.platform]} video draft by:{" "}
                <Text span fw={FW_BOLD}>
                  {deliverable.timeline.videoDraftDate.toDateString()}
                </Text>
              </Text>
            </List.Item>
            <List.Item>
              <Text span>
                {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle} submits
                final version of video by:{" "}
                <Text span fw={FW_BOLD}>
                  {deliverable.timeline.liveDate.toDateString()}
                </Text>
                <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                  <List.Item>
                    <Text fs="italic" span>
                      Final video must incorporate feedback/revisions by {brandName}.
                    </Text>
                  </List.Item>
                </List>
              </Text>
            </List.Item>
          </List>
        </Container>
      </Box>
    </>
  );
}

function DeliverablesSection({
  sortOrder,
  deliverables,
  brandName,
  creatorFirstName,
  creativeBriefUrl,
}: {
  sortOrder: number[];
  deliverables: Deliverable[];
  brandName: string;
  creatorFirstName: string;
  creativeBriefUrl: string;
}) {
  return (
    <DeliverableDetails
      deliverable={deliverables[sortOrder[0]]}
      brandName={brandName}
      creatorFirstName={creatorFirstName}
      creativeBriefUrl={creativeBriefUrl}
      numberOfDeliverables={deliverables.length}
    />
  );
}

export const UGCCampaignContract = ({
  contractStatus,
  creatorFirstName,
  brandName,
  contractAmount,
  bonusAmount,
  bonusCondition,
  deliverables,
  updateDeliverableTimeline,
  addendum,
  creativeBriefUrl,
  repeatContract,
  repeatInterval,
  repeatFrequency,
  repeatType,
  repeatNumOccurrences,
}: {
  contractStatus: ContractStatus;
  creatorFirstName: string;
  brandName: string;
  contractAmount: number;
  bonusAmount: number;
  bonusCondition: string;
  deliverables: Deliverable[];
  updateDeliverableTimeline: (deliverableId: string, timeline: DeliverableTimeline) => void;
  addendum: string;
  creativeBriefUrl: string;
  repeatContract?: boolean;
  repeatInterval?: number;
  repeatFrequency?: RecurrenceFrequency;
  repeatType?: RecurrenceType;
  repeatNumOccurrences?: number;
}) => {
  if (deliverables === null || deliverables.length === 0) {
    return null;
  }
  // Sort Order for Deliverables
  const sortOrder = useRef(
    Array.from({ length: deliverables.length }, (_, i) => i).sort((a, b) => {
      const aLiveDate = deliverables[a].timeline.liveDate;
      const bLiveDate = deliverables[b].timeline.liveDate;

      if (aLiveDate < bLiveDate) {
        return -1;
      } else if (aLiveDate > bLiveDate) {
        return 1;
      }
      return 0;
    }),
  );

  return (
    <Box>
      {repeatContract && (
        <RepeatCondition
          repeatInterval={repeatInterval}
          repeatFrequency={repeatFrequency}
          repeatType={repeatType}
          repeatNumOccurrences={repeatNumOccurrences}
        />
      )}
      <UGCCampaignSection
        contractStatus={contractStatus}
        brandName={brandName}
        sortOrder={sortOrder.current}
        deliverables={deliverables}
        updateDeliverableTimeline={updateDeliverableTimeline}
      />
      <SpacedDivider />
      <DeliverablesSection
        sortOrder={sortOrder.current}
        deliverables={deliverables}
        brandName={brandName}
        creatorFirstName={creatorFirstName}
        creativeBriefUrl={creativeBriefUrl}
      />
      <SpacedDivider />
      <TermsSection />
      <SpacedDivider />
      <CompensationSection
        contractAmount={contractAmount}
        bonusAmount={bonusAmount}
        bonusCondition={bonusCondition}
        willGoLive={false}
      />
      <SpacedDivider />
      <UGCUsageRightsSection brandName={brandName} multiDeliverable={deliverables.length > 1} />
      {addendum !== null && addendum.length > 0 && (
        <>
          <SpacedDivider />
          <Addendum addendum={addendum} />
        </>
      )}
    </Box>
  );
};

export default null;
