import React, { useState } from "react";
import { User } from "firebase/auth";
import { Modal, Text, Flex, Button, Box, Link, Icon, Heading, IconButton, Spinner } from "gestalt";

import Spacer from "components/Spacer";
import { processAuthResponse } from "social/fb/FBDataUtils";
import { AuthenticationState, SetAuthState } from "onboarding/OnboardingUtils";

enum ModalStates {
  INSTRUCTIONS = 1,
  LOADING = 2,
  NO_PAGE = 3,
  NO_PROFILE = 4,
  INSUFFICIENT_PERMISSIONS = 5,
}

const InstructionsBody = () => (
  <>
    <Text>Before continuing, confirm you have a:</Text>
    <ol style={{ lineHeight: 2 }}>
      <li>
        <Text>
          <Link href="https://help.instagram.com/502981923235522" inline target="blank">
            Business Account
          </Link>{" "}
          or{" "}
          <Link
            href="https://help.instagram.com/2358103564437429?helpref=faq_content"
            inline
            target="blank">
            Instagram Creator Account
          </Link>
        </Text>
      </li>
      <li>
        <Text>
          <Link
            href="https://www.facebook.com/business/tools/facebook-pages/get-started"
            inline
            target="blank">
            Business Facebook Page
          </Link>
        </Text>
      </li>
      <li>
        <Link href="https://help.instagram.com/570895513091465" inline target="blank">
          Instagram account linked to Facebook Page
        </Link>
      </li>
    </ol>
    <Text size="200">
      If you meet these requirements, proceed by{" "}
      <Text inline size="200" weight="bold">
        enabling all permissions
      </Text>{" "}
      in the following screens. Do not attempt to connect more than one Instagram profile or
      Facebook page at a time.
    </Text>
    <Spacer height={24} />
    <Flex gap={2} alignItems="center">
      <Icon size="24" accessibilityLabel="Your information is private" icon="lock" />
      <Text size="200" color="subtle">
        <Text size="200" inline weight="bold" color="subtle">
          Your data is secure.
        </Text>{" "}
        It&apos;s only used to verify eligibility for campaigns and generating insights for
        registered brands.
      </Text>
    </Flex>
  </>
);

const ErrorBody = ({ modalState }: { modalState: ModalStates }) => {
  if (modalState === ModalStates.NO_PROFILE) {
    return (
      <Flex direction="column" gap={6}>
        <Text color="error">Error: We couldn&apos;t find your Instagram account</Text>
        <Text>
          To fix this error, please make sure you&apos;ve converted your Instagram account into an{" "}
          <Link
            href="https://help.instagram.com/2358103564437429?helpref=faq_content"
            inline
            target="blank">
            Instagram Creator Account
          </Link>{" "}
          and{" "}
          <Link href="https://help.instagram.com/570895513091465" inline target="blank">
            linked that Instagram account to your Facebook Page
          </Link>
          .
        </Text>
      </Flex>
    );
  }
  return (
    <Flex direction="column" gap={6}>
      <Text color="error">Error: We couldn&apos;t find your Facebook Page</Text>
      <Text>
        To fix this error, please make sure you&apos;ve created a{" "}
        <Link
          href="https://www.facebook.com/business/tools/facebook-pages/get-started"
          inline
          target="blank">
          Business Facebook Page
        </Link>{" "}
        and that you&apos;ve{" "}
        <Link href="https://help.instagram.com/570895513091465" inline target="blank">
          linked your Instagram account to that Facebook Page
        </Link>
        .
      </Text>
    </Flex>
  );
};

const ModalBody = ({ modalState }: { modalState: ModalStates }) => {
  switch (modalState) {
    case ModalStates.INSTRUCTIONS:
      return <InstructionsBody />;
    case ModalStates.LOADING:
      return <Spinner show accessibilityLabel="Instagram Loading Spinner" />;
    case ModalStates.NO_PAGE:
    case ModalStates.NO_PROFILE:
      return <ErrorBody modalState={modalState} />;
    default:
      return <Text>Unknown Error. Please refresh</Text>;
  }
};

const ModalHeader = ({
  modalState,
  setShowInstructions,
}: {
  modalState: ModalStates;
  setShowInstructions: (showInstructions: boolean) => void;
}) => {
  const headerContent =
    modalState === ModalStates.NO_PAGE || modalState === ModalStates.NO_PROFILE ? (
      <Flex gap={2} alignItems="center">
        <Icon icon="alert" accessibilityLabel="alert" color="error" size="24" />
        <Heading size="500">Error connecting Instagram</Heading>
      </Flex>
    ) : (
      <Heading size="500">How to connect to Instagram</Heading>
    );

  return (
    <Box padding={9}>
      <Flex direction="row" justifyContent="between" alignItems="center">
        {headerContent}
        <IconButton
          accessibilityLabel="Dismiss modal"
          icon="cancel"
          iconColor="darkGray"
          onClick={() => setShowInstructions(false)}
        />
      </Flex>
    </Box>
  );
};

const loginCallback = async (
  setAuth: SetAuthState,
  setModalState: (modalState: ModalStates) => void,
  authResponse: fb.AuthResponse,
  setShowInstructions: (showInstructions: boolean) => void,
) => {
  const numProfiles = await processAuthResponse(authResponse);
  // If we are able to find a FB page and IG profile associated with their FB login, then
  // we can return success.
  if (numProfiles.num_pages > 0 && numProfiles.num_profiles > 0) {
    setShowInstructions(false);
    setAuth(AuthenticationState.AUTHENTICATED);
  } else if (numProfiles.num_pages <= 0) {
    setModalState(ModalStates.NO_PAGE);
    setAuth(AuthenticationState.NOT_AUTHENTICATED);
  } else if (numProfiles.num_profiles <= 0) {
    setModalState(ModalStates.NO_PROFILE);
    setAuth(AuthenticationState.NOT_AUTHENTICATED);
  } else {
    // in theory, should never get to this state, but in case it does, we just reset everything
    // and have to user try and log in again.
    setShowInstructions(false);
    setModalState(ModalStates.INSTRUCTIONS);
    setAuth(AuthenticationState.NOT_AUTHENTICATED);
  }
};

const login = async (
  setAuth: SetAuthState,
  setModalState: (modalState: ModalStates) => void,
  setShowInstructions: (showInstructions: boolean) => void,
) => {
  setModalState(ModalStates.LOADING);
  window.FB.login(
    (response: fb.StatusResponse) => {
      console.log(response);
      if (response.status === "connected") {
        console.log(response.authResponse.accessToken);
      }
      loginCallback(setAuth, setModalState, response.authResponse, setShowInstructions);
    },
    {
      // config_id: "1499295554285203",
      scope:
        "instagram_basic,pages_read_engagement,pages_show_list,instagram_manage_insights,business_management",
    },
  );
};

const InstructionModal = ({
  setAuth,
  setShowInstructions,
}: {
  setAuth: SetAuthState;
  setShowInstructions: (showInstructions: boolean) => void;
}) => {
  const [modalState, setModalState] = useState(ModalStates.INSTRUCTIONS);

  return (
    <Modal
      align="start"
      size="sm"
      heading={<ModalHeader modalState={modalState} setShowInstructions={setShowInstructions} />}
      accessibilityModalLabel="Facebook Authentication Instructions"
      onDismiss={() => {
        setShowInstructions(false);
      }}
      footer={
        <Flex justifyContent="end" gap={2}>
          <Button color="gray" text="Cancel" onClick={() => setShowInstructions(false)} />
          {modalState !== ModalStates.LOADING && (
            <Button
              color="blue"
              text={modalState === ModalStates.INSTRUCTIONS ? "Connect Instagram" : "Try Again"}
              onClick={() => login(setAuth, setModalState, setShowInstructions)}
            />
          )}
        </Flex>
      }>
      <Box direction="column" paddingX={9}>
        <ModalBody modalState={modalState} />
      </Box>
    </Modal>
  );
};

export default InstructionModal;
