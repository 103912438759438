import React from "react";

import {
  Avatar,
  ActionIcon,
  Card,
  CopyButton,
  Divider,
  Flex,
  Group,
  Paper,
  Text,
  Tooltip,
} from "@mantine/core";

import {
  IconAd2,
  IconCheck,
  IconCircleCheck,
  IconCircleX,
  IconCopy,
  IconDownload,
  IconExternalLink,
} from "@tabler/icons-react";

import {
  SupportedFormat,
  SupportedPlatform,
  SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES,
} from "models/Common";

import { toShortDateString } from "utils/DateUtils";

import { SUPPORTED_FORMATS_TO_LABELS } from "components/contracts/common/Common";
import PlatformLogo, { UgcLogo } from "components/contracts/common/PlatformLogo";
import DisplayContent from "components/contracts/content/DisplayContent";
import RequestUsageRights from "components/contracts/content/RequestUsageRights";
import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import LiveContentModel from "components/contracts/models/LiveContentModel";

const DEFAULT_WIDTH = 360;
const PADDING_WIDTH = 20;

function UsageRightsStatus({
  usageRightsInPerpetuity,
  usageRightsEndDate,
  usageRightsActive,
  size,
}: {
  usageRightsInPerpetuity: boolean;
  usageRightsEndDate: Date;
  usageRightsActive: boolean;
  size: string | number;
}) {
  let label = <Text size="sm">No Usage Rights</Text>;
  let icon = <IconCircleX size={size} color="red" stroke={2} />;

  if (usageRightsInPerpetuity) {
    label = (
      <Text size="sm">
        Usage Rights{" "}
        <Text span fw="600">
          In Perpetuity
        </Text>
      </Text>
    );
    icon = <IconCircleCheck size={size || "1rem"} color="green" stroke={2} />;
  } else if (usageRightsEndDate) {
    if (usageRightsActive) {
      label = (
        <Text size="sm">
          Usage Rights until{" "}
          <Text span fw="600">
            {toShortDateString(usageRightsEndDate)}
          </Text>
        </Text>
      );
      icon = <IconCircleCheck size={size || "1rem"} color="green" stroke={2} />;
    } else {
      label = (
        <Text size="sm">
          Usage Rights Expired on{" "}
          <Text span fw="600">
            {toShortDateString(usageRightsEndDate)}
          </Text>
        </Text>
      );
      icon = <IconCircleX size={size || "1rem"} color="red" stroke={2} />;
    }
  }

  return (
    <Flex gap="0.3rem" align="center">
      {icon}
      {label}
    </Flex>
  );
}

function CopyAdCode({
  platform,
  adCode,
  disabled,
}: {
  platform: SupportedPlatform;
  adCode: string;
  disabled: boolean;
}) {
  if (!adCode) {
    return null;
  }

  let tooltip = "";

  if (platform === SupportedPlatform.INSTAGRAM) {
    tooltip = "Copy Instagram Ad Code";
  } else if (platform === SupportedPlatform.TIKTOK) {
    tooltip = "Copy TikTok Spark Code";
  } else {
    return null;
  }

  return (
    <CopyButton value={adCode} timeout={1000}>
      {({ copied, copy }) => (
        <Tooltip label={copied ? "Copied!" : tooltip}>
          <ActionIcon
            variant="subtle"
            color={copied ? "teal" : "dark"}
            onClick={copy}
            size="md"
            disabled={disabled}>
            {copied ? <IconCheck size="1.2rem" /> : <IconCopy size="1.2rem" stroke={2} />}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  );
}

function CardControls({
  contractId,
  deliverableId,
  contractAmount,
  contractDeliverableCount,
  platform,
  liveContentUrl,
  adCode,
  rawVideoDownloadUrl,
  usageRightsActive,
  relatedContent,
}: {
  contractId: string;
  deliverableId: string;
  contractAmount: number;
  contractDeliverableCount: number;
  platform: SupportedPlatform;
  liveContentUrl: string;
  adCode: string;
  rawVideoDownloadUrl: string;
  usageRightsActive: boolean;
  relatedContent: LiveContentModel[] | DeliverableVideo[];
}) {
  const platformDisplayName = SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform];

  const downloadDisabled = !usageRightsActive || !rawVideoDownloadUrl;

  return (
    <ActionIcon.Group>
      <RequestUsageRights
        contractId={contractId}
        selectedDeliverableId={deliverableId}
        contractAmount={contractAmount}
        contractDeliverableCount={contractDeliverableCount}
        usageRightsActive={usageRightsActive}
        relatedContent={relatedContent}
        icon={<IconAd2 size="1.2rem" color="black" stroke={2} />}
      />
      {!downloadDisabled && (
        <Tooltip label="Download Video">
          <ActionIcon
            variant="subtle"
            color="dark"
            size="md"
            component="a"
            href={rawVideoDownloadUrl}
            target="_blank">
            <IconDownload size="1.2rem" color="black" stroke={2} />
          </ActionIcon>
        </Tooltip>
      )}
      <CopyAdCode platform={platform} adCode={adCode} disabled={!usageRightsActive} />
      {liveContentUrl && (
        <Tooltip label={`View on ${platformDisplayName}`}>
          <ActionIcon
            variant="subtle"
            color="dark"
            size="md"
            component="a"
            href={liveContentUrl}
            target="_blank">
            <IconExternalLink size="1.2rem" color="black" stroke={2} />
          </ActionIcon>
        </Tooltip>
      )}
    </ActionIcon.Group>
  );
}

export default function ContentCard({
  contractId,
  deliverableId,
  contractAmount,
  contractDeliverableCount,
  platform,
  format,
  creatorHandle,
  avatarUrl,
  profileLink,
  liveContentUrl,
  numViews,
  numClicks,
  numLikes,
  rawVideoDownloadUrl,
  playableVideoUrl,
  title,
  caption,
  adCode,
  usageRightsEndDate,
  usageRightsInPerpetuity,
  publishedAt,
  relatedContent,
  disableClicks,
  isUgc,
}: {
  contractId: string;
  deliverableId: string;
  contractAmount: number;
  contractDeliverableCount: number;
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  avatarUrl: string;
  profileLink: string;
  liveContentUrl: string;
  numViews: number;
  numClicks: number;
  numLikes: number;
  rawVideoDownloadUrl: string;
  playableVideoUrl: string;
  title: string;
  caption: string;
  adCode: string;
  usageRightsEndDate: Date;
  usageRightsInPerpetuity: boolean;
  publishedAt: Date;
  relatedContent: LiveContentModel[] | DeliverableVideo[];
  disableClicks?: boolean;
  isUgc?: boolean;
}) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const usageRightsActive = usageRightsInPerpetuity || new Date(usageRightsEndDate) >= currentDate;

  return (
    <Card shadow="md" radius="md" w={DEFAULT_WIDTH} withBorder>
      <Card.Section py="xs" px="md">
        <Group justify="space-between">
          <Paper
            shadow="none"
            radius={0}
            component="a"
            href={profileLink}
            target="_blank"
            style={{ cursor: "pointer", textDecoration: "none" }}>
            <Flex align="center" gap="sm">
              <Avatar src={avatarUrl} radius="xl" />
              <Text fw="500" c="black">
                {creatorHandle}
              </Text>
            </Flex>
          </Paper>
          {isUgc ? (
            <UgcLogo label="UGC Video" size={24} />
          ) : (
            <PlatformLogo
              platform={platform}
              label={`${SUPPORTED_FORMATS_TO_LABELS[format]}`}
              size={24}
            />
          )}
        </Group>
      </Card.Section>
      <Card.Section w={DEFAULT_WIDTH}>
        <Flex direction="column">
          <DisplayContent
            deliverableId={deliverableId}
            playableVideoUrl={playableVideoUrl}
            publishedAt={publishedAt}
            numViews={numViews}
            numClicks={numClicks}
            numLikes={numLikes}
            title={title}
            caption={caption}
            contentWidth={DEFAULT_WIDTH}
            paddingWidth={PADDING_WIDTH}
            disableClicks={disableClicks}
          />
          <Divider mt={8} mb={4} />
        </Flex>
      </Card.Section>
      <Card.Section>
        <Group justify="space-between" px={6} pb={6}>
          <UsageRightsStatus
            usageRightsInPerpetuity={usageRightsInPerpetuity}
            usageRightsEndDate={usageRightsEndDate}
            usageRightsActive={usageRightsActive}
            size="1.3rem"
          />
          <CardControls
            contractId={contractId}
            deliverableId={deliverableId}
            contractAmount={contractAmount}
            contractDeliverableCount={contractDeliverableCount}
            platform={isUgc ? null : platform}
            liveContentUrl={liveContentUrl}
            adCode={adCode}
            rawVideoDownloadUrl={rawVideoDownloadUrl}
            usageRightsActive={usageRightsActive}
            relatedContent={relatedContent}
          />
        </Group>
      </Card.Section>
    </Card>
  );
}
