import React, { useEffect, useState } from "react";

import { Container, Loader, Select, Stack } from "@mantine/core";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { AdminCreatorDebug } from "admin/app/labeling/AdminCreatorDebug";
import { AdminCreatorPlatformSection } from "admin/app/labeling/AdminCreatorPlatformSection";
import { CreatorInfo, getCreatorInfoFromDetails } from "components/creator_lists/CreatorListsUtils";
import { RecommendationAttribution } from "components/creator_lists/LabelingUtils";
import { fetchCreatorSetDetails } from "components/creator_sets/CreatorSetUtils";
import { CreatorDetails, CreatorSet } from "components/discovery/Datamodels";

const CreatorSetSelectorCard = ({
  creatorSetId,
  setCreatorSetId,
}: {
  creatorSetId: number;
  setCreatorSetId: (creatorSetId: number) => void;
}) => {
  const { creatorSets } = useAdminAppContext();

  const [creatorSetOptions, setCreatorSetOptions] = useState<{ label: string; value: string }[]>(
    [],
  );

  useEffect(() => {
    setCreatorSetOptions(creatorSets.map((cs) => ({ label: cs.name, value: cs.id.toString() })));
  }, [creatorSets]);

  return (
    <Select
      label="Creator Set"
      placeholder="All"
      data={creatorSetOptions}
      value={creatorSetId?.toString() || ""}
      onChange={(value) => setCreatorSetId(Number(value))}
      searchable
    />
  );
};

export const AdminCreatorLabelingMain = ({
  creatorId,
  creatorSetId,
  setCreatorSetId,
  cachedCreatorDetails,
  setCachedCreatorDetails,
  isLoadingCreatorDetails,
  setIsLoadingCreatorDetails,
  attributionsMap,
}: {
  creatorId: number;
  creatorSetId: number;
  setCreatorSetId: (creatorSetId: number) => void;
  cachedCreatorDetails: Record<number, CreatorDetails>;
  setCachedCreatorDetails: (creatorDetails: Record<number, CreatorDetails>) => void;
  isLoadingCreatorDetails: boolean;
  setIsLoadingCreatorDetails: (isLoading: boolean) => void;
  attributionsMap: Record<number, RecommendationAttribution[]>;
}) => {
  // Fetch the creator details
  const [creatorDetails, setCreatorDetails] = useState<CreatorDetails | null>(null);
  const [allPlatformCreatorDetails, setAllPlatformCreatorDetails] = useState<CreatorInfo[]>([]);
  const [attributions, setAttributions] = useState<RecommendationAttribution[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (creatorId && !cachedCreatorDetails[creatorId]) {
      setIsLoadingCreatorDetails(true);
      // set attributions
      setAttributions(attributionsMap[creatorId] || []);
      fetchCreatorSetDetails(
        [creatorId],
        cachedCreatorDetails,
        setCachedCreatorDetails,
        abortController,
      ).finally(() => {
        setIsLoadingCreatorDetails(false);
      });
    }
  }, [creatorId]);

  useEffect(() => {
    if (creatorId && cachedCreatorDetails[creatorId]) {
      setCreatorDetails(cachedCreatorDetails[creatorId]);
    }
  }, [cachedCreatorDetails, creatorId]);

  useEffect(() => {
    if (creatorDetails !== null) {
      const allPlatormCreatorDetails = (creatorDetails.platform_priority ?? ["all"]).map(
        (platform) => getCreatorInfoFromDetails(creatorDetails, platform),
      );
      setAllPlatformCreatorDetails(allPlatormCreatorDetails);
    }
  }, [creatorDetails]);

  if (!creatorSetId) {
    return null;
  }

  if (!creatorId || creatorDetails === null || isLoadingCreatorDetails) {
    return (
      <Container fluid h="100%">
        <Loader h="100%" />
      </Container>
    );
  }

  return (
    <Stack p="md" justify="flex-start" w="100%">
      <AdminCreatorDebug creatorId={creatorId} attributions={attributions} />
      {allPlatformCreatorDetails.map((creatorInfo, index) => {
        const platform = (creatorDetails.platform_priority ?? ["youtube", "tiktok", "instagram"])[
          index
        ];
        return (
          <AdminCreatorPlatformSection
            key={platform}
            creatorId={creatorId}
            creatorInfo={creatorInfo}
            creatorDetails={creatorDetails}
            platform={platform}
          />
        );
      })}
    </Stack>
  );
};

export default AdminCreatorLabelingMain;
