// List view for the campaign datasets page
// This view is used for labelling datasets
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Checkbox,
  Code,
  Flex,
  Group,
  Loader,
  MultiSelect,
  NumberInput,
  Paper,
  Radio,
  Select,
  Stack,
  Table,
  TagsInput,
  Text,
  TextInput,
} from "@mantine/core";
import { User } from "firebase/auth";
import "gestalt/dist/gestalt.css";
import { deflate, inflate } from "pako";
import { useOutletContext, useSearchParams } from "react-router-dom";

import { fetchAdminBrandData } from "admin/AdminUtils";
import { AuthContext } from "auth/AuthContext";
import { CreatorListView } from "components/creator/CreatorListView";
import { BrandState } from "components/creator_lists/CreatorListsUtils";
import {
  ATTRIBUTION_TYPE_LABELS,
  DatasetFilterDict,
  FilteredReasonStat,
  getCampaignInfoForLabeling,
  getLabelingDatasetItems,
  labelBrandApproveCreator,
  LabelBrandApproveCreatorParams,
  LabelingDatasetItemsResponse,
  LabelingDatasetProps,
  RecommendationAttribution,
  tagLabelingDatasetCreator,
  TagLabelingDatasetCreatorParams,
} from "components/creator_lists/LabelingUtils";
import { CreatorDetails, Platform } from "components/discovery/Datamodels";
import LayoutBase from "components/LayoutBase";
import { BrandCreatorSetPlatformFilterSelector } from "components/search/BrandCreatorSetPlatformFilterSelector";
import { SEARCH_STRATEGIES } from "components/search/SelectorComponents";

export enum ReviewState {
  UNKNOWN = 0,
  NEEDS_REVIEW = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

const BoolCheckbox = ({
  creatorId,
  user,
  idVal,
  idLabel,
  keyName,
  externKeyValue,
}: {
  creatorId: number;
  user: User;
  idVal: string;
  idLabel: string;
  keyName: "isEnglish" | "creatorInVideo" | "isCreator";
  externKeyValue: boolean;
}) => {
  const [internalVal, setInternalVal] = useState(externKeyValue);

  const tagDatasetCreatorParams: TagLabelingDatasetCreatorParams = {
    creatorId,
    user,
  };

  return (
    <Checkbox
      id={idVal}
      label={idLabel}
      checked={internalVal}
      onChange={(e) => {
        const boolVal = e.currentTarget.checked;

        if (keyName === "isEnglish") {
          tagDatasetCreatorParams.isEnglish = boolVal;
        } else if (keyName === "creatorInVideo") {
          tagDatasetCreatorParams.creatorInVideo = boolVal;
        } else if (keyName === "isCreator") {
          tagDatasetCreatorParams.isCreator = boolVal;
        }
        tagLabelingDatasetCreator(tagDatasetCreatorParams);

        setInternalVal(boolVal);
      }}
    />
  );
};

const RadioGroupScale = ({
  creatorId,
  user,
  label,
  leftLabel,
  rightLabel,
  id,
  keyName,
  externKeyValue,
}: {
  creatorId: number;
  user: User;
  label: string;
  leftLabel: string;
  rightLabel: string;
  id: string;
  keyName: "brandSafety" | "qualityScore";
  externKeyValue: number;
}) => {
  const safeExternKeyValue =
    externKeyValue === null || externKeyValue === undefined ? 0 : externKeyValue;
  const [internalVal, setInternalVal] = useState(safeExternKeyValue);

  const tagDatasetCreatorParams: TagLabelingDatasetCreatorParams = {
    creatorId,
    user,
  };

  return (
    <Stack gap="sm">
      <Text size="md" fw="bold">
        {label}
      </Text>
      <Flex display="flex" direction="row">
        <Box w={100}>
          <Text>{leftLabel}</Text>
        </Box>
        <Group>
          <Radio
            id={`${id}-${creatorId}-1`}
            label="1"
            value="1"
            checked={internalVal === 1}
            onChange={(e) => {
              if (keyName === "brandSafety") {
                tagDatasetCreatorParams.brandSafety = 1;
              } else if (keyName === "qualityScore") {
                tagDatasetCreatorParams.qualityScore = 1;
              }

              tagLabelingDatasetCreator(tagDatasetCreatorParams);
              setInternalVal(1);
            }}
          />
          <Radio
            id={`${id}-${creatorId}-2`}
            label="2"
            value="2"
            checked={internalVal === 2}
            onChange={() => {
              if (keyName === "brandSafety") {
                tagDatasetCreatorParams.brandSafety = 2;
              } else if (keyName === "qualityScore") {
                tagDatasetCreatorParams.qualityScore = 2;
              }

              tagLabelingDatasetCreator(tagDatasetCreatorParams);
              setInternalVal(2);
            }}
          />
          <Radio
            id={`${id}-${creatorId}-3`}
            label="3"
            value="3"
            checked={internalVal === 3}
            onChange={() => {
              if (keyName === "brandSafety") {
                tagDatasetCreatorParams.brandSafety = 3;
              } else if (keyName === "qualityScore") {
                tagDatasetCreatorParams.qualityScore = 3;
              }

              tagLabelingDatasetCreator(tagDatasetCreatorParams);
              setInternalVal(3);
            }}
          />
          <Radio
            id={`${id}-${creatorId}-4`}
            label="4"
            value="4"
            checked={internalVal === 4}
            onChange={() => {
              if (keyName === "brandSafety") {
                tagDatasetCreatorParams.brandSafety = 4;
              } else if (keyName === "qualityScore") {
                tagDatasetCreatorParams.qualityScore = 4;
              }

              tagLabelingDatasetCreator(tagDatasetCreatorParams);
              setInternalVal(4);
            }}
          />
          <Radio
            id={`${id}-${creatorId}-5`}
            label="5"
            value="5"
            checked={internalVal === 5}
            onChange={() => {
              if (keyName === "brandSafety") {
                tagDatasetCreatorParams.brandSafety = 5;
              } else if (keyName === "qualityScore") {
                tagDatasetCreatorParams.qualityScore = 5;
              }

              tagLabelingDatasetCreator(tagDatasetCreatorParams);
              setInternalVal(5);
            }}
          />
        </Group>
        <Flex justify="flex-end">
          <Text>{rightLabel}</Text>
        </Flex>
      </Flex>
    </Stack>
  );
};

const LabelCreatorSetCreator = ({
  user,
  creator,
  brandId,
  campaignParamsId,
  creatorSetIdToName,
  reviewState,
  creatorSetId,
  platform,
  seeds,
  searchStrategy,
  attribution = [],
}: {
  user: User;
  creator: CreatorDetails;
  brandId: number;
  campaignParamsId: number;
  creatorSetIdToName: Record<number, string>;
  reviewState: ReviewState;
  creatorSetId: number;
  platform: Platform;
  seeds: string[];
  searchStrategy: string;
  attribution?: RecommendationAttribution[];
}) => {
  const creatorId = creator.creator_id;
  const [brandApprovalState, setBrandApprovalState] = useState(reviewState);
  const [brandApprovalMsg, setBrandApprovalMsg] = useState("");
  const [brandApprovalMsgColor, setBrandApprovalMsgColor] = useState("yellow");

  const labelBrandApproveCreatorParams: LabelBrandApproveCreatorParams = {
    creatorId,
    user,
    brandId,
    creatorSetId,
    platform,
    seeds,
    searchStrategy,
    campaignParamsId,
    attribution,
  };

  return (
    <Stack py={2}>
      <Text>{`Match ${creatorSetIdToName[creatorSetId]} requirements`}</Text>
      <Group>
        <Button
          value={String(ReviewState.ACCEPTED)}
          key={`creator-set-brand-accept-${creatorId}`}
          id={`creator-set-brand-accept-${creatorId}`}
          color="blue"
          variant={brandApprovalState === ReviewState.ACCEPTED ? "filled" : "light"}
          onClick={() => {
            setBrandApprovalState(ReviewState.ACCEPTED);
            labelBrandApproveCreatorParams.reviewState = ReviewState.ACCEPTED;
            setBrandApprovalMsg("Submitting Accept.. Please wait");
            setBrandApprovalMsgColor("yellow");
            labelBrandApproveCreator(labelBrandApproveCreatorParams).then((response) => {
              setBrandApprovalMsg(response.message);
              const brandMsgColor = response.pass_filter ? "green" : "red";
              setBrandApprovalMsgColor(brandMsgColor);
            });
          }}>
          Accept
        </Button>
        <Button
          value={String(ReviewState.REJECTED)}
          key={`creator-set-brand-reject-${creatorId}`}
          id={`creator-set-brand-reject-${creatorId}`}
          color="red"
          variant={brandApprovalState === ReviewState.REJECTED ? "filled" : "light"}
          onClick={() => {
            setBrandApprovalState(ReviewState.REJECTED);
            labelBrandApproveCreatorParams.reviewState = ReviewState.REJECTED;
            setBrandApprovalMsg("Submitting Reject.. Please wait");
            setBrandApprovalMsgColor("yellow");
            labelBrandApproveCreator(labelBrandApproveCreatorParams).then((response) => {
              setBrandApprovalMsg(response.message);
              setBrandApprovalMsgColor("red");
            });
          }}>
          Reject
        </Button>
      </Group>
      <Text c={brandApprovalMsgColor}>{brandApprovalMsg}</Text>
    </Stack>
  );
};

const LabelingDatasetListEntry = ({
  creator,
  user,
  creatorProps,
  // Passing this through so we can update the creator props in the parent
  // We aren't using this for now because we don't need to have things synced up
  // but once the use cases arise we should use this
  setCreatorProps,
  brandId,
  campaignParamsId,
  creatorSetId,
  creatorSetIdToName,
  platform,
  seeds,
  searchStrategy,
  attribution,
}: {
  creator: CreatorDetails;
  user: User;
  creatorProps: LabelingDatasetProps;
  setCreatorProps: (creatorId: number, props: LabelingDatasetProps) => void;
  brandId: number;
  campaignParamsId: number;
  creatorSetId: number;
  creatorSetIdToName: Record<number, string>;
  platform: Platform;
  seeds: string[];
  searchStrategy: string;
  attribution: RecommendationAttribution[];
}) => {
  const creatorId = creator.creator_id;
  const actionButtons = (
    <Group justify="space-between">
      <Paper w={400}>
        <Flex display="flex" direction="column" align="flex-start">
          <Stack>
            <Text size="md" fw="bold">
              Relevance & Quality
            </Text>
            <Code block>
              {attribution?.length > 0 &&
                attribution.map((att) => (
                  <Text size="xs" key={att.source}>{`${att.source} (${
                    ATTRIBUTION_TYPE_LABELS[att.type]
                  }): ${att.score}`}</Text>
                ))}
            </Code>
            <LabelCreatorSetCreator
              user={user}
              creator={creator}
              brandId={brandId}
              campaignParamsId={campaignParamsId}
              reviewState={creatorProps.review_state}
              creatorSetId={creatorSetId}
              platform={platform}
              creatorSetIdToName={creatorSetIdToName}
              seeds={seeds}
              searchStrategy={searchStrategy}
              attribution={attribution}
            />
          </Stack>
        </Flex>
      </Paper>
      <Paper w={600}>
        <Flex display="flex" direction="column" gap="sm">
          <RadioGroupScale
            creatorId={creator.creator_id}
            user={user}
            keyName="brandSafety"
            externKeyValue={creatorProps.brand_safety_score}
            label="Brand Safety"
            leftLabel="Not Safe"
            rightLabel="Very Safe"
            id="brand-safety"
          />
          <RadioGroupScale
            creatorId={creator.creator_id}
            user={user}
            keyName="qualityScore"
            externKeyValue={creatorProps.quality_score}
            label="Quality Score"
            leftLabel="Low Quality"
            rightLabel="High Quality"
            id="quality-score"
          />
          <Text size="md" fw="bold">
            Must Haves
          </Text>
          <Group display="flex">
            <BoolCheckbox
              creatorId={creatorId}
              user={user}
              keyName="isEnglish"
              idVal={`creator-${creatorId}-is-english`}
              idLabel="English"
              externKeyValue={creatorProps.is_english}
            />
            <BoolCheckbox
              creatorId={creatorId}
              user={user}
              keyName="isCreator"
              idVal={`creator-${creatorId}-is-creator`}
              idLabel="Creator"
              externKeyValue={creatorProps.is_creator}
            />
            <BoolCheckbox
              creatorId={creatorId}
              user={user}
              keyName="creatorInVideo"
              idVal={`creator-${creatorId}-in-video`}
              idLabel="In Video"
              externKeyValue={creatorProps.creator_in_videos}
            />
          </Group>
          <Stack gap="xs">
            <Text>
              Brand state:{" "}
              {creatorProps.brand_state === 0 ? "NONE" : BrandState[creatorProps.brand_state]}
            </Text>
          </Stack>
        </Flex>
      </Paper>
    </Group>
  );

  return (
    <CreatorListView
      user={user}
      creatorId={creator.creator_id}
      platform={platform}
      key={`entry-${creatorId}`}
      creatorDetails={creator}
      actions={null}
      extendedActions={actionButtons}
      listWidth={1200}
      numThumbnails={4}
    />
  );
};

const LabelingDatasetEntries = ({
  labelingDatasetItems,
  user,
  brandId,
  campaignParamsId,
  creatorSetId,
  platform,
  seeds,
  searchStrategy,
}: {
  labelingDatasetItems: LabelingDatasetItemsResponse;
  user: User;
  brandId: number;
  campaignParamsId: number;
  creatorSetId: number;
  platform: Platform;
  seeds: string[];
  searchStrategy: string;
}) => {
  // Use a copy of creator props so that we can update it.
  // The creatorProps is only usable in this parent component, not something
  // more global like the LabelingDatasetsListView
  const { creatorProps, ...others } = labelingDatasetItems;
  const setCreatorProps = (creatorId: number, props: LabelingDatasetProps) => {
    creatorProps[creatorId] = props;
  };

  if (!(labelingDatasetItems?.creatorDetails?.length > 0)) {
    return <Text>No creators found.</Text>;
  }

  return (
    <Box>
      {labelingDatasetItems.creatorDetails.map((creator) => (
        <LabelingDatasetListEntry
          key={`label-entry-${creator.creator_id}`}
          creator={creator}
          user={user}
          platform={platform}
          creatorProps={creatorProps[creator.creator_id]}
          setCreatorProps={setCreatorProps}
          brandId={brandId}
          campaignParamsId={campaignParamsId}
          creatorSetId={creatorSetId}
          creatorSetIdToName={labelingDatasetItems.creatorSetIdToName}
          seeds={seeds}
          searchStrategy={searchStrategy}
          attribution={
            labelingDatasetItems?.attribution &&
            labelingDatasetItems.attribution[creator.creator_id]
          }
        />
      ))}
    </Box>
  );
};

const fetchLabelingItems = async (
  requestUser: User,
  brandId: number,
  campaignParameterId: number,
  creatorSetId: number,
  platform: string,
  seeds: string[],
  searchStrategy: string,
  setLabelingDatasetItems: (items: LabelingDatasetItemsResponse) => void,
  filters: Record<string, DatasetFilterDict>,
  pageNum: number,
  pageSize: number,
  useRelevanceSort: boolean,
  relevanceText: string,
  abortController?: AbortController,
) => {
  const filterList = Object.values(filters).map((filter) => ({
    filterName: filter.filterName,
    filterParams: filter.filterParams,
  }));

  const datasetItems = await getLabelingDatasetItems(
    requestUser,
    brandId,
    campaignParameterId,
    creatorSetId,
    seeds,
    searchStrategy,
    pageNum,
    pageSize,
    useRelevanceSort,
    relevanceText,
    platform,
    filterList,
    abortController,
  );
  setLabelingDatasetItems(datasetItems);
};

export const LabelingDatasetFilterInfoDisplay = ({
  filteredStats,
}: {
  filteredStats: FilteredReasonStat[];
}) => (
  <Paper shadow="xs" style={{ maxWidth: "800px", margin: "20px" }}>
    <Table>
      <thead>
        <tr>
          <th>Filter reason</th>
          <th>Num filtered</th>
          <th>Remaining</th>
        </tr>
      </thead>
      <tbody>
        {filteredStats &&
          filteredStats.map((stat) => (
            <tr key={stat.reason}>
              <td>{stat.reason}</td>
              <td>{stat.count}</td>
              <td>{stat.remaining}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  </Paper>
);

const LabelingDatasetItemCard = ({
  labelingDatasetItems,
}: {
  labelingDatasetItems: LabelingDatasetItemsResponse;
}) => {
  const hasMsg = labelingDatasetItems !== null && labelingDatasetItems?.searchMsg?.length > 0;
  return (
    <Card>
      {hasMsg && (
        <Box>
          {labelingDatasetItems.searchMsg.split("\n").map((line: string, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={`${line}-${index}`}>{line}</Text>
          ))}
        </Box>
      )}
    </Card>
  );
};

const MinNumberFilter = ({
  datasetFilterMap,
  setDatasetFilterMap,
  datasetFilterName,
  filterKey,
  filterLabel,
}: {
  datasetFilterMap: Record<string, DatasetFilterDict>;
  setDatasetFilterMap: (filterMap: Record<string, DatasetFilterDict>) => void;
  datasetFilterName: string;
  filterKey: string;
  filterLabel: string;
}) => {
  const datasetFilter = datasetFilterMap[datasetFilterName];

  const getInitialValue = () => {
    if (datasetFilter && datasetFilter.filterParams) {
      return Number(datasetFilter.filterParams[filterKey]);
    }
    return 0;
  };

  const [minVal, setMinVal] = useState(getInitialValue);

  useEffect(() => {
    setMinVal(getInitialValue());
  }, [datasetFilterMap]);

  return (
    <NumberInput
      thousandSeparator
      label={filterLabel}
      value={Number(minVal)}
      onChange={(v: number) => {
        setMinVal(v);
        setDatasetFilterMap({
          ...datasetFilterMap,
          [datasetFilterName]: {
            filterName: datasetFilter.filterName,
            filterParams: {
              ...datasetFilter.filterParams,
              [filterKey]: Number(v),
            },
          },
        });
      }}
    />
  );
};

const MultiSelectorWithMissingCountryFilter = ({
  datasetFilterMap,
  setDatasetFilterMap,
  datasetFilterName,
  filterArgKey,
  filterArgOptionKey,
  filterReadableName,
}: {
  datasetFilterMap: Record<string, DatasetFilterDict>;
  setDatasetFilterMap: (filterMap: Record<string, DatasetFilterDict>) => void;
  datasetFilterName: string;
  filterArgKey: string;
  filterArgOptionKey: string;
  filterReadableName: string;
}) => {
  const datasetFilter = datasetFilterMap[datasetFilterName];
  const [selectValues, setSelectValues] = useState<string[] | null>([]);
  // TODO(kevin): Make this a variable settable from the backend next month
  // once all the searches have been using the new filter. in the meantime hardcode
  // the selection options
  const selectOptions = ["US", "CA", "GB", "AU", "NZ", "MX", "Rest of World"];
  const [checkedMissingCountry, setCheckedMissingCountry] = useState<boolean>(
    Boolean(datasetFilterMap[datasetFilterName].filterParams.includeMissingCountry),
  );

  const getInitialSelectValues = () => {
    if (datasetFilter && datasetFilter.filterParams && datasetFilter.filterParams[filterArgKey]) {
      if (typeof datasetFilter.filterParams[filterArgKey] === "string") {
        return [datasetFilter.filterParams[filterArgKey] as string];
      }
      return datasetFilter.filterParams[filterArgKey] as string[];
    }
    return [];
  };

  useEffect(() => {
    setSelectValues(getInitialSelectValues);
  }, [datasetFilterMap]);

  return (
    <Group>
      <MultiSelect
        id={`multi-filter-${filterArgKey}`}
        label={filterReadableName}
        value={selectValues}
        onChange={(value) => {
          setSelectValues(value);
          setDatasetFilterMap({
            ...datasetFilterMap,
            [datasetFilterName]: {
              filterName: datasetFilter.filterName,
              filterParams: {
                ...datasetFilter.filterParams,
                [filterArgKey]: value,
              },
            },
          });
        }}
        data={selectOptions.map((option) => ({
          label: option,
          value: option,
        }))}
        searchable
      />
      <Checkbox
        checked={checkedMissingCountry}
        label="Include missing country"
        onChange={(e) => {
          const innerCheck = e.currentTarget.checked;
          setCheckedMissingCountry(innerCheck);
          setDatasetFilterMap({
            ...datasetFilterMap,
            [datasetFilterName]: {
              filterName: datasetFilter.filterName,
              filterParams: {
                ...datasetFilter.filterParams,
                includeMissingCountry: innerCheck,
              },
            },
          });
        }}
      />
    </Group>
  );
};

const SelectorFilter = ({
  datasetFilterMap,
  setDatasetFilterMap,
  datasetFilterName,
  filterArgKey,
  filterArgOptionKey,
  filterReadableName,
}: {
  datasetFilterMap: Record<string, DatasetFilterDict>;
  setDatasetFilterMap: (filterMap: Record<string, DatasetFilterDict>) => void;
  datasetFilterName: string;
  filterArgKey: string;
  filterArgOptionKey: string;
  filterReadableName: string;
}) => {
  const datasetFilter = datasetFilterMap[datasetFilterName];
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);

  const getInitialSelectValue = () => {
    if (datasetFilter && datasetFilter.filterParams && datasetFilter.filterParams[filterArgKey]) {
      return datasetFilter.filterParams[filterArgKey] as string;
    }
    return null;
  };

  const getInitialSelectOptions = () => {
    if (
      datasetFilter &&
      datasetFilter.filterParams &&
      datasetFilter.filterParams[filterArgOptionKey]
    ) {
      return datasetFilter.filterParams[filterArgOptionKey] as string[];
    }
    return [];
  };

  useEffect(() => {
    setSelectValue(getInitialSelectValue);
    setSelectOptions(getInitialSelectOptions);
  }, [datasetFilterMap]);

  return (
    <Group>
      <Select
        id={`filter-${filterArgKey}`}
        label={filterReadableName}
        value={selectValue}
        onChange={(value) => {
          setSelectValue(value);
          setDatasetFilterMap({
            ...datasetFilterMap,
            [datasetFilterName]: {
              filterName: datasetFilter.filterName,
              filterParams: {
                ...datasetFilter.filterParams,
                [filterArgKey]: value,
              },
            },
          });
        }}
        data={selectOptions.map((option) => ({
          label: option,
          value: option,
        }))}
        searchable
      />
    </Group>
  );
};

const NumberRangeFilter = ({
  datasetFilterMap,
  setDatasetFilterMap,
  datasetFilterName,
  lowerRangeFilterKey,
  upperRangeFilterKey,
  filterReadableName,
}: {
  datasetFilterMap: Record<string, DatasetFilterDict>;
  setDatasetFilterMap: (filterMap: Record<string, DatasetFilterDict>) => void;
  datasetFilterName: string;
  lowerRangeFilterKey: string;
  upperRangeFilterKey: string;
  filterReadableName: string;
}) => {
  const datasetFilter = datasetFilterMap[datasetFilterName];
  const getInitialLowerValue = () => {
    if (datasetFilter && datasetFilter.filterParams) {
      return Number(datasetFilter.filterParams[lowerRangeFilterKey]);
    }
    return 0;
  };

  const getInitialUpperValue = () => {
    if (datasetFilter && datasetFilter.filterParams) {
      return Number(datasetFilter.filterParams[upperRangeFilterKey]);
    }
    return 0;
  };

  const [lowerVal, setLowerVal] = useState(getInitialLowerValue);
  const [upperVal, setUpperVal] = useState(getInitialUpperValue);

  useEffect(() => {
    setLowerVal(getInitialLowerValue());
    setUpperVal(getInitialUpperValue());
  }, [datasetFilterMap]);

  return (
    <Group>
      <NumberInput
        thousandSeparator
        label={`${filterReadableName} (Min)`}
        value={Number(lowerVal)}
        onChange={(v: number) => {
          setLowerVal(v);
          setDatasetFilterMap({
            ...datasetFilterMap,
            [datasetFilterName]: {
              filterName: datasetFilter.filterName,
              filterParams: {
                ...datasetFilter.filterParams,
                [lowerRangeFilterKey]: Number(v),
              },
            },
          });
        }}
      />

      <NumberInput
        thousandSeparator
        label={`${filterReadableName} (Max)`}
        value={Number(upperVal)}
        onChange={(v: number) => {
          setUpperVal(v);
          setDatasetFilterMap({
            ...datasetFilterMap,
            [datasetFilterName]: {
              filterName: datasetFilter.filterName,
              filterParams: {
                ...datasetFilter.filterParams,
                [upperRangeFilterKey]: Number(v),
              },
            },
          });
        }}
      />
    </Group>
  );
};

const TextFilter = ({
  datasetFilterMap,
  setDatasetFilterMap,
  datasetFilterName,
  filterArgKey,
  filterReadableName,
}: {
  datasetFilterMap: Record<string, DatasetFilterDict>;
  setDatasetFilterMap: (filterMap: Record<string, DatasetFilterDict>) => void;
  datasetFilterName: string;
  filterArgKey: string;
  filterReadableName: string;
}) => {
  const datasetFilter = datasetFilterMap[datasetFilterName];
  const getInitTextVals = () => {
    if (datasetFilter && datasetFilter.filterParams && datasetFilter.filterParams[filterArgKey]) {
      return Array.from(datasetFilter.filterParams[filterArgKey] as string[], String);
    }
    return [];
  };

  const [textVals, setTextVals] = useState([]);
  useEffect(() => {
    setTextVals(getInitTextVals);
  }, [datasetFilterMap]);

  return (
    <TagsInput
      id={`filter-${filterArgKey}`}
      value={textVals}
      label={filterReadableName}
      onChange={(values) => {
        setTextVals(values);
        setDatasetFilterMap({
          ...datasetFilterMap,
          [datasetFilterName]: {
            filterName: datasetFilter.filterName,
            filterParams: {
              ...datasetFilter.filterParams,
              [filterArgKey]: values,
            },
          },
        });
      }}
      splitChars={[",", " "]}
    />
  );
};

const CheckboxFilter = ({
  datasetFilterMap,
  setDatasetFilterMap,
  datasetFilterName,
  filterArgKey,
  filterReadableName,
}: {
  datasetFilterMap: Record<string, DatasetFilterDict>;
  setDatasetFilterMap: (filterMap: Record<string, DatasetFilterDict>) => void;
  datasetFilterName: string;
  filterArgKey: string;
  filterReadableName: string;
}) => {
  const datasetFilter = datasetFilterMap[datasetFilterName];
  const getInitCheckVal = () => {
    if (datasetFilter && datasetFilter.filterParams && datasetFilter.filterParams[filterArgKey]) {
      return Boolean(datasetFilter.filterParams[filterArgKey]);
    }
    return false;
  };

  const [checked, setChecked] = useState(getInitCheckVal);
  useEffect(() => {
    setChecked(getInitCheckVal());
  }, [datasetFilterMap]);

  return (
    <Checkbox
      checked={checked}
      label={filterReadableName}
      onChange={(e) => {
        const innerCheck = e.currentTarget.checked;
        setChecked(innerCheck);
        setDatasetFilterMap({
          ...datasetFilterMap,
          [datasetFilterName]: {
            filterName: datasetFilter.filterName,
            filterParams: {
              ...datasetFilter.filterParams,
              [filterArgKey]: innerCheck,
            },
          },
        });
      }}
    />
  );
};

const makeDatasetFiltersMap = (datasetFilters: DatasetFilterDict[]) => {
  // return a map of filterName to datasetFilter
  const datasetFilterMap: Record<string, DatasetFilterDict> = {};
  datasetFilters.forEach((datasetFilter) => {
    datasetFilterMap[datasetFilter.filterName] = datasetFilter;
  });
  return datasetFilterMap;
};

const LabelingSearchConfiguration = ({
  pageNum,
  setPageNum,
  pageSize,
  setPageSize,
  useRelevanceSort,
  setUseRelevanceSort,
  relevanceText,
  setRelevanceText,
}: {
  pageNum: number;
  setPageNum: (pageNum: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  useRelevanceSort: boolean;
  setUseRelevanceSort: (useRelevanceSort: boolean) => void;
  relevanceText: string;
  setRelevanceText: (relevanceText: string) => void;
}) => (
  <Flex direction="column" gap="sm">
    <Text size="md" fw="bold">
      Configurations
    </Text>
    <Group>
      <Text size="sm">Page configurations:</Text>
      <NumberInput
        label="Page Number"
        value={pageNum}
        onChange={(v: number) => {
          setPageNum(v);
        }}
      />
      <NumberInput
        label="Page Size"
        value={pageSize}
        onChange={(v: number) => {
          setPageSize(v);
        }}
      />
    </Group>
    <Group>
      <Text size="sm">Sorting options:</Text>
      <Checkbox
        checked={useRelevanceSort}
        label="Sort by relevance"
        onChange={(e) => {
          const innerCheck = e.currentTarget.checked;
          setUseRelevanceSort(innerCheck);
        }}
      />
      <TextInput
        label="(Optional) Custom relevance sort"
        value={relevanceText}
        description="Sort creators by relevance to this text"
        onChange={(e) => {
          setRelevanceText(e.currentTarget.value);
        }}
      />
    </Group>
  </Flex>
);

const LabelingSearchButton = ({
  user,
  brandId,
  campaignParameterId,
  creatorSetId,
  platform,
  seeds,
  searchStrategy,
  setLabelingDatasetItems,
  datasetFilterMap,
  pageNum,
  pageSize,
  useRelevanceSort,
  relevanceText,
  campaignDatasetFilters,
  setDatasetFilterMap,
  setLoading,
}: {
  user: User;
  brandId: number;
  campaignParameterId: number;
  creatorSetId: number;
  platform: string;
  seeds: string[];
  searchStrategy: string;
  setLabelingDatasetItems: (items: LabelingDatasetItemsResponse) => void;
  datasetFilterMap: Record<string, DatasetFilterDict>;
  pageNum: number;
  pageSize: number;
  useRelevanceSort: boolean;
  relevanceText: string;
  campaignDatasetFilters: DatasetFilterDict[];
  setDatasetFilterMap: (filterMap: Record<string, DatasetFilterDict>) => void;
  setLoading: (loading: boolean) => void;
}) => (
  <Group>
    <Button
      variant="filled"
      onClick={() => {
        const abortController = new AbortController();
        setLoading(true);

        fetchLabelingItems(
          user,
          brandId,
          campaignParameterId,
          creatorSetId,
          platform,
          seeds,
          searchStrategy,
          setLabelingDatasetItems,
          datasetFilterMap,
          pageNum,
          pageSize,
          useRelevanceSort,
          relevanceText,
        ).finally(() => {
          setLoading(false);
        });
        return () => {
          abortController.abort();
        };
      }}>
      Search creators
    </Button>
    <Button
      variant="filled"
      onClick={() => {
        setDatasetFilterMap(makeDatasetFiltersMap(campaignDatasetFilters));
      }}>
      Reset filters
    </Button>
  </Group>
);

const NextPageButton = ({
  user,
  brandId,
  campaignParameterId,
  creatorSetId,
  platform,
  seeds,
  searchStrategy,
  setLabelingDatasetItems,
  datasetFilterMap,
  pageNum,
  pageSize,
  useRelevanceSort,
  relevanceText,
  setPageNum,
  setLoading,
}: {
  user: User;
  brandId: number;
  campaignParameterId: number;
  creatorSetId: number;
  platform: string;
  seeds: string[];
  searchStrategy: string;
  setLabelingDatasetItems: (items: LabelingDatasetItemsResponse) => void;
  datasetFilterMap: Record<string, DatasetFilterDict>;
  pageNum: number;
  pageSize: number;
  useRelevanceSort: boolean;
  relevanceText: string;
  setPageNum: (pageNum: number) => void;
  setLoading: (loading: boolean) => void;
}) => (
  <Group>
    <Button
      variant="filled"
      onClick={() => {
        setLoading(true);
        fetchLabelingItems(
          user,
          brandId,
          campaignParameterId,
          creatorSetId,
          platform,
          seeds,
          searchStrategy,
          setLabelingDatasetItems,
          datasetFilterMap,
          Number(pageNum) + 1,
          pageSize,
          useRelevanceSort,
          relevanceText,
        ).finally(() => {
          setLoading(false);
        });
        setPageNum(Number(pageNum) + 1);
      }}>
      Next Page
    </Button>
  </Group>
);

const LabelingDatasetFilters = ({
  datasetFilterMap,
  setDatasetFilterMap,
}: {
  datasetFilterMap: Record<string, DatasetFilterDict>;
  setDatasetFilterMap: (filterMap: Record<string, DatasetFilterDict>) => void;
}) => (
  <Flex gap="sm" direction="column">
    {datasetFilterMap !== null &&
    datasetFilterMap !== undefined &&
    Object.keys(datasetFilterMap).length > 0 ? (
      <Flex direction="column" gap="sm">
        <Text size="md" fw="bold">
          Filters
        </Text>
        <Group>
          <Text size="sm" fw="bold">
            Campaign Duplicates filters:
          </Text>
          <CheckboxFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="netNew"
            filterArgKey="netNew"
            filterReadableName="Remove creators labeled by the brand"
          />
        </Group>
        <Group>
          <Text size="sm" fw="bold">
            Creator Set filters:
          </Text>
          <CheckboxFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="creatorSetLabeled"
            filterArgKey="creatorSetLabeled"
            filterReadableName="Remove creators labeled for this Creator Set"
          />
        </Group>
        <Group>
          <Text size="sm" fw="bold">
            Email filters:
          </Text>
          <CheckboxFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="likelyEmail"
            filterArgKey="likelyEmail"
            filterReadableName="Bio description has @ (TT only)"
          />
          <CheckboxFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="creatorLikelyEmail"
            filterArgKey="likelyEmail"
            filterReadableName="Has Likely Email Heuristic (new)"
          />
        </Group>
        <Group>
          <Text size="sm" fw="bold">
            Language filters:
          </Text>
          <SelectorFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="languageFilter"
            filterArgKey="language"
            filterArgOptionKey="languages"
            filterReadableName="Select language (TT, YT only)"
          />
        </Group>
        <Group>
          <Text size="sm" fw="bold">
            Region filters:
          </Text>
          <MultiSelectorWithMissingCountryFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="countryFilter"
            filterArgKey="country"
            filterArgOptionKey="countries"
            filterReadableName="Select countries (TT, YT only)"
          />
        </Group>
        <Group>
          <Text size="sm">Video count filters: </Text>
          <MinNumberFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="videoCount"
            filterKey="videoCount"
            filterLabel="Min # of videos (all time)"
          />
          <MinNumberFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="recencyPosted"
            filterKey="minRecencyPosted"
            filterLabel="Min # of videos (28d)"
          />
        </Group>
        <Group>
          <Text size="sm">Follower count filter: </Text>
          <NumberRangeFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="followerCount"
            lowerRangeFilterKey="minFollowerCount"
            upperRangeFilterKey="maxFollowerCount"
            filterReadableName="# follower"
          />
        </Group>
        <Group>
          <Text size="sm">Median view filter: </Text>
          <NumberRangeFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="medianViews"
            lowerRangeFilterKey="minMedianViews"
            upperRangeFilterKey="maxMedianViews"
            filterReadableName="Median views"
          />
        </Group>
        <Group>
          <Text size="sm">Video text filters: </Text>
          <TextFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="keywordFilter"
            filterArgKey="keywords"
            filterReadableName="Has video with Keywords"
          />
          <TextFilter
            datasetFilterMap={datasetFilterMap}
            setDatasetFilterMap={setDatasetFilterMap}
            datasetFilterName="hashtagFilter"
            filterArgKey="hashtags"
            filterReadableName="Has video with hashtags"
          />
        </Group>
      </Flex>
    ) : (
      "Loading filters.."
    )}
  </Flex>
);

type SeedType = "creator" | "hashtags" | "keywords";

interface SelectData {
  value: string;
  label: string;
}

type PlatformStrategies = Partial<Record<SeedType, SelectData[]>>;

const SetSeedInput = ({
  seedType,
  seeds,
  searchStrategy,
  setSeedType,
  setSeeds,
  setSearchStrategy,
  platform,
}: {
  seedType: SeedType;
  seeds: string[];
  searchStrategy: string;
  setSeedType: (seedType: SeedType) => void;
  setSeeds: (seeds: string[]) => void;
  setSearchStrategy: (searchStrategy: string) => void;
  platform: Platform;
}) => {
  const [availableSeedTypes, setAvailableSeedTypes] = useState<SelectData[]>([]);
  const [avaiableStrategies, setAvailableStrategies] = useState<SelectData[]>([]);
  const [error, setError] = useState(null);

  const isValidSeed = (seed: string) => {
    if (seedType === "creator") {
      return seed.startsWith("@");
    } else if (seedType === "hashtags") {
      return seed.startsWith("#");
    } else if (seedType === "keywords") {
      return !(seed.startsWith("@") || seed.startsWith("#"));
    }
    return true;
  };

  const handleAddSeed = (seed: string) => {
    if (isValidSeed(seed)) {
      setError(null);
      return true;
    }
    if (seedType === "creator") {
      setError("Invalid seed - creators need @");
    } else if (seedType === "hashtags") {
      setError("Invalid seed - hashtags need #");
    } else if (seedType === "keywords") {
      setError("Invalid seed - keywords need no prefix");
    }
    return false;
  };

  const [splitChars, setSplitChars] = useState<string[]>([",", " ", "|"]);

  // Set the seedType based off the platform
  useEffect(() => {
    if (platform === null) {
      return;
    }
    const newTypesKeys = Object.keys(SEARCH_STRATEGIES[platform]);
    const validSeedType = newTypesKeys.includes(seedType);
    const newSeedTypes = newTypesKeys.map((type) => ({
      value: type,
      label: type,
    }));
    setAvailableSeedTypes(newSeedTypes);
    // if the seedType isn't in the available seed types, clear it out
    if (!validSeedType) {
      setSeedType("creator");
    }
  }, [platform]);

  // Set the search strategy based off the platform and seed type
  useEffect(() => {
    if (platform === null || seedType === null) {
      return;
    }
    if (!(seedType in SEARCH_STRATEGIES[platform])) {
      return;
    }
    setAvailableStrategies(SEARCH_STRATEGIES[platform][seedType]);
    const strategies = Object.entries(SEARCH_STRATEGIES[platform][seedType]).map(
      ([key, value]) => value.value,
    );
    const validStrategy = strategies.includes(searchStrategy);
    if (!validStrategy) {
      setSearchStrategy(SEARCH_STRATEGIES[platform][seedType][0].value);
      setSeeds([]);
    }
  }, [seedType, platform]);

  useEffect(() => {
    if (seedType === "keywords") {
      setSplitChars([",", "|"]);
    } else {
      setSplitChars([",", " ", "|"]);
    }
  }, [seedType]);

  return (
    <Stack gap="xs">
      <Text size="md" fw="bold">
        Search queries
      </Text>
      <Group>
        <Select
          id="seed-type"
          value={seedType}
          onChange={(value: SeedType) => {
            // Split the string by commas
            if (value === null) {
              return;
            }
            setSeedType(value);
          }}
          label="Seed type"
          data={availableSeedTypes}
          searchable
        />
        <Select
          id="search-strategy"
          value={searchStrategy}
          onChange={(value) => {
            // Split the string by commas
            if (value === null) {
              return;
            }
            setSearchStrategy(value.toLowerCase());
          }}
          label="Search strategy"
          data={avaiableStrategies}
          searchable
        />
        <TagsInput
          id="seed-input"
          data={[]}
          value={seeds}
          onChange={(value) => {
            if (value.length === seeds.length + 1) {
              const lastSeed = value[value.length - 1];
              if (handleAddSeed(lastSeed)) {
                setSeeds(value);
              }
            } else {
              setSeeds(value);
            }
          }}
          label="Seeds"
          error={error}
          splitChars={splitChars}
        />
      </Group>
    </Stack>
  );
};

function compressJSON(jsonObj: object): string {
  const str: string = JSON.stringify(jsonObj);
  const compressed: Uint8Array = deflate(str);
  return btoa(String.fromCharCode(...Array.from(compressed)));
}

function decompressJSON(compressedStr: string): object {
  const compressed: Uint8Array = Uint8Array.from(atob(compressedStr), (c) => c.charCodeAt(0));
  const decompressed: string = inflate(compressed, { to: "string" });
  return JSON.parse(decompressed);
}

const LabelingDatasetsListView = () => {
  const requestUser: User = useOutletContext<AuthContext>()?.user;
  const [brandData, setBrandData] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [creatorSetId, setCreatorSetId] = useState(null);
  const [platform, setPlatform] = useState<Platform | null>(null);
  const [campaignParameterId, setCampaignParameterId] = useState(null);
  const [existingFilters, setExistingFilters] = useState(null);
  const [labelingDatasetItems, setLabelingDatasetItems] = useState(null);
  const [loadedBrandData, setLoadedBrandData] = useState(false);
  const [seeds, setSeeds] = useState([]);
  const [seedType, setSeedType] = useState<SeedType | null>(null);
  const [searchStrategy, setSearchStrategy] = useState(null);
  const [datasetFilterMap, setDatasetFilterMap] = useState<Record<string, DatasetFilterDict>>({});
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [useRelevanceSort, setUseRelevanceSort] = useState(true);
  const [relevanceText, setRelevanceText] = useState("");
  const [isUserActionChange, setIsUserActionChange] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // Parse the searchParams and set all the state
  useEffect(() => {
    if (searchParams.get("brandId") !== null) {
      setBrandId(Number(searchParams.get("brandId")));
    }
    if (searchParams.get("creatorSetId") !== null) {
      setCreatorSetId(Number(searchParams.get("creatorSetId")));
    }
    if (searchParams.get("platform") !== null) {
      setPlatform(searchParams.get("platform") as Platform);
    }
    if (searchParams.get("campaignParameterId") !== null) {
      setCampaignParameterId(Number(searchParams.get("campaignParameterId")));
    }
    if (searchParams.get("seeds") !== null && Object.keys(searchParams.get("seeds")).length > 0) {
      setSeeds(
        searchParams
          .get("seeds")
          .split(",")
          .map((seed) => decodeURIComponent(seed)),
      );
    }
    if (searchParams.get("seedType") !== null) {
      setSeedType(searchParams.get("seedType") as SeedType);
    }
    if (searchParams.get("searchStrategy") !== null) {
      if (
        [
          "tiktok_raw_hashtags_ranked",
          "tiktok_raw_hashtags",
          "tiktok_expanded_hashtags_ranked",
        ].includes(searchParams.get("searchStrategy"))
      ) {
        setSearchStrategy("tiktok_raw_hashtags_ranked");
      }
      setSearchStrategy(searchParams.get("searchStrategy"));
    }
    if (searchParams.get("pageNum") !== null) {
      setPageNum(Number(searchParams.get("pageNum")));
    }
    if (searchParams.get("pageSize") !== null) {
      setPageSize(Number(searchParams.get("pageSize")));
    }
    if (
      searchParams.get("useRelevanceSort") !== null &&
      Boolean(searchParams.get("useRelevanceSort")) !== false
    ) {
      setUseRelevanceSort(Boolean(searchParams.get("useRelevanceSort")));
    }
    if (searchParams.get("relevanceText") !== null) {
      setRelevanceText(searchParams.get("relevanceText"));
    }
    if (searchParams.get("datasetFilterMap") !== null) {
      const datasetFilters = searchParams.get("datasetFilterMap");
      if (datasetFilters !== null) {
        const datasetFilterMapParam = decompressJSON(datasetFilters) as Record<
          string,
          DatasetFilterDict
        >;
        // TEMP(andrew):
        // We should also turn on the likely email heuristic by default
        datasetFilterMapParam.creatorLikelyEmail = {
          filterName: "creatorLikelyEmail",
          filterParams: { likelyEmail: true },
        };

        setDatasetFilterMap(datasetFilterMapParam);
      }
    }
  }, []);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (brandId !== null && brandId !== 0) {
      updatedSearchParams.set("brandId", String(brandId));
    }
    if (creatorSetId !== null && creatorSetId !== 0) {
      updatedSearchParams.set("creatorSetId", String(creatorSetId));
    } else if (creatorSetId === null) {
      updatedSearchParams.delete("creatorSetId");
    }
    if (campaignParameterId !== null) {
      updatedSearchParams.set("campaignParameterId", String(campaignParameterId));
    } else if (campaignParameterId === null) {
      updatedSearchParams.delete("campaignParameterId");
    }
    if (platform !== null) {
      updatedSearchParams.set("platform", String(platform));
    }
    if (seeds.length === 0) {
      updatedSearchParams.delete("seeds");
    } else if (seeds.length > 0) {
      updatedSearchParams.set("seeds", seeds.join(","));
    }
    if (seedType !== null) {
      updatedSearchParams.set("seedType", String(seedType));
    }
    if (searchStrategy !== null) {
      updatedSearchParams.set("searchStrategy", String(searchStrategy));
    }
    if (pageNum !== null) {
      updatedSearchParams.set("pageNum", String(pageNum));
    }
    if (pageSize !== null) {
      updatedSearchParams.set("pageSize", String(pageSize));
    }
    if (useRelevanceSort !== null) {
      updatedSearchParams.set("useRelevanceSort", String(useRelevanceSort));
    }
    if (relevanceText !== null) {
      if (relevanceText === "") {
        updatedSearchParams.delete("relevanceText");
      } else {
        updatedSearchParams.set("relevanceText", String(relevanceText));
      }
    }

    const encodedDatasetMap = compressJSON(datasetFilterMap);
    if (encodedDatasetMap !== updatedSearchParams.get("datasetFilterMap")) {
      updatedSearchParams.set("datasetFilterMap", encodedDatasetMap);
    }

    setSearchParams(updatedSearchParams.toString());
  }, [
    brandId,
    creatorSetId,
    platform,
    campaignParameterId,
    seedType,
    searchStrategy,
    seeds,
    pageNum,
    pageSize,
    useRelevanceSort,
    relevanceText,
    datasetFilterMap,
  ]);

  // Want to change the datasetFilterMap when the campaign is selected, but do not override it on the first time set.
  useEffect(() => {
    if (existingFilters !== null && existingFilters !== undefined) {
      const campaignDatasetFilterDeepCopy = JSON.parse(JSON.stringify(existingFilters || []));
      setDatasetFilterMap(makeDatasetFiltersMap(campaignDatasetFilterDeepCopy));
    }
  }, [existingFilters]);

  // Grab initial campaign / creator set data.
  useEffect(() => {
    const abortController = new AbortController();
    fetchAdminBrandData(abortController).then((data) => {
      if (data && data.brands) {
        setBrandData(data.brands);
        setLoadedBrandData(true);
      }
    });
    return () => {
      abortController.abort();
    };
  }, []);

  // Set the filters
  useEffect(() => {
    if (brandId !== 0 && creatorSetId !== 0 && platform !== null && isUserActionChange) {
      getCampaignInfoForLabeling(
        requestUser,
        Number(brandId),
        Number(creatorSetId),
        platform,
        campaignParameterId,
      ).then((datasetInfo) => {
        // Automatically enable likely email heuristic
        const filters = [
          ...datasetInfo.existingFilters,
          { filterName: "creatorLikelyEmail", filterParams: { likelyEmail: true } },
        ];
        setExistingFilters(filters);
      });
    }
  }, [brandId, creatorSetId, platform, campaignParameterId]);

  // TODO(kevin): Select a filter based off the brand to use.
  // Add this into the campaignsData for selection
  return (
    <LayoutBase>
      {!loadedBrandData ? (
        "Waiting for campaign data"
      ) : (
        <Stack h={300} justify="flex-start">
          <BrandCreatorSetPlatformFilterSelector
            brandData={brandData}
            brandId={brandId}
            creatorSetId={creatorSetId}
            platform={platform}
            campaignParameterId={campaignParameterId}
            setBrandId={setBrandId}
            setPlatform={setPlatform}
            setIsUserActionChange={setIsUserActionChange}
            setCampaignParameterId={setCampaignParameterId}
            setCreatorSetId={setCreatorSetId}
          />
          <Group w={2000}>
            <LabelingDatasetFilterInfoDisplay
              filteredStats={
                labelingDatasetItems !== null ? labelingDatasetItems.filteredStats : []
              }
            />
            <LabelingDatasetItemCard labelingDatasetItems={labelingDatasetItems} />
          </Group>
          <SetSeedInput
            seedType={seedType}
            seeds={seeds}
            searchStrategy={searchStrategy}
            setSeedType={setSeedType}
            setSeeds={setSeeds}
            setSearchStrategy={setSearchStrategy}
            platform={platform}
          />
          {datasetFilterMap !== null ? (
            <LabelingDatasetFilters
              datasetFilterMap={datasetFilterMap}
              setDatasetFilterMap={setDatasetFilterMap}
            />
          ) : null}
          <LabelingSearchConfiguration
            pageNum={pageNum}
            setPageNum={setPageNum}
            pageSize={pageSize}
            setPageSize={setPageSize}
            useRelevanceSort={useRelevanceSort}
            setUseRelevanceSort={setUseRelevanceSort}
            relevanceText={relevanceText}
            setRelevanceText={setRelevanceText}
          />
          <LabelingSearchButton
            user={requestUser}
            brandId={Number(brandId)}
            campaignParameterId={Number(campaignParameterId)}
            creatorSetId={Number(creatorSetId)}
            platform={platform}
            seeds={seeds}
            searchStrategy={searchStrategy}
            setLabelingDatasetItems={setLabelingDatasetItems}
            setLoading={setSearchLoading}
            datasetFilterMap={datasetFilterMap}
            pageNum={pageNum}
            pageSize={pageSize}
            useRelevanceSort={useRelevanceSort}
            relevanceText={relevanceText}
            campaignDatasetFilters={existingFilters}
            setDatasetFilterMap={setDatasetFilterMap}
          />
          {(() => {
            if (searchLoading) {
              return <Loader />;
            } else if (!searchLoading && labelingDatasetItems !== null) {
              return (
                <LabelingDatasetEntries
                  labelingDatasetItems={labelingDatasetItems}
                  user={requestUser}
                  brandId={Number(brandId)}
                  campaignParamsId={Number(campaignParameterId)}
                  creatorSetId={Number(creatorSetId)}
                  platform={platform}
                  seeds={seeds}
                  searchStrategy={searchStrategy}
                />
              );
            }
            return <Text>Please search for creators.</Text>;
          })()}
          <NextPageButton
            user={requestUser}
            brandId={Number(brandId)}
            campaignParameterId={Number(campaignParameterId)}
            creatorSetId={Number(creatorSetId)}
            platform={platform}
            seeds={seeds}
            searchStrategy={searchStrategy}
            setLabelingDatasetItems={setLabelingDatasetItems}
            setLoading={setSearchLoading}
            datasetFilterMap={datasetFilterMap}
            pageNum={pageNum}
            pageSize={pageSize}
            useRelevanceSort={useRelevanceSort}
            relevanceText={relevanceText}
            setPageNum={setPageNum}
          />
        </Stack>
      )}
    </LayoutBase>
  );
};

export default LabelingDatasetsListView;
