import React, { useEffect, useState } from "react";

import {
  Anchor,
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Group,
  Loader,
  LoadingOverlay,
  Pill,
  Select,
  Stack,
  Stepper,
  Table,
  Text,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  IconChecks,
  IconEdit,
  IconInfoCircle,
  IconMail,
  IconMessage2,
  IconPencil,
  IconUserCheck,
} from "@tabler/icons-react";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import {
  fetchCreatorProgressOverview,
  fetchCreatorsForProgress,
} from "campaigns/api/fetchCreatorProgressOverview";
import { SocialHandle } from "components/contracts/dashboard/Utils";
import Campaign, { CreatorProgressDetail } from "models/Campaign";
import { useUser } from "utils/UserContext";
import { useDisclosure } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";
import { fromISODateString } from "utils/DateUtils";
import SocialHandleButtonRow from "components/contracts/common/SimpleSocialHandleButtonRow";

// Actions for the table and for creators
const actionOrder = [
  "Activated",
  "Contacted",
  "Responded",
  "Submitted Bids",
  "Signed Contracts",
  "Completed Contracts",
];
// Stepper states for creators. Effectively singularized versions of action order
const creatorDoneTitles = [
  "Activated",
  "Contacted",
  "Responded",
  "Submitted Bid",
  "Signed Contract",
  "Completed",
];
// Stepper titles for states that are in progress. This is the default text to show
// when the creator is currently on this step.
const creatorInProgressTitles = [
  "Activated",
  "Contacting",
  "Waiting for Response",
  "In Discussion",
  "Negotiating",
  "In Production",
];
// The creator table headings
const creatorTableHeadings = [
  "Activated",
  "Outreach",
  "Response",
  "Bidding",
  "Contracting",
  "Production",
];

const generateStepItem = ({
  creator_id,
  subtitle,
  title,
  action,
  inProgress,
  notReached,
  isArchived,
  blockReason,
}: {
  creator_id: number;
  subtitle: string;
  title: string;
  action: string;
  inProgress: boolean;
  notReached: boolean;
  isArchived: boolean;
  blockReason: string;
}) => {
  // Default icon color for the step.
  let iconColor;
  if (isArchived || (inProgress && blockReason !== "")) {
    iconColor = "#FFFFFF";
  } else if (inProgress) {
    iconColor = "#228BE6";
  } else if (notReached) {
    iconColor = "#CED4DA";
  }

  // Default text color for the title and description of the step.
  const textColor = notReached ? "#CED4DA" : undefined;

  // Default description for the step.
  let description = (
    <Text inherit size="sm" c={textColor}>
      {inProgress ? "In Progress" : subtitle}
    </Text>
  );

  // Default label (title) for the step.
  let label = (
    <Text inherit size="md" c={textColor}>
      {title}
    </Text>
  );

  // Overrides "In Progress" for certain states
  if (action === "Archived Contracts") {
    description = (
      <Text inherit size="sm" c={textColor}>
        {subtitle}
      </Text>
    );
  } else if (action === "Completed Contracts" && subtitle.length > 0) {
    description = (
      <Text inherit size="sm" c={textColor}>
        {subtitle}
      </Text>
    );
  }

  // Apply overrides based on the block reason for a state.
  let blockColor;
  let borderColor;
  if (blockReason !== "") {
    switch (action) {
      case "Contacted":
        switch (blockReason) {
          case "COULD_NOT_FIND_EMAIL":
            label = (
              <Group gap={2}>
                <Text inherit size="md" c={textColor}>
                  Unable to Contact
                </Text>
                <Tooltip
                  multiline
                  withArrow
                  color="gray.6"
                  w={220}
                  label="Unfortunately, we were unable to find an email address for this creator.">
                  <IconInfoCircle size={16} color="#ADB5BD" />
                </Tooltip>
              </Group>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          case "REJECTED_EXPENSIVE_STATUS":
            label = (
              <Group gap={2}>
                <Text inherit size="md" c={textColor}>
                  Too Expensive
                </Text>
                <Tooltip
                  multiline
                  withArrow
                  color="gray.6"
                  w={220}
                  label={
                    "Based on recent discussions with this creator, their negotiated rates exceed the " +
                    "recommended rates for this campaign. To avoid being marked as spam, we did not contact " +
                    "the creator again for this campaign."
                  }>
                  <IconInfoCircle size={16} color="#ADB5BD" />
                </Tooltip>
              </Group>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          case "GHOSTED_GTE_5X_ACROSS_BRANDS":
          case "GHOSTED_GTE_3X_FOR_BRAND":
            label = (
              <Group gap={2}>
                <Text inherit size="md" c={textColor}>
                  Unable to Contact
                </Text>
                <Tooltip
                  multiline
                  withArrow
                  color="gray.6"
                  w={220}
                  label={
                    "We’ve tried reaching out to this creator for other campaigns but have never received " +
                    "a response. To avoid being marked as spam, we did not contact the creator again for this campaign."
                  }>
                  <IconInfoCircle size={16} color="#ADB5BD" />
                </Tooltip>
              </Group>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          case "ALREADY_LINKED_TO_OTHER_BRAND":
            label = (
              <Group gap={2}>
                <Text inherit size="md" c={textColor}>
                  Calendar Full
                </Text>
                <Tooltip
                  multiline
                  withArrow
                  color="gray.6"
                  w={220}
                  label="The creator’s partnership calendar is currently full. We will retry as their calendar opens up.">
                  <IconInfoCircle size={16} color="#ADB5BD" />
                </Tooltip>
              </Group>
            );
            description = (
              <Text inherit size="sm" c={textColor}>
                Will Retry
              </Text>
            );
            blockColor = "#228BE6";
            break;
          default:
            label = (
              <Text inherit size="md" c={textColor}>
                Unable to Contact
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
        }
        break;
      case "Responded":
        switch (blockReason) {
          case "EMAIL_BOUNCE":
            label = (
              <Text inherit size="md" c={textColor}>
                Unable to Contact
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          default:
            label = (
              <Text inherit size="md" c={textColor}>
                No Response
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
        }
        break;
      case "Submitted Bids":
        switch (blockReason) {
          case "EMAIL_BOUNCE":
            label = (
              <Text inherit size="md" c={textColor}>
                Unable to Contact
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          case "REJECTED_OTHER":
            label = (
              <Text inherit size="md" c={textColor}>
                Rejected
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          default:
            label = (
              <Text inherit size="md" c={textColor}>
                Not Interested
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
        }
        break;
      case "Signed Contracts":
        switch (blockReason) {
          case "EMAIL_BOUNCE":
            label = (
              <Text inherit size="md" c={textColor}>
                Unable to Contact
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          case "REJECTED_EXPENSIVE":
          case "GHOSTED":
            label = (
              <Text inherit size="md" c={textColor}>
                Too Expensive
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          case "REJECTED_WORKED_WITH":
          case "REJECTED_OTHER":
          case "CONTRACT_REVIEW_REJECTED":
            label = (
              <Text inherit size="md" c={textColor}>
                Rejected
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
          case "CAMPAIGN_ENDED_DISCUSSION_PAUSED":
            label = (
              <Text inherit size="md" c={textColor}>
                Campaign Ended
              </Text>
            );
            description = (
              <Text inherit size="sm" c={textColor}>
                Discussion Closed
              </Text>
            );
            blockColor = "#CED4DA";
            break;
          case "WAITING_FOR_CONTRACT":
            // WAITING_FOR_CONTRACT is somewhat special in that it mimics an in progress state but
            // has a "block" reason. As a result we have to set the block color, the icon color,
            // and the border color.
            label = (
              <Text inherit size="md" c={textColor}>
                Waiting to Sign
              </Text>
            );
            description = undefined;
            blockColor = "#E7F5FF";
            iconColor = "#228BE6";
            borderColor = "#228BE6";
            break;
          case "CONTRACT_IN_REVIEW":
            label = (
              <Text inherit size="md" c={textColor}>
                Needs Review
              </Text>
            );
            description = undefined;
            blockColor = "#FA5252"; // Red state, requires action.
            break;
          default:
            break;
        }
        break;
      case "Completed Contracts":
        switch (blockReason) {
          default:
            label = (
              <Text inherit size="md" c={textColor}>
                Dropped Out
              </Text>
            );
            description = undefined;
            blockColor = "#CED4DA";
            break;
        }
        break;
      default:
        break;
    }
  }

  // The icon is chosen based on state/phase, but the color is decided above.
  let icon;
  switch (action) {
    case "Activated":
      icon = <IconUserCheck color={iconColor} />;
      break;
    case "Contacted":
      icon = <IconMail color={iconColor} />;
      break;
    case "Responded":
      icon = <IconMessage2 color={iconColor} />;
      break;
    case "Submitted Bids":
      icon = <IconEdit color={iconColor} />;
      break;
    case "Signed Contracts":
      icon = <IconPencil color={iconColor} />;
      break;
    case "Completed Contracts":
    case "Archived Contracts":
      icon = <IconChecks color={iconColor} />;
      break;
    default:
      icon = undefined;
      break;
  }

  // Overrides for the background color style.
  let buttonStyle = {};
  if (notReached) {
    buttonStyle = {
      ...buttonStyle,
      stepIcon: {
        backgroundColor: "#FFFFFF",
      },
    };
  }
  if (inProgress) {
    buttonStyle = {
      ...buttonStyle,
      stepIcon: {
        backgroundColor: "#E7F5FF",
      },
    };
  }
  // Apply specified overrides.
  if (blockColor || (inProgress && isArchived)) {
    buttonStyle = {
      ...buttonStyle,
      stepIcon: {
        backgroundColor: blockColor || "#CED4DA",
      },
    };
  }

  let minimumWidth;
  if (action === "Archived Contracts" || action === "Completed Contracts") {
    minimumWidth = 80;
  } else if (action === "Contacted") {
    minimumWidth = 130;
  }

  return (
    <Stepper.Step
      key={`${creator_id}-${action}`}
      miw="8rem"
      label={label}
      description={description}
      icon={icon}
      completedIcon={icon}
      // This will color the border for in progress states, an effective tool in combination
      // with the background color to produce a solid color for an in-progress state.
      color={borderColor || blockColor || (inProgress && isArchived ? "#CED4DA" : "#228BE6")}
      styles={
        minimumWidth
          ? {
              ...buttonStyle,
              stepLabel: {
                // Force a fixed width for the label for this step because the labels are
                // quite variable
                minWidth: minimumWidth,
              },
            }
          : buttonStyle
      }
    />
  );
};

// Convert a UTC date into a local date (so things don't appear to happen in the future).
// Returns undefined if the input string can't be converted into a local date.
function localizeDate(raw: string): string {
  if (raw === undefined || raw === "") {
    return undefined;
  }
  const d: Date = fromISODateString(raw);
  if (d === null) {
    return undefined;
  }
  return d.toLocaleDateString();
}

// Avoid using displayUsername
function getExternalDisplayUsername(socialHandles: SocialHandle[]) {
  const handle = socialHandles.find((h) => h.handle);
  return handle ? handle.handle : "";
}

const CreatorRow = ({ detail }: { detail: CreatorProgressDetail }) => {
  const [userProfile, userProfileLoading] = useUser();
  const [isStaff, setIsStaff] = useState<boolean>(false);
  let stepperIndex = -1;
  for (let i = actionOrder.length - 1; i >= 0; i -= 1) {
    if (detail.actions[actionOrder[i]] !== undefined) {
      stepperIndex = i;
      break;
    }
  }
  // Increment stepper index so that the last step is complete rather than in progress
  stepperIndex += 1;

  useEffect(() => {
    if (!userProfileLoading && userProfile?.is_staff) {
      setIsStaff(userProfile.is_staff);
    }
  }, [userProfileLoading, userProfile]);

  const externalDisplayName = getExternalDisplayUsername(detail.socialHandles);

  // If this row ultimately ends in archived, we will display its end state as
  // gray rather than the usual in-progress state
  const isArchived = detail?.actions["Archived Contracts"] !== undefined;

  return (
    <Table.Tr>
      <Table.Td>
        {isStaff && detail.creatorId ? (
          <Flex align="center" wrap="nowrap" gap="sm">
            <Avatar
              component="a"
              href={`/ops_home/creator?creatorId=${detail.creatorId}`}
              src={detail.avatarUrl}
              size="md"
              radius="xl"
            />
            <Stack gap={0.2}>
              <Anchor
                href={`/admin/creator?creatorId=${detail.creatorId}`}
                underline="hover"
                size="md"
                c="black"
                fw={700}>
                {`${detail.firstName} ${detail.lastName}`}
              </Anchor>
              <Anchor
                href={`/admin/creator?creatorId=${detail.creatorId}`}
                underline="hover"
                size="sm"
                c="gray"
                fw={700}>
                {`${externalDisplayName}`}
              </Anchor>
              <SocialHandleButtonRow socialHandles={detail.socialHandles} />
            </Stack>
          </Flex>
        ) : (
          <Flex align="center" wrap="nowrap" gap="sm">
            <Avatar src={detail.avatarUrl} size="md" radius="xl" />
            <Stack gap={0.2}>
              <Text size="md" fw={700}>{`${detail.firstName} ${detail.lastName}`}</Text>
              <Text size="sm" c="gray" fw={700}>{`${externalDisplayName}`}</Text>
              <SocialHandleButtonRow socialHandles={detail.socialHandles} />
            </Stack>
          </Flex>
        )}
      </Table.Td>
      <Table.Td>
        <Stepper
          size="xs"
          iconSize={36}
          allowNextStepsSelect={false}
          styles={{
            steps: {
              flexWrap: "nowrap",
            },
          }}
          active={stepperIndex}>
          {actionOrder.map((elt, idx) => {
            // We have to check the previous state to see if it was blocked
            const blockReason =
              idx === stepperIndex && idx > 0
                ? detail.actions[actionOrder[idx - 1]]?.block_reason || ""
                : "";
            let subtitle = `${localizeDate(detail.actions[elt]?.happened_at) || ""}`;
            let title = creatorDoneTitles.at(idx);
            if (idx > stepperIndex) {
              title = "Not Started";
            } else if (idx === stepperIndex) {
              title = creatorInProgressTitles.at(idx);
            }
            let action = elt;
            if (
              action === "Signed Contracts" &&
              detail?.contractDetails?.contract_value !== undefined
            ) {
              subtitle = `${new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              }).format(Number(detail?.contractDetails?.contract_value) / 100)} - ${subtitle}`;
            }
            if (
              action === "Completed Contracts" &&
              detail?.actions["Archived Contracts"] !== undefined
            ) {
              // The presence of archived means we should replace completed with archived
              action = "Archived Contracts";
              title = "Dropped Out";
            } else if (action === "Completed Contracts") {
              // Attach the contract details to the completed state even if we have not yet reached it
              subtitle =
                detail?.contractDetails?.live_date === undefined
                  ? subtitle
                  : `Live ${stepperIndex > idx ? "on" : "by"} ${
                      localizeDate(String(detail.contractDetails?.live_date)) || ""
                    }`;
            }
            return generateStepItem({
              creator_id: detail.creatorId,
              title,
              subtitle,
              action,
              inProgress: idx === stepperIndex,
              notReached: idx > stepperIndex,
              isArchived,
              blockReason,
            });
          })}
        </Stepper>
      </Table.Td>
    </Table.Tr>
  );
};

const CreatorTable = ({
  creatorList,
  isLoading,
}: {
  creatorList: CreatorProgressDetail[];
  isLoading: boolean;
}) => {
  return (
    <Box pos="relative">
      <LoadingOverlay visible={isLoading} />
      <Table.ScrollContainer minWidth={500} type="native">
        <Table withRowBorders stickyHeader>
          <Table.Thead bg="#F1F3F5">
            <Table.Th>Creator</Table.Th>
            <Table.Th>
              <Group bg="#F1F3F5" grow gap="xl">
                {creatorTableHeadings.map((elt, idx) => {
                  // Insert the heading as well as an empty text to cover the stepper line for all
                  // but the last element.
                  return (
                    <>
                      <Text inherit miw={idx === 1 && 130}>
                        {elt}
                      </Text>
                      {idx < creatorTableHeadings.length - 1 ? <Text /> : undefined}
                    </>
                  );
                })}
              </Group>
            </Table.Th>
          </Table.Thead>
          <Table.Tbody>
            {creatorList.map((detail, idx) => {
              return <CreatorRow detail={detail} key={`creatortable-${detail.creatorId}`} />;
            })}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Box>
  );
};

const ButtonRow = ({
  type,
  stats,
  whichSelected,
  showLoading,
  selectFn,
  loadingStateFn,
}: {
  type: string;
  stats: Record<string, Record<string, number>>;
  showLoading?: boolean;
  whichSelected: string[];
  selectFn: (value: [string, string]) => void;
  loadingStateFn: (value: boolean) => void;
}) => {
  return (
    <Group grow gap="xl">
      {actionOrder.map((elt, idx) => {
        const isSelected: boolean = whichSelected[0] === type && whichSelected[1] === elt;
        const formattedValue: string =
          stats[elt]?.value ?? -1 > 0
            ? `${new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              }).format((stats[elt]?.value || 0) / 100)}`
            : "";
        return (
          <Button
            key={`${type}-${elt}-button`}
            variant={isSelected ? "filled" : "outline"}
            color={isSelected ? "#E7F5FF" : undefined}
            radius="md"
            loading={showLoading ?? false}
            styles={{
              root: {
                height: rem(110),
              },
            }}
            onClick={() => {
              loadingStateFn(true);
              // If already selected, this is an unselect
              if (whichSelected[0] === type && whichSelected[1] === elt) {
                selectFn(["window", undefined]);
              } else {
                selectFn([type, elt]);
              }
            }}>
            <Stack
              align="center"
              gap={
                (elt === "Signed Contracts" || elt === "Completed Contracts") &&
                formattedValue !== "" &&
                0.2
              }>
              <Title order={6} textWrap="wrap" c="black">
                {elt.toUpperCase()}
              </Title>
              <Title order={2} c="black">
                {stats[elt]?.num ?? 0}
              </Title>
              {(elt === "Signed Contracts" || elt === "Completed Contracts") &&
                formattedValue !== "" && <Pill size="lg">{formattedValue}</Pill>}
            </Stack>
          </Button>
        );
      })}
    </Group>
  );
};

const CampaignCreatorProgressTable = ({
  cumulative,
  window,
  creatorList,
  selectedState,
  selectedTimeframe,
  cohortWindowType,
  setSelectedCell,
  setActivityWindowType,
  setActivityRange,
  setCohortWindowType,
  setCohortRange,
}: {
  cumulative: Record<string, Record<string, number>>;
  window: Record<string, Record<string, number>>;
  creatorList: CreatorProgressDetail[];
  selectedState: string[];
  selectedTimeframe: string;
  cohortWindowType: string;
  setSelectedCell: (value: [string, string]) => void;
  setActivityWindowType: (value: string) => void;
  setActivityRange: (value: [Date | null, Date | null]) => void;
  setCohortWindowType: (value: string) => void;
  setCohortRange: (value: [Date | null, Date | null]) => void;
}) => {
  const [isCreatorTableLoading, setCreatorTableLoading] = useState<boolean>(false);
  const [isWindowRowLoading, setWindowRowLoading] = useState<boolean>(false);
  const [isCumulativeRowLoading, setCumulativeRowLoading] = useState<boolean>(false);

  const [cohortRangeOpened, { open: openCohortRange, close: closeCohortRange }] =
    useDisclosure(false);
  const [activityRangeOpened, { open: openActivitytRange, close: closeActivityRange }] =
    useDisclosure(false);

  const [localCohortRange, setLocalCohortRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [localActivityRange, setLocalActivityRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  // New creator list -> no longer loading the creator list
  useEffect(() => {
    setCreatorTableLoading(false);
  }, [creatorList]);

  useEffect(() => {
    setWindowRowLoading(false);
  }, [window]);

  useEffect(() => {
    setCumulativeRowLoading(false);
  }, [cumulative]);

  if (!cumulative || !window) {
    return null;
  }
  return (
    <Stack>
      <Container w="100%" pt={18} pb={18} pl={18} pr={18} mr="xs" ml="xs" fluid>
        <Stack>
          <Title order={1} w="100%">
            Creator Progress
          </Title>
          <Container
            fluid
            w="100%"
            bg="var(--mantine-color-white)"
            pt={18}
            pb={18}
            pl={24}
            pr={24}
            style={{
              borderRadius: 20,
              border: "5px",
            }}>
            <Stack>
              <Group>
                <Title order={2} c="var(--mantine-color-gray-7)">
                  Cumulative Activities
                </Title>
                <Select
                  color="blue"
                  data={["All Creators", "Dates activated"]}
                  defaultValue="All Creators"
                  allowDeselect={false}
                  styles={{
                    input: {
                      backgroundColor: "#E7F5FF",
                      color: "#228BE6",
                    },
                  }}
                  onChange={(newValue) => {
                    let newCohortWindowType;
                    switch (newValue) {
                      case "All Creators":
                        newCohortWindowType = "all";
                        break;
                      case "Dates activated":
                        newCohortWindowType = "range";
                        break;
                      default:
                        newCohortWindowType = undefined;
                        break;
                    }
                    if (newCohortWindowType === cohortWindowType) {
                      setCumulativeRowLoading(false);
                      return;
                    }

                    if (newCohortWindowType === "all") {
                      setCreatorTableLoading(true);
                      setWindowRowLoading(true);
                      setCumulativeRowLoading(true);
                      setCohortWindowType("all");
                      setCohortRange([null, null]);
                      setLocalCohortRange([null, null]);
                      closeCohortRange();
                    } else if (newCohortWindowType === "range") {
                      openCohortRange();
                    }
                  }}
                />
                {cohortRangeOpened && (
                  <DatePickerInput
                    type="range"
                    allowSingleDateInRange
                    placeholder="Please select a range"
                    value={localCohortRange}
                    onChange={(newState) => {
                      // Always update the local value for display.
                      setLocalCohortRange(newState);
                      if (newState[0] === null || newState[1] === null) {
                        // We only care about the selection of a full range.
                        // Half-selecting a range is not actionable, so we just track it locally.
                        return;
                      }
                      setCreatorTableLoading(true);
                      setWindowRowLoading(true);
                      setCumulativeRowLoading(true);
                      // These will trigger refetching data
                      setCohortWindowType("range");
                      setCohortRange(newState);
                    }}
                  />
                )}
              </Group>
              <ButtonRow
                type="cumulative"
                stats={cumulative}
                whichSelected={selectedState}
                showLoading={isCumulativeRowLoading}
                selectFn={setSelectedCell}
                loadingStateFn={setCreatorTableLoading}
              />
              <Divider />
              <Group>
                <Title order={2} c="var(--mantine-color-gray-7)">
                  Activities
                </Title>
                <Select
                  color="blue"
                  data={["Last 7 days", "Last 30 days", "Lifetime", "Custom"]}
                  defaultValue="Last 7 days"
                  allowDeselect={false}
                  styles={{
                    input: {
                      backgroundColor: "#E7F5FF",
                      color: "#228BE6",
                    },
                  }}
                  onChange={(newWindow) => {
                    setCreatorTableLoading(true);
                    setWindowRowLoading(true);
                    let state;
                    switch (newWindow) {
                      case "Last 7 days":
                        state = "weekly";
                        break;
                      case "Last 30 days":
                        state = "monthly";
                        break;
                      case "Lifetime":
                        state = "lifetime";
                        break;
                      case "Custom":
                        state = "custom";
                        break;
                      default:
                        state = undefined;
                        break;
                    }
                    if (state !== selectedTimeframe) {
                      if (state === "custom") {
                        setCreatorTableLoading(false);
                        setWindowRowLoading(false);
                        openActivitytRange();
                      } else {
                        // One of the named ranges is selected
                        closeActivityRange();
                        setLocalActivityRange([null, null]);
                        setActivityRange([null, null]);
                        setActivityWindowType(state);
                      }
                    } else {
                      setCreatorTableLoading(false);
                      setWindowRowLoading(false);
                    }
                  }}
                />
                {activityRangeOpened && (
                  <DatePickerInput
                    type="range"
                    allowSingleDateInRange
                    placeholder="Please select a range"
                    value={localActivityRange}
                    onChange={(newState) => {
                      // Always update the local value for display.
                      setLocalActivityRange(newState);
                      if (newState[0] === null || newState[1] === null) {
                        // We only care about the selection of a full range.
                        // Half-selecting a range is not actionable, so we just track it locally.
                        return;
                      }
                      setCreatorTableLoading(true);
                      setWindowRowLoading(true);
                      setCumulativeRowLoading(false);
                      // These will trigger refetching data
                      setActivityWindowType("custom");
                      setActivityRange(newState);
                    }}
                  />
                )}
              </Group>
              <ButtonRow
                type="window"
                stats={window}
                whichSelected={selectedState}
                showLoading={isWindowRowLoading}
                selectFn={setSelectedCell}
                loadingStateFn={setCreatorTableLoading}
              />
              <Title order={2}>Creator List</Title>
              <CreatorTable creatorList={creatorList} isLoading={isCreatorTableLoading} />
            </Stack>
          </Container>
        </Stack>
      </Container>
    </Stack>
  );
};

const CampaignCreatorProgressDisplay = ({ campaign }: { campaign: typeof Campaign }) => {
  const [cumulativeStats, setCumulativeStats] =
    useState<Record<string, Record<string, number>>>(null);
  const [windowStats, setWindowStats] = useState<Record<string, Record<string, number>>>(null);
  const [selectedCell, setSelectedCell] = useState<string[]>(["window", undefined]);
  // TODO(chris): update this to use an actual start and end time. For now all windows are named.
  const [activityWindowType, setActivityWindowType] = useState<string>("weekly");
  const [activityRange, setActivityRange] = useState<[Date | null, Date | null]>([null, null]);
  const [creatorList, setCreatorList] = useState<CreatorProgressDetail[]>([]);
  const [cohortWindowType, setCohortWindowType] = useState<string>("all");
  const [cohortRange, setCohortRange] = useState<[Date | null, Date | null]>([null, null]);

  useEffect(() => {
    const abortController = new AbortController();

    // reset on campaign change so we don't see the previous campaign's content while new campaigns overview is loading
    setCumulativeStats(null);
    setWindowStats(null);
    setSelectedCell(["window", undefined]);

    // Starting out, we have to fetch both window and cumulative reports
    const cohortRangeToSend: [Date | null, Date | null] =
      cohortWindowType === "all" ? [null, null] : cohortRange;
    const activityRangeToSend: [Date | null, Date | null] =
      activityWindowType !== "custom" ? [null, null] : activityRange;
    fetchCreatorProgressOverview(
      campaign.hash_id,
      activityWindowType,
      activityRangeToSend,
      cohortRangeToSend,
      "both",
      abortController,
    ).then((campaignCreatorProgress) => {
      if (campaignCreatorProgress?.success) {
        setCumulativeStats(campaignCreatorProgress?.cumulative);
        setWindowStats(campaignCreatorProgress?.window);
        // Start off showing the window row
        setSelectedCell(["window", undefined]);
      }
    });

    return () => {
      abortController.abort();
    };
  }, [campaign?.hash_id]);

  useEffect(() => {
    const abortController = new AbortController();
    const cohortRangeToSend: [Date | null, Date | null] =
      cohortWindowType === "all" ? [null, null] : cohortRange;
    // Half-selected cohort ranges should cause nothing to happen
    if (cohortWindowType === "range" && (cohortRange[0] === null || cohortRange[1] === null)) {
      return () => {
        abortController.abort();
      };
    }
    const activityRangeToSend: [Date | null, Date | null] =
      activityWindowType !== "custom" ? [null, null] : activityRange;
    fetchCreatorProgressOverview(
      campaign.hash_id,
      activityWindowType,
      activityRangeToSend,
      cohortRangeToSend,
      "both",
      abortController,
    ).then((campaignCreatorProgress) => {
      if (campaignCreatorProgress?.success) {
        setCumulativeStats(campaignCreatorProgress?.cumulative);
        setWindowStats(campaignCreatorProgress?.window);
        if (selectedCell === null) {
          setSelectedCell(["window", undefined]);
        }
      }
    });

    return () => {
      abortController.abort();
    };
  }, [cohortWindowType, cohortRange]);

  useEffect(() => {
    const abortController = new AbortController();

    const cohortRangeToSend: [Date | null, Date | null] =
      cohortWindowType === "all" ? [null, null] : cohortRange;

    const activityRangeToSend: [Date | null, Date | null] =
      activityWindowType !== "custom" ? [null, null] : activityRange;

    // When window changes, we only need to fetch the window report
    fetchCreatorProgressOverview(
      campaign.hash_id,
      activityWindowType,
      activityRangeToSend,
      cohortRangeToSend,
      "window",
      abortController,
    ).then((campaignCreatorProgress) => {
      if (campaignCreatorProgress?.success) {
        setWindowStats(campaignCreatorProgress?.window);
      }
    });

    return () => {
      abortController.abort();
    };
  }, [activityWindowType, activityRange]);

  useEffect(() => {
    const abortController = new AbortController();
    if (selectedCell?.at(0) === undefined && selectedCell?.at(1) === undefined) {
      // Unable to fetch creators when nothing has been selected (either at the start or by the user)
      setCreatorList([]);
      return () => {
        abortController.abort();
      };
    }

    const cohortRangeToSend: [Date | null, Date | null] =
      cohortWindowType === "all" ? [null, null] : cohortRange;
    const timeframe = selectedCell?.at(0) === "window" ? activityWindowType : "cumulative";
    const activityRangeToSend: [Date | null, Date | null] =
      activityWindowType !== "custom" ? [null, null] : activityRange;
    fetchCreatorsForProgress(
      campaign.hash_id,
      cohortRangeToSend,
      timeframe,
      activityRangeToSend,
      selectedCell?.at(1),
      abortController,
    ).then((creatorListResponse) => {
      if (creatorListResponse?.success) {
        setCreatorList(creatorListResponse.creators);
      } else {
        setCreatorList([]);
      }
    });

    return () => {
      abortController.abort();
    };
  }, [selectedCell, activityWindowType, activityRange, cohortWindowType, cohortRange]);

  return (
    <CampaignCreatorProgressTable
      cumulative={cumulativeStats}
      window={windowStats}
      creatorList={creatorList}
      selectedState={selectedCell}
      selectedTimeframe={activityWindowType}
      cohortWindowType={cohortWindowType}
      setSelectedCell={setSelectedCell}
      setActivityWindowType={setActivityWindowType}
      setActivityRange={setActivityRange}
      setCohortWindowType={setCohortWindowType}
      setCohortRange={setCohortRange}
    />
  );
};

export const CampaignCreatorProgress = () => {
  const { selectedCampaign, selectedAdGroup } = useCampaignAppContext();

  if (!selectedCampaign?.hash_id) {
    return (
      <Container fluid h="100vh">
        <Center h="100%">
          <Loader color="blue" />
        </Center>
      </Container>
    );
  }

  return <CampaignCreatorProgressDisplay campaign={selectedCampaign} />;
};

export default CampaignCreatorProgress;
