import { signInWithCustomToken } from "firebase/auth";
import { auth } from "auth/firebaseAuthHelpers";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { API_URL } from "configs/Configs";
import { User } from "models/User";

const GET_BRAND_USERS_URL = `${API_URL}/api/admin/brands/users`;
const LOGIN_AS_USER_URL = `${API_URL}/brannan/login_as_user`;

export const getBrandUsers = async (
  setBrandUsers: (users: User[]) => void,
  abortController: AbortController,
) => {
  const request = await createRequestWithFirebaseToken({
    url: GET_BRAND_USERS_URL,
  });
  const response = await handleResult(request, abortController);
  if (response?.results && response?.results?.length > 0) {
    setBrandUsers(response.results);
  }
  return response;
};

export const loginAsUser = async (username: string, onSuccess: () => void) => {
  const request = await createRequestWithFirebaseToken({
    url: LOGIN_AS_USER_URL,
    method: "POST",
    body: JSON.stringify({ requested_username: username }),
  });
  const result = await handleResult(request);
  const firebaseIdToken = await signInWithCustomToken(auth, result.customToken);
  if (firebaseIdToken) {
    onSuccess();
  }
};

export default null;
