import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { User as FirebaseUser } from "firebase/auth";
import Deliverable from "components/contracts/models/Deliverable";
import { SupportedFormat } from "models/Common";

const BASE_CREATOR_URL = `${API_URL}/api/creator/`;
const LOOKUP_URL = `${BASE_CREATOR_URL}lookup/`;
const SEARCH_URL = `${BASE_CREATOR_URL}search/`;

export interface PriceMapMetrics {
  adjusted_cpm: number;
  adjusted_lower_views: number;
  adjusted_upper_views: number;
  format_weight: number;
  lower_price: number;
  lower_views: number;
  upper_price: number;
  upper_views: number;
  views_adjustment: number;
}
export interface PriceMap {
  [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: PriceMapMetrics;
  [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: PriceMapMetrics;
  [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: PriceMapMetrics;
  [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: PriceMapMetrics;
  [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: PriceMapMetrics;
  [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: PriceMapMetrics;
  [SupportedFormat.YOUTUBE_SHORT]: PriceMapMetrics;
  // UGC doesn't make sense here but we need an entry for the format.
  [SupportedFormat.UGC]: PriceMapMetrics;
}

const priceCache: Record<string, any> = {};

export const fetchCreatorById = async (
  requestUser: FirebaseUser,
  creatorId: number,
  abortController: AbortController,
) => {
  const firebaseToken = await requestUser.getIdToken();
  const endpoint = `${LOOKUP_URL}?creatorId=${creatorId}`;
  const request = new Request(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    signal: abortController.signal,
  });

  const response = await handleResult(request);
  return response;
};

export const fetchCreatorByQuery = async (
  query: string,
  abortController: AbortController = null,
) => {
  const endpoint = `${SEARCH_URL}?query=${query}`;
  const request = await createRequestWithFirebaseToken({ url: endpoint, method: "GET" });
  const response = await handleResult(request, abortController);
  return response;
};

export const fetchPricing = async (
  creatorId: number,
  cpm: number,
  idList: Record<string, string>,
  abortController: AbortController,
  deliverables?: Deliverable[],
) => {
  const endpoint = new URL(`${BASE_CREATOR_URL}get_price`);
  endpoint.searchParams.append("creatorId", creatorId.toString());
  endpoint.searchParams.append("cpm", cpm.toString());
  Object.entries(idList).forEach(([key, value]) => {
    endpoint.searchParams.append(key, value);
  });

  if (deliverables && deliverables.length > 0) {
    const serializedDeliverables = deliverables.map((deliverable) => JSON.stringify(deliverable));
    endpoint.searchParams.append("deliverables", JSON.stringify(deliverables));
  }

  const request = await createRequestWithFirebaseToken({ url: endpoint, method: "GET" });

  const response = await handleResult(request, abortController);
  return response;
};

// TODO: does this live here or in the component?
export const memoizedFetchPricing = async (
  opportunityId: number,
  creatorId: number,
  cpm: number,
  idList: Record<string, string>,
  abortController: AbortController,
) => {
  if (priceCache[opportunityId]) {
    return priceCache[opportunityId];
  }
  const pricePromise = fetchPricing(creatorId, cpm, idList, abortController);
  priceCache[opportunityId] = pricePromise;
  return pricePromise;
};

export default null;
