import React from "react";

import { Card, Group } from "@mantine/core";

import { TaskStage } from "components/contracts/tasks/models/Common";
import { Task } from "components/contracts/tasks/models/Task";

import { TaskTypeBadge } from "components/contracts/tasks/cards/ContractCard";
import TaskCard from "components/contracts/tasks/cards/TaskCard";

export default function ContractReviewCard({
  primaryTask,
  taskType,
  handleCompleteTask,
  isCompletedTask,
}: {
  primaryTask: Task;
  taskType: TaskStage;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
  isCompletedTask?: boolean;
}) {
  return (
    <Card p="sm" radius="md" withBorder bg="#F8F9FA">
      <Group justify="right">
        <TaskTypeBadge taskType={taskType} />
      </Group>
      <TaskCard
        task={primaryTask}
        taskType={taskType}
        isCompletedTask={isCompletedTask}
        handleCompleteTask={handleCompleteTask}
      />
    </Card>
  );
}
