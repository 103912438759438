import React from "react";

import { Center, Grid, Text } from "@mantine/core";

import { CreatorInfo } from "components/creator_lists/CreatorListsUtils";
import Video from "components/creator_lists/unified_creator_rep/Video";
import { CreatorDetails } from "components/discovery/Datamodels";
import { MediaEntry } from "components/MediaBlockCard";

// Make this 12 if multiple platforms and 18 if single platform
const MAX_VIDEOS_TO_DISPLAY = 15;

const AdminCreatorVideo = ({ video }: { video: MediaEntry }) => {
  return <Video video={video} refetchThumbnails />;
};

export const AdminCreatorLabelingVideos = ({
  creatorId,
  creatorDetails,
  creatorInfo,
}: {
  creatorId: number;
  creatorDetails: CreatorDetails;
  creatorInfo: CreatorInfo;
}) => {
  const { videos } = creatorInfo;
  if (videos.length === 0) {
    return (
      <Center>
        <Text>No recent videos found</Text>
      </Center>
    );
  }
  return (
    <Grid columns={10}>
      {videos.slice(0, MAX_VIDEOS_TO_DISPLAY).map((video) => (
        <Grid.Col span="content" key={video.video_id}>
          <AdminCreatorVideo video={video} />
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default AdminCreatorLabelingVideos;
