import React, { useEffect, useState } from "react";

import { Campaign } from "models/Campaign";
import { Icon, IconProps, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import BasicNavButton from "campaigns/navbar/BasicNavButton";
import { ActionIcon, Badge } from "@mantine/core";
import { getTextWidth } from "campaigns/helpers/mainNavButtonHelpers";

import { useAppSelector } from "reduxStore/hooks";

const CampaignNavButton = ({
  campaign,
  isSelected,
  numTodos,
  onClick,
  onToggleAdGroups,
  shouldShowAdGroups,
}: {
  campaign: Campaign;
  isSelected: boolean;
  numTodos: number | null;
  onClick: (campaign: Campaign) => void;
  onToggleAdGroups: (campaignHashId: number, newValue: boolean) => void;
  shouldShowAdGroups: boolean;
}) => {
  const ChevronIconName: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  > = shouldShowAdGroups ? IconChevronUp : IconChevronDown;

  const user = useAppSelector((state) => state.me.user);

  // get staff flag
  const [isStaff, setIsStaff] = useState<boolean>(user?.is_staff || false);

  useEffect(() => {
    setIsStaff(user?.is_staff || false);
  }, [user]);

  return (
    <BasicNavButton
      isSelected={isSelected}
      text={isStaff ? `${campaign.title} (${campaign?.brand?.display_name})` : campaign.title}
      leftSection={
        // This is the icon
        <ActionIcon
          variant="transparent"
          component="div"
          size={26}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation(); // allow toggle of showing ad groups to be separate from selecting campaign button

            const newShouldShowAdGroups = !shouldShowAdGroups;
            onToggleAdGroups(campaign.hash_id, newShouldShowAdGroups);
          }}>
          {campaign.ad_groups?.length > 0 ? (
            <ChevronIconName
              height={14}
              width={14}
              color={isSelected ? "var(--mantine-color-white)" : "var(--mantine-color-black)"}
              stroke={3}
            />
          ) : null}
        </ActionIcon>
      }
      rightSection={
        numTodos ? (
          <Badge size="sm" color="red" miw={getTextWidth(numTodos.toString()) + 15}>
            {numTodos}
          </Badge>
        ) : null
      }
      onButtonClick={() => onClick(campaign)}
    />
  );
};
export default CampaignNavButton;
